import api from "../api";

class NotificationTypesService {
    //to get all account types
    async getAllNotificationTypes() {
        return new Promise((res, reject) => {
            api
                .get("/salesNotificationTypes")
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getTypeById(id) {
        return new Promise((res, reject) => {
            api
                .get("/salesNotificationTypes/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createNotificationType(type) {
        return api.post("/salesNotificationTypes", type);
    }

    async updateNotificationType(type) {
        return new Promise((res, reject) => {
            api
                .put("/salesNotificationTypes/" + type.id, type)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async deleteNotificationType(id){
        return new Promise( (res, reject) => {
            api.delete("/salesNotificationTypes/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                })
        })
    }

    async getNotificationsForPCR(id, date){
        let params = {
            pcr: id,
            messageDate: date
        }

        return new Promise((res, reject) => {
            api.get("/salesNotificationsPCR/", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                })
        })
    }
}

const notificationTypeService = new NotificationTypesService();

export default notificationTypeService;
