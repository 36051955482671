import React from "react";
import {ResponsiveBar} from '@nivo/bar'
import {Col, MDBCol, MDBRow} from "mdbreact";
import RegionService from "../../Seating/Settings/regionServices";
import {Card} from "@material-ui/core";
import Select from "react-select";


export default class ColumnPipeline extends React.Component {
    constructor(props) {
        super(props);

        this.sortByRegion = props.props.sortByRegion

        this.state = {
            data: props.props.pro,
            filteredData: props.props.pro,
            currentType: "",
            median: props.props.median,
            totals: props.props.totals,
            regions: []
        };
    }

    componentDidMount() {
        this.retrieveRegions()
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.props.pro !== this.state.data){
            this.setState({
                data: nextProps.props.pro,
                filteredData: nextProps.props.pro,
                median: nextProps.props.median,
                totals: nextProps.props.totals,
                currentType: "",
            })
        }
        return true;
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(prevProps.props.pro !== prevState.data){
    //
    //     }
    // }

    clickFunction = (data) => {
        this.reformatData(data.id);
    }


    retrieveRegions() {
        return RegionService.getAllRegions()
            .then((res) => {
                let arr = [];

                res.forEach((region) => {
                    arr.push({
                        label: region.name,
                        value: region.id,
                    });
                });

                // arr.push({
                //     label: "All Regions",
                //     value: -1,
                // })
                this.setState({
                    regions: arr,
                });
            })
            .catch((err) => {
            });

    }

    handleSelectChange = (e) => {
        this.setState({
                selectedRegion: e.value,
            },
            () => {
                this.regionChanged(e.value);
            });
    };


    renderRegionSelect() {
        return (
            <Select
                placeholder="Region"
                options={this.state.regions.length !== 0 ? this.state.regions : [{label: "Loading Regions"}]}
                onChange={this.handleSelectChange.bind(this)}
            />

        )
    }


    regionChanged(region) {
        this.sortByRegion(region)
    }

    reformatData(type) {
        let od = this.state.data,
            nd = [],
            medianValues = [];

        if (type === this.state.currentType) {

            this.setState({
                currentType: "",
                median: this.props.props.median,
                filteredData: od
            });
            return;
        }

        let propA,
            propB;

        switch (type) {
            case "New":
                propA = "New";
                propB = "NewData";
                break;
            case "InProcess":
                propA = "InProcess";
                propB = "InProcessData";
                break;
            case "Ready to Deliver":
                propA = "Ready to Deliver";
                propB = "Ready to DeliverData"
                break;
            case "Setup":
            default:
                propA = "Setup";
                propB = "SetupData"
                break;
        }

        od.forEach(r => {
            let obj = {
                'Sales Location': r["Sales Location"],
                [propA]: r[propA],
                [propB]: r[propB],
                TotalPoints: r[propA],
            }

            nd.push(obj);
            medianValues.push(r[propA]);
        })

        nd.sort((a, b) => {
            return a.TotalPoints > b.TotalPoints ? -1 : 1;
        });

        if(nd.length === 0){
            return;
        }

        this.setState({
            filteredData: nd,
            currentType: type,
            median: nd[Math.floor(medianValues.length / 2)].TotalPoints
        })

    }

    customTooltip = (d) => {
        let data = d.data[d.id + "Data"];

        let fd = data.filter(p => {
            return p.points > 0
        });

        return (
            <div style={{height: 275, width: 350, backgroundColor: 'black'}}>
                <MDBRow style={{justifyContent: 'space-around', textAlign: 'center'}}>
                    <MDBCol size={12} style={{color: "#FFF"}}>
                        {d.indexValue + " -- " + d.id}
                    </MDBCol>
                </MDBRow>
                <ResponsiveBar
                    data={fd}
                    keys={['points']}
                    indexBy={"salesRep"}
                    margin={{top: 20, right: 0, bottom: 100, left: 60}}
                    labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                    colors={{scheme: 'category10'}}
                    colorBy={'index'}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: -36,
                        legend: 'Sales Rep',
                        legendPosition: 'middle',
                        legendOffset: 60
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Points',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    theme={{
                        textColor: "#FFF",
                    }}
                />
            </div>
        )
    }

    render() {
        let p = this.props,
            t = this.state.totals;

        let lgs = `New: ${t.newTotal}  In Process: ${t.processTotal}  Ready to Deliver: ${t.rtdTotal}  Setup: ${t.setupTotal}`

        return (
            <div>
                <MDBRow>
                    <MDBCol size={4}>
                        {this.renderRegionSelect()}
                    </MDBCol>
                </MDBRow>
                <MDBRow style={{textAlign: "center"}}>
                    <MDBCol style={{
                        color: "#418CBF",
                        fontWeight: 'bold',
                        border: "3px solid #418CBF",
                        borderRadius: 3,
                        margin: 5
                    }}>
                        New: {t.newTotal}
                    </MDBCol>
                    <MDBCol style={{
                        color: "#FF9333",
                        fontWeight: 'bold',
                        border: "3px solid #FF9333",
                        borderRadius: 3,
                        margin: 5
                    }}>
                        In Process: {t.processTotal}
                    </MDBCol>
                    <MDBCol style={{
                        color: "#4CAF4C",
                        fontWeight: 'bold',
                        border: "3px solid #4CAF4C",
                        borderRadius: 3,
                        margin: 5
                    }}>
                        Ready to Deliver: {t.rtdTotal}
                    </MDBCol>
                    <MDBCol style={{
                        color: "#DC4849",
                        fontWeight: 'bold',
                        border: "3px solid #DC4849",
                        borderRadius: 3,
                        margin: 5
                    }}>
                        Setup: {t.setupTotal}
                    </MDBCol>
                </MDBRow>
                <div style={{height: window.innerHeight * .9}}>
                    <ResponsiveBar
                        data={this.state.filteredData}
                        tooltip={(e) => this.customTooltip(e)}
                        enableLabel={true}
                        keys={['New', 'InProcess', 'Ready to Deliver', 'Setup']}
                        indexBy="Sales Location"
                        margin={{top: 0, right: 130, bottom: 150, left: 60}}
                        padding={0.15}
                        valueScale={{type: 'linear'}}
                        indexScale={{type: 'band', round: true}}
                        colors={{scheme: 'category10'}}

                        markers={[
                            {
                                axis: 'y',
                                value: this.state.median,
                                lineStyle: {stroke: 'rgba(0, 0, 0, .35)', strokeWidth: 2},
                                legend: (this.state.currentType || "New") + ' Median Points: ' + this.state.median,
                                legendOrientation: 'vertical',
                            },
                        ]}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -40,
                            legend: "Sales Location",
                            legendPosition: 'middle',
                            legendOffset: 80
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Points',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                        legends={[
                            {
                                onClick: (data) => this.clickFunction(data),
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        theme={{
                            tooltip: {
                                top: 300,
                                anchor: "right",
                                container: {
                                    background: '#555',
                                },
                            },
                        }}
                    />

                </div>
                <MDBRow>
                    <MDBCol>
                        Data displayed is for the past 60 days; Setup points are for current month only.
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }
}