import React from "react";
import { MDBContainer, Spinner } from "mdbreact";
import OrderService from "../Security/OrderService/orderService";

export default class DeliveryManifest extends React.Component {
	constructor(props) {
		super(props);
		let id = "";
		if (props.match && props.match.params) {
			id = props.match.params.id;
			this.retrieveOrder(id);
		}

		this.state = {
			order: {},
			isLoaded: false,
		};
	}

	retrieveOrder(id) {
		return OrderService.getActivity(id).then((data) => {
			this.setState({
				order: data,
				isLoaded: true,
			});
			this.renderDeliveryManifest();
		});
	}

	renderDeliveryManifest() {
		let o = this.state.order;
		return (
			<div>
				<h2>{o.patientName}</h2>
				<p>{o.address + " " + o.city + ", " + o.state + " " + o.zipCode}</p>
				<p>
					<b>Order ID: </b>
					{o.id}
				</p>
				<p>
					{" "}
					<b>Setup Date: </b>{" "}
					{new Date(o.setupDate).toLocaleDateString() || "NO SETUP DATE"}
				</p>
				<table style={{ width: "100%" }}>
					<thead>
						<tr style={{ padding: "5%" }}>
							<th>Category</th>
							<th>Product</th>
							<th>HCPCS</th>
							<th>Quantity</th>
							<th>Serial Number</th>
						</tr>
					</thead>
					<tbody>
						{o.products.map((product, index) => {
							if (product.approved) {
								return (
									<tr style={{ padding: "5%" }}>
										<td>{product.productType.name || "NO CATEGORY NAME"}</td>
										<td>{product.productSubType.name || "NO PRODUCT NAME"}</td>
										<td>{product.hcpcs || "NO HCPCS"}</td>
										<td>{product.qty || "NO QUANTITY"}</td>
										<td>{product.serial || "NO SERIAL NUMBER"}</td>
									</tr>
								);
							} else {
								return null;
							}
						})}
					</tbody>
				</table>
			</div>
		);
	}

	renderLoadingSpinner() {
		return (
			<MDBContainer>
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</MDBContainer>
		);
	}

	render() {
		if (!this.state.isLoaded) {
			return this.renderLoadingSpinner();
		}
		return (
			<div style={{ backgroundColor: "white", margin: "5%" }}>
				{this.renderDeliveryManifest()}
			</div>
		);
	}
}
