import api from "../api";

class PatientOrdersService {

	// calls proc spGetPatientProducts
	async getPatientOrders(patientId) {
		return new Promise((res, reject) => {
			api
				.get("/patientOrders", patientId)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const patientOrdersService = new PatientOrdersService();

export default patientOrdersService;
