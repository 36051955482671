import React from "react";
import { Button, DataTable, Container, Spinner, MDBIcon } from "mdbreact";
import ReportsService from "../Security/ReportsService/reportsService";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

export default class deliveryReport extends React.Component {
	constructor(props) {
		super(props);

		let filters = this.determineFilters(props.filters);
		let topFilters = props.topFilters;

		//table headers & columns
		const data = {
			columns: [
				{
					label: "Technician",
					field: "user",
					sort: "asc",
					width: 200,
				},
				{
					label: "Service Location",
					field: "serviceLocation",
					sort: "asc",
					width: 200,
				},
				{
					label: "New (Total)",
					field: "total_new",
					sort: "asc",
					width: 200,
				},
				{
					label: "Pickup (Total)",
					field: "total_pickup",
					sort: "asc",
					width: 200,
				},
				{
					label: "Service (Total)",
					field: "total_service",
					sort: "asc",
					width: 200,
				},
				{
					label: "Exchange (Total)",
					field: "total_exchange",
					sort: "asc",
					width: 200,
				},
				{
					label: "Total (All)",
					field: "total_all",
					sort: "asc",
					width: 200,
				},
			],
			rows: [],
		};

		this.state = {
			allDeliveries: [],
			deliveriesData: data,
			startDate: new Date(filters.startDate),
			endDate: new Date(filters.endDate),
			isLoaded: true,
			csvFunction: props.csvFunction,
			topFilters: topFilters,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		techs: PropTypes.array,
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Get the most up-to-date filters passed down from parent
		if (
			JSON.stringify(this.props.topFilters) !==
			JSON.stringify(this.state.topFilters)
		) {
			this.setState({
				topFilters: this.props.topFilters,
			});
		}
		// Checks if the generate reports button has been clicked...JK
		if (prevProps.generateClicked !== this.props.generateClicked) {
			this.getDeliveryReport();
		}
	}

	determineFilters(userFilters) {
		let d = new Date(),
			filters = {};

		if (userFilters) {
			if (userFilters.startDate) {
				filters.startDate = new Date(userFilters.startDate);
			} else {
				filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			}

			if (userFilters.endDate) {
				filters.endDate = new Date(userFilters.endDate);
			} else {
				filters.endDate = d;
			}
		} else {
			filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			filters.endDate = d;
		}

		return filters;
	}

	getDeliveryReport() {
		const { currentUser, techs } = this.context;
		let st = this.state,
			reps = [],
			tp = st.topFilters;
		let filters = {
			startDate: tp.startDate,
			endDate: tp.endDate,
		};

		try {
			filters = {
				startDate: new Date(tp.startDate).toLocaleDateString(),
				endDate: new Date(tp.endDate).toLocaleDateString(),
			};
		} catch {
			// toast.error("Please check your dates.");
			return;
		}

		// Have to check len since showDatePicker defaults to false at startup
		if (!tp.showDatePicker && tp.quickFilter.length > 0) {
			filters.startDate = tp.quickFilter[0].toLocaleDateString();
			filters.endDate = tp.quickFilter[1].toLocaleDateString();
		} else {
			// Check to make sure they have selected a date and the dates aren't set to the default if not using the quickfilter
			if (
				filters.startDate === "12/31/1969" ||
				filters.endDate === "12/31/1969"
			) {
				// toast.error('Please check your dates');
				return;
			}
		}
		// Get users in the sales rep dropdown and add to the filter
		if (tp.techsSelected.length > 0) {
			filters.users = tp.techsSelected;
		}
		// Only default to getting all users if they have the correct permissions
		else if (
			currentUser.role === "ADMIN" ||
			currentUser.role === "SYSTEM_ADMIN" ||
			currentUser.role === "EXECUTIVE_MANAGEMENT" ||
			currentUser.role === "FINANCE"
		) {
			techs.forEach((rep) => {
				reps.push(rep.id);
			});

			filters.users = reps;
		}

		this.setState({ isLoaded: false });

		return ReportsService.getDeliveryReport(filters)
			.then((res) => {
				this.setState({
					allDeliveries: res,
				});

				this.formatRows();
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	formatRows() {
		let deliveries = this.state.allDeliveries,
			ary = [],
			dt = this.state.deliveriesData;

		deliveries.forEach((delivery) => {
			ary.push({
				user: delivery.technician,
				serviceLocation: delivery.serviceLocation,
				total_new: delivery.newOrders ? parseInt(delivery.newOrders) : 0,
				total_pickup: delivery.pickupOrders
					? parseInt(delivery.pickupOrders)
					: 0,
				total_service: delivery.serviceOrders
					? parseInt(delivery.serviceOrders)
					: 0,
				total_exchange: delivery.exchangeOrders
					? parseInt(delivery.exchangeOrders)
					: 0,
				total_all: delivery.totalOrders ? parseInt(delivery.totalOrders) : 0,
			});
		});
		dt.rows = ary;

		this.setState({
			deliveriesData: dt,
			isLoaded: true,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	//load data in the table
	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div>
				<DataTable
					entriesOptions={[25, 50, 100]}
					pagesAmount={10}
					data={this.state.deliveriesData}
					responsive
					fixed
					striped
					bordered
				></DataTable>

				<Button
					style={{ float: "right" }}
					color={"blue"}
					floating
					size="sm"
					data-tip={"Download CSV"}
					onClick={this.state.csvFunction.bind(
						this,
						this.state.deliveriesData,
						"deliveryExport.csv"
					)}
				>
					<MDBIcon icon="download" style={{ fontSize: "2em" }} />
				</Button>
				<ReactTooltip />
			</div>
		);
	}
}
