import React from "react";
import {
	Button,
	CardBody,
	Col,
	Input,
	InputFile,
	MDBBtn,
	MDBCard,
	MDBCol,
	MDBIcon,
	MDBInput,
	MDBRow,
	MDBSelect,
	MDBSelectInput,
	MDBSelectOption,
	MDBSelectOptions,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Spinner,
	toast,
} from "mdbreact";
import PropTypes from "prop-types";
import OrderService from "../../Security/OrderService/orderService";
import ReactTooltip from "react-tooltip";
import DualListBox from "react-dual-listbox";

export default class AttachmentsTab extends React.Component {
	constructor(props) {
		super(props);

		this.updateParent = props.updateParent;

		this.state = {
			eOrder: props.eOrder,
			deleteModalOpen: false,
			mergeModalOpen: false,
			modalFile: {},
			documentType: "",
			documentTypes: [],
			otherDocType: "",
			addTimeStamp: true,

			thumbLoading: false,
			thumbnails: [],
			retrievedImages: [],
			selectedDocuments: [],
			formActivePanel1: 1,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		documentTypes: PropTypes.array,
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.eOrder !== prevState.eOrder) {
			return { eOrder: nextProps.eOrder };
		} else return null;
	}

	async getDocumentImage(id) {
		let tRef = this;

		return new Promise((resolve, reject) => {
			OrderService.getDocumentThumbnails(id)
				.then((res) => {
					if (res) {
						let obj = {
							id: id,
							data: res.map((r) => {
								return {
									image: r,
									included: true,
								};
							}),
						};
						resolve(obj);
					}
				})
				.catch((err) => {
					// need index in case we can't get the actual image for the doc...BC
					// let idx = tRef.state.selectedDocuments.indexOf(id);

					toast.error(
						"Unable to add document to packet. Ensure documents are of type '.pdf' "
					);

					let sel = tRef.state.selectedDocuments;

					tRef.setState({
						selectedDocuments: sel,
					});

					resolve();
				});
		});
	}

	async changeMergeDocumentSelection(items) {
		//Set the selected documents to be the current selection...BC
		this.setState({
			selectedDocuments: items,
		});

		//diff should be the items that have not been retrieved
		let retrieved = this.state.retrievedImages,
			diff = items.filter((a) => {
				return retrieved.indexOf(a) < 0;
			});

		let img = [];

		this.setState({
			thumbLoading: true,
		});

		for (let id of diff) {
			let val = await this.getDocumentImage(id);

			//do not push this again if we already have it...BC
			if (val && retrieved.indexOf(id) === -1) {
				img.push(val);
				retrieved.push(id);
			}
		}

		let fin = this.state.thumbnails.concat(img);

		this.setState({
			thumbLoading: false,
			thumbnails: fin,
			retrievedImages: retrieved,
		});
	}

	formatDate(date) {
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "short",
			day: "2-digit",
			hour: "numeric",
			minute: "numeric",
		}).format(date);
	}

	changePageStatus = (index, itemNum) => {
		let thumbs = this.state.thumbnails;
		thumbs[itemNum].data[index].included = !thumbs[itemNum].data[index]
			.included;
		this.setState({ thumbnails: thumbs });
	};

	toggleMergeModalPopup() {
		let open = this.state.mergeModalOpen,
			thumbs = this.state.thumbnails;

		thumbs.forEach((x) => {
			x.data.forEach((y) => {
				y.included = true;
			});
		});
		let dt = new Date(),
			mo =
				dt.getMonth() < 10
					? "0" + (dt.getMonth() + 1).toString()
					: dt.getMonth() + 1,
			dy = dt.getDate() < 10 ? "0" + dt.getDate().toString() : dt.getDate(),
			ho = dt.getHours() < 9 ? "0" + dt.getHours().toString() : dt.getHours(),
			mi =
				dt.getMinutes() < 9
					? "0" + dt.getMinutes().toString()
					: dt.getMinutes(),
			se =
				dt.getSeconds() < 9
					? "0" + dt.getSeconds().toString()
					: dt.getSeconds(),
			newName = `${
				this.state.eOrder.id
			}_${dt.getFullYear()}${mo}${dy}_${ho}${mi}${se}_MergedDocuments`;

		if (open) {
			this.setState({
				selectedDocuments: [],
				rearrangeData: [],
				formActivePanel1: 1,
				formActivePanel1Changed: false,
			});
		}

		this.setState({
			mergeModalOpen: !open,
			thumbLoading: false,
			documentPreview: null,
			mergeDocumentName: newName,
			thumbnails: thumbs,
		});
		ReactTooltip.rebuild();
	}

	toggleDeleteModalPopup(file) {
		let currentState = this.state.deleteModalOpen;

		this.setState({
			deleteModalOpen: !currentState,
			modalFile: file,
		});
	}

	handleOtherDoc(e) {
		this.setState({ otherDocType: e.target.value });
	}

	handleDocumentTypeChange = (e) => {
		if (e.length === 0) {
			return;
		}

		if (e[0] === "Completed PPW") {
			this.toggleMergeModalPopup();
		} else {
			this.setState({
				documentType: e[0],
			});
		}
	};

	checkBoxChange = (event) => {
		this.setState({ addTimeStamp: event.target.checked });
	};

	fileInputHandler(files) {
		let o = this.state.eOrder,
			pat = o.patient,
			docType = this.state.documentType,
			fileName = "";

		let firstName = pat.firstName || "",
			lastName = pat.lastName || "",
			pName = firstName + "_" + lastName;

		if (docType === "Other") {
			fileName = pName + "-" + this.state.otherDocType;
		} else {
			fileName = pName + "-" + docType;
		}

		function fin(t, res) {
			const order = t.state.eOrder;
			const {currentUser} = t.context;

			res.uploadedBy = {
				id: currentUser.id,
				name: currentUser.firstname + " " + currentUser.lastname
			};

			order.files.push(res);
			t.setState({
				eOrder: order,
				documentType: "",
				otherDocType: "",
			});
			t.updateParent(order);
		}

		if (!this.state.addTimeStamp) {
			return OrderService.uploadDocument(o, files, fileName)
				.then((res) => {
					fin(this, res);
				})
				.catch((err) => {
					toast.warn("Upload Unsuccessful");
				});
		} else {
			return OrderService.uploadDocumentWithStamp(o, files, fileName)
				.then((res) => {
					fin(this, res);
				})
				.catch((err) => {
					toast.warn("Upload Unsuccessful");
				});
		}
	}

	fileDownloadHandler(attachment) {
		return OrderService.downloadDocument(attachment.id).then((res) => {
			let file = new File([res], attachment.filename);
			let url = window.URL.createObjectURL(file);
			let temp = document.createElement("a");
			temp.href = url;
			temp.setAttribute("download", attachment.filename);
			temp.click();
			window.URL.revokeObjectURL(url);
		});
	}

	deleteFile(file) {
		let files = this.state.eOrder.files,
			fIndex = files.findIndex((f) => {
				return f.id === file.id;
			});

		files.splice(fIndex, 1);

		return OrderService.deleteDocument(file.id)
			.then((res) => {
				this.toggleDeleteModalPopup();
				let ord = this.state.eOrder;

				ord.files = files;
				this.setState({ eOrder: ord });
				this.updateParent(ord);
			})
			.catch((e) => {
				this.toggleDeleteModalPopup();
				toast.error("There was an error trying to delete the file");
			});
	}

	getModalHeaderName() {
		let v = this.state.formActivePanel1;
		if (v === 1) {
			return "Select Documents";
		} else if (v === 2) {
			return "Re-order Pages";
		} else {
			return "Name Packet";
		}
	}

	handleRearrange = (direction) => {
		let files = this.state.rearrangeData,
			indx = files.findIndex((file) => {
				return file.selected;
			});
		if (
			indx === -1 ||
			(direction === -1 && indx === 0) ||
			(direction === 1 && indx === files.length - 1)
		) {
			return;
		}
		let temp = files.splice(indx + direction, 1);
		files.splice(indx, 0, temp[0]);
		this.setState({
			rearrangeData: files,
		});
	};

	handleStepperNext = () => {
		let files = [],
			st = this.state;

		let thumbs = this.state.thumbnails.filter((t) => {
			return this.state.selectedDocuments.indexOf(t.id) > -1;
		});

		thumbs = thumbs.sort(function (a, b) {
			return (
				st.selectedDocuments.indexOf(a.id) - st.selectedDocuments.indexOf(b.id)
			);
		});

		thumbs.map((item) => {
			return item.data.map((dat, indx) => {
				if (dat.included) {
					return files.push({
						fileId: item.id,
						pageIndeces: indx + 1,
						selected: false,
						data: dat.image,
					});
				}
				return null;
			});
		});

		this.setState({
			formActivePanel1: 2,
			formActivePanel1Changed: true,
			rearrangeData: files,
		});
	};

	swapFormActive = (a) => (param) => (e) => {
		this.setState({
			["formActivePanel" + a]: param,
			["formActivePanel" + a + "Changed"]: true,
		});
	};

	handleNextPrevClick = (a) => (param) => (e) => {
		this.setState({
			["formActivePanel" + a]: param,
			["formActivePanel" + a + "Changed"]: true,
		});
	};

	calculateAutofocus = (a) => {
		if (this.state["formActivePanel" + a + "Changed"]) {
			return true;
		}
	};

	handleImageSelect = (fileIndex) => {
		let files = this.state.rearrangeData;
		files.map((f, index) => {
			if (index === fileIndex) {
				f.selected = !f.selected;
			} else {
				f.selected = false;
			}
			return f;
		});
		this.setState({
			rearrangeData: files,
		});
	};

	onSelectedItemChanged = (event) => {
		event.preventDefault();
		let selectedDoc = this.state.documentPreview;

		if (event.target) {
			let id = event.target.getAttribute("data-real-value");
			let par = document.getElementById("mergeList-selected");
			let val = par.value;

			// double click can add/remove items, need to make sure the selected item is still in the listbox
			if (val || val === id) {
				selectedDoc = val.toString();
			} else {
				selectedDoc = null;
			}
		}
		this.setState({
			documentPreview: selectedDoc,
		});
	};

	submitMergeAttachments = () => {
		let st = this.state,
			docs = st.selectedDocuments,
			order = st.eOrder,
			name = st.mergeDocumentName,
			data = st.rearrangeData,
			fil = order.files,
			element7 = null,
			dpd = null;

		dpd = fil.find(
			(x) =>
				docs.includes(x.id) &&
				x.filename.toLowerCase().indexOf("signed dpd") >= 0
		);
		element7 = fil.find(
			(f) =>
				docs.includes(f.id) &&
				f.filename.toLowerCase().indexOf("7 element") >= 0
		);

		if (dpd != null && element7 != null) {
			if (dpd.uploadedAt < element7.uploadedAt) {
				toast.error(
					"The DPD document needs to be uploaded after the 7 Element."
				);
				return;
			}
			// Get last occuraance of the 7 Element
			let ele7Index = -1;
			for (let i = data.length - 1; i >= 0; i--) {
				if (data[i].fileId === element7.id) {
					ele7Index = i;
					break;
				}
			}
			//Get first occurance of the DPD
			let dpdIndex = data.findIndex((f) => {
				return f.fileId === dpd.id;
			});
			// If first occurance of DPD is less than last occurance of 7 Element then you know its wrong...JK
			if (dpdIndex < ele7Index) {
				toast.error(
					"Error on page " +
						(dpdIndex + 1) +
						". The DPD document must be merged after the 7 Element"
				);
				return;
			}
		}

		data.map((f, index) => {
			delete f.data;
			delete f.selected;
			f.pageIndex = index;
			return f;
		});

		let dist = [...new Set(data.map((f) => f.fileId))];
		let params = {
			documents: dist,
			upload: data,
		};

		OrderService.mergePagedDocuments(order, params, name)
			.then((res) => {
				const eorder = this.state.eOrder;
				eorder.files.push(res);
				this.setState({
					selectedDocuments: [],
					eOrder: eorder,
				});
				this.toggleMergeModalPopup();
			})
			.catch((err) => {
				console.log(err);
				toast.error("An error occurred while merging the documents.");
			});
	};

	renderFileInput() {
		const { currentUser } = this.context;

		let d = this.state.documentType,
			tf = this.state.addTimeStamp;

		if (d) {
			if (d === "Other" && !this.state.otherDocType) {
				return <div />;
			} else {
				return (
					<MDBRow>
						{!["SALES", "SALES_MANAGER"].includes(currentUser.role) ? (
							<MDBCol size="12" style={{ marginBottom: 18 }}>
								<Input
									id={"chkAddStamp"}
									checked={tf}
									type="checkbox"
									label={"Add Timestamp"}
									onChange={this.checkBoxChange.bind(this)}
								/>
							</MDBCol>
						) : (
							""
						)}

						<MDBCol size="12">
							<InputFile
								type="file"
								name="file"
								getValue={this.fileInputHandler.bind(this)}
							/>
						</MDBCol>
					</MDBRow>
				);
			}
		} else {
			return <div />;
		}
	}

	renderDeleteConfirmation() {
		let f = this.state.modalFile;

		if (!f) {
			return;
		}

		return (
			<div>
				<Modal
					centered
					className={"orderTasksModal"}
					isOpen={this.state.deleteModalOpen}
					toggle={() => this.toggleDeleteModalPopup()}
				>
					<ModalBody>
						<CardBody>
							<h5>Are you sure you want to delete this file?</h5>
							<br />
							{f.filename}

							<div style={{ paddingTop: 6 }}>
								<MDBIcon
									style={{ float: "right", padding: 8, color: "#4caf50" }}
									size={"2x"}
									icon={"check"}
									onClick={() => this.deleteFile(f)}
									data-tip={"Delete File"}
								/>
								<MDBIcon
									style={{ float: "right", padding: 8, color: "#f44336" }}
									size={"2x"}
									icon={"times"}
									onClick={() => this.toggleDeleteModalPopup()}
									data-tip={"Cancel"}
								/>
							</div>
						</CardBody>
					</ModalBody>
					<ReactTooltip />
				</Modal>
			</div>
		);
	}

	renderAttachmentButtons(file) {
		const { currentUser } = this.context,
			t = this;

		function renderDeleteButton() {
			let canDelete = false;

			if (currentUser.role === "ADMIN" || currentUser.role === "SYSTEM_ADMIN") {
				canDelete = true;
			} else if (currentUser.id === file.uploadedBy.id) {
				let d = new Date();
				if (file.uploadedAt + 86400000 > d) {
					//this is within a 24 hour period...BC they own it and can delete
					canDelete = true;
				}
			}

			if (canDelete) {
				return (
					<MDBIcon
						size={"2x"}
						style={{ color: "#6B7A85", float: "right", padding: 8 }}
						data-tip={"Delete File"}
						onClick={() => t.toggleDeleteModalPopup(file)}
						icon={"trash"}
					/>
				);
			}
		}

		return (
			<div>
				{renderDeleteButton()}
				<MDBIcon
					size={"2x"}
					style={{ color: "#6B7A85", padding: 8, float: "right" }}
					onClick={() => this.fileDownloadHandler(file)}
					data-tip={"Download"}
					icon="cloud-download-alt"
				/>
			</div>
		);
	}

	generateAttachmentCard(file) {
		return (
			<MDBRow>
				<MDBCol size="9">
					<MDBRow style={{ paddingBottom: 4 }}>
						<MDBCol size="12" style={{ fontSize: ".9em" }}>
							{file.filename}
						</MDBCol>
					</MDBRow>
					<MDBRow style={{ fontSize: ".9em", color: "#6B7A85" }}>
						<MDBCol size="6">{this.formatDate(file.uploadedAt)}</MDBCol>
						<MDBCol size="6">
							{file.uploadedBy ? file.uploadedBy.name : ""}
						</MDBCol>
					</MDBRow>
				</MDBCol>
				<MDBCol size="3">{this.renderAttachmentButtons(file)}</MDBCol>
			</MDBRow>
		);
	}

	renderThumbnailBar() {
		let st = this.state,
			selectedDoc = st.documentPreview,
			thumbs = st.thumbnails,
			selectedDocuments = st.selectedDocuments;

		if (!this.state.thumbLoading) {
			if (
				selectedDoc &&
				selectedDocuments &&
				selectedDocuments.length > 0 &&
				selectedDocuments.filter((d) => d.toString() === selectedDoc).length > 0
			) {
				if (thumbs && thumbs.length > 0) {
					let filtered = thumbs.filter((x) => x.id.toString() === selectedDoc);
					if (
						filtered &&
						filtered[0] &&
						filtered[0].data &&
						filtered[0].data.length > 0
					) {
						return thumbs.map((item, ind) => {
							if (selectedDoc === item.id.toString()) {
								return item.data.map((dat, index) => {
									let colorCode = dat.included ? "green" : "red",
											iconName = dat.included ? "check" : "ban",
											src = "data:image/jpeg;base64, " + dat.image;

									return (
										<Col key={index} md={"2"} className={"upload-thumbnail"}>
											<img
												style={{
													width: "100%",
													height: "auto",
													border: "1px solid black",
												}}
												src={src}
												data-tip={
													"<img src='" +
													src +
													"' alt='Preview' style='height: 70vh; width: auto;' />"
												}
												data-for="preview"
												data-place={"right"}
												data-html="true"
												className={"thumbnail"}
												onClick={() => this.changePageStatus(index, ind)}
												alt={"Page " + index}
											/>
											<div
												style={{ textAlign: "center", color: colorCode }}
												onClick={() => this.changePageStatus(index, ind)}
											>
												<MDBIcon icon={iconName} />
											</div>
											<ReactTooltip id={"preview"} html={true} />
										</Col>
									);
								});
							}
							return null;
						});
					} else {
						return <div />;
						// return <div>{ReactTooltip.rebuild()}{this.renderLoadingSpinner()}</div>;
					}
				} else {
					return <div />;
					// return <div>{ReactTooltip.rebuild()}{this.renderLoadingSpinner()}</div>;
				}
			} else {
				return <div />;
				// return <div>{ReactTooltip.rebuild()}</div>;
			}
		} else {
			return (
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			);
		}
	}

	renderRearrange() {
		let st = this.state,
			thumbs = st.rearrangeData;
		return thumbs.map((item, index) => {
			let src = "data:image/jpeg;base64, " + item.data;
			return (
				<Col
					key={index}
					md={"2"}
					style={{ paddingBottom: "5px" }}
					className={"upload-thumbnail"}
				>
					<img
						style={{
							width: "100%",
							height: "auto",
							border: item.selected ? "2px solid red" : "1px solid black",
						}}
						src={src}
						data-tip={
							"<img src='" +
							src +
							"' alt='Preview' style='height: 70vh; width: auto;' />"
						}
						data-place={"right"}
						data-for="preview"
						data-html="true"
						className={"thumbnail"}
						onClick={() => this.handleImageSelect(index)}
						alt={"Page " + index}
					/>
					<ReactTooltip id={"preview"} html={true} />
				</Col>
			);
		});
	}

	renderArrangeDocuments() {
		if (this.state.formActivePanel1 === 2) {
			return (
				<MDBCol md="12">
					<div>
						<Row style={{ marginTop: "1%", minHeight: "140px" }}>
							{this.renderRearrange()}
						</Row>
					</div>
				</MDBCol>
			);
		}
	}

	renderPacketName() {
		let st = this.state;
		if (st.formActivePanel1 === 3) {
			return (
				<Col md={"12"}>
					<Input
						value={st.mergeDocumentName}
						label={"Document Name:"}
						onChange={(e) => {
							this.setState({ mergeDocumentName: e.target.value });
						}}
					/>
				</Col>
			);
		}
	}

	renderMergeModalOne(options, selected) {
		if (this.state.formActivePanel1 === 1) {
			return (
				<MDBCol md="12">
					<div>
						<div>
							<Row>
								<Col md={"6"}>
									<span>Available:</span>
								</Col>
								<Col md={"6"}>
									<span>Selected: (Click on an item to select pages)</span>
								</Col>
							</Row>
						</div>
						<div>
							<DualListBox
								id={"mergeList"}
								options={options}
								preserveSelectOrder
								selected={selected}
								showOrderButtons
								selectedRef={(element) => {
									if (element) {
										element.addEventListener(
											"click",
											this.onSelectedItemChanged
										);
									}
								}}
								onChange={this.changeMergeDocumentSelection.bind(this)}
							/>
						</div>
						<div>
							<Row style={{ marginTop: "1%", minHeight: "140px" }}>
								{this.renderThumbnailBar()}
							</Row>
						</div>
					</div>
				</MDBCol>
			);
		}
	}

	renderMergeModalFooterOne(selected) {
		let st = this.state;

		if (st.formActivePanel1 === 1) {
			return (
				<div>
					<MDBBtn
						floating
						onClick={() => this.toggleMergeModalPopup()}
						data-tip={"Cancel"}
						size="sm"
						type="submit"
						color={"red"}
					>
						<MDBIcon icon="times-circle" />
					</MDBBtn>
					<MDBBtn
						floating
						color="mdb-color"
						size="sm"
						disabled={(selected && selected.length < 2) || st.thumbLoading}
						data-tip={"Re-order Pages"}
						onClick={() => this.handleStepperNext()}
					>
						<MDBIcon icon="arrow-alt-circle-right" />
					</MDBBtn>
					<ReactTooltip />
				</div>
			);
		}
	}

	renderMergeModalFooterTwo() {
		if (this.state.formActivePanel1 === 2) {
			return (
				<div>
					<Button
						floating
						size="sm"
						data-tip={"Move Page Left"}
						onClick={() => this.handleRearrange(-1)}
					>
						<MDBIcon icon="arrow-left" />
					</Button>
					<Button
						floating
						size="sm"
						data-tip={"Move Page Right"}
						onClick={() => this.handleRearrange(1)}
					>
						<MDBIcon icon="arrow-right" />
					</Button>
					<MDBBtn
						style={{ textTransform: "none" }}
						color="mdb-color"
						floating
						size="sm"
						data-tip={"Select Documents"}
						onClick={this.handleNextPrevClick(1)(1)}
					>
						<MDBIcon icon="arrow-alt-circle-left" />
					</MDBBtn>
					<MDBBtn
						style={{ textTransform: "none" }}
						color="mdb-color"
						floating
						size="sm"
						data-tip={"Name Packet"}
						onClick={this.handleNextPrevClick(1)(3)}
					>
						<MDBIcon icon="arrow-alt-circle-right" />
					</MDBBtn>

					<MDBBtn
						floating
						onClick={() => this.toggleMergeModalPopup()}
						data-tip={"Cancel"}
						size="sm"
						type="submit"
						color={"red"}
					>
						<MDBIcon icon="times-circle" />
					</MDBBtn>

					<ReactTooltip />
				</div>
			);
		}
	}

	renderMergeModalFooterThree() {
		if (this.state.formActivePanel1 === 3) {
			return (
				<div>
					<MDBBtn
						style={{ textTransform: "none" }}
						color="mdb-color"
						floating
						size="sm"
						className="float-left"
						data-tip={"Re-order Pages"}
						onClick={this.handleNextPrevClick(1)(2)}
					>
						<MDBIcon icon="arrow-alt-circle-left" />
					</MDBBtn>

					<MDBBtn
						floating
						onClick={() => this.submitMergeAttachments()}
						data-tip={"Submit"}
						size="sm"
						disabled={
							!this.state.selectedDocuments ||
							this.state.selectedDocuments.length <= 1
						}
						type="submit"
						color={"success"}
					>
						<MDBIcon icon="cloud-upload-alt" />
					</MDBBtn>

					<MDBBtn
						floating
						onClick={() => this.toggleMergeModalPopup()}
						data-tip={"Cancel"}
						size="sm"
						type="submit"
						color={"red"}
					>
						<MDBIcon icon="times-circle" />
					</MDBBtn>
					<ReactTooltip />
				</div>
			);
		}
	}

	renderMergeDocumentModal() {
		let st = this.state,
			files = st.eOrder.files,
			options = [],
			selected = st.selectedDocuments || [];
		if (files && files.length > 0) {
			files.map((file) => {
				return options.push({ value: file.id, label: file.filename });
			});
		}

		return (
			<Modal
				className={"merge-modal"}
				centered
				size={"lg"}
				isOpen={st.mergeModalOpen}
				toggle={() => {
					return;
				}}
			>
				<ModalHeader style={{ textAlign: "center" }}>
					{this.getModalHeaderName()}
				</ModalHeader>
				<ModalBody
					className={
						st.thumbLoading
							? "merge-modal-body"
							: "merge-modal-body thumb-scroll"
					}
				>
					<MDBRow>
						{this.renderMergeModalOne(options, selected)}
						{this.renderArrangeDocuments()}
						{this.renderPacketName()}
					</MDBRow>
				</ModalBody>
				<ModalFooter>
					{this.renderMergeModalFooterOne(selected)}
					{this.renderMergeModalFooterTwo()}
					{this.renderMergeModalFooterThree()}
				</ModalFooter>
			</Modal>
		);
	}

	render() {
		const { documentTypes } = this.context;

		let files = this.state.eOrder.files,
			st = this.state;

		files = files.sort((a, b) => {
			return new Date(b.uploadedAt) - new Date(a.uploadedAt);
		});

		return (
			<div>
				{this.renderDeleteConfirmation()}
				{this.renderMergeDocumentModal()}
				<MDBRow>
					<MDBCol size="4">
						{/*<MDBRow>*/}
						{/*	<MDBCol size={"12"}>*/}
						{/*		<MDBSelect*/}
						{/*			outline*/}
						{/*			getValue={(e) => this.handleDocumentTypeChange(e)}*/}
						{/*			label={"Select a Document Type"}*/}
						{/*		>*/}
						{/*			<MDBSelectInput />*/}
						{/*			<MDBSelectOptions>*/}
						{/*				{documentTypes.map((type, idx) => {*/}
						{/*					return (*/}
						{/*						<MDBSelectOption*/}
						{/*							key={idx}*/}
						{/*							selected={st.documentType === type.name}*/}
						{/*						>*/}
						{/*							{type.name}*/}
						{/*						</MDBSelectOption>*/}
						{/*					);*/}
						{/*				})}*/}
						{/*			</MDBSelectOptions>*/}
						{/*		</MDBSelect>*/}
						{/*	</MDBCol>*/}

						{/*	{st.documentType === "Other" ? (*/}
						{/*		<MDBCol size="12" className={"attachmentsOtherField"}>*/}
						{/*			<MDBInput*/}
						{/*				outline*/}
						{/*				style={{ marginTop: 2, marginBottom: 2 }}*/}
						{/*				onBlur={this.handleOtherDoc.bind(this)}*/}
						{/*				valueDefault={st.otherDocType}*/}
						{/*				label="Document Name"*/}
						{/*			/>*/}
						{/*		</MDBCol>*/}
						{/*	) : (*/}
						{/*		<div />*/}
						{/*	)}*/}
						{/*</MDBRow>*/}
						{/*<MDBRow>{this.renderFileInput()}</MDBRow>*/}
					</MDBCol>
					<MDBCol className={"pvNotesGroupOuterContainer"} size="8">
						<div className={"pvNotesGroupInnerContainer"}>
							{files.map((f, idx) => {
								return (
									<MDBCard
										key={idx}
										style={{
											padding: 6,
											margin: 4,
											backgroundColor: "#E2F1FF",
										}}
									>
										{this.generateAttachmentCard(f)}
									</MDBCard>
								);
							})}
						</div>
					</MDBCol>
				</MDBRow>
			</div>
		);
	}
}
