import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Container,
    MDBBtn,
    MDBIcon,
    MDBSelect,
    Modal,
    ModalBody,
    ModalFooter,
    MDBInput,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane,
    MDBFormInline,
    MDBCol,
    MDBRow,
    MDBModalHeader,
    MDBCardHeader,
    MDBCardBody,
    MDBCard,
} from "mdbreact";
import classnames from "classnames";
import {ToastContainer, toast} from "react-toastify";
import "./deliveries.css";
import '../../Components/jkTooltip.css';
import OrderService from "../Security/OrderService/orderService";
import CallLogService from "../Security/CallLogService/callLogService";
import EquipmentTrainingLinkService from "../Settings/EquipmentTrainingLinkService";
import patientCommunicationService from "../Settings/PatientCommunicationService";
import PropTypes from "prop-types";

//import DateTimePicker from 'material-ui-pickers/DateTimePicker';
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as moment from "moment";
import ReactTooltip from "react-tooltip";
import NoteService from "../Security/NoteService/noteService";
import StreetMap from "./StreetMap";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.compact.css";

import ReadyToScheduleGrid from "./readyToScheduleGrid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import InformationChangeModal from "../../Components/Custom/InformationChangeModal";

// region Constants

const colorGroups = [
    [75, 192, 192, 1],    [75, 90, 192, 1],    [192, 75, 115, 1],   [75, 188, 66, 1],
    [147, 81, 160, 1],    [244, 171, 68, 1],   [191, 244, 68, 1],   [68, 244, 171, 1],
    [33, 75, 89, 1],      [81, 97, 160, 1],    [255, 210, 215, 1],  [255, 210, 140, 1],
    [145, 230, 205, 1],   [130, 220, 225, 1],  [255, 100, 180, 1],  [50, 90, 105, 1],
    [255, 245, 175, 1],   [225, 160, 60, 1],   [60, 40, 45, 1],     [85, 10, 15, 1],
];

// endregion

export default class DeliveryList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            techScheduleLoaded: false,
            rootData: [],

            dataNotScheduled: [],
            dataApproved: [],
            dataEquipmentOrdered: [],

            dataTechSchedule: [],
            userLocations: [],
            selectedLocationIds: [],
            locationList: [],
            editRow: {},
            toggleModal: false,
            errorMessage: "",
            mapWindow: null,
            tooltipContent: "",
            geoLocations: [],
            noteToggle: false,
            scheduledStyle: [],
            nNote: "",
            TabsOneActiveItem: "4",
            techs: [],
            equipApproval: false,
            equipApprovalModal: false,
            hiddenOrders: [],
            isOpen: true,
            equipOrder: {},
            callRadio: null,
            callPatientNote: "",
            techTooltip: "",
            technicianText: "",
            ssAcknowledge: false,

            orderRetrieved: false,
            orderProducts: [],
            smsMessages: null,

            notesRetrieved: false,
            orderNotes: [],

            patientInfoModalOpen: false,
        };

        this.renderEditOrderModal = this.renderEditOrderModal.bind(this);

        setTimeout(() => {
            this.getLocalStorage();
        }, 2500);
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allLocations: PropTypes.array,
        productTypes: PropTypes.array,
        techs: PropTypes.array,
        reasons: PropTypes.array,
        companyName: PropTypes.string,
    };

    toggle = (tabset, tab) => {
        if (this.state.TabsOneActiveItem !== tab) {
            this.setState({
                TabsOneActiveItem: tab,
            });
        }
    };

    toggleCollapse() {
        this.setState((state) => ({
            isOpen: !state.isOpen,
            isLoaded: false,
        }));
        window.mapComponent.forceMapRender();
        //This is necessary because otherwise the grid won't use the full width on resize
        let t = this;
        setTimeout(() => {
            t.setState({
                isLoaded: true,
            });
        }, 5);
    }

    componentDidMount() {
        this.getUserLocations();
        this.getLocationOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {allLocations} = this.context;

        if (allLocations.length > 0 && this.state.locationList.length === 0) {
            this.getUserLocations();
            this.getLocationOptions();
        }
    }

    // region Locations
    getUserLocations() {
        const {currentUser, allLocations} = this.context;

        let locations = [];

        if (
            currentUser.role === "ADMIN" ||
            currentUser.role === "SYSTEM_ADMIN" ||
            currentUser.role === "EXECUTIVE_MANAGEMENT"
        ) {
            locations = allLocations;
        } else {
            locations = currentUser.locations;
        }

        this.setState({
            userLocations: locations,
        });
    }

    getLocationOptions() {
        let locations = this.state.userLocations,
            a = [];

        locations.map((loc) => {
            if (loc.type === "Internal") {
                return a.push({
                    disabled: false,
                    text: loc.name,
                    value: loc.id.toString(),
                    locVal: loc,
                });
            } else {
                return null;
            }
        });

        this.setState({
            locationList: a,
        });
    }

    setLocalStorage(e) {
        window.localStorage.setItem("deliveriesFilters", JSON.stringify(e));
    }

    getLocalStorage() {
        let f = window.localStorage.getItem("deliveriesFilters");

        if (f != null) {
            f = JSON.parse(f);

            let ll = this.state.locationList;

            if (ll.length > 0) {
                let v = ll.find((r) => {
                    return r.value === f;
                });

                let t = "";

                if (v) {
                    v.checked = true;
                    t = v.text;
                }

                this.setState({
                    locationList: ll,
                    selectedLocationText: t,
                });
            }

            this.handleLocationChange(f);
        }
    }

    handleLocationChange = (e) => {
        let mapWindow = this.state.mapWindow;
        this.setState({
            selectedLocationIds: e,
            dataTechSchedule: [],
        });
        if (window.mapComponent) {
            window.mapComponent.clearTechStyle();
        }
        if (mapWindow && !mapWindow.closed) {
            this.refreshMapWindow(e[0]);
        }
        if (e.length > 0) {
            let loc = this.state.locationList.filter((lo) => {
                return lo.value === e[0];
            });
            if (loc[0] && loc[0].locVal.address != null) {
                let lo = loc[0].locVal;
                let addr = lo.address; // + " " + lo.city + ", " + lo.state + " " + lo.zipCode;
                addr += lo.city === null ? "" : " " + lo.city;
                addr += lo.state === null ? "" : ", " + lo.state;
                addr += lo.zipCode === null ? "" : " " + lo.zipCode;

                window.mapComponent.setOfficeLocation(
                    addr,
                    loc[0].locVal.latitude,
                    loc[0].locVal.longitude
                );
            }
            this.retrieveDeliveries(e);
            this.setLocalStorage(e);
        }
    };

    // endregion

    createPatientCommunication(patientId, orderId, commType) {
        const { companyName } = this.context;
        if (["uat", "rehabmedical"].includes(companyName) && this.state.order !== null && [2, 26].includes(this.state.order.salesLocationRef.id)) {
            var ds = new Date();
            var day = ds.getDate(),
                month = ds.getMonth() + 1,
                yr = ds.getFullYear();

            var dateStamp = (month < 9 ? "0" : "") + (month).toString() + "/" + (day < 9 ? "0" : "") + day.toString() + "/" + yr;

            var comm = {
                id: null,
                name: "Patient Registration",
                orderID: orderId,
                patientID: patientId,
                type: commType,
                dateToSend: dateStamp,
                sent: false,
                sentOn: null,
            };
            patientCommunicationService.createPatientCommunication(comm)
                .then(res => {
                    toast.success("The link has been created for the patient.");
                })
                .catch(err => {
                    console.log(err)
                });
        }
    }

    retrieveDeliveries(ids) {
        this.setState({
            isLoaded: false,
            techScheduleLoaded: false,
        });

        // This takes the locations selected in the dropdown and adds them as the part of the parameters for api call...JK
        if (ids.length > 0) {
            OrderService.getDeliveriesForLocation(ids)
                .then((res) => {
                    this.buildTechTimeline(res);
                    this.buildDataSets(res);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("An error occurred getting the deliveries");
                });
        }
    }

    formatDate(date) {
        if (date == null || date === "") {
            return "";
        }
        let dt = new Date(date);
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
        }).format(dt);
    }

    formatNoteDateTime(date) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
        }).format(date);
    }

    formatTime(date) {
        if (date == null || date === "") {
            return "";
        }
        let dt = new Date(new Date(date).toString() + " UTC");
        return new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            second: "2-digit",
        }).format(dt);
    }

    compareDateOfBirth(dob) {
        let a = moment(new Date());
        let b = moment(dob);

        let y = a.diff(b, 'years');

        if(y < 18){
            return "Yes";
        }
        return "No"
    }

    buildDataSets(data) {
        let d2 = [],
            d3 = [],
            d4 = [],
            ordersToMap = [],
            regEx = new RegExp("No Answer.+");
        // Have to use reg ex since reaason is slightly different in Rehab compared to Cork...JK

        let blank = data.orders.filter((bl) => {
            return !bl.reason || bl.reason === null || regEx.test(bl.reason);
        });
        blank.map((item) => {
            return d3.push({
                order: item.id,
                type: item.orderType,
                name: item.patientName,
                address: item.address,
                city: item.city,
                state: item.state,
                zip: item.zipCode,
                reason: item.reason,
                category: item.category,
                product: item.product,
                pts: item.totalPoints,
                lastNoteDate: this.formatDate(item.lastNoteDate),
                answered: item.answered,
                calledBy: item.calledBy,
                calledOn: item.calledOn,
            });
        });

        let eo = data.orders.filter((bl) => {
            return bl.reason && bl.reason === "Equipment Ordered";
        });
        eo.map((item) => {
            return d4.push({
                order: item.id,
                category: item.category,
                product: item.product,
                phone: item.phone,
                name: item.patientName,
                equipmentOrderedDate: item.equipmentOrderedDate,
                lastNoteDate: this.formatDate(item.lastNoteDate),
                answered: item.answered,
                calledBy: item.calledBy,
                calledOn: item.calledOn,
            });
        });

        // let rest = data.orders.filter(bl => {
        //     return bl.reason !== 'Equipment Ordered'
        // });
        data.orders.map((item) => {
            ordersToMap.push(item);
            let tc = item.technicianId
                ? data.techs.find((x) => x.id === item.technicianId)
                : null;
            return d2.push({
                order: item.id,
                pediatric: this.compareDateOfBirth(item.dateOfBirth),
                type: item.orderType,
                address: item.address,
                city: item.city,
                state: item.state,
                zip: item.zipCode,
                reason: item.reason,
                name: item.patientName,
                product: item.product,
                pts: item.totalPoints,
                tech: tc ? tc.username : "",
                scheduledDate: this.formatDate(item.scheduledDeliveryDate),
                expectedShipDate: item.expectedShipDate || "",
                approvalDate: this.formatDate(item.approvalDate),
                equipmentOrderedDate: item.equipmentOrderedDate,
                lastNoteDate: this.formatDate(item.lastNoteDate),
            });
        });

        window.mapComponent.setOrderLocations(ordersToMap);

        this.setState({
            dataNotScheduled: d2,
            dataApproved: d3,
            dataEquipmentOrdered: d4,
            rootData: data.orders,
            techs: data.techs,
            isLoaded: true,
        });
    }

    buildTechTimeline(data) {
        let d4 = this.state.dataTechSchedule,
            ids = this.state.selectedLocationIds,
            techs = data.techs;

        if (ids.length > 0) {
            this.setState({techScheduleLoaded: false});

            d4 = [];

            let filteredData = data.orders.filter((order) => {
                return (
                    order.scheduledDeliveryDate !== null && order.technicianId !== null
                );
            });

            techs.forEach((tech, index) => {
                let x = index%colorGroups.length,
                currStyle = { tech: null, stroke: [0, 0, 0, 0.5], fill: [], visible: true, name: null};

                currStyle.fill = colorGroups[x];
                currStyle.tech = tech.id;
                currStyle.name = tech.firstname + " " + tech.lastname;
                currStyle.visible = true;

                let orderCount = 0,
                    row = {
                        isOpen: true,
                        techId: tech.id,
                        name: tech.firstname + " " + tech.lastname + " - ",
                        deliveries: [],
                        stroke: "1px solid rgba(" + currStyle.stroke.toString() + ")",
                        fill: "rgba(" + currStyle.fill.toString() + ")",
                    };

                let ords = filteredData.filter((ord) => {
                    return ord.technicianId === tech.id;
                });

                window.mapComponent.setTechStyle(currStyle);

                ords.forEach((od, idx) => {
                    let orderDate = new Date(
                        od.scheduledDeliveryDate
                    ).toLocaleDateString();

                    let exists = row.deliveries.findIndex((del) => {
                        return del.date === orderDate;
                    });

                    if (exists > -1) {
                        row.deliveries[exists].orders.push(od);
                    } else {
                        row.deliveries.push({
                            date: orderDate,
                            orders: [od],
                        });
                    }

                    orderCount++;
                });

                row.deliveries.sort((a, b) => {
                    return new Date(a.date) - new Date(b.date);
                });

                row.name += orderCount.toString();
                d4.push(row);
            });

            this.setState({
                dataTechSchedule: d4,
                techScheduleLoaded: true,
                techs: techs,
            });
        }
    }

    handleDeliveryDate(e) {
        let row = this.state.editRow,
            date = e;

        if (e) {
            date = new Date(e);
            let dateStr = moment.utc(date).format();
            row.scheduledDeliveryDate = dateStr
                .substr(0, dateStr.length - 1)
                .toString();
        } else {
            row.scheduledDeliveryDate = null;
        }

        this.setState({editRow: row});
    }

    handleTechChange(e) {
        let row = this.state.editRow,
            text = "";

        if (e) {
            row.technicianId = e.id;
            text = e.username;
        } else {
            row.technicianId = null;
        }

        this.setState({
            editRow: row,
            technicianText: text,
        });
    }

    handleReasonChange(e) {
        const {reasons} = this.context;
        let row = this.state.editRow;

        if (e) {
            let r = reasons.find((o) => {
                return e === o.description;
            });

            if (r) {
                row.reason = r.description;
                row.reasonId = r.id;
            } else {
                row.orderStatusReason = null;
            }
            this.setState({editRow: row});
        }
    }

    goToTechOrdersOnMap(orders, techId) {
        let id = "";
        orders.map((od) => {
            if (id.length > 0) {
                id += ",";
            }

            return (id += od.id);
        });
        if (window.mapComponent) {
            window.mapComponent.setFocusByScheduledOrder(id, techId);
        }
    }

    goToOrderOnMap(id) {
        if (id) {
            this.toggle("4", "4");
            window.mapComponent.focusOnOrder(id);
        }
    }

    updateOrder(rowData, note) {
        const {currentUser} = this.context;
        let row = this.state.editRow;

        if ((!row || !row.id) && rowData != null) {
            row = rowData;
        }

        if (row.id) {
            if (this.state.nNote || note) {
                this.saveNote(row, note);
            }
            if (row.scheduledDeliveryDate) {
                let noteDate = new Date(row.scheduledDeliveryDate).toLocaleString();
                row.scheduledDeliveryDate = moment
                    .utc(row.scheduledDeliveryDate)
                    .local()
                    .format();

                if (this.state.ssAcknowledge) {
                    if (!this.state.nNote) {
                        toast.error(
                            "You must add a note explaining why you are scheduling with Same/Sim on file."
                        );
                        return;
                    }

                    let note =
                        currentUser.username +
                        " acknowledged that there is a same/sim on file and scheduled the order to be delivered on " +
                        noteDate;

                    if (this.state.technicianText) {
                        note += " with technician " + this.state.technicianText;
                    }

                    this.saveNote(row, note);
                }

                if (row.technicianId && row.reason === "Scheduled") {
                    let text =
                        "Order scheduled on " +
                        noteDate +
                        " by " +
                        currentUser.username +
                        " with technician " +
                        this.state.technicianText;
                    this.saveNote(row, text);
                }
            }

            if (row.reason === "Equipment Ordered") {
                if (!row.equipmentOrderedDate) {
                    row.equipmentOrderedDate = new Date().toLocaleString();
                }
            }

            //update smsMessages property...BC
            row.smsMessages = this.state.smsMessages;

            OrderService.updateDelivery(row)
                .then((ord) => {
                    this.toggleEditModal();
                    //window.mapComponent.clearTechStyle();
                    toast.success("Saved Successfully!");
                    this.setState({
                        editRow: {},
                        toggleModal: false,
                        equipApprovalModal: false,
                        callPatientModal: false,
                        equipOrder: {},
                    });
                    if (row.reason && row.reasonId !== 8) {
                        window.mapComponent.updateOrderPin(row);
                    }
                    this.retrieveDeliveries(this.state.selectedLocationIds);
                    this.refreshMapWindow(this.state.selectedLocationIds[0]);
                })
                .catch((err) => {
                    toast.error("An error occurred while saving.");
                    console.log(err);
                    this.setState({isLoaded: true});
                });
        }
    }

    firePatientVideos() {
        let row = this.state.editRow;

        if (row) {
            let id = row.id,
                tech = row.technicianId,
                date = row.scheduledDeliveryDate;

            //this has a technician and date and is being scheduled...BC
            if (tech && date) {
                EquipmentTrainingLinkService.fireEquipmentVideoProcess({orderId: id})
                    .then((r) => {
                        console.log(r);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }

    saveNote(order, noteText) {
        const {currentUser} = this.context;
        let n = noteText ? noteText : this.state.nNote;
        let note = {
            type: "ActivityNote",
            text: n,
            createdAt: new Date(),
            account: order.account,
            task: false,
            completed: false,
            createdBy: {id: currentUser.id, name: currentUser.username},
        };
        NoteService.createActivityNote(order.id, note)
            .then((res) => {
                toast.success("Note saved");
            })
            .catch((err) => {
                toast.error("Error: Note not saved");
            });
        this.setState({
            nNote: "",
        });
    }

    toggleEditModal() {
        this.setState({
            toggleModal: !this.state.toggleModal,
            orderRetrieved: false,
            notesRetrieved: false,
            ssAcknowledge: false,
            orderProducts: [],
            smsMessages: null,
            orderNotes: [],
            technicianText: "",
        });
    }

    toggleTechRow(index) {
        let d4 = this.state.dataTechSchedule,
            tech = d4[index];

        tech.isOpen = !tech.isOpen;
        d4[index] = tech;

        this.setState({
            dataTechSchedule: d4,
        });
    }

    printTechTooltip(tech, date) {
        var mywindow = window.open("", "PRINT", "height=600,width=600");
        mywindow.document.write(
            "<html><head><title>Daily Delivery Schedule</title>"
        );
        mywindow.document.write("</head><body >");
        mywindow.document.write("<h1>" + tech + "</h1>");
        mywindow.document.write("<h1>Delivery Schedule for " + date + "</h1>");
        mywindow.document.write(document.getElementById("techTooltip").innerHTML);
        mywindow.document.write("</body></html>");
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); //  for IE >= 10*/
        mywindow.print();
        mywindow.close();
        return true;
    }

    getTooltipContent(orders, tech, date) {
        orders.sort((a, b) => {
            return (
                new Date(a.scheduledDeliveryDate) - new Date(b.scheduledDeliveryDate)
            );
        });

        let ary = [];

        orders.forEach((ord, index) => {
            let address =
                !ord.address && !ord.city && !ord.state && !ord.zipCode
                    ? "*** No Address Provided ***"
                    : ord.address +
                    "\n\t" +
                    ord.city +
                    ", " +
                    ord.state +
                    " " +
                    ord.zipCode;
            let phone = ord.phone ? ord.phone : "*** No phone Provided ***";
            ary.push(
                <p
                    key={index}
                    className={"techTooltipBoxInner"}
                    onClick={() => this.printTechTooltip(tech, date)}
                >
                    {"Order ID: " + ord.id} <br/>
                    {"Patient Name: " + ord.patientName} <br/>
                    {"Delivery Time: " + this.formatTime(ord.scheduledDeliveryDate)}{" "}
                    <br/>
                    {"Address: " + address} <br/>
                    {"Phone: " + phone} <br/>
                    {"Equipment: " + ord.product}
                </p>
            );
        });
        this.setState({
            techTooltip: ary,
        });
    }

    clientCSV() {
        let result,
            ctr,
            keys = [
                "order",
                "name",
                "approvalDate",
                "address",
                "city",
                "state",
                "zip",
                "lastNoteDate",
                "type",
                "product",
                "pts",
                "reason",
                "scheduledDate",
                "tech",
            ],
            headers = [
                "Order ID",
                "Patient Name",
                "Approval Date",
                "Address",
                "City",
                "State",
                "Zip",
                "Last Note Date",
                "Order Type",
                "Product",
                "Points",
                "Reason",
                "Scheduled Date",
                "Technician",
            ],
            columnDelimiter = ",",
            lineDelimiter = "\n",
            data = this.state.dataNotScheduled;

        if (data == null || !data.length) {
            return null;
        }

        result = "";
        result += headers.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) {
                    result += columnDelimiter;
                }

                if (item[key]) {
                    let v = item[key].toString();
                    result += v.replace(/,/g, " ");
                } else {
                    result += "";
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, "DeliveriesList.csv");
    }

    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    openMapWindow() {
        let win = this.state.mapWindow,
            id = this.state.selectedLocationIds;

        if (!win) {
            win = window.open(
                "./mapWindow/" + (id ? id[0] : ""),
                "_blank",
                "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=800,height=500"
            );
            if (this.state.TabsOneActiveItem === "4") {
                this.setState({TabsOneActiveItem: "3"});
            }
            win.onunload = () => {
                setTimeout(() => {
                    if (win.closed) {
                        this.setState({mapWindow: null});
                    }
                }, 100);
            };
        }

        this.setState({
            mapWindow: win,
        });
    }

    updateStatusReason(orderId, reasonId, reason) {
        let ords = this.state.rootData,
            eOrder = ords.find((o) => o.id === orderId);

        eOrder.reason = reason;
        eOrder.reasonId = reasonId;
        this.updateOrder(eOrder);
    }

    updateEquipmentOrderedDate(orderId) {
        let ords = this.state.rootData,
            eOrder = ords.find((o) => o.id === orderId);
        this.setState({
            equipOrder: eOrder,
        });
        this.toggleEquipmentApprovalModal();
    }

    callPatient(orderId) {
        let ords = this.state.rootData,
            eOrder = ords.find((o) => o.id === orderId);
        this.setState({
            equipOrder: eOrder,
        });
        this.toggleCallPatientModal();
    }

    getOrderProducts(id) {
        OrderService.getActivity(id)
            .then((ord) => {
                console.log(ord)
                this.setState({
                    orderRetrieved: true,
                    smsMessages: ord.patient.smsMessages,
                    orderProducts: ord.products,
                    order: ord,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getOrderNotes(id) {
        NoteService.getNotes(id)
            .then((n) => {
                this.setState({
                    notesRetrieved: true,
                    orderNotes: n.content,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    refreshMapWindow(ids) {
        let id = ids,
            mapWindow = this.state.mapWindow;

        if (mapWindow !== null && !mapWindow.closed) {
            let pathArray = mapWindow.location.pathname.split("/");
            if (pathArray[pathArray.length - 1] === id) {
                mapWindow.location.reload();
            } else {
                let pathFiltered = "";
                pathArray.forEach((item, index) => {
                    if (index < pathArray.length - 1) {
                        pathFiltered += item + "/";
                    }
                });

                let newPath = mapWindow.location.origin + pathFiltered + id;
                mapWindow.location = newPath;
            }
        }
    }

    renderEditOrderModal = (id) => {
        let row = this.state.rootData.find((item) => {
            return item.id === id;
        });
        if (row) {
            let techs = this.state.techs,
                technicianText = "";

            let index = techs.find((rep) => {
                return rep.id === row.technicianId;
            });

            if (index) {
                technicianText = index.username;
            }

            this.setState({
                editRow: row,
                selectedTech: row.technicianId,
                technicianText: technicianText,
            });
            this.getOrderProducts(id);
            this.getOrderNotes(id);
            this.toggleEditModal();
        } else {
            toast.warn("Could not locate the order.");
        }
    };

    renderTechnicianDropdown() {
        const { companyName } = this.context;
        let techs = this.state.techs,
            row = this.state.editRow;

        let index = techs.find((rep) => {
            return rep.id === row.technicianId;
        });

        return (
            <div className={"uiOutlines"}>
                <TextField
                    id="Technicians"
                    select
                    size="small"
                    fullWidth
                    label="Technician"
                    value={index ? index : ""}
                    onChange={(e) => this.handleTechChange(e.target.value)}
                    variant="outlined"
                >
                    <MenuItem key={-1} value={null}>
                        {"(Remove Technician)"}
                    </MenuItem>
                    {techs.map((option, idx) => {
                        return (
                            <MenuItem key={idx} value={techs[idx]}>
                                {option.username}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </div>
        );
    }

    renderProductsList() {
        let p = this.state.orderProducts;

        if (this.state.orderRetrieved) {
            return (
                <div>
                    <MDBRow style={{textDecoration: "underline", fontWeight: "bold"}}>
                        <MDBCol size="8">Product </MDBCol>
                        <MDBCol size="1">Qty </MDBCol>
                        <MDBCol size="3">Serial </MDBCol>
                    </MDBRow>

                    {p.map((prod, idx) => {
                        return (
                            <MDBRow key={idx}>
                                <MDBCol size="8">
                                    {prod.productType.name +
                                    " " +
                                    (prod.productSubType ? prod.productSubType.name : "")}
                                </MDBCol>
                                <MDBCol size="1">{prod.qty}</MDBCol>
                                <MDBCol size="3">{prod.serial}</MDBCol>
                            </MDBRow>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <MDBRow>
                    <MDBCol/>
                    <MDBCol>
                        <Spinner small/>
                    </MDBCol>
                </MDBRow>
            );
        }
    }

    renderNotesList() {
        let n = this.state.orderNotes;

        return (
            <div style={{overflowY: "scroll", height: 250}}>
                {n.map((note) => {
                    return (
                        <MDBCard style={{backgroundColor: "#FFF7D1", margin: 6}}>
                            <MDBCardHeader style={{backgroundColor: "#FFF2AB"}}>
                                <MDBRow>
                                    <MDBCol size="7">
                                        {this.formatNoteDateTime(new Date(note.createdAt))}
                                    </MDBCol>

                                    <MDBCol size="5">
                                        {note.createdBy ? note.createdBy.name : ""}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCardHeader>

                            <MDBCardBody>
                                <p style={{whiteSpace: "pre-line"}}>{note.text}</p>
                            </MDBCardBody>
                        </MDBCard>
                    );
                })}
            </div>
        );
    }

    verifiedPatientRegistration() {
        const { currentUser } = this.context;
        let order = this.state.order;
        order.patientRegistered = true;

        if (order.orderStatusReason.id === 62)
            order.orderStatusReason.id = null;

        let txt = "Patient Registration confirmed correct by: " + currentUser.username + " at " + new Date().toLocaleString();
        this.saveNote(order, txt);
        OrderService.updateOrder(order)
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    }

    renderModal() {
        const { companyName } = this.context;
        let dataRow = this.state.editRow,
            ss = dataRow.sameOrSimilar,
            onFile =
                ss === "Change in Condition" ||
                ss === "Damage" ||
                ss === "Identified Same/Sim on File" ||
                ss === "Identified Patient in SNF";

        let t = this;
        let order = t.state.order;

        function renderSSSection() {
            if (onFile === true) {
                return (
                    <MDBCol size="3">
                        <MDBInput
                            type={"checkbox"}
                            value={t.state.ssAcknowledge}
                            id={"sameSimCheckbox"}
                            onChange={(e) =>
                                t.setState({
                                    ssAcknowledge: e.target.checked,
                                })
                            }
                            label={"I acknowledge there is a same or similar on file."}
                        />
                    </MDBCol>
                );
            }
        }

        function renderRegistrationSection() {
            //if (["uat", "rehabmedical"].includes(companyName) && ['2', '26'].includes(locId) && patientRegistered === null) {
            if (["uat", "rehabmedical"].includes(companyName) && order && (order.salesLocationRef && [2, 26].includes(order.salesLocationRef.id)) && order.patientRegistered !== true && order.orderType === "New") {
                return (
                    <div style={{ border: "1px solid black", padding: "0 12px 0 12px" }}>
                        <MDBCol size="2" style={{ display: "inline-block" }}>
                            {order.patient.id}
                        </MDBCol>
                        <MDBCol size="2" style={{ display: "inline-block" }}>
                            <Button
                                color={"indigo"}
                                size="sm"
                                onClick={() => t.createPatientCommunication(order.patient.id, order.id, "SMS")}>
                                Send SMS Link
                            </Button>
                        </MDBCol>
                        <MDBCol size="2" style={{ display: "inline-block" }}>
                            <Button
                                color={"indigo"}
                                size="sm"
                                onClick={() => t.createPatientCommunication(order.patient.id, order.id, "Email")}>
                                Send Email Link
                            </Button>
                        </MDBCol>
                        <MDBCol size="2" style={{ display: "inline-block" }}>
                            <Button
                                color={"indigo"}
                                size="sm"
                                onClick={() => t.togglePatientInfoRegistrationModal()}>
                                Fill in for Patient
                            </Button>
                        </MDBCol>
                        <MDBCol size="2" style={{ display: "inline-block" }}>
                            {
                                order && order.patientRegistered === false ?
                                <Button
                                    color={"green"}
                                    size="sm"
                                        onClick={() => t.verifiedPatientRegistration()}>
                                    Patient Registration is Complete
                                </Button>
                                : <div/>
                            }
                        </MDBCol>
                        <MDBCol size="2" style={{ display: "inline-block" }}>
                        </MDBCol>
                    </div>
                );
            }
            else {
                return '';
            }
        }

        if (dataRow && dataRow.id) {
            // DateTime issue for java - The date is stored in UTC and sent in UTC,
            // changing the display is the best option currently
            // The following can be done on one line, but is very unreadable- Chip
            let orgDate = dataRow.scheduledDeliveryDate,
                parsedDate = null;
            if (orgDate) {
                let tempDate = new Date(orgDate).toString() + " UTC";
                let secondParse = new Date(tempDate).toString();
                parsedDate = moment(secondParse);
            }
            let dbd = dataRow.deliverByDate
                ? new Date(dataRow.deliverByDate)
                : "01/01/2999";

            return (
                <Modal
                    size="fluid"
                    className={"delModal"}
                    isOpen={this.state.toggleModal}
                    toggle={() => {
                        return;
                    }}
                >
                    <MDBModalHeader
                        className={"mHeaderTitle"}
                        style={{
                            backgroundColor: "#1976D2",
                            padding: "4px 10px",
                            color: "#FFF",
                        }}
                    >
                        <MDBRow>
                            <MDBCol size="6">{dataRow.patientName}</MDBCol>
                            <MDBCol size="6" style={{textAlign: "right", paddingRight: 10}}>
                                <span>Order Id: {dataRow.id}</span>
                            </MDBCol>
                            <Col md="12">
                                <h6 style={{color: "red"}}>{this.state.errorMessage}</h6>
                            </Col>
                        </MDBRow>
                    </MDBModalHeader>
                    <ModalBody>
                        <Row>
                            <MDBCol size="2" style={{marginTop: -4}}>
                                <MDBInput
                                    outline
                                    disabled={true}
                                    value={dataRow.sameOrSimilar || "Not Specified"}
                                    label={"Same or Similar"}
                                />

                                <MDBInput
                                    outline
                                    disabled={true}
                                    value={dataRow.expectedShipDate || "Not Specified"}
                                    label={"Expected Ship Date"}
                                />

                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker
                                        keyboard
                                        clearable
                                        style={{width: "100%"}}
                                        inputVariant="outlined"
                                        label={"Scheduled Date / Time"}
                                        minutesStep={15}
                                        size="small"
                                        maxDate={dbd}
                                        maxDateMessage={"Scheduled Date is After Deliver By Date!"}
                                        value={parsedDate}
                                        onChange={this.handleDeliveryDate.bind(this)}
                                    />
                                </MuiPickersUtilsProvider>



                                {this.renderTechnicianDropdown()}

                                {this.renderReasonsDropdown()}
                            </MDBCol>

                            <MDBCol size="6">{this.renderProductsList()}</MDBCol>

                            <MDBCol size="4">
                                {this.renderNotesList()}

                                <MDBInput
                                    type="textarea"
                                    label="Note"
                                    onBlur={this.handleNoteChange}
                                    outline
                                />
                            </MDBCol>
                        </Row>
                        <MDBRow>
                            <MDBCol size="12">{renderRegistrationSection()}</MDBCol>
                        </MDBRow>
                    </ModalBody>

                    <MDBRow style={{justifyContent: "flex-end"}}>
                        {renderSSSection()}

                        <MDBCol size="4" style={{float: "right"}}>
                            <Button
                                floating
                                rounded
                                size="sm"
                                data-tip={"Save"}
                                disabled={onFile === true && this.state.ssAcknowledge !== true}
                                color={"success"}
                                onClick={() => {
                                    this.updateOrder();
                                    this.firePatientVideos();
                                }}
                            >
                                <MDBIcon far icon="save" style={{fontSize: "2em"}}/>
                            </Button>
                            <Button
                                floating
                                size="sm"
                                color={"red"}
                                data-tip={"Cancel"}
                                onClick={() => this.toggleEditModal()}
                            >
                                <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                            </Button>
                        </MDBCol>
                        <ReactTooltip/>
                    </MDBRow>
                </Modal>
            );
        } else {
            return <div/>;
        }
    }

    renderReasonsDropdown() {
        const {reasons} = this.context;

        let o = this.state.editRow;

        return (
            <div className={"uiOutlines smallMargin"}>
                <TextField
                    id="outlined-select-statusReason"
                    select
                    size="small"
                    fullWidth
                    label="Status Reason"
                    value={o.reason || "none"}
                    onChange={(e) => this.handleReasonChange(e.target.value)}
                    variant="outlined"
                >
                    <MenuItem key={-1} value={null}>
                        {"(Remove Reason)"}
                    </MenuItem>
                    {reasons.map((r, idx) => {
                        if (r.orderStatus === "ReadyToDeliver") {
                            if (r.description === "Scheduled") {
                                if (!o.technicianId || !o.scheduledDeliveryDate) {
                                    return (
                                        <MenuItem disabled key={idx} value={r.description}>
                                            {r.description}
                                        </MenuItem>
                                    );
                                }
                            }

                            return (
                                <MenuItem key={idx} value={r.description}>
                                    {r.description}
                                </MenuItem>
                            );
                        }
                        return null;
                    })}
                </TextField>
            </div>
        );
    }

    toggleNoteCollapse = () => {
        this.setState({
            noteToggle: !this.state.noteToggle,
        });
    };

    handleNoteChange = (e) => {
        this.setState({
            nNote: e.target.value,
        });
    };

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    renderMapView() {
        return (
            <StreetMap
                id={"mapRef"}
                editHandler={this.renderEditOrderModal.bind(this)}
            />
        );
    }

    renderCollapse(item) {
        if (item.deliveries.length === 0) {
            return <div/>;
        }
        return (
            <Collapse isOpen={item.isOpen}>
                {item.deliveries.map((del, index) => {
                    return (
                        <Row key={index}>
                            <Col md={"8"}>
                                <span style={{paddingLeft: "10px"}}>{del.date}</span>
                            </Col>
                            <Col md={"4"}>
								<span
                                    data-for="notes"
                                    style={{
                                        cursor: "pointer",
                                        textAlign: "right",
                                        margin: 5,
                                    }}
                                    onClick={() => this.printTechTooltip(item.name, del.date)}
                                    onMouseEnter={() =>
                                        this.getTooltipContent(del.orders, item.name, del.date)
                                    }
                                    onMouseLeave={() => this.setState({techTooltip: ""})}
                                >
									{del.orders.length}
								</span>
                            </Col>
                        </Row>
                    );
                })}
                <hr style={{borderColor: "#555555", borderWidth: "3px"}}/>
            </Collapse>
        );
    }

    renderSideBar() {
        let ds = this.state.dataTechSchedule;
        return (
            <CardBody style={{overflowY: "scroll"}}>
                <MDBSelect
                    color="info"
                    selected={this.state.selectedLocationText || "Choose Location"}
                    style={{margin: "5px"}}
                    options={this.state.locationList}
                    getValue={this.handleLocationChange}
                />

                <div>
                    <div
                        style={{
                            paddingRight: 0,
                            width: "100%",
                            paddingTop: "10px",
                        }}
                    >
                        {this.state.techScheduleLoaded && ds.length > 0 ? (
                            ds.map((item, id) => {
                                return (
                                    <div key={id} style={{maxWidth: "90%"}}>
                                        <div
                                            style={{
                                                paddingTop: "6px",
                                                paddingBottom: "6px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => this.toggleTechRow(id)}
                                        >
                                            <MDBIcon
                                                icon="caret-down"
                                                style={{color: item.fill, fontSize: 28}}
                                            />

                                            <h6 style={{display: "inline"}}>{item.name}</h6>
                                            <hr
                                                style={{borderColor: "#555555", borderWidth: "3px"}}
                                            />
                                        </div>

                                        {this.renderCollapse(item)}
                                    </div>
                                );
                            })
                        ) : this.state.selectedLocationIds.length > 0 &&
                        !this.state.techScheduleLoaded ? (
                            <div>{this.renderLoadingSpinner()}</div>
                        ) : (
                            <div/>
                        )}
                    </div>
                    <ReactTooltip/>
                </div>
            </CardBody>
        );
    }

    renderTabs() {
        let unscheduled = this.state.dataNotScheduled;

        return (
            <div>
                <Nav
                    style={{padding: 2}}
                    tabs
                    className="nav-justified"
                    color={"info"}
                >
                    <NavItem>
                        <NavLink
                            link
                            to="#"
                            className={classnames({
                                active: this.state.TabsOneActiveItem === "3",
                            })}
                            onClick={() => {
                                this.toggle("3", "3");
                            }}
                        >
                            Ready To Schedule - {unscheduled ? unscheduled.length : 0}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <Row>
                            <Col md={"8"}>
                                <NavLink
                                    link
                                    to="#"
                                    className={classnames({
                                        active: this.state.TabsOneActiveItem === "4",
                                    })}
                                    onClick={() => {
                                        this.toggle("4", "4");
                                    }}
                                >
                                    Map
                                </NavLink>
                            </Col>
                            <Col md={"2"}>
                                <Button
                                    floating
                                    size="sm"
                                    style={{margin: "0"}}
                                    color={"secondary"}
                                    title={"View Map in New Window"}
                                    onClick={this.openMapWindow.bind(this)}
                                >
                                    <MDBIcon icon="window-restore"/>
                                </Button>
                            </Col>
                            <Col md={"2"}>
                                <Button
                                    floating
                                    size="sm"
                                    style={{margin: "0"}}
                                    color={"indigo"}
                                    title={"Download CSV"}
                                    onClick={this.clientCSV.bind(this)}
                                >
                                    <MDBIcon icon="download"/>
                                </Button>
                            </Col>
                        </Row>
                    </NavItem>
                </Nav>
            </div>
        );
    }

    renderCollapseButton() {
        return (
            <div
                style={{
                    position: "absolute",
                    top: "13%",
                    left: ".4rem",
                    zIndex: "50000",
                }}
            >
                <Button
                    floating
                    size="sm"
                    color={"orange"}
                    data-tip={"Hide/Show Filters"}
                    onClick={() => this.toggleCollapse()}
                >
                    <MDBIcon icon="filter" style={{fontSize: "2em"}}/>
                </Button>
                <ReactTooltip/>
            </div>
        );
    }

    renderTooltipCards() {
        return (
            <div id={"techTooltip"} className={"techTooltipBoxOuter"}>
                <div className="wrapper">
                    {this.state.techTooltip ? (
                        this.state.techTooltip.map((c, idx) => {
                            return (
                                <div className={"techTooltipBox"} key={idx}>
                                    {c}
                                </div>
                            );
                        })
                    ) : (
                        <div/>
                    )}
                </div>
            </div>
        );
    }

    patientCalledRadio = (result) => () => {
        this.setState({
            callRadio: result,
        });
    };

    generatePhoneLink(phone) {
        let regEx = /\(?\d{3}\)?\s*-?\s*\d{3}\s*-?\s*\d"4"/;
        return phone.match(regEx);
    }

    renderCallPatientModal() {
        let st = this.state,
            order = st.equipOrder,
            phoneLink = order.phone ? this.generatePhoneLink(order.phone) : "";
        return (
            <Modal
                centered
                isOpen={st.callPatientModal}
                toggle={() => this.toggleCallPatientModal()}
            >
                <ModalBody>
                    <CardHeader
                        style={{marginBottom: "1rem"}}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>{order ? order.patientName : ""}</h3>
                    </CardHeader>
                    <div align="left">
                        {phoneLink ? (
                            <a className={"callPatientLink"} href={"tel:+1" + phoneLink[0]}>
                                <MDBIcon
                                    style={{margin: "0 10px 0 0"}}
                                    size="lg"
                                    data-tip={phoneLink[0]}
                                    icon="phone"
                                />
                                {"Call Patient: " + phoneLink[0]}
                            </a>
                        ) : (
                            <MDBInput
                                value={order.phone}
                                icon="phone"
                                group
                                disabled={true}
                                type="text"
                            />
                        )}
                        <MDBInput
                            value={order.patientName}
                            icon="user"
                            group
                            disabled={true}
                            type="text"
                        />
                        <MDBInput
                            value={
                                order.address +
                                ", " +
                                order.city +
                                "\n" +
                                order.state +
                                " " +
                                order.zipCode
                            }
                            icon="home"
                            group
                            className="text-left"
                            disabled={true}
                            type="textarea"
                        />
                        <MDBInput
                            value={order.category + " - " + order.product}
                            icon="wheelchair"
                            group
                            disabled
                            type="text"
                        />
                        <MDBInput
                            className="callPatientInput"
                            rows="2"
                            onChange={(e) => {
                                this.setState({callPatientNote: e.target.value});
                            }}
                            label={"Add notes..."}
                            icon="notes-medical"
                            group
                            type="textarea"
                        />
                        <MDBFormInline>
                            <MDBInput
                                onClick={this.patientCalledRadio(1)}
                                checked={st.callRadio === 1}
                                label="Answered"
                                type="radio"
                                id="radio1"
                            />
                            <MDBInput
                                onClick={this.patientCalledRadio(2)}
                                checked={st.callRadio === 2}
                                label="Didn't answer"
                                type="radio"
                                id="radio2"
                            />
                        </MDBFormInline>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        floating
                        size="sm"
                        color={"danger"}
                        data-tip={"Close"}
                        onClick={() => this.toggleCallPatientModal()}
                    >
                        <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                    </Button>
                    <Button
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Save Order"}
                        onClick={this.handleCallPatient}
                    >
                        <MDBIcon icon="save" style={{fontSize: "2em"}}/>
                    </Button>
                </ModalFooter>
                <ReactTooltip/>
            </Modal>
        );
    }

    handleCallPatient = () => {
        let st = this.state,
            cr = st.callRadio,
            order = st.equipOrder,
            note = st.callPatientNote;

        console.log(order);

        if (!cr) {
            toast.warn("Please select if patient answered");
            return;
        }

        this.saveNote(order, note);

        let callLog = {
            answered: cr === 1,
            orderId: order.id,
        };

        CallLogService.createCallLog(callLog)
            .then((res) => {
                this.setState({
                    callRadio: null,
                });
                this.toggleCallPatientModal();
                this.retrieveDeliveries(this.state.selectedLocationIds);
                this.refreshMapWindow(this.state.selectedLocationIds[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    renderEquipApprovalModal() {
        let st = this.state,
            order = st.equipOrder;
        return (
            <Modal
                centered
                isOpen={st.equipApprovalModal}
                toggle={() => this.toggleEquipmentApprovalModal()}
            >
                <ModalBody>
                    <CardHeader
                        style={{marginBottom: "1rem"}}
                        className="form-header text-center font-weight-bold blue-gradient"
                    >
                        <h3>Equipment Acknowledgment</h3>
                    </CardHeader>
                    <div align="left">
                        <MDBInput
                            key={order.id}
                            id={"equipCheckBox"}
                            onChange={this.handleEquipmentApproval.bind(this)}
                            value={st.equipApproval}
                            label={
                                "I hereby acknowledge that all equipment have been ordered for order " +
                                order.id
                            }
                            type="checkbox"
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <MDBBtn
                        color="secondary"
                        onClick={() => this.toggleEquipmentApprovalModal()}
                    >
                        Close
                    </MDBBtn>
                    <MDBBtn
                        disabled={!st.equipApproval}
                        color="primary"
                        onClick={() => this.equipAcknowledgeClicked(order)}
                    >
                        Acknowledge
                    </MDBBtn>
                </ModalFooter>
            </Modal>
        );
    }

    equipAcknowledgeClicked(order) {
        const {currentUser} = this.context;
        let note =
            currentUser.username +
            " acknowledged that all equipment has been ordered for order " +
            this.state.equipOrder.id;
        order.equipmentOrderedDate = new Date().toLocaleString();
        this.updateOrder(order, note);
    }

    toggleEquipmentApprovalModal = () => {
        this.setState({
            equipApprovalModal: !this.state.equipApprovalModal,
        });
    };

    toggleCallPatientModal = () => {
        this.setState({
            callPatientModal: !this.state.callPatientModal,
        });
    };

    handleEquipmentApproval = (event) => {
        this.setState({equipApproval: event.target.checked});
    };

    togglePatientInfoRegistrationModal = () => {
        const { companyName } = this.context;
        if (["uat", "rehabmedical"].includes(companyName)) {
            this.setState({
                patientInfoModalOpen: !this.state.patientInfoModalOpen,
            })
        }
    }

    updatePatientRegistration(value) {
        const { companyName } = this.context;
        if (["uat", "rehabmedical"].includes(companyName)) {
            let order = this.state.order;
            order.patientRegistered = value;
            if (value === false) {
                order.orderStatusReason.id = 62;
            }
            OrderService.updateOrder(order)
                .then(res => {
                    console.log(res);
                    this.togglePatientInfoRegistrationModal();
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    renderPatientInfoRegistrationModal() {
        const { companyName } = this.context;
        if (["uat", "rehabmedical"].includes(companyName)) {
            let order = this.state.order,
                modalOpen = this.state.patientInfoModalOpen;
            console.log(order)
            if (order && order.salesLocationRef && [2, 26].includes(order.salesLocationRef.id)) {
                return (
                    <InformationChangeModal
                        key={this.state.order.id}
                        cancelFunction={() => { this.togglePatientInfoRegistrationModal() }}
                        submitFunction={(e) => { this.updatePatientRegistration(e) }}
                        activeOrder={order}
                        modalOpen={modalOpen} />
                )
            }
            else {
                return <div/>
            }
        }
    }

    render() {
        let h = window.innerHeight,
            c1 = 2,
            c2 = 10;

        if (!this.state.isOpen) {
            c1 = 0;
            c2 = 12;
        }

        return (
            <Container style={{width: "98%", maxWidth: "98%"}}>
                {this.renderModal()}
                {this.renderEquipApprovalModal()}
                {this.renderCallPatientModal()}
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                {this.renderCollapseButton()}
                {this.renderTooltipCards()}
                <Row>
                    <Col size={c1}>
                        <Collapse
                            delay={{show: 100, hide: 0}}
                            id="accordion1"
                            isOpen={this.state.isOpen}
                        >
                            <Card
                                style={{
                                    height: h * 0.8,
                                }}
                            >
                                {this.renderSideBar()}
                            </Card>
                        </Collapse>
                    </Col>
                    <Col size={c2}>
                        <div>
                            {this.renderTabs()}
                            <TabContent
                                style={{
                                    padding: 0,
                                    paddingTop: "2rem",
                                    backgroundColor: "#dddddd",
                                }}
                                activeItem={this.state.TabsOneActiveItem}
                            >
                                {/*<TabPane tabId="1" role="tabpanel"*/}
                                {/*         style={{backgroundColor: '#dddddd'}}>*/}
                                {/*    <ApprovedGrid*/}
                                {/*        isLoaded={this.state.isLoaded}*/}
                                {/*        selectedLocationIds={this.state.selectedLocationIds}*/}
                                {/*        dataApproved={this.state.dataApproved}*/}
                                {/*        callPatient={this.callPatient.bind(this)}*/}
                                {/*        updateStatusReason={this.updateStatusReason.bind(this)}*/}
                                {/*    />*/}
                                {/*</TabPane>*/}
                                {/*<TabPane tabId="2" role="tabpanel"*/}
                                {/*         style={{backgroundColor: '#dddddd'}}>*/}
                                {/*    <EquipmentOrderedGrid*/}
                                {/*        isLoaded={this.state.isLoaded}*/}
                                {/*        selectedLocationIds={this.state.selectedLocationIds}*/}
                                {/*        dataEquipmentOrdered={this.state.dataEquipmentOrdered}*/}
                                {/*        updateStatusReason={this.updateStatusReason.bind(this)}*/}
                                {/*        updateEquipmentOrderedDate={this.updateEquipmentOrderedDate.bind(this)}*/}
                                {/*        callPatient={this.callPatient.bind(this)}*/}
                                {/*        renderEditOrderModal={this.renderEditOrderModal.bind(this)}*/}
                                {/*    />*/}
                                {/*</TabPane>*/}
                                <TabPane
                                    tabId="3"
                                    role="tabpanel"
                                    style={{backgroundColor: "#dddddd"}}
                                >
                                    <ReadyToScheduleGrid
                                        isLoaded={this.state.isLoaded}
                                        selectedLocationIds={this.state.selectedLocationIds}
                                        dataNotScheduled={this.state.dataNotScheduled}
                                        renderEditOrderModal={this.renderEditOrderModal.bind(this)}
                                        gotoMap={this.goToOrderOnMap.bind(this)}
                                    />
                                </TabPane>
                                <TabPane
                                    tabId="4"
                                    role="tabpanel"
                                    style={{backgroundColor: "#dddddd"}}
                                >
                                    <div
                                        id={"mapHost"}
                                        style={{height: h * 0.7, width: "100%"}}
                                    >
                                        {this.renderMapView()}
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </Col>
                </Row>
                {this.renderPatientInfoRegistrationModal()}
                <ReactTooltip/>
            </Container>
        );
    }
}
