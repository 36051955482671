import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Input,
	toast,
	CardHeader,
	MDBIcon,
} from "mdbreact";
import ProductSubTypeService from "../Security/ProductService/productSubTypeService";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import Select from "react-select";

export default class addProductSubType extends React.Component {
	constructor(props) {
		super(props);

		let pt = props.location.state.productType;

		this.state = {
			productName: "",
			hcpcs: "",
			errorMessage: "",
			productType: pt,
			allVendors: [],
			vendorOptions: [],
			selectedVendor: null,
			nProduct: {
				overridePoints: false,
			},
		};
	}

	static contextTypes = {
		allVendors: PropTypes.array,
	};

	componentDidMount() {
		this.renderVendorsOptions();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { allVendors } = this.context;
		if (
			allVendors.length > 0 &&
			allVendors.length !== prevState.allVendors.length
		) {
			this.renderVendorsOptions();
		}
	}

	renderVendorsOptions() {
		const { allVendors } = this.context;
		let arr = [];
		allVendors.map((v) => {
			return arr.push({
				label: v.name,
				value: v.id,
				val: { id: v.id, name: v.name },
			});
		});
		this.setState({
			allVendors: allVendors,
			vendorOptions: arr,
		});
	}

	addNameChange = (e) => {
		this.setState({
			productName: e.target.value,
		});
	};

	addHcpcsChange = (e) => {
		this.setState({
			hcpcs: e.target.value,
		});
	};

	handleChange(prop, evt) {
		let p = this.state.nProduct;
		if (prop === "overridePoints") {
			p[prop] = !p[prop];
		} else {
			p[prop] = evt.target.value;
		}
		this.setState({
			nProduct: p,
		});
	}

	renderAddProductButton() {
		let st = this.state,
			pt = st.productType,
			np = st.nProduct;
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<Input
					value={np.name || ""}
					label={"Product Name"}
					onChange={this.handleChange.bind(this, "name")}
				/>
				<Input
					value={np.hcpcs || ""}
					label={"HCPCS"}
					onChange={this.handleChange.bind(this, "hcpcs")}
				/>
				<Input
					onChange={this.handleChange.bind(this, "itemId")}
					label="Item Id"
					value={np.itemId || ""}
					size="sm"
				/>
				<Select
					placeholder={"Vendor"}
					options={st.vendorOptions}
					onChange={this.handleVendorChange.bind(this)}
					value={st.selectedVendor}
				/>
				<Input
					id="overridepnts"
					checked={np.overridePoints}
					type={"checkbox"}
					onChange={this.handleChange.bind(this, "overridePoints")}
					label="Override Points"
					size="sm"
				/>
				<div style={{ float: "right" }}>
					<Button
						size="sm"
						color={"warning"}
						data-tip={"Back to List"}
						onClick={() =>
							this.props.history.push({
								pathname: "/products/" + pt.id,
								state: {
									product: pt,
								},
							})
						}
					>
						<MDBIcon icon="backward" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						size="sm"
						color={"success"}
						data-tip={"Save New Product"}
						onClick={this.addSaveButtonClick}
					>
						<MDBIcon far icon="save" style={{ fontSize: "2em" }} />
					</Button>
				</div>
				<ReactTooltip />
			</div>
		);
	}

	handleVendorChange = (e) => {
		this.setState({
			selectedVendor: e,
		});
	};

	addSaveButtonClick = () => {
		if (this.validate()) {
			let st = this.state,
				p = this.state.nProduct;
			p.productType = st.productType;
			if (st.selectedVendor) {
				p.vendor = st.selectedVendor.val;
			}
			return ProductSubTypeService.addProductSubType(p)
				.then((res) => {
					this.setState({
						errorMessage: "",
						nProduct: { overridePoints: false },
						selectedVendor: null,
					});
					toast.success("Product has been Added!");
				})
				.catch((err) => {
					toast.warn("An error occured adding a new product");
				});
		}
	};

	validate() {
		let p = this.state.nProduct;
		if (!p.name) {
			this.setError("Please enter a product name");
			return false;
		} else if (!p.hcpcs) {
			this.setError("Please enter HCPCS");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Container style={{ width: "30%" }}>
					<Card>
						<CardHeader
							color={"blue-grey lighten-2"}
							style={{ textAlign: "center" }}
						>
							{" "}
							Add New Product
						</CardHeader>
						<CardBody>{this.renderAddProductButton()}</CardBody>
					</Card>
				</Container>
			</div>
		);
	}
}
