import React from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Container,
	Input,
	Modal,
	ModalBody,
	ModalFooter,
	DataTable,
	ToastContainer,
	Spinner,
	MDBIcon,
	toast,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import VendorService from "../Security/VendorService/vendorService";

export default class Vendor extends React.Component {
	constructor(props) {
		super(props);
		const data = {
			columns: [
				{
					label: "Id",
					field: "id",
					sort: "asc",
					width: 150,
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
					width: 270,
				},
				{
					label: "Address",
					field: "address",
					sort: "asc",
					width: 200,
				},
				{
					label: "Address 2",
					field: "address2",
					sort: "asc",
					width: 100,
				},
				{
					label: "City",
					field: "city",
					sort: "asc",
					width: 150,
				},
				{
					label: "State",
					field: "state",
					sort: "asc",
					width: 100,
				},
				{
					label: "Zip",
					field: "zip",
					sort: "asc",
					width: 100,
				},
				{
					label: "Phone",
					field: "phone",
					sort: "asc",
					width: 100,
				},
				{
					label: "Account Number",
					field: "accountnumber",
					sort: "asc",
					width: 100,
				},
				{
					label: "Email",
					field: "email",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
					width: 100,
				},
				{
					label: "Delete",
					field: "delete",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		this.state = {
			isLoaded: false,
			data: data,
			nVendor: {},
			modalToggle: false,
			editToggle: false,
			errorMessage: "",
			currIndex: -1,
			currNote: "",
		};
	}

	componentDidMount() {
		this.retrieveVendors();
	}

	retrieveVendors() {
		VendorService.getAllVendors()
			.then((res) => {
				let arr = [],
						d = this.state.data;
				res.map((vendor, index) => {
					return arr.push(this.createRow(vendor, index));
				});
				d.rows = arr;
				this.setState({
					data: { columns: d.columns, rows: [] },
				});
				this.setState({
					isLoaded: true,
					data: d,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	createRow(vendor, index) {
		let c = {
				id: vendor.id,
				name: vendor.name,
				address: vendor.address || "",
				address2: vendor.address2 || "",
				city: vendor.city || "",
				state: vendor.state || "",
				zip: vendor.zip || "",
				phone: vendor.phone || "",
				accountnumber: vendor.accountNumber || "",
				email: vendor.recipientEmail || "",
				edit: this.renderEditColumn(vendor, index),
				delete: this.renderDeleteColumn(vendor.id),
		};
		return c;
	}

	renderEditColumn(vendor, index) {
		return (
			<Button
				floating
				size="sm"
				onClick={() =>
					this.setState({
						nVendor: JSON.parse(JSON.stringify(vendor)),
						editToggle: true,
						currIndex: index,
						currNote: vendor.notes,
					})
				}
			>
				<MDBIcon icon="edit" />
			</Button>
		);
	}

	editVendor = () => {
		let st = this.state,
			v = st.nVendor,
			i = st.currIndex,
			d = st.data,
			n = st.currNote;
		v.notes = n;

		VendorService.editVendor(v)
			.then((res) => {
				d.rows[i] = this.createRow(res, i);
				this.setState({
					data: { columns: d.columns, rows: [] },
				});
				this.setState({
					data: d,
				});
				ReactTooltip.rebuild();
				this.toggleModal();
				toast.success(res.name + " Edited");
			})
			.catch((err) => {
							console.log(err);
						});
	};

	renderDeleteColumn(id) {
		return (
			<Button
				floating
				color={"red"}
				size="sm"
				onClick={() => this.handleRemoveClick(id)}
			>
				<MDBIcon icon="trash" />
			</Button>
		);
	}

	handleRemoveClick(id) {
		VendorService.deleteVendor(id)
			.then((res) => {
				ReactTooltip.rebuild();
				this.retrieveVendors();
				this.toggleModal();
			})
			.catch((err) => {
							console.log(err);
						});
	}

	renderTable() {
		return <DataTable striped data={this.state.data} />;
	}

	toggleModal = () => {
		this.setState({
			modalToggle: false,
			editToggle: false,
			nVendor: {},
			errorMessage: "",
			currIndex: -1,
			currNote: "",
		});
	};

	handleModalInput(prop, evt) {
		if (this.state.editToggle && prop === "notes") {
			this.setState({
				currNote: evt.target.value,
			});
		} else {
			let v = this.state.nVendor;
			v[prop] = evt.target.value;
			this.setState({
				nVendor: v,
			});
		}
	}

	renderModal() {
		let st = this.state,
			v = st.nVendor;
		return (
			<Modal
				toggle={() => {
					return;
				}}
				isOpen={st.modalToggle || st.editToggle}
			>
				<CardHeader>Add Vendor</CardHeader>
				<ModalBody>
					<p style={{ color: "red" }}>{this.state.errorMessage}</p>
					<Input
						label="Name"
						value={v.name || ""}
						onChange={this.handleModalInput.bind(this, "name")}
					/>
					<Input
						label="Address"
						value={v.address || ""}
						onChange={this.handleModalInput.bind(this, "address")}
					/>
					<Input
						label="Address 2"
						value={v.address2 || ""}
						onChange={this.handleModalInput.bind(this, "address2")}
					/>
					<Input
						label="City"
						value={v.city || ""}
						onChange={this.handleModalInput.bind(this, "city")}
					/>
					<Input
						label="State"
						value={v.state || ""}
						onChange={this.handleModalInput.bind(this, "state")}
					/>
					<Input
						label="Zip"
						value={v.zip || ""}
						onChange={this.handleModalInput.bind(this, "zip")}
					/>
					<Input
						label="Phone"
						value={v.phone || ""}
						onChange={this.handleModalInput.bind(this, "phone")}
					/>
					<Input
						label="Account Number"
						value={v.accountNumber || ""}
						onChange={this.handleModalInput.bind(this, "accountNumber")}
					/>
					<Input
						label="Email"
						value={v.recipientEmail || ""}
						onChange={this.handleModalInput.bind(this, "recipientEmail")}
					/>
					<Input
						style={{ textAlign: "start" }}
						type="textarea"
						rows="2"
						label="notes"
						value={st.editToggle ? st.currNote : st.nVendor.notes || ""}
						onChange={this.handleModalInput.bind(this, "notes")}
					/>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={this.toggleModal}>
						Cancel
					</Button>
					<Button
						color="primary"
						onClick={st.modalToggle ? this.saveNewVendor : this.editVendor}
					>
						Save
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	saveNewVendor = () => {
		if (this.shouldSave()) {
			return VendorService.createVendor(this.state.nVendor)
				.then((res) => {
					let d = this.state.data;
					d.rows.push(this.createRow(res, d.rows.length));

					this.setState({
						data: { columns: d.columns, rows: [] },
					});
					this.setState({
						data: d,
					});
					ReactTooltip.rebuild();
					this.toggleModal();
					toast.success("Vendor Created");
				})
				.catch((err) => {
					console.log(err);
				});
		}
		return;
	};

	shouldSave() {
		if (!this.state.nVendor.name) {
			this.setState({
				errorMessage: "Please enter a name",
			});
			return false;
		}
		return true;
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}

		return (
			<Container style={{ width: "98%", maxWidth: "98%" }}>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						color={"blue-grey lighten-2"}
						style={{ textAlign: "center" }}
					>
						Vendors
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Vendor"}
							onClick={() => this.setState((state) => ({ modalToggle: true }))}
						>
							<MDBIcon icon="plus" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>
						{this.renderTable()}
						<ReactTooltip />
					</CardBody>
				</Card>
				{this.renderModal()}
			</Container>
		);
	}
}
