import React from "react";
import PropTypes from "prop-types";
import {
	Card,
	CardHeader,
	CardBody,
	Container,
	Spinner,
	ToastContainer,
	Chip,
	toast,
	Input,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	DatePicker,
	Row,
	Col,
	InputFile,
	View,
} from "mdbreact";
import ExpenseService from "../Security/ExpenseService/expenseService";
import ActivityService from "../Security/ActivityService/activityService";

export default class expenseAdd extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			nExpense: {
				receipt: null,
				expenseDate: null,
				when: null,
				owner: {},
				expenseDescription: null,
				expenseType: {},
				cardType: {},
				expenses: 0,
				type: "Appointment",
				isExpense: true,
				quality: false,
			},
			cardTypes: [],
			expenseTypes: [],
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	componentWillMount() {
		this.retrieveCreditCardTypes();
		this.retrieveExpenseTypes();
	}

	retrieveCreditCardTypes() {
		return ActivityService.getCreditCardTypes()
			.then((types) => {
				this.setState({ cardTypes: types });
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	retrieveExpenseTypes() {
		return ActivityService.getExpenseTypes()
			.then((types) => {
				this.setState({ expenseTypes: types });
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	renderExpenseInfo() {
		let expense = this.state.nExpense,
			expenseDate = new Date(expense.expenseDate);

		return (
			<div>
				<DatePicker
					label="Expense Date"
					value={expenseDate}
					getValue={this.handleDatePickerChange.bind(this, "expenseDate")}
				/>
				<Row>
					<Col md="6" className="md-4">
						<Dropdown>
							<DropdownToggle caret color="primary">
								Select Card Type
							</DropdownToggle>
							<DropdownMenu basic>
								{this.state.cardTypes.map((card, index) => {
									return (
										<DropdownItem
											key={index}
											onClick={this.changeCardType.bind(this, card)}
										>
											{card.name}{" "}
										</DropdownItem>
									);
								})}
							</DropdownMenu>
						</Dropdown>
					</Col>
					<Col md="6" className="md-4">
						<Dropdown>
							<DropdownToggle caret color="primary">
								Select Expense Type
							</DropdownToggle>
							<DropdownMenu basic>
								{this.state.expenseTypes.map((expense, index) => {
									return (
										<DropdownItem
											key={index}
											onClick={this.changeExpenseType.bind(this, expense)}
										>
											{expense.name}{" "}
										</DropdownItem>
									);
								})}
							</DropdownMenu>
						</Dropdown>
					</Col>
				</Row>
				<Input
					value={expense.expenses || ""}
					label="Amount"
					onChange={this.handleChange.bind(this, "expenses")}
					size="sm"
					icon="usd"
				/>
				<Input
					value={expense.vendor || ""}
					label="Vendor"
					onChange={this.handleChange.bind(this, "vendor")}
					size="sm"
					icon="user"
				/>
				<Input
					value={expense.expenseDescription || ""}
					label="Description"
					onChange={this.handleChange.bind(this, "expenseDescription")}
					size="sm"
					icon="commenting-o"
				/>
				<InputFile
					textFieldTitle="only .png or .jpg files are currently supported"
					getValue={this.fileInputHandler.bind(this)}
				/>
				{this.renderReceipt()} <br />
			</div>
		);
	}

	fileInputHandler(value) {
		let reader = new FileReader(),
			file = value[0],
			data,
			rt,
			newStr,
			expense = this.state.nExpense;

		reader.onloadend = () => {
			data = reader.result;
			rt = data.indexOf(",");
			if (rt > -1) {
				newStr = data.slice(rt + 1);
			}
			expense.receipt = newStr;
			this.setState({ nExpense: expense });
		};

		reader.readAsDataURL(file);
	}

	renderReceipt() {
		let expense = this.state.nExpense;
		if (expense.receipt) {
			return (
				<Row>
					<Col md="3">
						<h6>Preview Receipt :</h6>
					</Col>
					<Col md="6">
						<View hover zoom>
							<img
								src={"data:image/jpeg;base64," + expense.receipt}
								style={{ height: 250, width: 500 }}
								alt={"Receipt"}
							/>
						</View>
					</Col>
				</Row>
			);
		} else {
			return <div></div>;
		}
	}

	renderErrorMessage() {
		let res = this.validateForm(),
			color = "green darken-2",
			text = "Save",
			onClick = this.updateExpense.bind(this);

		//if the form is not valid, change the style...BC
		if (res !== true) {
			text = res;
			color = "pink darken-4";
			onClick = () => {
				return;
			};
		}

		//return the chip...BC
		return (
			<Chip
				className="activityHeader"
				size="lg"
				bgColor={color}
				text="white"
				onClick={onClick}
			>
				{text}
			</Chip>
		);
	}

	validateForm() {
		let n = this.state.nExpense;

		if (n.cardType.name === null) {
			return "Select a Card.";
		} else if (n.expenseType.name === null) {
			return "Select an Expense.";
		} else if (n.expenses === 0) {
			return "Enter an Amount.";
		} else if (n.vendor === null) {
			return "Enter Vendor.";
		} else if (n.expenseDescription === null) {
			return "Please enter a description.";
		}
		return true;
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	handleChange = (property, event) => {
		//update the property that the user has edited in the order...BC
		const nExpense = this.state.nExpense;
		nExpense[property] = event.target.value;
		this.setState({ nExpense: nExpense });
	};

	handleDatePickerChange = (property, value) => {
		//update the date property on the object that the user has edited in the order...BC
		const nExpense = this.state.nExpense;
		nExpense[property] = value;
		this.setState({ nExpense: nExpense });
	};

	changeCardType(cardType) {
		let oExpense = this.state.nExpense;
		oExpense.cardType = { id: cardType.id, name: cardType.name };
		this.setState({ nExpense: oExpense });
	}

	changeExpenseType(expenseType) {
		let oExpense = this.state.nExpense;
		oExpense.expenseType = { id: expenseType.id, name: expenseType.name };
		this.setState({ nExpense: oExpense });
	}

	updateExpense() {
		console.log(this.state.nExpense);
		const { currentUser } = this.context;
		let expense = this.state.nExpense;
		expense.when = new Date();
		expense.owner = { id: currentUser.id };

		return ExpenseService.createExpense(expense)
			.then((data) => {
				let nExpense = {
					receipt: null,
					expenseDate: null,
					when: null,
					owner: {},
					expenseDescription: null,
					expenseType: {},
					cardType: {},
					expenses: 0,
					type: "Appointment",
					isExpense: true,
					quality: false,
				};
				this.setState({ nExpense: nExpense });
				toast.success("Saved Successfully!");
			})
			.catch((err) => {
				toast.warn("An error occurred while saving.");
			});
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>

				<Container className="mt-5 w-50">
					<Card>
						<CardBody>
							<CardHeader
								style={{ backgroundColor: "#324191", color: "white" }}
								className="form-header text-center"
							>
								<h2>Add Expense {this.renderErrorMessage()}</h2>
							</CardHeader>
							{this.renderExpenseInfo()}
						</CardBody>
					</Card>
				</Container>
			</div>
		);
	}
}
