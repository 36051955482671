import React from "react";
import { Line, Bar } from "react-chartjs-2";
import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	DatePicker,
	Fa,
	MDBInput,
	MDBSelect,
	Row,
	Spinner,
	ToastContainer,
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import PropTypes from "prop-types";
import "./marketing.css";
import QuickFilter from "../Filters/quickFilter";
import Moment from "moment";

export default class CampaignCharts extends React.Component {
	constructor(props) {
		super(props);

		let stDate = Moment().toDate(),
			edDate = Moment().toDate(),
			prevDate = Moment().toDate(),
			stats = ["New", "In Process", "Ready To Deliver", "Setup", "Cancelled"],
			months = [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"June",
				"July",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			];

		let colorGroups = [
			{ legend: "rgba(75,192,192,0.4)", color: "rgba(75,192,192,1)" },
			{ legend: "rgba(75,90,192,0.4)", color: "rgba(75,90,192,1)" },
			{ legend: "rgba(192,75,115,0.4)", color: "rgba(192,75,115,1)" },
			{ legend: "rgba(75,188,66,0.4)", color: "rgba(75,188,66,1)" },
			{ legend: "rgba(147,81,160,0.4)", color: "rgba(147,81,160,1)" },
			{ legend: "rgba(244,171,68,0.4)", color: "rgba(244,171,68,1)" },
			{ legend: "rgba(191,244,68,0.4)", color: "rgba(191,244,68,1)" },
			{ legend: "rgba(68,244,171,0.4)", color: "rgba(68,244,171,1)" },
			{ legend: "rgba(33,75,89,0.4)", color: "rgba(33,75,89,1)" },
			{ legend: "rgba(81,97,160,0.4)", color: "rgba(81,97,160,1)" },
		];

		this.state = {
			isLoaded: false,
			data: {},
			campaignLabels: [],

			lineData: {},
			barData: {},

			labels: [],

			statuses: stats,
			allStatuses: [],

			showDatePicker: true,
			previousStartDate: prevDate,
			startDate: stDate,
			endDate: edDate,
			selectedStatusNames: ["Setup"],
			quickFilterSelected: null,
			quickFilterOptions: [],

			showAllTime: false,
			colorsList: colorGroups,
			datesList: [],
			labelMode: -1,
			monthList: months,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	componentDidMount() {
		this.getStatusOptions();
		this.getQuickFilterOptions();

		this.getOrders();
	}

	buildFilters() {
		let st = this.state.startDate
				? Moment(this.state.startDate).format("YYYY-M-D")
				: Moment().format("YYYY-M-D"),
			ed = this.state.endDate
				? Moment(this.state.endDate).format("YYYY-M-D")
				: Moment().format("YYYY-M-D");

		return {
			deleted: false,
			startDate: st,
			endDate: ed,
		};
	}

	buildNewResults(orders) {
		let countArr = [],
			monthArr = [],
			yearArr = [],
			dayArr = [],
			statuses = this.state.selectedStatusNames.map((stat) => {
				return stat.replace(" ", "").toLowerCase();
			}) || ["setup"],
			st = Moment(this.state.startDate),
			ed = Moment(this.state.endDate);

		let dayCount = ed.diff(st, "days");

		let arr = orders.filter((ord) => {
			return (
				ord.campaignName != null &&
				(statuses.length === 0
					? true
					: statuses.includes(ord.status.replace(" ", "").toLowerCase()))
			);
		});

		let campaigns = [
			...new Set(
				arr.map((item) => {
					return item.campaignName;
				})
			),
		];

		this.buildDateLists(
			arr.map((item) => {
				return item.lastUpdatedOn;
			})
		);

		arr.forEach((item) => {
			let date = Moment(item.lastUpdatedOn);
			let dat = countArr.filter((res) => {
				if (this.state.labelMode === 0) {
					return (
						res.month === date.month() &&
						res.day === date.day() &&
						res.year === date.year() &&
						res.campaignName === item.campaignName
					);
				} else {
					return (
						res.month === date.month() &&
						res.year === date.year() &&
						res.campaignName === item.campaignName
					);
				}
			});
			if (monthArr.indexOf(date.month()) < 0) {
				monthArr.push(date.month());
			}
			if (yearArr.indexOf(date.year()) < 0) {
				yearArr.push(date.year());
			}
			if (dayCount < 32 && dayCount > 27 && dayArr.indexOf(date.day()) < 0) {
				dayArr.push(date.day());
			}

			if (dat.length === 1) {
				dat[0].count++;
			} else {
				let mo = date.month();

				countArr.push({
					status: item.status,
					day: date.day(),
					month: mo,
					year: date.year(),
					count: 1,
					campaignName: item.campaignName,
				});
			}
		});

		this.setState({ data: orders, campaignLabels: campaigns });
		this.buildLineData();
		this.buildBarData();
		this.setState({ isLoaded: true });
	}

	buildDateLists(datesArr) {
		let st = Moment(this.state.startDate),
			ed = Moment(this.state.endDate),
			labels = [],
			tempDates = [...new Set(datesArr)];

		tempDates.sort((a, b) => {
			let ac = Moment(a),
				bc = Moment(b);

			return ac.diff(bc, "days");
		});

		let dayDiff = ed.diff(st, "days");
		if (dayDiff < 32) {
			if (tempDates.length === 0) {
				this.setState({ labels: [], datesList: [], labelMode: -1 });
			} else {
				this.setState({
					labels: tempDates,
					datesList: tempDates,
					labelMode: 0,
				});
			}
		} else {
			if (tempDates.length === 0) {
				this.setState({ labels: [], datesList: [], labelMode: -1 });
			} else {
				let res = [],
					temp = [];
				tempDates.forEach((item) => {
					let date = Moment(item);
					if (
						temp.indexOf(date.month() + "-" + date.year()) < 0 ||
						temp.length === 0
					) {
						temp.push(date.month() + "-" + date.year());
						res.push(item);
						labels.push(
							this.state.monthList[date.month()] + " - " + date.year()
						);
					}
				});
				this.setState({ labels: labels, datesList: res, labelMode: 1 });
			}
		}
	}

	buildLineData() {
		let data = this.state.data;
		let colors = this.state.colorsList;
		let dates = this.state.datesList;
		let camps = this.state.campaignLabels;

		let lineData = [],
			counter = 0;

		camps.forEach((camp) => {
			let results = [];
			dates.forEach((date) => {
				let dt = Moment(date);
				let res = data.filter((item) => {
					let itemDt = Moment(item.lastUpdatedOn);
					if (this.state.labelMode === 1) {
						return (
							item.campaignName === camp &&
							itemDt.month() === dt.month() &&
							itemDt.year() === dt.year()
						);
					} else {
						return (
							item.campaignName === camp &&
							itemDt.month() === dt.month() &&
							itemDt.year() === dt.year() &&
							itemDt.day() === dt.day()
						);
					}
				});

				if (!res || res.length === 0) {
					results.push(0);
				} else {
					results.push(res.length);
				}
			});

			// now build the graph part
			let set = {
				label: camp,
				fill: false,
				lineTension: 0.1,
				backgroundColor: colors[counter].legend,
				borderColor: colors[counter].color,
				borderCapStyle: "butt",
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: "miter",
				pointBorderColor: colors[counter].color,
				pointBackgroundColor: "#fff",
				pointBorderWidth: 1,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: colors[counter].color,
				pointHoverBorderColor: "rgba(220,220,220,1)",
				pointHoverBorderWidth: 2,
				pointRadius: 1,
				pointHitRadius: 10,
				data: results,
			};

			lineData.push(set);

			if (counter === 9) {
				counter = 0;
			} else {
				counter++;
			}
		});

		this.setState({ lineData: lineData });
	}

	buildBarData() {
		let data = this.state.data;
		let colors = this.state.colorsList;
		let camps = this.state.campaignLabels;
		let labs = this.state.statuses;

		let barData = [],
			counter = 0;

		camps.forEach((camp) => {
			let results = [];
			labs.forEach((stat) => {
				let res = data.filter((item) => {
					return (
						item.campaignName === camp &&
						item.status.replace(" ", "").toLowerCase() ===
							stat.replace(" ", "").toLowerCase()
					);
				});

				if (!res || res.length === 0) {
					results.push(0);
				} else {
					results.push(res.length);
				}
			});

			// now build the graph part
			let set = {
				label: camp,
				data: results,
				backgroundColor: colors[counter].legend,
				borderWidth: 1,
			};

			barData.push(set);

			if (counter === 9) {
				counter = 0;
			} else {
				counter++;
			}
		});

		this.setState({ barData: barData });
	}

	getOrders() {
		let filters = this.buildFilters();

		OrderService.getAllOrdersNative(filters)
			.then((res) => {
				this.buildNewResults(res);
			})
			.catch((err) => {
				this.setState({ isLoaded: true });
			});
	}

	handleQuickFilter = (e) => {
		let val = parseInt(e[0]);
		let a = QuickFilter.getDates(val),
			st = this.state,
			t = this;

		if (a && a.length > 0) {
			st.startDate = a[0];
			st.endDate = a[1];
		} else {
			st.startDate = "";
			st.endDate = "";
		}

		this.setState({
			startDate: st.startDate,
			endDate: st.endDate,
			showDatePicker: false,
			quickFilterSelected: e[0],
		});

		setTimeout(() => {
			t.setState({
				showDatePicker: true,
			});
		}, 500);
	};

	handleCheckChange(e) {
		let res = !this.state.showAllTime;
		if (res) {
			this.setState({
				previousStartDate: this.state.startDate,
				startDate: Moment([2000, 0, 1]).toDate(),
				endDate: Moment().toDate(),
			});
		} else if (this.state.previousStartDate) {
			this.setState({
				startDate: this.state.previousStartDate,
				previousStartDate: null,
			});
		}
		this.setState({ showAllTime: res });
	}

	handleSearchClick() {
		this.setState({ isLoaded: false });
		this.getOrders();
	}

	getQuickFilterOptions() {
		let filterOptions = QuickFilter.getOptions(),
			arr = [];

		filterOptions.forEach((item) => {
			arr.push({ text: item.label, value: item.value.toString() });
		});
		this.setState({ quickFilterOptions: arr });
	}

	getStatusValue(e) {
		this.setState({
			selectedStatusNames: e,
		});
	}

	getStatusOptions() {
		let oldNames = this.state.selectedStatusNames,
			a = [];

		a.push({
			checked: oldNames.indexOf("New") > -1,
			disabled: false,
			text: "New",
			value: "New",
		});

		a.push({
			checked: oldNames.indexOf("InProcess") > -1,
			disabled: false,
			text: "In Process",
			value: "InProcess",
		});

		a.push({
			checked: oldNames.indexOf("ReadyToDeliver") > -1,
			disabled: false,
			text: "Ready To Deliver",
			value: "ReadyToDeliver",
		});

		a.push({
			checked: oldNames.indexOf("Setup") > -1,
			disabled: false,
			text: "Setup",
			value: "Setup",
		});

		a.push({
			checked: oldNames.indexOf("Cancelled") > -1,
			disabled: false,
			text: "Cancelled",
			value: "Cancelled",
		});

		this.setState({
			allStatuses: a,
		});
	}

	renderLineChart() {
		let data = {
			labels: this.state.labels,
			datasets: this.state.lineData,
		};

		return (
			<div>
				<hr />
				<Line data={data} options={{ responsive: true }} />
			</div>
		);
	}

	renderBarChart() {
		let data = {
			labels: this.state.statuses,
			datasets: this.state.barData,
		};
		return (
			<div>
				<hr />
				<Bar data={data} options={{ responsive: true }} />
			</div>
		);
	}

	renderQuickFilter() {
		return (
			<Col size="10">
				<MDBSelect
					color="danger"
					selected={"Quick Filter"}
					style={{ maxHeight: "100px" }}
					options={this.state.quickFilterOptions}
					getValue={this.handleQuickFilter.bind(this)}
					value={this.state.quickFilterSelected}
					disabled={this.state.showAllTime}
					className={this.state.showAllTime ? "disabled" : ""}
					readOnly={this.state.showAllTime}
				/>
				<MDBInput
					id="allTimeCheck"
					type="checkbox"
					filled
					checked={this.state.showAllTime}
					onChange={this.handleCheckChange.bind(this)}
					label={"Show All Time?"}
				/>
			</Col>
		);
	}

	renderStartDate() {
		if (this.state.showDatePicker) {
			return (
				<Col size="12">
					<label>Start Date:</label>
					<DatePicker
						format="MM-DD-YYYY"
						keyboard
						value={this.state.startDate || null}
						getValue={(evt) => this.setState({ startDate: evt })}
						mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
						disabled={this.state.showAllTime}
					/>
				</Col>
			);
		} else {
			return (
				<div
					style={{ marginLeft: "15px", marginRight: "55px" }}
					className="spinner-border text-info"
					role="status"
				>
					<span className="sr-only">Loading...</span>
				</div>
			);
		}
	}

	renderEndDate() {
		if (this.state.showDatePicker) {
			return (
				<Col size="12">
					<label>End Date:</label>
					<DatePicker
						format="MM-DD-YYYY"
						keyboard
						value={this.state.endDate || null}
						getValue={(evt) => this.setState({ endDate: evt })}
						mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
						disabled={this.state.showAllTime}
					/>
				</Col>
			);
		} else {
			return (
				<div
					style={{ marginLeft: "15px", marginRight: "55px" }}
					className="spinner-border text-info"
					role="status"
				>
					<span className="sr-only">Loading...</span>
				</div>
			);
		}
	}

	renderStatuses() {
		return (
			<span>
				<Col>
					<MDBSelect
						multiple
						color="danger"
						selected={"Choose Statuses"}
						style={{ maxHeight: "100px" }}
						options={this.state.allStatuses}
						getValue={this.getStatusValue.bind(this)}
					/>
				</Col>
			</span>
		);
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderTables() {
		return (
			<div>
				<Row>
					<Container>
						<div>{this.renderLineChart()}</div>
					</Container>
				</Row>
				<Row>
					<Container>
						<div>{this.renderBarChart()}</div>
					</Container>
				</Row>
			</div>
		);
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={true}
					newestOnTop={true}
					autoClose={3000}
					position={"top-right"}
					style={{ marginTop: "75px" }}
				/>
				<Card
					style={{
						paddingBottom: "1rem",
						paddingTop: ".5rem",
						marginBottom: "6rem",
						marginLeft: "1rem",
						marginRight: "1rem",
					}}
					className="mt-2"
				>
					<CardBody style={{ padding: 0 }}>
						<Row className="filter-row">
							<Col>{this.renderQuickFilter()}</Col>
							<Col>{this.renderStartDate()}</Col>
							<Col>{this.renderEndDate()}</Col>
							<Col>{this.renderStatuses()}</Col>
						</Row>
						<Row>
							<Col size="12" style={{ textAlign: "right" }}>
								<Button
									size="md"
									color={"primary"}
									onClick={this.handleSearchClick.bind(this)}
								>
									<Fa icon={"search"} />
								</Button>
							</Col>
						</Row>
						<hr style={{ width: "100%", marginTop: 4, marginBottom: 4 }} />
						{!this.state.isLoaded
							? this.renderLoadingSpinner()
							: this.renderTables()}
					</CardBody>
				</Card>
			</div>
		);
	}
}
