import React from "react";
import {
	Button,
	Card,
	CardFooter,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import DeploymentService from "./deploymentService";
import Select from "react-select";

export default class deployment extends React.Component {
	constructor(props) {
		super(props);

		const platformsList = [
			{
				label: "IOS",
				value: "IOS",
			},
			{
				label: "Android",
				value: "ANDROID",
			},
		];

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Version",
					field: "version",
					sort: "asc",
				},
				{
					label: "Download URL",
					field: "downloadurl",
					sort: "asc",
				},
				{
					label: "Platform",
					field: "platform",
					sort: "asc",
				},
				{
					label: "Deployed By",
					field: "deployedby",
					sort: "asc",
				},
				{
					label: "Deployed At",
					field: "deployedat",
					sort: "asc",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			name: "",
			currIndex: -1,
			errorMessage: "",
			currDeployment: {},
			platformsList: platformsList,
		};
	}

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveDeployments();
	}

	retrieveDeployments() {
		let t = this;
		return DeploymentService.getAllDeployments()
			.then((res) => {
				let arr = [],
					d = this.state.data;
				res.content.forEach((deploy) => {
					arr.push({
						id: deploy.id,
						version: deploy.version,
						downloadUrl: t.downloadURL(deploy.downloadUrl),
						platform: deploy.platform,
						deployedBy: deploy.deployedBy.name,
						deployedAt: new Date(deploy.deployedAt).toLocaleString(),
					});
				});

				d.rows = arr;

				t.setState({
					data: d,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	downloadURL(url) {
		return (
			<a className="blue-text" href={url}>
				{url}
			</a>
		);
	}

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
			currDeployment: {},
		});
	};

	renderEditModal() {
		let cd = this.state.currDeployment;
		return (
			<Modal
				fullHeight
				position="bottom"
				isOpen={this.state.editModal}
				toggle={this.editToggle}
			>
				<ModalHeader
					style={{ backgroundColor: "white" }}
					toggle={this.editToggle}
				>
					New Deployment
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					<div>
						<p style={{ color: "red" }}>{this.state.errorMessage}</p>
						<Input
							label="Version"
							value={cd.version}
							onChange={this.handleEditChange.bind(this, "version")}
						/>
						<Select
							placeholder={"Select Platform..."}
							options={this.state.platformsList}
							onChange={this.handleEditChange.bind(this, "platform")}
						/>
						<Input
							label="Download URL"
							value={cd.downloadUrl}
							onChange={this.handleEditChange.bind(this, "downloadUrl")}
						/>
					</div>
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button color="secondary" onClick={this.handleEditClose}>
						Close
					</Button>{" "}
					<Button color="primary" onClick={this.handleEditSave}>
						Save changes
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	// *** Append new deployment ***
	handleEditSave = () => {
		if (this.validateEdit()) {
			let deploy = this.state.currDeployment,
				t = this;
			return DeploymentService.createDeployment(deploy)
				.then((res) => {
					t.retrieveDeployments();
					this.setState({
						editModal: !this.state.editModal,
						errorMessage: "",
					});
					toast.success("Deployment Added!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateEdit() {
		let a = this.state.currDeployment;
		if (a.version === undefined || a.version === "") {
			this.setError("Please add a Version");
			return false;
		} else if (a.platform === undefined || a.platform === "") {
			this.setError("Please select a Platform");
			return false;
		} else if (a.downloadUrl === undefined || a.downloadUrl === "") {
			this.setError("Please add URL");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleEditClose = () => {
		this.setState({
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
			currDeployment: {},
		});
	};

	handleEditChange(property, e) {
		let c = this.state.currDeployment;
		if (property === "platform") {
			c[property] = e.value;
		} else {
			c[property] = e.target.value;
			this.setState({
				currDeployment: c,
			});
		}
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader style={{ textAlign: "center" }}>Deployments</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
					<CardFooter>
						<Button
							style={{ float: "right" }}
							color="secondary"
							onClick={this.editToggle}
						>
							Create New Deployment
						</Button>
					</CardFooter>
				</Card>
				{this.renderEditModal()}
			</Container>
		);
	}
}
