import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBIcon,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast, MDBContainer, MDBRow,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import ContactTypeService from "./contactTypesService";
import ReactTooltip from "react-tooltip";

export default class contactTypes extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
				},
				{
					label: "Delete",
					field: "delete",
					sort: "asc",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			confirmationModal: false,
			addNewModal: false,
			name: "",
			currIndex: -1,
			errorMessage: "",
			newContact:{
			name:"",
			},
			currContact: {},
		};
	}

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveContactType();
	}

	retrieveContactType() {
		let t = this;
		return ContactTypeService.getAllContactTypes()
			.then((res) => {
				let arr = [],
					d = this.state.data;
				res.forEach((contact, index) => {
					arr.push({
						id: contact.id,
						name: contact.name,
						edit: t.renderEditButton(contact, index),
						remove: t.renderRemoveButton(contact),
					});
				});

				d.rows = arr;

				t.setState({
					data: d,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderEditButton(contact, index) {
		// let a = JSON.parse(JSON.stringify(account));
		return (
				<MDBIcon icon="edit"
						 onClick={() => this.handleEditClick(contact, index)}
						 style={{color: "#7ac5ff"}}
				/>
		);
	}

	renderRemoveButton(contact) {
		return (
				<MDBIcon icon="trash"
						 onClick={() => this.handleRemoveClick(contact)}
						 style={{color: "#db0404"}}
				/>
			);
	}

	handleConfirmationDelete(id){
		ContactTypeService.deleteContact(id)
			.then((res) => {
				this.retrieveContactType();
				toast.success("Contact Type Removed");
				this.handleConfirmationClose();
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Contact Type");
			});
	}

	handleRemoveClick(contact) {
		let i = {
			id: contact.id,
			name: contact.name,
		};
		this.setState({
			confirmationModal: !this.state.confirmationModal,
			currContact: i,
		});
	}

	handleEditClick(account, index) {
		let a = {
			id: account.id,
			name: account.name,
		};
		this.setState({
			currContact: a,
			editModal: !this.state.editModal,
			currIndex: index,
		});
	}

	handleConfirmationClose= () => {
		let i = {
			id: -1,
			name: "",
		};
		this.setState({
			currContact: i,
			currIndex: -1,
			errorMessage: "",
			confirmationModal: !this.state.confirmationModal,
		});
	}

	confirmationToggle = () => {
		this.setState({
			confirmationModal: !this.state.confirmationModal,
			errorMessage: "",
		})
	}

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};

	handleAddNewChange(e) {
		let i = this.state.newContact;
		i.name = e.target.value;
		this.setState({
				newContact: i,
			}
		)
	}

	renderConfirmationModal() {
		return (
			<Modal isOpen={this.state.confirmationModal} toggle={this.confirmationToggle}>
				<ModalHeader
					style={{ backgroundColor: "#F93154", color: "white" }}
					toggle={this.confirmationToggle}
				>
					Confirm Delete
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderConfirmationModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Confirm Delete"}
						onClick={() => this.handleConfirmationDelete(this.state.currContact.id)}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleConfirmationClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderConfirmationModalBody() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<MDBContainer>
					<MDBRow>
						Are you sure you want to delete {this.state.currContact.name}?
					</MDBRow>
				</MDBContainer>
			</div>
		);
	}

	renderAddNewModal() {
		return (
			<Modal isOpen={this.state.addNewModal} toggle={this.handleAddNewClose}>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.handleAddNewClose}
				>
					Add New Contact Type
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderAddNewModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Add New Contact Type"}
						onClick={this.addNewContact}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleAddNewClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderAddNewModalBody() {
		if (this.state.addNewModal) {
			return (
				<div>
					<p style={{ color: "red" }}>{this.state.errorMessage}</p>
					<Input
						outline
						label="Notification Name/Description"
						onChange={this.handleAddNewChange.bind(this)}
					/>
				</div>
			);
		}
	}

	renderEditModal(){
		return (
			<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.editToggle}
				>
					Edit Contact Type
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					<div>
						<p style={{ color: "red" }}>{this.state.errorMessage}</p>
						<Input
							outline
							label="Name"
							value={this.state.currContact.name}
							onChange={this.handleEditChange.bind(this)}
						/>
					</div>
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Update Contact Type"}
						onClick={this.handleEditSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Close"}
						onClick={this.handleEditClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>

					<ReactTooltip />
				</ModalFooter>
			</Modal>
		);
	}

	handleAddNewClose = () => {
		this.setState({
			newContact:{
				name: "",
			},
			addNewModal: !this.state.addNewModal,
			errorMessage: "",
		})
	}

	handleEditSave = () => {
		if (this.validateEdit()) {
			let d = this.state.data,
				i = this.state.currIndex,
				t = this;
			return ContactTypeService.updateContactType(this.state.currContact)
				.then((contact) => {
					d.rows[i].name = contact.name;
					d.rows[i].edit = t.renderEditButton(contact, i);
					this.setState({
						data: d,
						editModal: !this.state.editModal,
					});
					toast.success("Contact Type Edited!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateAddNew(){
		let a = this.state.newContact;
		if (a.name === "") {
			this.setError("Please add a Contact Name");
			return false;
		}
		return true;
	}

	addNewContact = () => {
		if(this.validateAddNew()){
			let obj = this.state.newContact;
			return ContactTypeService.createContactType(obj)
				.then((res) => {
					this.setState({
						errorMessage: "",
						name: "",
					});
					this.retrieveContactType();
					this.handleAddNewClose();
					toast.success(obj.name + " Contact Type has been Added!");
				})
				.catch((err) => {
					toast.warn("An error occurred adding a new Contact Type");
				});
		}
	}

	validateEdit() {
		let a = this.state.currContact;
		if (a.name === "") {
			this.setError("Please add a Contact Name");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleEditClose = () => {
		this.setState({
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
			currContact: {},
		});
	};

	handleEditChange(e) {
		let c = this.state.currContact;
		c.name = e.target.value;
		this.setState({
			currContact: c,
		});
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable
					small
					striped
					style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	addNewClick = () => {
		this.setState({
			addNewModal: true
		})
	};

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30}}
					>
						Contact Types
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Contact Type"}
							onClick={this.addNewClick}
						>
							<MDBIcon icon="address-card" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				<ReactTooltip />
				{this.renderEditModal()}
				{this.renderConfirmationModal()}
				{this.renderAddNewModal()}
			</Container>
		);
	}
}
