import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Input,
	Spinner,
	toast,
	ToastContainer,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	MDBIcon,
	CardHeader,
} from "mdbreact";
import {
	Grid,
	Table,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";
import { PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import PropTypes from "prop-types";
import "./marketing.css";

import MarketingService from "../Security/MarketingService/marketingService";
import marketingService from "../Security/MarketingService/marketingService";
import ReactTooltip from "react-tooltip";

const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

export default class CategoryList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					title: "ID",
					name: "id",
				},
				{
					title: "Name",
					name: "name",
				},
				{
					title: "Description",
					name: "description",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			newModal: false,
			currCategory: {},
			errorMessage: "",
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	componentDidMount() {
		this.retrieveCategories();
	}

	componentDidUpdate() {
		if (!this.state.data.rows.length) {
			this.retrieveCategories();
		}
	}

	retrieveCategories() {
		MarketingService.findAllCategories().then((cat) => {
			let dt = this.state.data;
			dt.rows = cat.content;
			this.setState({ data: dt, isLoaded: true });
		});
	}

	handleEditClick(row) {
		let category = JSON.parse(JSON.stringify(row));
		this.setState({
			currCategory: category,
			editModal: !this.state.editModal,
		});
	}

	addNewClick = () => {
		let camp = {
			id: null,
			name: null,
			description: null,
		};
		this.setState({
			currCategory: camp,
			newModal: !this.state.newModal,
		});
	};

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};

	newToggle = () => {
		this.setState({
			newModal: !this.state.newModal,
			errorMessage: "",
		});
	};

	renderEditModal() {
		if (this.state.editModal) {
			let exp = this.state.currCategory;
			return (
				<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
					<ModalHeader
						style={{ backgroundColor: "#90A4AE", color: "white" }}
						toggle={this.editToggle}
					>
						Edit Category
					</ModalHeader>
					<ModalBody style={{ backgroundColor: "white" }}>
						<div>
							<p style={{ color: "red" }}>{this.state.errorMessage}</p>
							<Input
								label={"Campaign Name"}
								onChange={this.handleChange.bind(this, "name")}
								value={exp.name}
							/>
							<Input
								label={"Description"}
								onChange={this.handleChange.bind(this, "description")}
								value={exp.description}
							/>
						</div>
					</ModalBody>
					<ModalFooter style={{ backgroundColor: "white" }}>
						<Button
							style={{ float: "left" }}
							floating
							size="sm"
							color={"success"}
							data-tip={"Update Marketing Category"}
							onClick={this.handleEditSave}
						>
							<MDBIcon icon="check" style={{ fontSize: "2em" }} />
						</Button>
						<Button
							style={{ float: "left" }}
							floating
							size="sm"
							color={"red"}
							data-tip={"Cancel"}
							onClick={this.handleEditClose}
						>
							<MDBIcon icon="times" style={{ fontSize: "2em" }} />
						</Button>
					</ModalFooter>
					<ReactTooltip />
				</Modal>
			);
		}
		return <div></div>;
	}

	renderNewModal() {
		if (this.state.newModal) {
			let exp = this.state.currCategory;
			return (
				<Modal isOpen={this.state.newModal} toggle={this.newToggle}>
					<ModalHeader
						style={{ backgroundColor: "#90A4AE", color: "white" }}
						toggle={this.newToggle}
					>
						Create Category
					</ModalHeader>
					<ModalBody style={{ backgroundColor: "white" }}>
						<div>
							<p style={{ color: "red" }}>{this.state.errorMessage}</p>
							<Input
								label={"Category Name"}
								onChange={this.handleChange.bind(this, "name")}
								value={exp.name || undefined}
							/>
							<Input
								label={"Description"}
								onChange={this.handleChange.bind(this, "description")}
								value={exp.description || undefined}
							/>
						</div>
					</ModalBody>

					<ModalFooter style={{ backgroundColor: "white" }}>
						<Button
							style={{ float: "left" }}
							floating
							size="sm"
							color={"success"}
							data-tip={"Save New Marketing Category"}
							onClick={this.handleNewSave}
						>
							<MDBIcon icon="check" style={{ fontSize: "2em" }} />
						</Button>
						<Button
							style={{ float: "left" }}
							floating
							size="sm"
							color={"red"}
							data-tip={"Cancel"}
							onClick={this.handleNewClose}
						>
							<MDBIcon icon="times" style={{ fontSize: "2em" }} />
						</Button>
					</ModalFooter>
					<ReactTooltip />
				</Modal>
			);
		}
		return <div></div>;
	}

	handleEditSave = () => {
		if (this.state.currCategory.name) {
			return marketingService
				.updateCategory(this.state.currCategory)
				.then((res) => {
					this.setState({
						editModal: !this.state.editModal,
						errorMessage: "",
					});
					this.retrieveCategories();
				})
				.catch((err) => toast.warn("An error occurred while saving."));
		}
	};

	handleNewSave = () => {
		if (this.state.currCategory.name) {
			return marketingService
				.createCategory(this.state.currCategory)
				.then((res) => {
					this.setState({
						newModal: !this.state.newModal,
						errorMessage: "",
					});
					this.retrieveCategories();
				})
				.catch((err) => toast.warn("An error occurred while saving."));
		}
	};

	handleEditClose = () => {
		this.setState({
			errorMessage: "",
			editModal: !this.state.editModal,
		});
	};

	handleNewClose = () => {
		this.setState({
			errorMessage: "",
			newModal: !this.state.newModal,
		});
	};

	handleChange = (property, e) => {
		let exp = this.state.currCategory;
		exp[property] = e.target.value;
		this.setState({
			currCategory: exp,
		});
	};

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderTable() {
		const TableRow = ({ row, ...restProps }) => (
			<Table.Row
				{...restProps}
				onClick={() => this.handleEditClick(row)}
				style={{ cursor: "pointer" }}
			/>
		);

		if (this.state.isLoaded === true) {
			return (
				<Grid
					style={{ maxWidth: "1800px !important" }}
					rows={this.state.data.rows}
					rootComponent={Root}
					columns={this.state.data.columns}
				>
					<PagingState defaultCurrentPage={0} pageSize={10} />
					<IntegratedPaging />
					<VirtualTable rowComponent={TableRow} />
					<TableHeaderRow />
					<PagingPanel />
				</Grid>
			);
		} else {
			return <div />;
		}
	}

	render() {
		return (
			<div
				className={"campaignList"}
				style={{ marginLeft: "5%", marginRight: "5%" }}
			>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={5000}
				/>
				<Card style={{ marginBottom: "1%" }}>
					<CardHeader
						color={"blue-grey lighten-2"}
						style={{ textAlign: "center" }}
					>
						Marketing Categories
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Category"}
							onClick={this.addNewClick}
						>
							<MDBIcon icon="certificate" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>

					<CardBody>
						{this.state.isLoaded
							? this.renderTable()
							: this.renderLoadingSpinner()}
					</CardBody>
				</Card>
				<ReactTooltip />
				{this.renderEditModal()}
				{this.renderNewModal()}
			</div>
		);
	}
}
