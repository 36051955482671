import React from "react";
import {
	Fa,
	Button,
	Card,
	CardBody,
	Container,
	CardHeader,
	Input,
	MDBTable,
	MDBTableHead,
	MDBTableBody,
	Spinner,
	MDBBtn,
	MDBIcon,
	MDBModal,
	MDBContainer,
	MDBRow,
	MDBCol,
} from "mdbreact";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import patientAppService from "../Security/PatientService/PatientAppService";

export default class PatientApprovals extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
				},
				{
					label: "Patient ID",
					field: "patientId",
					sort: "asc",
				},
				{
					label: "Pin",
					field: "pin",
					sort: "asc",
				},
				{
					label: "Email",
					field: "email",
					sort: "asc",
				},
				{
					label: "Birth Date",
					field: "birthdate",
					sort: "asc",
				},
				{
					label: "Zip Code",
					field: "zipcode",
					sort: "asc",
				},
				{
					label: "Status",
					field: "status",
					sort: "asc",
				},
				{
					label: "Status Date",
					field: "statusDate",
					sort: "asc",
				},
				{
					label: "Changed By",
					field: "changedBy",
					sort: "asc",
				},
				{
					label: "Operations",
					field: "buttons",
				},
			],
			rows: [],
		};

		this.state = {
			pendingApprovals: [],
			completedApprovals: [],
			loaded: false,
			data: data,
			fullData: { columns: data.columns },
			selectedPatient: null,
			isApproval: false,
			isApprovalError: false,
			onlyNew: false,
			onlyDenied: false,
			isChangingPassword: false,
			newPassword: null,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	componentDidMount() {
		this.retrieveApprovals();
	}

	toggleModified() {
		let fd = this.state.fullData,
			dd = this.state.data,
			r = fd.rows.slice(),
			on = !this.state.onlyNew;

		r = r.filter((v) => {
			if (on === true) {
				return v.statusDate == null;
			}
			return v;
		});

		dd.rows = r;

		this.setState({
			onlyNew: on,
			onlyDenied: false,
			data: dd,
		});
	}

	toggleDenied() {
		let fd = this.state.fullData,
			dd = this.state.data,
			r = fd.rows.slice(),
			on = !this.state.onlyDenied;

		r = r.filter((v) => {
			if (on === true) {
				return v.status === "Denied";
			}
			return v;
		});

		dd.rows = r;

		this.setState({
			onlyNew: false,
			onlyDenied: on,
			data: dd,
		});
	}

	retrieveApprovals() {
		patientAppService
			.getPendingApprovals()
			.then((res) => {
				let data = this.state.data,
					fd = this.state.fullData,
					rows = [];

				res.forEach((item) => {
					rows.push({
						id: item.id,
						name: item.name,
						patientId: item.patientId,
						pin: item.pin,
						email: item.email,
						birthdate: item.birthdate,
						zipcode: item.zipcode,
						status:
							item.approved === true
								? "Approved"
								: item.approved === false
								? "Denied"
								: null,
						statusDate: item.statusDate,
						changedBy: item.statusChangedBy,
						buttons: this.renderButtons(item),
					});
				});
				data.rows = rows;
				fd.rows = rows.slice();

				this.setState({
					loaded: true,
					data: data,
					fullData: fd,
				});

				this.toggleModified();
			})
			.catch((err) => {
				console.log(err);
				this.setState({ loaded: true });
			});
	}

	approvePatient(patient) {
		if (patient.patientId !== null) {
			patientAppService
				.approvePatient(patient.id, patient.patientId)
				.then((res) => {
					this.setState({
						loaded: false,
						isApproval: false,
						selectedPatient: null,
					});
					this.retrieveApprovals();
				})
				.catch((err) => {
					console.log(err);
					this.setState({
						loaded: false,
						isApproval: false,
						selectedPatient: null,
					});
					this.retrieveApprovals();
				});
		} else {
			this.setState({
				isApprovalError: true,
			});
		}
	}

	changePassword(patientId) {
		var pWord = this.state.newPassword;
		if (pWord) {
			patientAppService
				.changePassword(patientId, pWord)
				.then((res) => {
					console.log("password changed");
					this.setState({
						isChangingPassword: false,
						newPassword: null,
					});
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			alert("You must add the new password.");
			return;
		}
	}

	denyPatient(id) {
		patientAppService
			.denyPatient(id)
			.then((res) => {
				this.setState({ loaded: false });
				this.retrieveApprovals();
			})
			.catch((err) => {
				console.log(err);
				this.setState({ loaded: false });
				this.retrieveApprovals();
			});
	}

	renderButtons(patient) {
		return (
			<MDBCol>
				{this.renderApproveButton(patient)}
				{this.renderDenyButton(patient.id)}
				{this.renderChangePasswordButton(patient)}
			</MDBCol>
		);
	}

	renderApproveButton(patient) {
		return (
			<Button
				floating
				style={{ marginTop: "0" }}
				onClick={() => {
					this.setState({
						selectedPatient: patient,
						isApproval: true,
					});
				}}
				data-tip={"Approve"}
				size="sm"
				color={"green"}
			>
				<Fa icon="check-circle" />
			</Button>
		);
	}

	renderDenyButton(id) {
		return (
			<MDBBtn
				floating
				style={{ marginTop: "0" }}
				onClick={() => this.denyPatient(id)}
				data-tip={"Deny"}
				size="sm"
				color={"red"}
			>
				<MDBIcon icon="times-circle" />
			</MDBBtn>
		);
	}

	renderChangePasswordButton(patient) {
		return (
			<MDBBtn
				floating
				style={{ marginTop: "0" }}
				onClick={() => {
					this.setState({
						selectedPatient: patient,
						isChangingPassword: true,
					});
				}}
				data-tip={"Change Password"}
				size="sm"
				color={"blue"}
			>
				<Fa icon="edit" />
			</MDBBtn>
		);
	}

	renderTable() {
		if (this.state.loaded === true) {
			return (
				<MDBTable className={"smallerText"} style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div />;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderApprovalModal() {
		var pt = this.state.selectedPatient;

		return (
			<MDBModal
				centered
				isOpen={this.state.isApproval}
				toggle={() => this.setState({ isApproval: false })}
			>
				<MDBContainer>
					<CardHeader
						style={{ marginBottom: 2 }}
						color={"indigo"}
						className="form-header text-center"
					>
						Approve Patient App Request
					</CardHeader>
					<MDBRow style={{ paddingTop: "10px" }}>
						<MDBCol>
							<b>Name</b>
						</MDBCol>
						<MDBCol>
							<b>Zip Code</b>
						</MDBCol>
						<MDBCol>
							<b>Birthdate</b>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol>{pt != null ? pt.name : ""}</MDBCol>
						<MDBCol>{pt != null ? pt.zipcode : ""}</MDBCol>
						<MDBCol>{pt != null ? pt.birthdate : ""}</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol size={"9"}>
							<div>
								<Input
									onChange={(evt) => {
										let pat = this.state.selectedPatient;
										pat.patientId = evt.target.value;
										this.setState({ selectedPatient: pat });
									}}
									filled
									label="Patient ID"
								/>
							</div>
						</MDBCol>
						<MDBCol size={"3"}>
							<div style={{ paddingTop: 25 }}>
								<MDBBtn
									size={"sm"}
									color={"success"}
									onClick={() =>
										this.approvePatient(this.state.selectedPatient)
									}
								>
									Save
								</MDBBtn>
							</div>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol size={"12"}>
							{this.state.isApprovalError === true ? (
								<div style={{ paddingTop: 25 }}>
									<label style={{ color: "red" }}>
										* You must add the Patient ID to approve patient access.
									</label>
								</div>
							) : (
								""
							)}
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBModal>
		);
	}

	renderChangePasswordModal() {
		return (
			<MDBModal
				centered
				isOpen={this.state.isChangingPassword}
				toggle={() => this.setState({ isChangingPassword: false })}
			>
				<MDBContainer>
					<CardHeader
						style={{ marginBottom: 2 }}
						color={"indigo"}
						className="form-header text-center"
					>
						Change Patient Password
					</CardHeader>
					<MDBRow>
						<MDBCol size={"12"}>
							<div>
								<Input
									onChange={(evt) => {
										this.setState({ newPassword: evt.target.value });
									}}
									filled
									label="New Password"
								/>
							</div>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol size={"6"}></MDBCol>
						<MDBCol size={"3"}>
							<div style={{ paddingTop: 25 }}>
								<MDBBtn
									size={"sm"}
									color={"red"}
									onClick={() =>
										this.setState({
											isChangingPassword: false,
											newPassword: null,
										})
									}
								>
									cancel
								</MDBBtn>
							</div>
						</MDBCol>
						<MDBCol size={"3"}>
							<div style={{ paddingTop: 25 }}>
								<MDBBtn
									size={"sm"}
									color={"success"}
									onClick={() =>
										this.changePassword(this.state.selectedPatient.id)
									}
								>
									Save
								</MDBBtn>
							</div>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBModal>
		);
	}

	render() {
		if (this.state.loaded === true) {
			return (
				<Container style={{ maxWidth: "98%" }} className="mt-5">
					<Card>
						<CardHeader
							color={"blue-grey lighten-2"}
							style={{ textAlign: "center" }}
						>
							Patient App Approvals
							<div
								style={{ cursor: "pointer", float: "left", color: "white", paddingRight: 10 }}
							>
								<MDBIcon
									onClick={() => this.toggleModified()}
									icon="lightbulb"
									color={"#000"}
									style={{ fontSize: "1.5em" }}
									data-tip={"Toggle All / New"}
								/>
							</div>
							<div
								style={{ cursor: "pointer", float: "left", color: "white" }}
							>
								<MDBIcon
									onClick={() => this.toggleDenied()}
									icon="ban"
									color={"#000"}
									style={{ fontSize: "1.5em" }}
									data-tip={"Toggle All / Denied"}
								/>
							</div>
							<div
								style={{ cursor: "pointer", float: "right", color: "white" }}
							>
								<a href={"/patientList"} target={"_blank"} rel="opener">
									<MDBIcon
										icon="search"
										color={"#000"}
										style={{ fontSize: "1.5em" }}
										data-tip={"Search for patients"}
									/>
								</a>
							</div>
						</CardHeader>
						<CardBody>{this.renderTable()}</CardBody>

						<ReactTooltip />

						{this.renderApprovalModal()}
						{this.renderChangePasswordModal()}
					</Card>
				</Container>
			);
		} else {
			return this.renderLoadingSpinner();
		}
	}
}
