import React from "react";
import PropTypes from "prop-types";
import {
    Container,
    Spinner,
    Col,
    CardHeader,
    Button,
    Input,
    InputFile,
    MDBDatePicker,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    toast,
    ToastContainer,
    View,
    MDBIcon,
    Fa,
    MDBBtn, MDBCard, MDBCol, MDBRow, Navbar,
} from "mdbreact";
import UserService from "../Security/UserService/userService";
import Select from "react-select";

import ReactTooltip from "react-tooltip";

const formatDate = (date) => {
    if (date == null || date === "") {
        return "";
    }
    return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "numeric",
        day: "2-digit",
    }).format(date);
};

export default class TechList extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            isLoaded: true,
            allLocs: [],
            allTechs: [],
            locationsSelect: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            locationsSelected: [],
        };

    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allLocations: PropTypes.array,
        techs: PropTypes.array,
    };

    componentDidMount() {
        this.renderLocationsOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {allLocations} = this.context;
        let st = this.state;

        if (st.allLocs.length !== allLocations.length) {
            this.renderLocationsOptions();
        }
    }

    renderTechOptions(e) {
        const {techs} = this.context;
        let options = [];

        if (e === undefined || e.length === 0) {
            options = techs;
        } else {
            techs.map((tech) => {
                let hasLocation = tech.locations.find(l => {
                    return l.id === e.value
                })

                if (hasLocation != null) {
                    return options.push(tech);
                }

            });
        }


        this.setState({
            allTechs: options,
        });

    }

    renderLocationsOptions() {
        const {allLocations} = this.context;

        let select = [];

        allLocations.map((location) => {
            // Populates the locations dropdown depending on which locations the user is in...JK
            if (location.type === "Internal") {
                return select.push({
                    label: location.name,
                    value: location.id,
                });
            }
            return null;
        });

        this.setState({
            locationsSelect: select,
            allLocs: allLocations,
        });
    }

    handleLocationChange = (e) => {
        this.setState({
            locationsSelected: e,
        });
        this.renderTechOptions(e);
    };

    renderProfilePicture(receipt) {
        if (!receipt) {
            return (
                <div style={{width: 200, height: 300, border: "2px solid black", textAlign: "center", margin:'auto'}}>
                    Pic Here
                </div>
            )
        }

        return (
            <View>
                <img
                    src={"data:image/jpeg;base64," + receipt}
                    className="img-thumbnail"
                    alt={"Receipt"}
                    style={{height: 80, width: 100}}
                />
            </View>
        );
    }

    updateProfile(profile) {
        return UserService.updateUser(profile)
            .then((data) => {
                toast.success("Saved Successfully!");
            })
            .catch((err) => {
                toast.warn("An error occurred while saving.");
            });
    }

    fileInputHandler( user, value) {
        let reader = new FileReader(),
            file = value[0],
            data,
            rt,
            newStr;

        reader.onloadend = () => {
            data = reader.result;
            rt = data.indexOf(",");
            if (rt > -1) {
                newStr = data.slice(rt + 1);
            }

            user.profilePicture = newStr;

            //this.setState({nExpense: expense});
        };

        reader.readAsDataURL(file);
    }

    renderProfilePicInput(user) {
        return (
            <Row>
                <Col md={12}>
                    <InputFile
                        textFieldTitle="only .png or .jpg files are currently supported"
                        getValue={this.fileInputHandler.bind(this, user)}
                    />
                </Col>
            </Row>
        );
    }

    renderGreetingTextInput(user){
        return(
            <Input
                valueDefault={user.greeting || ""}
                type="textarea"
                label="Enter greeting.."
                rows="2"
                cols="50"
                outline
                onBlur={(e) => {
                    user.greeting = e.target.value
                }}
            />
        )
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <MDBRow>
                {this.renderTechCards()}
            </MDBRow>
        );
    }

    renderTechCards() {
        let at = this.state.allTechs,
            t = this;

        function renderCard(tech, idx) {
            return (
                <MDBCol key={tech.id} size={3}>
                    <MDBCard style={{padding: 10}}>

                        {t.renderProfilePicture(tech.profilePicture)}

                        {t.renderProfilePicInput(tech)}

                        {t.renderGreetingTextInput(tech)}


                        <MDBRow>
                            <MDBCol style={{textAlign: "center"}} size={8}>
                                {tech.firstname + " " + tech.lastname}
                            </MDBCol>
                            <MDBCol size={4}>
                                <MDBBtn onClick={() => t.updateProfile(tech)} data-tip={"Save"} size={"sm"} outline rounded>
                                    <MDBIcon icon={"check"}/>
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBCard>
                    <ReactTooltip/>
                </MDBCol>
            )
        }

        return (
            at.map((tech, idx) => {
                return (renderCard(tech, idx))
            })
        )


    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }


    renderLocationsFilter() {
        const {currentUser} = this.context;
        let role = currentUser.role;

        switch (role) {
            case "SALES":
            case "OFFICE_SUPPORT":
            case "CARE_COORDINATOR":
            case "CALL_CENTER":
            case "TECHNICIAN":
            case "DATA_EXPORT":
            case "VERIFICATION":
            case "FOLLOWUP":
            case "COLLECTIONS":
            case "BILLING":
            case "PURCHASING":
            case "FINANCE":
            default:
                return <div/>;
            case "SYSTEM_ADMIN":
            case "ADMIN":
            case "EXECUTIVE_MANAGEMENT":
            case "OFFICE_MANAGER":
            case "SALES_MANAGER":
                return (
                    <Row>
                        <Col md={4} style={{marginTop: 5}}>
                            <Select
                                placeholder="Select Location"
                                // closeMenuOnSelect={false}
                                options={this.state.locationsSelect}
                                onChange={this.handleLocationChange.bind(this)}
                                value={this.state.locationsSelected}
                            />
                        </Col>
                    </Row>
                );
        }
    }


    render() {
        return (
            <div style={{marginLeft: "4%", marginRight: "4%"}}>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />

                <CardHeader style={{backgroundColor: "#778899"}}>
                    <Row>
                        <Col md={10} style={{color: "#000"}}>
                            {this.renderLocationsFilter()}
                        </Col>
                    </Row>
                </CardHeader>

                <Row style={{zIndex: 0}}>
                    <Col style={{zIndex: 0}}>{this.renderTableOrSpinner()}</Col>
                </Row>
            </div>
        );
    }
}
