import React from "react";
import "./jkTooltip.css";
import {MDBCol, MDBRow} from "mdbreact";

export default class UpdateNotes extends React.Component {

    renderVersion259(){
        return (
            <div>
                Version 2.59
                <ul>
                    <li>Sales Rep Dropdown List</li>
                    <ul>
                        <li>
                            Fixed issues in an order where sales reps were coming up as 'undefined'.
                        </li>
                    </ul>
                    <li>Locations Page</li>
                    <ul>
                        <li>
                            Fixed issue where you could not open the list to see what users where in each location.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion258(){
        return (
            <div>
                Version 2.58
                <ul>
                    <li>Technicians Dropdown List</li>
                    <ul>
                        <li>
                            Users with the role of Technician, Warehouse Technician, ATP, Area Manager, and Office Manager will show up
                            in the list of people that a delivery can be assigned to.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion257(){
        return (
            <div>
                Version 2.57
                <ul>
                    <li>President's Club</li>
                    <ul>
                        <li>
                            Increased top section to top 13 reps and re-styled the page.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion256(){
        return (
            <div>
                Version 2.56
                <ul>
                    <li>Dashboard</li>
                    <ul>
                        <li>
                           Added new button for 'Month at a Glance' to mimic the functionality from the IOS app.
                        </li>
                    </ul>
                    <li>Order View</li>
                    <ul>
                        <li>
                            When an order is moved to setup and the setup date is entered from the web, the time is set to 12:00 pm
                            to avoid issues with orders being on different dates in reports due to timezones.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion255(){
        return (
            <div>
                Version 2.55
                <ul>
                    <li>Recurring Orders</li>
                    <ul>
                        <li>
                            On the order page, a new order type is available: Re-Supply.  Your manager can provide more information
                            if this order type will change anything within your role.
                        </li>
                        <li>
                            When that order type is selected, another section is added to the left in the order, allowing you to set the
                            frequency that the order will be created.  This is not necessary to save a Re-Supply order.
                        </li>
                        <li>
                            A corresponding page will show all of the orders that are set to be re-created and the date that will happen.
                            This tracks orders that are using the frequency, not just orders that are of the Re-Supply type.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion254(){
        return (
            <div>
                Version 2.54
                <ul>
                    <li>Account Details View</li>
                    <ul>
                        <li>
                            The notes on the account view page will now also display the notes for the activities associated with the account.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion253(){
        return (
            <div>
                Version 2.53
                <ul>
                    <li>Expenses Page</li>
                    <ul>
                        <li>
                            Fixed issue preventing users from being able to download the CSV.
                        </li>
                    </ul>
                    <li>Purchase Tracking</li>
                    <ul>
                        <li>
                            Added field to the order for 'Expected Ship Date' that will be populated during the purchasing process.
                        </li>
                        <li>
                            That field will also show up on the main orders listing page, and the deliveries page, allowing for the ability to
                            reschedule and know what date it needs to be after.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion252(){
        return (
            <div>
                Version 2.52
                <ul>
                    <li>Follow-Up View</li>
                    <ul>
                        <li>
                            Added button to allow users to search for orders by insurance policy number.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion251(){
        return (
            <div>
                Version 2.51
                <ul>
                    <li>Patient Order View</li>
                    <ul>
                        <li>Added ability to create a home assessment on the web which will then populate on the tablet.</li>
                    </ul>

                    <li>Purchasing</li>
                    <ul>
                        <li>Updated permissions to add and edit products.</li>
                        <li>Added ability to track a PO and update it with appropriate tracking information.</li>
                        <li>If the updated tracking is past the scheduled delivery date, that order will move into
                            a new status under the Ready to Deliver bucket.</li>
                    </ul>

                    <li>Routing</li>
                    <ul>
                        <li>A couple of the listing screens now properly route to and from the order page.</li>
                    </ul>

                    <li>Home Assessment Compare</li>
                    <ul>
                        <li>An Admin screen has been created to determine differences between the home assessment created on
                        the web and one created on the field.</li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion250() {
        return (
            <div>
                Version 2.50
                <ul>
                    <li>Liaison Meetings View</li>
                    <ul>
                        <li>
                            Fixed issue preventing meetings from being able to be moved if order status was "Ready to
                            Deliver"
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }


    renderVersion249() {
        return (
            <div>
                Version 2.49
                <ul>
                    <li>General</li>
                    <ul>
                        <li>
                            Changed multiple pages to use a smaller user object to save time on initial load and limit
                            server usage.
                        </li>
                    </ul>

                    <li>Insurance Details</li>
                    <ul>
                        <li>Created page that allows a manager to input all the details about an insurance used in the
                            verification, billing, etc. processes.
                        </li>
                        <li>Added icon next to insurance on the order page that when clicked, pops up a modal with the
                            insurance details currently in the system.
                        </li>
                    </ul>

                </ul>
            </div>
        )
    }

    renderVersion248() {
        return (
            <div>
                Version 2.48
                <ul>
                    <li>Orders List</li>
                    <ul>
                        <li>Fixed issues with time portion of 'Last Status Change' not being formatted for current
                            timezone.
                        </li>
                    </ul>

                    <li>Verification & Follow-Up View</li>
                    <ul>
                        <li>Fixed issues with time portion of 'Moved Into Queue' not being formatted for current
                            timezone.
                        </li>
                    </ul>
                </ul>
            </div>
        )
    }

    renderVersion247() {
        return (
            <div>
                Version 2.47
                <ul>
                    <li>
                        Task List
                        <ul>
                            <li>
                                Fixed an issue where tasks were not displaying the correct information.
                            </li>
                            <li>
                                Fixed tasks to link to the corresponding order.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Verification View
                        <ul>
                            <li>
                                Fixed issue with incorrect date for column "Moved Into Queue"
                            </li>
                        </ul>
                    </li>
                </ul>
                <hr/>
            </div>
        )
    }

    renderVersion246() {
        return (
            <div>
                Version 2.46
                <ul>

                    <li>Main</li>
                    <ul>
                        <li>Added blue background to all pages.</li>
                    </ul>

                    <li>Users</li>
                    <ul>
                        <li>Required at least one location to be selected for new user accounts.</li>
                    </ul>

                    <li>Region</li>
                    <ul>
                        <li>Added ability to set a Sales Director and Operations Director for regions.</li>
                    </ul>

                    <li>Locations</li>
                    <ul>
                        <li>Added ability to set a Area Manager and Sales Manager for locations.</li>
                    </ul>

                    <li>Dashboard</li>
                    <ul>
                        <li>Reformatted dashboard for a better user experience.</li>
                        <li>Added Region drop-down for pipeline chart and delivery averages chart.</li>
                    </ul>

                    <li>Sales Report</li>
                    <ul>
                        <li>
                            Added Service Location to the table and CSV export.
                        </li>
                    </ul>

                    <li>Order View</li>
                    <ul>
                        <li>Added visual indicator to DOB field if the patient is Pediatric. (under 18)</li>
                        <li>Added popup if the patient has an open legal complaint on file.</li>
                    </ul>

                    <li>Patient View</li>
                    <ul>
                        <li>Added visual indicator to DOB field if the patient is Pediatric. (under 18)</li>
                        <li>Added checks to help prevent orders from being created without a Sales or Service
                            Location.
                        </li>
                    </ul>

                    <li>Listing Screens</li>
                    <ul>
                        <li>Added Pediatric column to the following listing screens with a value of "Yes" or "No":</li>
                        <li>In Rehab Only, grid is first grouped by pediatric field for all except the order listing
                            grid
                        </li>
                        <ul>
                            <li>Order Listing</li>
                            <li>Deliveries</li>
                            <li>Verification</li>
                            <li>Follow Up</li>
                        </ul>
                    </ul>

                </ul>
                <hr/>
            </div>
        )
    }

    renderVersion245() {
        return (
            <div>
                Version 2.45
                <ul>
                    <li>
                        PCR Links
                        <ul>
                            <li>
                                Fixed an issue where sorting on location was not sorting the existing PCR links by that
                                location.
                            </li>
                        </ul>
                    </li>
                    <li>
                        PCR Notifications View
                        <ul>
                            <li>
                                Reformatted view to separate notifications by Sales Rep that is also in a collapsible
                                box and includes the total number
                                of notifications for that rep (for that day).
                            </li>
                            <li>
                                Added Sales Rep name to each notification card as well.
                            </li>
                            <li>
                                Added loading spinner until user context is loaded, which is necessary for getting the
                                rep information before
                                rendering the cards.
                            </li>
                        </ul>
                    </li>
                </ul>
                <hr/>
            </div>
        )
    }

    renderVersion244() {
        return (
            <div>
                Version 2.44
                <ul>
                    <li>
                        Verification View

                        <ul>
                            <li>
                                Fixed issue with CSV download columns not lining up properly
                            </li>

                            <li>
                                Added a column for "Currently Being Worked By"
                            </li>

                            <li>
                                Updated Priority column name on screen and in download
                            </li>

                            <li>
                                Changed default sorting to be Insurance -> Priority -> Moved Into Queue
                            </li>

                            <li>
                                Added feature where orders clicked from this view will be assigned to the user that
                                clicks it.
                                If another user is already working that order, a message will be displayed alerting
                                them.
                            </li>

                        </ul>

                    </li>

                    <li>
                        Follow-Up View
                        <ul>
                            <li>
                                Added column "Moved Into Queue"
                            </li>

                            <li>
                                Added column "Currently Being Worked By"
                            </li>

                            <li>
                                Added feature where orders clicked from this view will be assigned to the user that
                                clicks it.
                                If another user is already working that order, a message will be displayed alerting
                                them.
                            </li>
                        </ul>
                    </li>

                    <li>
                        Orders Listing Screen
                        <ul>
                            <li>
                                Changed "Last Status Change" column to include time of day
                            </li>
                        </ul>
                    </li>

                    <li>
                        Operations Dropdown
                        <ul>
                            <li>
                                Moved Product Categories and Product Sizes out of the admin menu and into this menu;
                                It can only be accessed by Executive Management roles and above
                            </li>
                        </ul>
                    </li>

                    <li>
                        PCR Links
                        <ul>
                            <li>Added Sales Managers to the PCR links and fixed issues with the page not showing current
                                links.
                            </li>
                        </ul>
                    </li>
                </ul>
                <MDBRow>
                    <MDBCol>

                    </MDBCol>
                </MDBRow>
                <hr/>
            </div>
        )


    }

    render() {

        return (
            <div>
                {this.renderVersion259()}
                {this.renderVersion258()}
                {this.renderVersion257()}
                {this.renderVersion256()}
                {this.renderVersion255()}
                {this.renderVersion254()}
                {this.renderVersion253()}
                {this.renderVersion252()}
                {this.renderVersion251()}
                {this.renderVersion250()}
                {this.renderVersion249()}
                {this.renderVersion248()}
                {this.renderVersion247()}
                {this.renderVersion246()}
                {this.renderVersion245()}
                {this.renderVersion244()}
            </div>
        );
    }
}
