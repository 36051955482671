import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	DataTable,
	Spinner,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	MDBBtn,
	toast,
	CardHeader,
	MDBIcon,
	MDBCol,
	MDBRow,
	MDBSelect,
	MDBCard,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import ProductService from "../Security/ProductService/productTypeService";
import ProductSubTypeService from "../Security/ProductService/productSubTypeService";
import InsuranceService from "../Security/InsuranceService/insuranceService";
import ReactTooltip from "react-tooltip";
import TrainingLinkService from "./trainingLinkService";
import EquipmentTrainingLinkService from "./EquipmentTrainingLinkService";

export default class productTypes extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
					width: 100,
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
					width: 200,
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			modal: false,
			addNewModal: false,
			currIndex: 0,
			currProduct: {},
			newProduct: {
				insuranceMatrixEntries: [],
				requiresSerialNumber: false,
			},
			errorMessage: "",
			insuranceList: [],
			insuranceLoaded: false,
			videosLoaded: false,
			allVideos: [],
			vidsList: [],
			fullVideosLoaded: false,
			chosenVideo: {},
			insurance: [],
			insuranceListNew: [],
		};
	}

	componentDidMount() {
		this.retrieveInsurance();
		this.getAllVideos();
		this.getInsuranceTypes();
	}

	getAllVideos() {
		TrainingLinkService.getAllTrainingLinks().then((r) => {
			let obj = [];

			r.forEach((v) => {
				obj.push({ text: v.name + ":   " + v.description, value: v });
			});

			this.setState({
				allVideos: r,
				vidsList: obj,
				fullVideosLoaded: true,
			});
		});
	}

	getCategoryVideos(id) {
		EquipmentTrainingLinkService.getVideosByCategory(id).then((r) => {
			this.setState({
				currentEquipmentVideos: r,
				videosLoaded: true,
			});
		});
	}

	getInsuranceTypes() {
		return InsuranceService.findAllInsurance()
			.then((res) => {
				let arr = [],
					l = [],
					cp = this.state.newProduct;
				res.forEach((insurance) => {
					arr.push({
						id: insurance.id,
						name: insurance.name,
						order:
							typeof insurance.order === "number"
								? insurance.order
								: "no order",
					});
					l.push(insurance.name);
					cp.insuranceMatrixEntries.push({
						insuranceType: insurance,
					});
				});
				this.setState({
					insurance: arr,
					insuranceListNew: l,
					newProduct: cp,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	handleVideoChange(video) {
		this.setState({ chosenVideo: video[0] });
	}

	addVideo() {
		let video = this.state.chosenVideo,
			prod = this.state.currProduct,
			v = this.state.currentEquipmentVideos;

		if (!video.id) {
			return;
		}

		if (
			v.findIndex((vals) => {
				return vals.trainingLinkId === video.id;
			}) > -1
		) {
			toast.warning("Video already exists foRr this product.");
			return;
		}

		this.setState({
			videosLoaded: false,
		});

		let vo = {
			name: video.name,
			productCategoryId: prod.id,
			trainingLinkId: video.id,
			productSubTypeId: -1,
		};

		EquipmentTrainingLinkService.createTrainingLink(vo)
			.then((r) => {
				v.push(r);

				this.setState({
					currentEquipmentVideos: v,
					chosenVideo: {},
				});
			})
			.catch((err) => {
				console.log(err);
			})
			.finally((a) => {
				this.setState({
					videosLoaded: true,
				});
			});
	}

	retrieveInsurance() {
		let t = this;
		return InsuranceService.findAllInsurance()
			.then((res) => {
				let d = this.state.data,
					l = [];
				res.forEach((insurance) => {
					d.columns.push({
						label: insurance.name,
						field: insurance.name,
						sort: "asc",
						width: 100,
					});
					l.push(insurance.name);
				});
				// I push this here so the buttons are at the end of the data table
				d.columns.push(
					{
						label: "View Products",
						field: "editButton",
						sort: "asc",
						width: 100,
					},
					{
						label: "Edit Category",
						field: "editCategory",
						sort: "asc",
						width: 100,
					},
					{
						label: "Delete",
						field: "delete",
						width: 100,
					}
				);
				t.setState({
					data: d,
					insuranceList: l,
					insuranceLoaded: true,
				});
				this.retrieveProducts();
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	retrieveProducts() {
		let t = this;
		return ProductService.getAllProducts()
			.then((res) => {
				let arr = [],
					dt = this.state.data;

				// Sorts the insurance arr in each product so its easier to add to each row of the table
				res.forEach((element) => {
					element.insuranceMatrixEntries.sort(function (a, b) {
						return a.order - b.order;
					});
				});

				res.forEach((product, index) => {
					arr.push(this.makeRows(product, index));
				});

				dt.rows = arr;
				t.setState({
					data: dt,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	makeRows(product, index) {
		let l = this.state.insuranceList,
			obj = {};

		obj.id = product.id;
		obj.name = product.name || "no name";
		l.forEach((name, index) => {
			obj[name] =
				typeof product.insuranceMatrixEntries[index].points === "number"
					? product.insuranceMatrixEntries[index].points
					: "no points";
		});
		obj.editButton = this.renderRouteButton(product, index);
		obj.editCategory = this.renderCategoryButton(product, index);
		obj.delete = this.renderRemoveButton(product);
		return obj;
	}

	renderCategoryButton(product, index) {
		return (
				<MDBIcon icon="edit"
						 style={{color: "#7ac5ff"}}
						 onClick={() => {
							 let p = JSON.parse(JSON.stringify(product));
							 this.getCategoryVideos(p.id);

							 this.setState({
								 currIndex: index,
								 currProduct: p,
								 modal: !this.state.modal,
							 });
						 }}
				/>
		);
	}

	renderRemoveButton(product) {
		return (
				<MDBIcon icon="trash"
						 style={{color: "#db0404"}}
						 onClick={() => this.handleRemoveClick(product)}
				/>
		);
	}

	handleRemoveClick(product) {
		ProductService.deleteProduct(product)
			.then((res) => {
				this.retrieveProducts();
				toast.success("Product Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Product");
			});
	}

	toggleEdit = () => {
		this.setState({
			modal: !this.state.modal,
			errorMessage: "",
		});
	};

	removeVideoFromEquipment(v, idx) {
		this.setState({
			videosLoaded: false,
		});

		let myVideos = this.state.currentEquipmentVideos.slice();

		EquipmentTrainingLinkService.deleteTrainingLink(v.id)
			.then((r) => {
				myVideos.splice(idx, 1);
				this.setState({
					currentEquipmentVideos: myVideos,
				});
			})
			.catch((e) => {
				toast.warning("There was an error.  Try again later.");
			})
			.finally((a) => {
				this.setState({
					videosLoaded: true,
				});
			});
	}

	addNewToggle = () =>{
		this.setState({
			addNewModal: false,
			newProduct:	{insuranceMatrixEntries: [],
			requiresSerialNumber: false, }
			}
		)
	}

	renderAddNewModal() {
		return (
			<Modal isOpen={this.state.addNewModal} toggle={this.addNewToggle}>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.addNewToggle}
				>
					Add New Product Category
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderAddNewModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Add New Payor Source"}
						onClick={this.handleAddNewSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.addNewToggle}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}
	renderAddNewModalBody(){
		return(
		this.renderForm()
	);
	}

	handleAddNewSave = () => {
		if (this.validateAddNew()) {
			let cp = this.state.newProduct;
			cp.insuranceMatrixEntries.forEach((insurance) => {
				parseInt(insurance.points, 10);
			});

			return ProductService.createProduct(cp)
				.then(() => {
					toast.success(cp.name + " Product Type Created Has Been Created");
					this.getInsuranceTypes();
					this.retrieveProducts();
					this.setState({
						newProduct: {
							name: "",
							insuranceMatrixEntries: [],
							requiresSerialNumber: false,
						},
						errorMessage: "",
					});
					this.addNewToggle();
				})
				.catch((err) => {
					toast.warn("An error occurred while saving");
				});
		}
	};
	renderForm() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<Input
					outline
					label={"Name"}
					value={this.state.newProduct.name}
					onChange={this.handleNewNameChange.bind(this)}
				/>
				<Input
					filled
					type="checkbox"
					id="requiresSerialNumber"
					value="Requires Serial Number"
					label="Requires Serial Number"
					checked={this.state.currProduct.requiresSerialNumber}
					onChange={this.handleNewCheckChange.bind(this)}
				/>
				<MDBRow>{this.renderInputs()}</MDBRow>
			</div>
		);
	}
	renderInputs() {
		let l = this.state.insuranceListNew;
		let cp = this.state.newProduct.insuranceMatrixEntries;

		return l.map((name, index) => {
			return (
				<MDBCol size="4">
					<Input
						outline
						key={index}
						value={cp[index] ? cp[index].points : ""}
						onChange={this.handleNewInsuranceChange.bind(this, index)}
						label={name}
					/>
				</MDBCol>
			);
		});
	}

	handleNewInsuranceChange(property, e) {
		let p = this.state.newProduct;
		p.insuranceMatrixEntries[property].points = e.target.value;
		this.setState({
			currProduct: p,
		});
	}

	handleNewCheckChange = (event) => {
		var p = this.state.newProduct;
		p.requiresSerialNumber = !p.requiresSerialNumber;
		this.setState({ newProduct: p });
	};
	renderModal() {
		return (
			<Container>
				<Modal size={"lg"} isOpen={this.state.modal} toggle={this.toggleEdit}>
					<ModalHeader
						toggle={this.toggleEdit}
						style={{ backgroundColor: "#5881C1", color: "white" }}
					>
						Edit Product Type
					</ModalHeader>
					<ModalBody style={{ backgroundColor: "white" }}>
						<Container>
							<p style={{ color: "red" }}>{this.state.errorMessage}</p>
							<Input

								value={this.state.currProduct.name}
								onChange={this.handleNameChange.bind(this, "name")}
								label="Name"
								size="sm"
							/>
							<Input

								filled
								type="checkbox"
								id="requiresSerialNumber"
								value="Requires Serial Number"
								label="Requires Serial Number"
								checked={this.state.currProduct.requiresSerialNumber}
								onChange={this.handleCheckChange.bind(this)}
							/>
							<MDBRow>{this.renderModalBody()}</MDBRow>
						</Container>

						<MDBRow>
							<MDBCol size="10">
								<MDBSelect
									outline
									color="default"
									style={{ maxHeight: "100px", marginTop: 1 }}
									options={this.state.vidsList}
									label={"Choose a Video to Add"}
									getValue={this.handleVideoChange.bind(this)}
								/>
							</MDBCol>
							<MDBCol size="2">
								<MDBIcon
									data-tip={"Add Video"}
									size={"2x"}
									icon={"plus"}
									style={{ color: "green" }}
									onClick={() => this.addVideo()}
								/>
							</MDBCol>
						</MDBRow>

						<MDBRow>{this.renderProductVideos()}</MDBRow>
					</ModalBody>
					<ModalFooter style={{ backgroundColor: "white" }}>
						<Button
							floating
							size="sm"
							color={"success"}
							data-tip={"Update Product Type"}
							onClick={this.saveButtonClick}
						>
							<MDBIcon icon="check" style={{ fontSize: "2em" }} />
						</Button>

						<Button
							floating
							size="sm"
							color={"red"}
							data-tip={"Cancel"}
							onClick={this.closeButtonClick}
						>
							<MDBIcon icon="times" style={{ fontSize: "2em" }} />
						</Button>

						<ReactTooltip />
					</ModalFooter>
				</Modal>
			</Container>
		);
	}

	renderProductVideos() {
		let t = this;

		function deleteButton(v, idx) {
			return (
				<MDBIcon
					style={{ color: "red" }}
					icon={"trash"}
					onClick={() => t.removeVideoFromEquipment(v, idx)}
				/>
			);
		}

		if (!this.state.videosLoaded) {
			return <Spinner small />;
		}
		return this.state.currentEquipmentVideos.map((v, idx) => {
			return (
				<MDBCol size="6">
					<MDBCard key={idx} style={{ margin: 6, padding: 4 }}>
						<MDBRow>
							<MDBCol size="9">{v.name}</MDBCol>

							<MDBCol size="3">{deleteButton(v, idx)}</MDBCol>
						</MDBRow>
					</MDBCard>
				</MDBCol>
			);
		});
	}

	handleCheckChange = (event) => {
		let p = this.state.currProduct;
		p.requiresSerialNumber = !this.state.currProduct.requiresSerialNumber;
		this.setState({
			currProduct: p,
		});
	};

	renderModalBody() {
		if (this.state.modal) {
			let l = this.state.insuranceList,
				insurance = this.state.currProduct.insuranceMatrixEntries;

			return l.map((name, index) => {
				return (
					<MDBCol size="4">
						<Input
							outline
							key={index}
							label={name}
							value={String(insurance[index].points)}
							onChange={this.handleInsuranceChange.bind(this, index)}
							size="sm"
						/>
					</MDBCol>
				);
			});
		}
	}

	handleInsuranceChange(property, e) {
		let p = this.state.currProduct;
		p.insuranceMatrixEntries[property].points = e.target.value;
		this.setState({
			currProduct: p,
		});
	}

	handleNewNameChange(e) {
		let np = this.state.newProduct;
		np.name = e.target.value;
		this.setState({
			newProduct: np,
		});
	}

	handleNameChange(property, e) {
		let p = this.state.currProduct;
		p[property] = e.target.value;
		this.setState({
			currProduct: p,
		});
	}

	closeButtonClick = () => {
		this.setState({
			currProduct: {},
			modal: !this.state.modal,
			errorMessage: "",
		});
	};

	saveButtonClick = () => {
		let d = this.state.data,
			i = this.state.currIndex,
			cp = this.state.currProduct,
			l = this.state.insuranceList;

		if (this.validate()) {
			return ProductService.updateProduct(cp)
				.then((product) => {
					product.insuranceMatrixEntries.sort((a, b) => a.order - b.order);
					d.rows[i].name = product.name;
					l.forEach((name, index) => {
						d.rows[i][name] = product.insuranceMatrixEntries[index].points;
					});
					d.rows[i].editCategory = this.renderCategoryButton(product, i);

					this.setState({
						data: d,
						modal: !this.state.modal,
					});
					toast.success("Product Edited!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateAddNew(){
		let cp = this.state.newProduct,
			cc = this.state.newProduct.insuranceMatrixEntries,
			i = 0;
		cc.forEach((insurance) => {
			if (insurance.points === undefined || insurance.points === "") {
				this.setError(
					"Please enter points for " + insurance.insuranceType.name
				);
				i += 1;
			}
		});
		if (cp.name === undefined || cp.name === "") {
			this.setError("Please enter a Name");
			i += 1;
		}
		return i === 0;
	}

	validate() {
		let cp = this.state.currProduct,
			cc = this.state.currProduct.insuranceMatrixEntries,
			l = this.state.insuranceList,
			i = 0;

		l.forEach((name, index) => {
			if (cc[index].points === "") {
				this.setError("Please enter " + name + " Points");
				i += 1;
			}
		});
		if (cp.name === "") {
			this.setError("Please enter a Name");
			i += 1;
		}
		return i === 0;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	renderRouteButton(product, index) {
		let arr = [];
		return (

				<MDBIcon far icon="eye"
						 key={index}
						 size="sm"
						 floating
						 onClick={() => {
							 ProductSubTypeService.getOneProductSubType(product.id)
								 .then((res) => {
									 res.forEach((element) => {
										 arr.push({
											 id: element.id,
											 name: element.name,
											 deleted: element.deleted,
											 deletedAt: element.deletedAt,
											 hcps: element.hcps,
											 productType: element.productType.name,
										 });
									 });
								 })
								 .catch((err) => {
									 console.log(err);
								 });
							 this.props.history.push({
								 pathname: "products/" + product.id,
								 state: {
									 product: product,
								 },
							 });
						 }}/>

		);
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<DataTable striped info={false} small data={{rows: this.state.data.rows, columns: this.state.data.columns}}>
					{" "}
				</DataTable>
			);
		} else {
			return <div></div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	addNewClick = () => {
		this.setState({
			addNewModal: true
		})
		// return this.props.history.push({
		// 	pathname: "products/new",
		// });
	};

	render() {
		if (this.state.isLoaded === true && this.state.insuranceLoaded === true) {
			return (
				<div>
					<ToastContainer
						hideProgressBar={false}
						newestOnTop={true}
						autoClose={3000}
					/>
					<Container className="mt-5" style={{maxWidth: "80%"}}>
						<Card>
							<CardHeader
								style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30 }}
							>
								Product Categories
								<Button
									style={{ float: "right" }}
									floating
									size="sm"
									color={"secondary"}
									data-tip={"Add New Product Category"}
									onClick={this.addNewClick}
								>
									<MDBIcon icon="cart-plus" style={{ fontSize: "2em" }} />
								</Button>
							</CardHeader>
							<CardBody>{this.renderTable()}</CardBody>

							<ReactTooltip />
						</Card>
						{this.renderModal()}
						{this.renderAddNewModal()}
					</Container>
				</div>
			);
		} else {
			return this.renderLoadingSpinner();
		}
	}
}
