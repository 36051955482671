import React from "react";
import InsuranceService from "../Security/InsuranceService/insuranceService";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody, MDBCol,
    MDBContainer,
    MDBDataTableV5,
    MDBIcon,
    MDBModal, MDBModalBody, MDBModalFooter,
    MDBModalHeader, MDBRow,
    MDBSpinner,
    ModalHeader
} from "mdbreact";
import {ContentState, convertFromRaw, convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import PropTypes from "prop-types";
import {toast, ToastContainer} from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

export default class insuranceSubTypeDetails extends React.Component {
    constructor(props) {
        super(props);

        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Insurance Sub Type",
                    field: "insuranceSubTypeName",
                    sort: "asc",
                },
                {
                    label: "Last Updated",
                    field: "lastUpdatedOn",
                    sort: "asc",
                },
                {
                    label: "Updated By",
                    field: "lastUpdatedBy",
                    sort: "asc",
                },
                {
                    label: "View / Edit Details",
                    field: "edit",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        this.state = {
            data: data,
            isLoaded: false,
            editorModalOpen: false,
            newWysiwygModal: false,
            editorState: EditorState.createEmpty(),
            currentRecord: {},
            currentIndex: -1,
            fullData: [],
            unused: [],
            insuranceSelectModal: false,
            selectedInsurance: {},
        };
    }

    static contextTypes = {
        insuranceSubTypes: PropTypes.array,
        currentUser: PropTypes.object,
    };

    componentDidMount() {
        this.getAllInsuranceDetails()

    }

    convertRecord(rec, idx) {
        let o = {
            id: rec.id,
            insuranceSubTypeName: rec.insuranceSubType.name,
            lastUpdatedOn: new Date(rec.lastUpdatedOn).toLocaleString(),
            lastUpdatedBy: rec.lastUpdatedBy ? rec.lastUpdatedBy.name : "Unknown",
            edit: this.renderEditButton(rec, idx),
        }

        return o;
    }

    getAllInsuranceDetails() {
        let t = this,
            d = this.state.data;
        InsuranceService.findAllSubInsuranceDetails().then(r => {
            let rd = [],
                t = [];

            r.forEach((rec, idx) => {
                rd.push(this.convertRecord(rec, idx));
                t.push(rec.insuranceSubType);
            })

            d.rows = rd;

            this.setState({
                fullData: r,
                usedTypes: t,
                data: d,
                isLoaded: true
            });
        }).catch(e => {

        })
    }

    handleNewClick() {
        const {insuranceSubTypes} = this.context;
        let used = this.state.usedTypes;

        let unused = [];

        insuranceSubTypes.forEach(type => {
            let found = false;
            used.forEach(used => {
                if (used.id === type.id) {
                    found = true;
                    return;
                }
            })

            if (found === false) {
                unused.push(type);
            }

        })

        this.setState({
            insuranceSelectModal: true,
            unused: unused
        })
    }

    handleEditClick(record, index) {

        let parsedJSON = JSON.parse(record.details),
            raw = convertFromRaw(parsedJSON);

        let contentBlockArray = ContentState.createFromBlockArray(raw.getBlocksAsArray()),
            est = EditorState.createWithContent(contentBlockArray);

        this.setState({
            currentRecord: record,
            currentIndex: index,
            editorState: est,
            editorModalOpen: true
        })


    }

    saveRecord() {
        const {currentUser} = this.context;

        let i = this.state.currentRecord;

        let raw = convertToRaw(this.state.editorState.getCurrentContent()),
            tojs = JSON.stringify(raw);

        i.details = tojs;
        i.lastUpdatedOn = new Date();
        i.lastUpdatedBy = {
            name: currentUser.username,
            id: currentUser.id
        }

        InsuranceService.updateSubInsuranceDetails(i).then(r => {
            let d = this.state.data,
                rows = d.rows;

            let nr = this.convertRecord(r, this.state.currentIndex);

            rows.splice(this.state.currentIndex, 1, nr);

            d.rows = rows;

            this.setState({
                editorModalOpen: false,
                currentIndex: -1,
                data: d
            })
            toast.success("Updated Successfully");
        }).catch(e => {
            let cat = 5;
        })

    }

    createNewRecord() {
        const {currentUser} = this.context;

        let i = this.state.selectedInsurance,
            nr = {};

        let raw = convertToRaw(this.state.editorState.getCurrentContent()),
            tojs = JSON.stringify(raw);

        nr.name = "Hello";
        nr.details = tojs;
        nr.lastUpdatedOn = new Date();
        nr.lastUpdatedBy = {
            name: currentUser.name,
            id: currentUser.id
        }
        nr.insuranceSubType = {
            name: i.name,
            id: i.id
        };

        InsuranceService.createSubInsuranceDetails(nr).then(r => {
            toast.success("Details created successfully")

            let d = this.state.data,
                rows = d.rows;

            let nr = this.convertRecord(r, rows.length);
            rows.push(nr);

            d.rows = rows;

            this.setState({
                newWysiwygModal: false,
                selectedInsurance: {},
                data: d
            })
        }).catch(e => {
            let cat = 5
        })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    changeInsuranceType(type) {
        this.setState({
            selectedInsurance: type
        })
    }

    nextPressed() {
        this.setState({
            insuranceSelectModal: false,
            newWysiwygModal: true,
            EditorState: EditorState.createEmpty()
        })
    }

    renderEditButton(insurance, index) {
        return (
            <MDBIcon icon="edit"
                     style={{color: "#7ac5ff"}}
                     onClick={() => {
                         this.handleEditClick(insurance, index);
                     }}
            />
        );
    }

    renderInsuranceSelectModal() {
        let it = this.state.unused,
            t = this;

        return (
            <MDBModal isOpen={this.state.insuranceSelectModal}>
                <MDBModalHeader style={{backgroundColor: "#5881C1", color: "white"}}
                                toggle={() => this.setState({insuranceSelectModal: false, selectedInsurance: {}})}>
                    Select Insurance
                </MDBModalHeader>

                <div style={{margin: 20}}>
                    <TextField
                        id="outlined-Primary-Insurance"
                        select
                        size={"small"}
                        fullWidth
                        label="Payor Source"
                        //value={index}
                        onChange={(e) => t.changeInsuranceType(e.target.value)}
                        variant="outlined"
                    >
                        {it.map((option, idx) => {
                            return (
                                <MenuItem key={idx} value={option}>
                                    {option.name}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </div>

                <MDBRow>
                    <MDBCol size={8}/>
                    <MDBCol size={3}>
                        <MDBBtn color={'primary'}
                                disabled={!this.state.selectedInsurance.name}
                                onClick={() => this.nextPressed()}>
                            Next
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>

                <MDBModalBody>
                </MDBModalBody>
            </MDBModal>
        )
    }

    renderWYSIWYGModal() {
        let current = this.state.currentRecord;

        return (
            <MDBModal size={"lg"} isOpen={this.state.editorModalOpen} toggle={() => null}>
                <MDBModalHeader style={{backgroundColor: "#5881C1", color: "white"}}
                                toggle={() => this.setState({editorModalOpen: false})}>
                    {current.insuranceSubType ? current.insuranceSubType.name : "none"}
                </MDBModalHeader>

                <MDBModalBody>
                    <Editor
                        editorState={this.state.editorState}
                        //initialEditorState={this.state.editorState}
                        // toolbarHidden
                        // readOnly
                        // toolbarClassName="toolbarClassName"
                        // wrapperClassName="wrapperClassName"
                        // editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                    />
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn size={"sm"} color={"primary"} onClick={() => this.saveRecord()}>
                        Save
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        )
    }

    renderNewWYSIWYGModal() {
        let current = this.state.selectedInsurance;

        return (
            <MDBModal size={"lg"} isOpen={this.state.newWysiwygModal} toggle={() => null}>
                <MDBModalHeader style={{backgroundColor: "#5881C1", color: "white"}}
                                toggle={() => this.setState({newWysiwygModal: false, selectedInsurance: {}})}>
                    {current ? current.name : "none"}
                </MDBModalHeader>

                <MDBModalBody>
                    <Editor
                        editorState={this.state.editorState}
                        //initialEditorState={this.state.editorState}
                        // toolbarHidden
                        // readOnly
                        // toolbarClassName="toolbarClassName"
                        // wrapperClassName="wrapperClassName"
                        // editorClassName="editorClassName"
                        onEditorStateChange={this.onEditorStateChange}
                    />
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBBtn size={"sm"} color={"success"} onClick={() => this.createNewRecord()}>
                        Add Entry
                    </MDBBtn>
                </MDBModalFooter>
            </MDBModal>
        )
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <MDBSpinner/>
            )
        }

        return (
            <div>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                {this.renderWYSIWYGModal()}
                {this.renderInsuranceSelectModal()}
                {this.renderNewWYSIWYGModal()}
                <MDBContainer className="mt-5">
                    <MDBCard>
                        <MDBCardBody>
                            <span style={{float: 'right'}}>
                                <MDBBtn color={'primary'} size={"sm"} onClick={() => this.handleNewClick()}>
                                New
                            </MDBBtn>
                            </span>

                            <MDBDataTableV5 hover
                                            paging={false}
                                            data={{columns: this.state.data.columns, rows: this.state.data.rows}}
                                            searchTop
                                            searchBottom={false}/>
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </div>
        )
    }
}
