import React from "react";
import PropTypes from "prop-types";
import {
    Container,
    Spinner,
    Col,
    CardHeader,
    Button,
    Input,
    InputFile,
    MDBDatePicker,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    toast,
    ToastContainer,
    View,
    MDBIcon,
    Fa,
    MDBBtn,
} from "mdbreact";
import ExpenseService from "../Security/ExpenseService/expenseService";
import Select from "react-select";
import moment from "moment";
import QuickFilter from "../Filters/quickFilter";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import GroupService from "../Security/UserService/groupService";
import {
    Table,
    Grid,
    PagingPanel,
    TableHeaderRow,
    TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import {CustomPaging, PagingState} from "@devexpress/dx-react-grid";
import "./expenses.css";
import ReactTooltip from "react-tooltip";
import MomentUtils from "@date-io/moment";

const formatDate = (date) => {
    if (date == null || date === "") {
        return "";
    }
    return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "numeric",
        day: "2-digit",
    }).format(date);
};

export default class expensesList extends React.Component {
    constructor(props) {
        super(props);

        const devData = {
            columns: [
                {
                    title: "Approved By",
                    name: "approvedBy",
                },
                {
                    title: "Denied By",
                    name: "deniedBy",
                },
                {
                    title: "Receipt",
                    name: "receipt",
                },
                {
                    title: "Details",
                    name: "details",
                },
                {
                    title: "Expense Info",
                    name: "expenseInfo",
                },
                {
                    title: "Appointment Info",
                    name: "appointmentInfo",
                },
                {
                    title: "Deny Reason",
                    name: "approveDenyNote",
                },
            ],
            groupSummaryItems: [{columnName: "totalPoints", type: "sum"}],
            rows: [],
        };

        const defaultWidths = [
            {columnName: "receipt", width: 300},
            {columnName: "details", width: 200},
            {columnName: "appointmentInfo", width: 200},
            {columnName: "approveDenyNote", width: 300},
            {columnName: "expenseInfo", width: 200},
            {columnName: "expenseDate", width: 120},
            {columnName: "lastUpdated", width: 120},
            {columnName: "owner", width: 120},
            {columnName: "vendor", width: 120},
            {columnName: "expenseDescription", width: 120},
            {columnName: "peopleAttending", width: 150},
            {columnName: "educationalTopics", width: 150},
            {columnName: "expenseType", width: 120},
            {columnName: "cardType", width: 120},
            {columnName: "amount", width: 120},
            {columnName: "approvedBy", width: 120},
            {columnName: "deniedBy", width: 120},
        ];

        const columnHeaders = [
            "id",
            "expenseDate",
            "lastUpdated",
            "salesRep",
            "company",
            "expenseDesc",
            "peopleAttending",
            "educationalTopics",
            "physiciansAttending",
            "expenseType",
            "cardType",
            "amount",
            "approvedBy",
            "deniedBy",
        ];

        let hasData = props.location.filters;

        this.state = {
            devData: devData,
            defaultWidths: defaultWidths,
            columnHeaders: columnHeaders,
            isLoaded: true,
            filters: {},
            addExpenseModal: false,
            denyModalOpen: false,
            nExpense: {
                receipt: null,
                expenseDate: null,
                when: null,
                owner: {},
                expenseDescription: null,
                vendor: null,
                expenseType: {},
                cardType: {},
                expenses: 0,
                type: "Appointment",
                isExpense: true,
                quality: false,
            },
            errorMessage: [],
            allLocs: [],
            allSystemUsers: [],
            locationsSelect: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            allSystemUsersSelect: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            allSystemUsersSelected: [],
            locationsSelected: [],
            showDatePicker: true,
            quickFilter: [],
            quickFilterSelected: null,
            totalCount: 0,
            currentPage: 0,
            startDate: new Date(),
            endDate: new Date(),
            rawData: [],
            skip: 0,
            rowData: [],
        };

        if (hasData) {
            let st = this.state;

            st.isLoaded = true;
            st.allSystemUsersSelected = hasData.users;
            st.locationsSelected = hasData.locations;
            st.quickFilter = hasData.quickFilter;
            st.quickFilterSelected = this.renderQuickFilter(hasData);
            st.totalCount = hasData.totalCount;
            st.currentPage = hasData.currentPage;
            st.startDate = this.renderStartDate(hasData);
            st.endDate = this.renderEndDate(hasData);
        }

        //this.changeCurrentPage = this.changeCurrentPage.bind(this);
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        internalLocations: PropTypes.array,
        cardTypes: PropTypes.array,
        expenseTypes: PropTypes.array,
        allLocations: PropTypes.array,
        salesReps: PropTypes.array,
        userRefs: PropTypes.array,
    };

    componentDidMount() {
        this.renderLocationsOptions();
        this.getLocalStorage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {userRefs, currentUser, allLocations} = this.context;
        let st = this.state;
        if (
            st.allLocs.length !== allLocations.length &&
            st.currUser !== currentUser
        ) {
            this.renderLocationsOptions();
        }
        if (st.allSystemUsers.length !== userRefs.length) {
            this.renderSalesRepsOptions();
        }
    }

    renderStartDate(props) {
        return props.startDate;
    }

    renderEndDate(props) {
        return props.endDate;
    }

    renderQuickFilter(props) {
        return props.quickFilterSelected;
    }

    changeCurrentPage(currentPage) {
        this.setState({
            isLoaded: false,
            currentPage,
        });

        this.retrieveExpenses(currentPage);
    }

    renderSalesRepsOptions(e) {
        const {userRefs, currentUser} = this.context;
        let options = [];
        if (e === undefined || e.length === 0) {
            if (
                currentUser.role === "ADMIN" ||
                currentUser.role === "SYSTEM_ADMIN" ||
                currentUser.role === "EXECUTIVE_MANAGEMENT" ||
                currentUser.role === "FINANCE"
            ) {
                options = userRefs
            }
            this.setState({
                allSystemUsersSelect: userRefs,
                allSystemUsers: userRefs,
            });
        } else {
            let salesList = [];
            let locs = [];
            e.forEach((location) => {
                locs.push(location.value);
            });
            return GroupService.getAllUsersByLocations(locs)
                .then((res) => {
                    res.forEach((t) => {
                        if (salesList.indexOf(t.id) === -1) {
                            options.push({
                                label: t.firstname + " " + t.lastname,
                                value: {name: t.firstname + " " + t.lastname, id:t.id},
                            });
                        }
                        salesList.push(t.id);
                    });

                    this.setState({
                        allSystemUsersSelect: options,
                        allSystemUsersSelected: options,
                    });
                })
                .catch((e) => {
                });
        }
    }

    renderLocationsOptions() {
        const {allLocations, currentUser} = this.context;
        let cu = currentUser;
        let select = [],
            locations = [];
        if (
            cu.role === "ADMIN" ||
            cu.role === "SYSTEM_ADMIN" ||
            cu.role === "EXECUTIVE_MANAGEMENT" ||
            currentUser.role === "FINANCE"
        ) {
            locations = allLocations;
        } else {
            locations = cu.locations;
        }
        locations.map((location) => {
            // Populates the locations dropdown depending on which locations the user is in...JK
            if (location.type === "Internal") {
                return select.push({
                    label: location.name,
                    value: location.id,
                });
            }
            return null;
        });
        this.setState({
            locationsSelect: select,
            allLocs: allLocations,
        });
    }

    handleLocationChange = (e) => {
        this.setState({
            locationsSelected: e,
        });
        this.renderSalesRepsOptions(e);
    };

    handleSaleRepChange = (e) => {
        this.setState({
            allSystemUsersSelected: e,
        });
    };

    handleQuickFilter = (e) => {
        let a = QuickFilter.getDates(e.value),
            startDate = "",
            endDate = "";

        if (a.length > 0) {
            startDate = a[0];
            endDate = a[1];
        }

        this.setState({
            quickFilter: a,
            quickFilterSelected: e,
            startDate: startDate,
            endDate: endDate,
        });
    };

    renderExpenseReceipt(receipt) {
        return (
            <View>
                <img
                    src={"data:image/jpeg;base64," + receipt}
                    className="img-thumbnail"
                    alt={"Receipt"}
                    style={{height: 80, width: 100}}
                />
            </View>
        );
    }

    retrieveExpenses(pageNumber) {
        let filters = {},
            users = this.state.allSystemUsersSelected,
            arr = [];

        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let filterStartDate = new Date(startDate).toLocaleDateString();
        let filterEndDate = new Date(endDate).toLocaleDateString();
        filters.startDate = filterStartDate;
        filters.endDate = filterEndDate;


        users.forEach((u) => {
            arr.push(u.value ? u.value.id : "");
        });

        if (arr.length) {
            filters.users = arr;
        }
        filters.page = pageNumber;
        filters.pageSize = 10;

        return ExpenseService.getAllExpenses(filters)
            .then((res) => {
                this.setState({
                    rawData: res.content,
                    totalCount: res.totalElements,
                });

                this.formatRows(res.content, res.totalElements);
            })
            .catch((err) => {
                //handle error..BC
            });
    }

    ableToApproveDeny(expense) {
        const {currentUser} = this.context;

        switch (currentUser.role) {
            case "ADMIN":
            case "SYSTEM_ADMIN":
                return true;
            case "EXECUTIVE_MANAGEMENT":
            case "SALES_MANAGER":
                return !(expense.owner && expense.owner.id === currentUser.id);
            default:
                return false;
        }
    }

    getExpensesForCSV() {
        // this.clientCSV(this.state.rawData, "Expenses.csv");
        let filters = {},
            st = this.state;

        if (!this.state.startDate || !this.state.endDate) {
            toast.error("Check your date filters before downloading");
            return;
        }

        filters.startDate = new Date(st.startDate).toLocaleDateString();
        filters.endDate = new Date(st.endDate).toLocaleDateString();

        if (st.allSystemUsersSelected.length > 0) {
            let u = [];
            st.allSystemUsersSelected.forEach((r) => {
                u.push(r.value ? r.value.id : "");
            });
            filters.users = u.toString();
        }

        return ExpenseService.getExpensesForCSV(filters)
            .then((res) => {
                this.clientCSV(res, "Expenses.csv");
            })
            .catch((err) => {
                //handle error..BC
            });
    }

    renderApprovedBy(expense) {
        if (expense.approvedBy) {
            return expense.approvedBy.name;
        }

        if (this.ableToApproveDeny(expense)) {
            return (
                <Button
                    floating
                    size="sm"
                    color={"success"}
                    data-tip={"Approve"}
                    onClick={(e) => {
                        this.approveDenyExpense(expense, true);
                    }}
                >
                    <MDBIcon icon="check" style={{fontSize: "2em"}}/>
                </Button>
            );
        } else {
            return "Not Approved";
        }
    }

    toggleDenyModal(expense) {
        this.setState({
            denyModalOpen: !this.state.denyModalOpen,
            selectedExpense: expense,
        });
    }

    approveDenyExpense(expense, approve) {
        const {currentUser} = this.context;

        let cb = {name: currentUser.username, id: currentUser.id},
            devData = this.state.rawData;

        let toEdit = devData.find((exp) => {
            return exp.id === expense.id;
        });

        if (approve === true) {
            toEdit.approvedBy = cb;
            toEdit.deniedBy = null;
        } else {
            toEdit.deniedBy = cb;
            toEdit.approvedBy = null;
        }

        this.updateExpense(toEdit);

        this.formatRows(devData, this.state.totalCount);
    }

    updateExpense(expense) {
        return ExpenseService.updateExpense(expense)
            .then((data) => {
                toast.success("Saved Successfully!");
            })
            .catch((err) => {
                toast.warn("An error occurred while saving.");
            });
    }

    setDenyReason(reason) {
        let expense = this.state.selectedExpense;
        expense.approveDenyNote = reason;

        this.setState({
            selectedExpense: expense,
        });
    }

    renderDenyModal() {
        let se = this.state.selectedExpense;

        return (
            <Modal
                size="md"
                isOpen={this.state.denyModalOpen}
                value={se ? se.approveDenyNote : ""}
                toggle={() => this.toggleDenyModal(null)}
            >
                <ModalBody>
                    <Input
                        onBlur={(e) => this.setDenyReason(e.target.value)}
                        outline
                        label={"Deny Reason"}
                    />
                </ModalBody>
                <ModalFooter>
                    <MDBBtn
                        color={"red"}
                        disabled={!se || !se.approveDenyNote}
                        onClick={() =>
                            this.approveDenyExpense(this.state.selectedExpense, false)
                        }
                    >
                        Deny Expense
                    </MDBBtn>
                    <MDBBtn
                        color={"secondary"}
                        onClick={() => this.toggleDenyModal(null)}
                    >
                        Cancel
                    </MDBBtn>
                </ModalFooter>
            </Modal>
        );
    }

    renderDeniedBy(expense) {
        if (expense.deniedBy) {
            return expense.deniedBy.name;
        }

        if (this.ableToApproveDeny(expense)) {
            return (
                <Button
                    floating
                    size="sm"
                    color={"red"}

                    data-tip={"Deny Expense"}
                    onClick={() => {
                        this.toggleDenyModal(expense);
                    }}
                >
                    <MDBIcon icon="times" style={{fontSize: "2em"}}/>
                </Button>
            );
        } else {
            return "Not Denied";
        }
    }

    renderInfoColumn(expense) {
        let str = "";

        if (expense.expenseDate) {
            str += "Date: " + formatDate(expense.expenseDate);
            str += " \n";
        }
        if (expense.owner) {
            str += "Owner: " + expense.owner.name;
            str += " \n";
        }

        if (expense.vendor) {
            str += "Vendor: " + expense.vendor;
            str += " \n";
        }
        if (expense.expenses) {
            str += "Amount: $" + expense.expenses.toString();
            str += " \n";
        }
        if (expense.expenseDescription) {
            str += expense.expenseDescription;
            str += " \n";
        }

        return str;
    }

    renderAppointmentInfoColumn(expense) {
        let str = "";

        if (expense.peopleAttending) {
            str += "People Attending: " + expense.peopleAttending;
            str += "\n";
        }

        if (expense.educationalTopics) {
            str += "Topics: " + expense.educationalTopics;
            str += "\n";
        }

        if (expense.physiciansAttending) {
            str += "Physicians Attending: " + expense.physiciansAttending;
            str += "\n";
        }

        return str;
    }

    renderExpenseInfo(expense) {
        let str = "";

        if (expense.cardType) {
            str += "Card Type: " + expense.cardType.name;
            str += "\n";
        }

        if (expense.expenseType) {
            str += "Expense Type: " + expense.expenseType.name;
            str += "\n";
        }

        if (expense.when) {
            str += "Last Updated: " + formatDate(expense.when);
            str += "\n";
        }

        return str;
    }

    formatRows(expenses, totalElements) {
        let ary = [],
            dt = this.state.devData;

        expenses.forEach((expense, index) => {
            ary.push({
                receipt: expense.receipt
                    ? this.renderExpenseReceipt(expense.receipt)
                    : "",
                details: this.renderInfoColumn(expense),
                appointmentInfo: this.renderAppointmentInfoColumn(expense),
                expenseInfo: this.renderExpenseInfo(expense),
                // expenseDate: expense.expenseDate? this.formatDate(expense.expenseDate) : '',
                // lastUpdated: expense.when? this.formatDate(expense.when) : '',
                // owner: expense.owner? expense.owner.name : '',
                // vendor: expense.vendor? expense.vendor.replace(/,/g, ' ') : '',
                // expenseDescription: expense.expenseDescription? expense.expenseDescription.replace(/,/g, ' ') : '',
                // peopleAttending: expense.peopleAttending || '',
                // educationalTopics: expense.educationalTopics? expense.educationalTopics.replace(/,/g, ' ') : '',
                // physiciansAttending: expense.physiciansAttending? expense.physiciansAttending.replace(/,/g, ' ') : '',
                // expenseType: expense.expenseType? expense.expenseType.name.replace(/,/g, ' ') : '',
                // cardType: expense.cardType? expense.cardType.name.replace(/,/g, ' ') : '',
                // amount: expense.expenses? ('$' + expense.expenses) :  '',
                approvedBy: this.renderApprovedBy(expense),
                deniedBy: this.renderDeniedBy(expense),
                approveDenyNote: expense.approveDenyNote,
                id: expense.id,
            });
        });

        dt.rows = ary;
        if (this.state.currentPage === 0 && totalElements > 0) {
            toast.success("Found " + totalElements + " Results");
        }

        this.setState({
            devData: dt,
            isLoaded: true,
            totalCount: totalElements,
        });
    }

    toggleNewExpenseModalPopup = () => {
        //toggle,clear the inputs & disble file input
        let currentState = this.state.addExpenseModal,
            nExpense = {
                receipt: null,
                expenseDate: null,
                when: null,
                owner: {},
                expenseDescription: null,
                expenseType: {},
                cardType: {},
                expenses: 0,
                type: "Appointment",
                isExpense: true,
                quality: false,
            };
        this.setState({
            addExpenseModal: !currentState,
            nExpense: nExpense,
        });
    }

    handleExpenseDate = (property, value) => {
        //update the date property on the object that the user has edited in the order...BC
        const nExpense = this.state.nExpense;
        nExpense[property] = value;
        this.setState({nExpense: nExpense});
    };

    changeCardType(cardType) {
        let oExpense = this.state.nExpense;
        oExpense.cardType = cardType.value;
        oExpense.expenseType = {};
        this.setState({nExpense: oExpense});
    }

    changeExpenseType(expenseType) {
        let oExpense = this.state.nExpense;
        oExpense.expenseType = {
            id: expenseType.value.id,
            name: expenseType.value.name,
        };
        this.setState({
            nExpense: oExpense,
        });
    }

    handleChange = (property, event) => {
        //update the property that the user has edited in the order...BC
        const nExpense = this.state.nExpense;
        nExpense[property] = event.target.value;
        this.setState({nExpense: nExpense});
    };

    fileInputHandler(value) {
        let reader = new FileReader(),
            file = value[0],
            data,
            rt,
            newStr,
            expense = this.state.nExpense;

        reader.onloadend = () => {
            data = reader.result;
            rt = data.indexOf(",");
            if (rt > -1) {
                newStr = data.slice(rt + 1);
            }
            expense.receipt = newStr;
            this.setState({nExpense: expense});
        };

        reader.readAsDataURL(file);
    }

    renderReceipt() {
        let expense = this.state.nExpense;
        if (expense.receipt) {
            return (
                <Row>
                    <Col md="3">
                        <h6>
                            <strong>Preview Receipt :</strong>
                        </h6>
                    </Col>
                    <Col md="6">
                        <View>
                            <img
                                className={"thumbnail"}
                                src={"data:image/jpeg;base64," + expense.receipt}
                                alt={"Receipt"}
                                style={{height: 250, width: 500}}
                            />
                        </View>
                    </Col>
                </Row>
            );
        } else {
            return <div></div>;
        }
    }

    addValidation(msg) {
        let errorMessage = this.state.errorMessage;
        if (!errorMessage.includes(msg)) {
            errorMessage.push(msg);
        }
        this.setState({errorMessage: errorMessage});
    }

    removeValidation(msg) {
        let errorMessage = this.state.errorMessage;
        if (errorMessage.includes(msg)) {
            errorMessage.splice(msg);
        }
        this.setState({errorMessage: errorMessage});
    }

    validateForm() {
        let n = this.state.nExpense;

        if (Object.keys(n.cardType).length === 0) {
            this.addValidation("Select Card Type");
        } else if (Object.keys(n.cardType).length > 0) {
            this.removeValidation("Select Card Type");
        }
        if (Object.keys(n.expenseType).length === 0) {
            this.addValidation("Select Expense Type");
        } else {
            this.removeValidation("Select Expense Type");
        }
        if (n.expenseDate == null) {
            this.addValidation("Select Expense Date");
        } else {
            this.removeValidation("Select Expense Date");
        }

        if (n.expenseType) {
            if (n.expenseType.receiptRequired) {
                if (n.receipt == null) {
                    this.addValidation("Upload a receipt");
                } else {
                    this.removeValidation("Upload a receipt");
                }
            } else {
                //need to make sure to remove the validation if they don't need a receipt...BC
                this.removeValidation("Upload a receipt");
            }
        }

        if (n.vendor == null) {
            this.addValidation("Enter Vendor");
        } else {
            this.removeValidation("Enter Vendor");
        }

        if (n.expenseDescription == null) {
            this.addValidation("Enter Description");
        } else {
            this.removeValidation("Enter Description");
        }

        if (n.expenses == null || n.expenses <= 0) {
            this.addValidation("Add an amount greater than 0");
        } else {
            this.removeValidation("Add an amount greater than 0");
        }

        //check errormessage
        if (this.state.errorMessage.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    addExpense = () => {
        if (this.validateForm()) {
            const {currentUser} = this.context;
            let expense = this.state.nExpense;
            expense.when = moment(new Date());
            expense.owner = {id: currentUser.id, name: currentUser.name};

            return ExpenseService.createExpense(expense)
                .then((data) => {
                    let nExpense = {
                        receipt: null,
                        expenseDate: null,
                        when: null,
                        owner: {},
                        expenseDescription: null,
                        expenseType: {},
                        cardType: {},
                        expenses: 0,
                        type: "Appointment",
                        isExpense: true,
                        quality: false,
                    };
                    this.setState({nExpense: nExpense, isLoaded: false});
                    this.toggleNewExpenseModalPopup();

                    toast.success("Expense Created Successfully");
                    this.retrieveExpenses(0);
                })
                .catch((err) => {
                    toast.error("There was an error adding the expense");
                    //handle err bcz
                });
        }
    };

    getCardTypes() {
        const {cardTypes} = this.context;
        let ary = [];

        cardTypes.map((card) => {
            return ary.push({
                label: card.name,
                value: card,
            });
        });
        return ary;
    }

    getExpenseTypes() {
        let oExpense = this.state.nExpense;

        const {expenseTypes} = this.context;
        let ary = [];

        ary.push({
            label: "Choose Type",
            value: "",
        });

        expenseTypes.map((expense) => {
            return expense.cardTypes.forEach((card) => {
                if (oExpense.cardType.id === card.id) {
                    ary.push({
                        label: expense.name,
                        value: expense,
                    });
                }
            });
        });
        return ary;
    }

    renderReceiptInput() {
        return (
            <Row>
                <Col md={12}>
                    <InputFile
                        textFieldTitle="only .png or .jpg files are currently supported"
                        getValue={this.fileInputHandler.bind(this)}
                    />
                </Col>
            </Row>
        );
    }

    renderAddExpenseModal() {
        let expense = this.state.nExpense;

        return (
            <Modal
                size="lg"
                centered
                isOpen={this.state.addExpenseModal}
                toggle={this.toggleNewExpenseModalPopup}
            >
                <ModalHeader style={{justifyContent: "center", backgroundColor: "#5881C1", color: "white"}}
                             toggle={this.toggleNewExpenseModalPopup}>
                    <h1>Add New Expense</h1>
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col md={12}>
                            {this.state.errorMessage.map((msg, index) => {
                                return (
                                    <h6 key={index} style={{color: "red"}}>
                                        {msg}
                                    </h6>
                                );
                            })}
                        </Col>
                    </Row>
                    <Row>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Col md={6} className={"  filterSearchBoxCol uiDateOutline uiDateOutlineNext"}>
                                <div>
                                    <DatePicker
                                        className={"expenseSearchDate"}
                                        format="MM/DD/YYYY"
                                        size={"small"}
                                        inputVariant={"outlined"}
                                        keyboard
                                        allowKeyboardControl
                                        value={this.state.nExpense.expenseDate}
                                        onChange={(evt) => {
                                            let newExpense = this.state.nExpense;
                                            newExpense.expenseDate = evt;
                                            this.setState({nExpense: newExpense});
                                        }}
                                        mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                                        label="Expense Date"
                                    />

                                </div>
                            </Col>
                        </MuiPickersUtilsProvider>
                        <Col md={6}>
                            <Input
                                outline
                                value={expense.expenses || ""}
                                label="Amount"
                                onChange={this.handleChange.bind(this, "expenses")}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} style={{color: "#000"}}>
                            <Select
                                placeholder="Select Card Type"
                                options={this.getCardTypes()}
                                onChange={this.changeCardType.bind(this)}
                            />
                        </Col>
                        <Col md={6} style={{color: "#000"}}>
                            <Select
                                placeholder="Select Expense Type"
                                options={this.getExpenseTypes()}
                                onChange={this.changeExpenseType.bind(this)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Input
                                outline
                                value={expense.vendor || ""}
                                label="Vendor"
                                onChange={this.handleChange.bind(this, "vendor")}

                            />
                        </Col>
                        <Col md={6}>
                            <Input
                                outline
                                value={expense.expenseDescription || ""}
                                label="Description"
                                onChange={this.handleChange.bind(this, "expenseDescription")}

                            />
                        </Col>
                    </Row>
                    {this.renderReceiptInput()}
                    {this.renderReceipt()}
                </ModalBody>
                <ModalFooter style={{backgroundColor: "white"}}>
					<span
                        className={"expBtn exSaveBtn"}
                        data-tip={"Add New Expense"}
                        onClick={this.addExpense}
                    >
						<Fa size="2x" icon={"check"}>
							{" "}
						</Fa>
					</span>
                    <span
                        className={"expBtn exCancelBtn"}
                        data-tip={"Cancel"}
                        onClick={() => this.toggleNewExpenseModalPopup()}
                    >
						<Fa size="2x" icon={"times"}>
							{" "}
						</Fa>
					</span>
                    <ReactTooltip/>
                </ModalFooter>
            </Modal>
        );
    }

    getInternalLocations() {
        const {internalLocations, currentUser} = this.context;

        let ary = [],
            role = currentUser.role;

        ary.push({
            label: "Choose a Location...",
            value: "",
        });

        switch (role) {
            case "SYSTEM_ADMIN":
            case "ADMIN":
            case "EXECUTIVE_MANAGEMENT":
            case "FINANCE":
                internalLocations.map((location) => {
                    return ary.push({
                        label: location.name,
                        value: location,
                    });
                });
                break;
            case "SALES_MANAGER":
                if (currentUser.locations) {
                    currentUser.locations.map((location) => {
                        if (location.type === "Internal") {
                            return ary.push({
                                label: location.name,
                                value: location,
                            });
                        }
                        return null;
                    });
                }
                break;
            default:
                break;
        }
        return ary;
    }

    selectLocation(location) {
        let nFilters = this.state.filters;
        nFilters.locationId = location.value.id;
        this.setState({filters: nFilters});
    }

    getLocalStorage() {
        let f = window.localStorage.getItem("expenseFilters");

        if (f != null) {
            f = JSON.parse(f);

            this.setState({
                startDate: f.startDate ? new Date(f.startDate) : new Date(),
                endDate: f.endDate ? new Date(f.endDate) : new Date(),
                allSystemUsersSelected: f.users,
                locationsSelected: f.locations,
                isLoaded: false,
            });
        }

        setTimeout(() => {
            this.retrieveExpenses(0);
        }, 500);
    }

    updateLocalStorage() {
        let filters = {},
            st = this.state;

        filters.startDate = st.startDate;
        filters.endDate = st.endDate;
        filters.users = st.allSystemUsersSelected;
        filters.locations = st.locationsSelected;

        window.localStorage.setItem("expenseFilters", JSON.stringify(filters));
    }

    filterOnLocation() {
        let st = this.state;
        if (
            st.startDate == null ||
            st.startDate === "" ||
            st.endDate == null ||
            st.endDate === ""
        ) {
            toast.error("Please check your date filters");
            return;
        }

        this.setState({isLoaded: false});

        this.updateLocalStorage();
        this.retrieveExpenses(0);
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div
                className={"expensesGrid orderList"}
                style={{marginBottom: "1rem"}}
            >
                {this.renderDevTable()}
            </div>
        );
    }

    renderDevTable() {
        let filtersData = {
            users: this.state.allSystemUsersSelected,
            locations: this.state.locationsSelected,
            currentPage: this.state.currentPage,
            totalCount: this.state.totalCount,
        };

        //build filters to send over
        if (this.state.showDatePicker) {
            filtersData.startDate = this.state.startDate;
            filtersData.endDate = this.state.endDate;
        } else {
            filtersData.quickFilter = this.state.quickFilter;
            filtersData.quickFilterSelected = this.state.quickFilterSelected;
        }

        const TableCell = ({row, column, ...restProps}) => (
            <Table.Cell
                {...restProps}
                onClick={() => {
                    let c = column;

                    if (c.name === "approvedBy" || c.name === "deniedBy") {
                        //we don't want to move with these columns...BC
                        return;
                    }

                    this.props.history.push({
                        pathname: "/expenses/" + row.id,
                        filters: filtersData,
                    });
                }}
                style={{
                    cursor: "pointer",
                }}
            />
        );

        return (
            <Grid
                style={{maxWidth: "1800px !important"}}
                rows={this.state.devData.rows}
                columns={this.state.devData.columns}
            >
                <PagingState
                    defaultCurrentPage={0}
                    pageSize={10}
                    currentPage={this.state.currentPage}
                    onCurrentPageChange={this.changeCurrentPage.bind(this)}
                />
                <CustomPaging totalCount={this.state.totalCount}/>
                <Table cellComponent={TableCell}/>
                <TableColumnResizing defaultColumnWidths={this.state.defaultWidths}/>
                <TableHeaderRow/>
                <PagingPanel/>
            </Grid>
        );
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    handleDatePickerChange = (property, value) => {
        this.setState({
            [property]: value,
        });
    };

    renderLocationsFilter() {
        const {currentUser} = this.context;
        let role = currentUser.role;

        switch (role) {
            case "SALES":
            case "OFFICE_MANAGER":
            case "OFFICE_SUPPORT":
            case "CARE_COORDINATOR":
            case "CALL_CENTER":
            case "TECHNICIAN":
            case "DATA_EXPORT":
            case "VERIFICATION":
            case "FOLLOWUP":
            case "COLLECTIONS":
            case "BILLING":
            case "PURCHASING":
            default:
                return <div/>;
            case "SYSTEM_ADMIN":
            case "ADMIN":
            case "EXECUTIVE_MANAGEMENT":
            case "FINANCE":
            case "SALES_MANAGER":
                return (
                    <Row>
                        <Col md={4}>
                            <Select
                                placeholder="Select Location"
                                // closeMenuOnSelect={false}
                                isMulti
                                options={this.state.locationsSelect}
                                onChange={this.handleLocationChange.bind(this)}
                                value={this.state.locationsSelected}
                            />
                        </Col>
                        <Col md={8}>
                            <Select
                                placeholder="Select User"
                                closeMenuOnSelect={false}
                                isMulti
                                options={this.state.allSystemUsersSelect}
                                onChange={this.handleSaleRepChange.bind(this)}
                                value={this.state.allSystemUsersSelected}
                            />
                        </Col>
                    </Row>
                );
        }
    }

    //call to download data
    clientCSV(stateData, filename) {
        let result = "",
            ctr = 0,
            keys = this.state.columnHeaders,
            columnDelimiter = ",",
            lineDelimiter = "\n";

        if (stateData == null || !stateData.length) {
            return null;
        }

        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        stateData.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (
                    key === "expenseDesc" ||
                    key === "educationalTopics" ||
                    key === "physiciansAttending"
                ) {
                    result += '"' + item[key] + '"';
                } else {
                    result +=
                        item[key] === null || item[key] === undefined ? "" : item[key];
                }

                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, filename);
    }

    //csv downlaod data here
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {
        return (
            <div style={{marginLeft: "4%", marginRight: "4%"}}>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />
                {this.renderDenyModal()}
                {this.renderAddExpenseModal()}
                <CardHeader style={{backgroundColor: "#5881C1"}}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Row>
                            <Col md={"12"} className={"filterSearchBoxCol"} style={{color: "#000"}}>
                                {this.renderLocationsFilter()}
                            </Col>
                        </Row>
                        <Row>
                            <Col className={"filterSearchBoxCol"} style={{color: "#000"}}>
                                <Select
                                    placeholder="Quick Filter"
                                    options={QuickFilter.getOptions()}
                                    onChange={this.handleQuickFilter.bind(this)}
                                    value={this.state.quickFilterSelected}
                                />
                            </Col>
                            <Col md={2} className={"  filterSearchBoxCol uiDateOutline uiDateOutlineNext"}>
                                <div>
                                    <DatePicker
                                        className={"expenseSearchDate"}
                                        format="MM/DD/YYYY"
                                        size={"small"}
                                        inputVariant={"outlined"}
                                        keyboard
                                        allowKeyboardControl
                                        value={this.state.startDate}
                                        onChange={(evt) => {
                                            this.setState({startDate: evt});
                                        }}
                                        mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                                        label="Start Date"
                                    />

                                </div>
                            </Col>
                            <Col md={2} className={"  filterSearchBoxCol uiDateOutline uiDateOutlineNext"}>
                                <div>
                                    <DatePicker
                                        className={"expenseSearchDate"}
                                        format="MM/DD/YYYY"
                                        size={"small"}
                                        inputVariant={"outlined"}
                                        keyboard
                                        allowKeyboardControl
                                        value={this.state.endDate}
                                        onChange={(evt) => {
                                            this.setState({endDate: evt});
                                        }}
                                        mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                                        label="End Date"
                                    />

                                </div>
                            </Col>

                            <Col>
                                <Button
                                    floating
                                    size="sm"
                                    color={"indigo"}
                                    data-tip={"Save and Search"}
                                    onClick={() => {
                                        this.filterOnLocation();
                                    }}
                                >
                                    <MDBIcon icon="search-plus" style={{fontSize: "2em"}}/>
                                </Button>

                                <Button
                                    floating
                                    size="sm"
                                    color={"secondary"}
                                    data-tip={"Add New Expense"}
                                    onClick={() => this.toggleNewExpenseModalPopup()}
                                >
                                    <MDBIcon icon="plus" style={{fontSize: "2em"}}/>
                                </Button>
                            </Col>
                        </Row>
                    </MuiPickersUtilsProvider>
                </CardHeader>
                <Row style={{zIndex: 0}}>
                    <Col style={{zIndex: 0}}>{this.renderTableOrSpinner()}</Col>
                </Row>
                <div className={"fixed-bottom downloadCsvDiv"}>
                    <Button
                        className={"downloadCsvButton"}
                        floating
                        size="sm"
                        color={"indigo"}
                        data-tip={"Download CSV"}
                        onClick={this.getExpensesForCSV.bind(this)}
                    >
                        <MDBIcon icon="download" style={{fontSize: "2em"}}/>
                    </Button>

                    <ReactTooltip/>
                </div>
            </div>
        );
    }
}
