import React from "react";
import {
	Button,
	Card,
	CardFooter,
	CardBody,
	Container,
	Col,
	Row,
	Spinner,
	CardHeader,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Dropdown,
	MDBIcon,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import ComplaintService from "../Security/ComplaintService/ComplaintService";

export default class customerComplaintsSettings extends React.Component {
	constructor(props) {
		super(props);

		const categoryData = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Name",
					field: "name",
				},
				{
					label: "Edit",
					field: "edit",
				},
				{
					label: "Delete",
					field: "delete",
				},
			],
			rows: [],
		};

		const reasonData = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Category",
					field: "category",
				},
				{
					label: "Name",
					field: "name",
				},
				{
					label: "Edit",
					field: "edit",
				},
				{
					label: "Delete",
					field: "delete",
				},
			],
			rows: [],
		};

		this.state = {
			categoryData: categoryData,
			reasonData: reasonData,
			complaintCategories: [],
			selectedCategory: {},
			isLoaded: false,
			editCategoryModal: false,
			addCategoryModal: false,
			name: "",
			currIndex: -1,
			errorMessage: "",
			currCC: {},
			currCR: {},
		};
	}

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveComplaintCategories();
		this.retrieveComplaintReasons();
	}

	retrieveComplaintCategories() {
		let t = this;
		return ComplaintService.getAllIssueCategories()
			.then((res) => {
				let arr = [],
					d = this.state.categoryData;

				res = res.sort((a, b) => {
					return a.id - b.id;
				});

				res.forEach((cc, index) => {
					arr.push({
						id: cc.id,
						name: cc.name,
						edit: t.renderEditButton(cc, index),
						remove: t.renderRemoveButton(cc.id),
					});
				});

				d.rows = arr;

				t.setState({
					categoryData: d,
					complaintCategories: res,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	categorySelected(category) {
		this.setState({ selectedCategory: category });
	}

	retrieveComplaintReasons() {
		let t = this;
		return ComplaintService.getAllIssueReasons()
			.then((res) => {
				let arr = [],
					d = this.state.reasonData;

				res = res.sort((a, b) => {
					return a.id - b.id;
				});

				res.forEach((cc, index) => {
					arr.push({
						id: cc.id,
						category: cc.issueType ? cc.issueType.name : "No Category",
						name: cc.name,
						edit: t.renderEditButton(cc, index, true),
						remove: t.renderRemoveButton(cc.id, true),
					});
				});

				d.rows = arr;

				t.setState({
					reasonData: d,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	//region Issue Categories

	renderCategoryDropdown(categories) {
		if (categories) {
			return (
				<Dropdown size="lg">
					<DropdownToggle caret>
						{this.state.selectedCategory.name || "Select Category"}
					</DropdownToggle>
					<DropdownMenu basic>
						{categories.map((cat, idx) => {
							return (
								<DropdownItem
									key={idx}
									onClick={() => this.categorySelected(cat)}
								>
									{cat.name}
								</DropdownItem>
							);
						})}
					</DropdownMenu>
				</Dropdown>
			);
		}
	}

	handleRemoveCategoryClick(id) {
		ComplaintService.deleteIssueCategory(id)
			.then((res) => {
				this.retrieveComplaintCategories();
				toast.success("Feedback Category Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing the feedback Category");
			});
	}

	handleEditCategoryClick(cc, index) {
		let a = {
			id: cc.id,
			name: cc.name,
		};
		this.setState({
			currCC: a,
			editCategoryModal: !this.state.editCategoryModal,
			currIndex: index,
		});
	}

	editCategoryToggle = () => {
		this.setState({
			editCategoryModal: !this.state.editCategoryModal,
			errorMessage: "",
		});
	};

	renderEditCategoryModal() {
		return (
			<Modal
				isOpen={this.state.editCategoryModal}
				toggle={this.editCategoryToggle}
			>
				<ModalHeader
					style={{ backgroundColor: "white" }}
					toggle={this.editCategoryToggle}
				>
					Edit Complaint Category
				</ModalHeader>

				<ModalBody style={{ backgroundColor: "white" }}>
					<div>
						<p style={{ color: "red" }}>{this.state.errorMessage}</p>
						<Input
							label="Name"
							value={this.state.currCC.name}
							onChange={this.handleEditCategoryChange.bind(this)}
						/>
					</div>
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button color="secondary" onClick={this.handleEditClose}>
						Close
					</Button>{" "}
					<Button color="primary" onClick={this.handleEditCategorySave}>
						Save changes
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	renderAddCategoryModal() {
		return (
			<Modal
				isOpen={this.state.addCategoryModal}
				toggle={this.editCategoryToggle}
			>
				<ModalHeader
					style={{ backgroundColor: "white" }}
					toggle={this.editCategoryToggle}
				>
					Add Complaint Category
				</ModalHeader>

				<ModalBody style={{ backgroundColor: "white" }}>
					<div>
						<p style={{ color: "red" }}>{this.state.errorMessage}</p>
						<Input
							label="Name"
							value={this.state.currCC.name}
							onChange={this.handleEditCategoryChange.bind(this)}
						/>
					</div>
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button color="secondary" onClick={this.handleEditClose}>
						Close
					</Button>{" "}
					<Button color="primary" onClick={this.handleCreateCategory}>
						Create
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	handleEditCategorySave = () => {
		if (this.validateCategoryEdit()) {
			let d = this.state.categoryData,
				i = this.state.currIndex,
				t = this;

			return ComplaintService.updateIssueCategory(this.state.currCC)
				.then((cc) => {
					d.rows[i].name = cc.name;
					d.rows[i].edit = t.renderEditButton(cc, i);

					this.setState({
						categoryData: d,
						editCategoryModal: false,
						errorMessage: "",
					});
					toast.success("Feedback Category Edited!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	handleCreateCategory = () => {
		if (this.validateCategoryEdit()) {
			let d = this.state.categoryData,
				i = this.state.currIndex,
				t = this;

			return ComplaintService.createIssueCategory(this.state.currCC)
				.then((cc) => {
					let category = {};

					category.id = cc.id;
					category.name = cc.name;
					category.edit = t.renderEditButton(cc, i);
					category.remove = t.renderRemoveButton(cc.id);

					d.rows.push(category);

					this.setState({
						categoryData: d,
						editCategoryModal: false,
						addCategoryModal: false,
						errorMessage: "",
					});
					toast.success("Feedback Category Added!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateCategoryEdit() {
		let a = this.state.currCC;
		if (a.name === "") {
			this.setError("Please add a category Name");
			return false;
		}
		return true;
	}

	handleEditCategoryChange(e) {
		let c = this.state.currCC;
		c.name = e.target.value;

		this.setState({
			currCC: c,
		});
	}

	//endregion

	//region issue reasons
	handleRemoveReasonClick(id) {
		ComplaintService.deleteIssueReason(id)
			.then((res) => {
				this.retrieveComplaintReasons();
				toast.success("Complaint Reason Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Complaint Reason");
			});
	}

	handleEditReasonClick(cc, index) {
		let a = {
			id: cc.id,
			name: cc.name,
		};
		this.setState({
			currCR: a,
			editReasonModal: !this.state.editReasonModal,
			currIndex: index,
			selectedCategory: cc.issueType,
		});
	}

	editReasonToggle = () => {
		this.setState({
			editCategoryModal: !this.state.editCategoryModal,
			errorMessage: "",
		});
	};

	renderEditReasonModal() {
		return (
			<Modal
				isOpen={this.state.editReasonModal}
				toggle={this.editCategoryToggle}
			>
				<ModalHeader
					style={{ backgroundColor: "white" }}
					toggle={this.editCategoryToggle}
				>
					Edit Complaint Reason
				</ModalHeader>

				<ModalBody style={{ backgroundColor: "white" }}>
					<div>
						<p style={{ color: "red" }}>{this.state.errorMessage}</p>
						{this.renderCategoryDropdown(this.state.complaintCategories)}
						<Input
							label="Name"
							value={this.state.currCR.name}
							onChange={this.handleEditReasonChange.bind(this)}
						/>
					</div>
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button color="secondary" onClick={this.handleEditClose}>
						Close
					</Button>{" "}
					<Button color="primary" onClick={this.handleEditReasonSave}>
						Save changes
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	renderAddReasonModal() {
		return (
			<Modal isOpen={this.state.addReasonModal} toggle={this.editReasonToggle}>
				<ModalHeader
					style={{ backgroundColor: "white" }}
					toggle={this.editReasonToggle}
				>
					Add Complaint Reason
				</ModalHeader>

				<ModalBody style={{ backgroundColor: "white" }}>
					<div>
						<p style={{ color: "red" }}>{this.state.errorMessage}</p>
						{this.renderCategoryDropdown(this.state.complaintCategories)}
						<Input
							label="Name"
							value={this.state.currCR.name}
							onChange={this.handleEditReasonChange.bind(this)}
						/>
					</div>
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button color="secondary" onClick={this.handleEditClose}>
						Close
					</Button>{" "}
					<Button color="primary" onClick={this.handleCreateReason}>
						Create
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	handleEditReasonChange(e) {
		let c = this.state.currCR;
		c.name = e.target.value;
		this.setState({
			currCR: c,
		});
	}

	handleEditReasonSave = () => {
		if (this.validateCategoryEdit()) {
			let d = this.state.reasonData,
				i = this.state.currIndex,
				r = this.state.currCR,
				c = this.state.selectedCategory;

			r.issueType = c;

			return ComplaintService.updateIssueReason(r)
				.then((cc) => {
					d.rows[i].name = cc.name;
					d.rows[i].category = cc.issueType.name;
					d.rows[i].edit = this.renderEditButton(cc, i, true);

					this.setState({
						reasonData: d,
						editReasonModal: false,
						errorMessage: "",
					});
					toast.success("Complaint Category Edited!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	handleCreateReason = () => {
		if (this.validateReasonEdit()) {
			let d = this.state.reasonData,
				i = this.state.currIndex,
				c = this.state.selectedCategory,
				r = this.state.currCR;

			r.issueType = c;

			return ComplaintService.createIssueReason(r)
				.then((cc) => {
					let reason = {};

					reason.id = cc.id;
					reason.category = cc.issueType.name;
					reason.name = cc.name;
					reason.edit = this.renderEditButton(cc, i, true);
					reason.remove = this.renderRemoveButton(cc.id, true);

					d.rows.push(reason);

					this.setState({
						reasonData: d,
						editReasonModal: false,
						addReasonModal: false,
						errorMessage: "",
						selectedCategory: {},
					});
					toast.success("Complaint Reason Added!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	validateReasonEdit() {
		let a = this.state.currCR,
			b = this.state.selectedCategory;
		if (a.name == null) {
			this.setError("Please add a reason name");
			return false;
		}
		if (b.name == null) {
			this.setError("Please add a complaint category");
			return false;
		}
		return true;
	}

	//endregion

	renderEditButton(cc, index, reason) {
		// let a = JSON.parse(JSON.stringify(account));
		return (
			<Button
				floating
				size="sm"
				onClick={() => {
					if (reason) {
						this.handleEditReasonClick(cc, index);
					} else {
						this.handleEditCategoryClick(cc, index);
					}
				}}
			>
				<MDBIcon icon="edit" />
			</Button>
		);
	}

	renderRemoveButton(id, reason) {
		return (
			<Button
				floating
				color={"red"}
				size="sm"
				onClick={() => {
					if (reason) {
						this.handleRemoveReasonClick(id);
					} else {
						this.handleRemoveCategoryClick(id);
					}
				}}
			>
				<MDBIcon icon="trash" />
			</Button>
		);
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleEditClose = () => {
		this.setState({
			currIndex: -1,
			errorMessage: "",
			editCategoryModal: false,
			addCategoryModal: false,
			editReasonModal: false,
			addReasonModal: false,
			currCC: {},
			currCR: {},
		});
	};

	renderCategoryTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.categoryData.columns} />
					<MDBTableBody rows={this.state.categoryData.rows} />
				</MDBTable>
			);
		} else {
			return <div></div>;
		}
	}

	renderReasonTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.reasonData.columns} />
					<MDBTableBody rows={this.state.reasonData.rows} />
				</MDBTable>
			);
		} else {
			return <div />;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Row>
					<Col size="6">
						<Card>
							<CardHeader style={{ textAlign: "center" }}>
								Issue Categories
							</CardHeader>
							<CardBody>{this.renderCategoryTable()}</CardBody>
							<CardFooter>
								<Button
									style={{ float: "right" }}
									color="secondary"
									onClick={() => {
										// ComplaintService.getAllIssueReasons().then((res) => {
										//     let dog = res;
										// }).catch(err => {
										//     let cat = err;
										// })
										this.setState({ addCategoryModal: true });
									}}
								>
									Add New Issue Category
								</Button>
							</CardFooter>
						</Card>
					</Col>

					<Col size="6">
						<Card>
							<CardHeader style={{ textAlign: "center" }}>
								Issue Reasons
							</CardHeader>

							<CardBody>{this.renderReasonTable()}</CardBody>

							<CardFooter>
								<Button
									style={{ float: "right" }}
									color="secondary"
									onClick={() => this.setState({ addReasonModal: true })}
								>
									Add New Issue Reason
								</Button>
							</CardFooter>
						</Card>
					</Col>
				</Row>
				{this.renderEditCategoryModal()}
				{this.renderAddCategoryModal()}
				{this.renderAddReasonModal()}
				{this.renderEditReasonModal()}
			</Container>
		);
	}
}
