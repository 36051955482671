import React from "react";
import {
	Button,
	Card,
	CardFooter,
	CardBody,
	MDBContainer,
	MDBIcon,
	DataTable,
	Spinner,
} from "mdbreact";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

export default class userList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
					width: 150,
				},
				{
					label: "Active",
					field: "active",
					sort: "asc",
					width: 150,
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
					width: 150,
				},
				{
					label: "Username",
					field: "username",
					sort: "asc",
					width: 100,
				},
				{
					label: "E-Mail",
					field: "email",
					sort: "asc",
					width: 200,
				},
				{
					label: "Position",
					field: "role",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "button",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allActiveUsers: PropTypes.array,
	};

	componentDidMount() {
		const { allActiveUsers } = this.context;
		// checks if allUsers had already propagated to this page. This be true when initially opening the page.
		// If page is refreshed then this will be false making sure table isn't empty and waiting until users have been propagated down...JK
		if (allActiveUsers.length > 0) {
			this.populateUsers();
		}
	}

	// Ensures that users are populated on page refresh
	componentDidUpdate(prevProps, prevState, snapshot) {
		const { allActiveUsers } = this.context;
		if (allActiveUsers.length > 0 && this.state.isLoaded === false) {
			this.populateUsers();
		}
	}

	populateUsers() {
		const { allActiveUsers } = this.context;

		let ary = [],
			dt = this.state.data;

		allActiveUsers.forEach((user, index) => {
			ary.push({
				id: user.userId,
				active: user.active != null ? user.active.toString() : "Not Set",
				name: user.firstname + " " + user.lastname || "no name",
				username: user.username,
				email: user.email || "no email",
				role: user.role || "no role",
				button: this.renderRouteButton(user, index),
			});
		});

		dt.rows = ary;

		this.setState({
			data: dt,
			isLoaded: true,
		});
	}

	renderAddUserButton() {
		return (
			<Button
				style={{ float: "right" }}
				color={"secondary"}
				floating
				size="sm"
				data-tip={"Add New User"}
				onClick={() => {
					this.props.history.push({
						pathname: "/addUser",
					});
				}}
			>
				<MDBIcon icon="user-plus" style={{ fontSize: "1.5em" }} />
			</Button>
		);
	}

	renderRouteButton(user, index) {
		return (
			<MDBIcon
				icon="edit"
				key={index}
				className="fa fa-pencil-square-o"
				aria-hidden="true"
				color="warning"
				onClick={() => {
					this.props.history.push({
						pathname: "/user/" + user.userId,
						state: { user: user },
					});
				}}
			/>
		);
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<DataTable striped info={false} small data={this.state.data}>
					{" "}
				</DataTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<MDBContainer>
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</MDBContainer>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}

		return (
			<MDBContainer>
				<Card>
					<CardBody>{this.renderTable()}</CardBody>
					<CardFooter>
						{this.renderAddUserButton()}
						<ReactTooltip />
					</CardFooter>
				</Card>
			</MDBContainer>
		);
	}
}
