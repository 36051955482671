import api from "../Security/api";

class ReverseQualityService {
	async getAllCategories() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				paged: false,
			};
			api
				.get("/reverseQualityCategories", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createRQCategory(category){
		return new Promise((res, reject) => {
			api
				.post("/reverseQualityCategories", category)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}


	async getAllLogs(params) {
		return new Promise((res, reject) => {
			api
				.get("/reverseQualityLogs", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	// calls proc spReverseQualityReport
	async getAllLogsNative(params) {
		return new Promise((res, reject) => {
			api
				.get("/reverseQualityLogsNative", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createReverseQualityLog(log) {
		return new Promise((res, reject) => {
			api
				.post("/reverseQualityLogs", log)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async fixReverseQualityLog(id) {
		return new Promise((res, reject) => {
			api.put("/reverseQualityLogs/" + id + "/fix", new Date())
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async reviewReverseQualityLog(id) {
		return new Promise((res, reject) => {
			api.put("/reverseQualityLogs/"+ id + "/review", new Date())
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}




	async createQualityAssuranceLog(log) {
		return new Promise((res, reject) => {
			api
				.post("/qualityAssuranceRecords", log)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

}

const reverseQualityService = new ReverseQualityService();

export default reverseQualityService;
