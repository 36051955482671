import React from "react";
import PropTypes from "prop-types";
import {
	Button,
	MDBTabContent,
	MDBTabPane,
	MDBNav,
	MDBNavItem,
	MDBIcon,
	MDBNavLink,
} from "mdbreact";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import ReactTooltip from "react-tooltip";
const InventoryProductsTable = React.lazy(() =>
	import("./inventoryProductTable")
);

export default class inventoryProductView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeItemJustified: "1",
			printTable: 0,
			currentUser: {},
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	componentDidMount() {
		const { currentUser } = this.context;
		this.setState({
			currentUser: currentUser,
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { currentUser } = this.context;
		if (this.state.currentUser.id !== currentUser.id) {
			this.setState({
				currentUser: currentUser,
			});
		}
	}

	toggleJustified = (tab) => (e) => {
		if (this.state.activeItemJustified !== tab) {
			this.setState({
				activeItemJustified: tab,
			});
		}
	};

	getLocations() {
		return { location: this.state.currentUser.locations[0].id };
	}

	getAckPrams() {
		let p = this.getLocations();
		p.acknowledged = "true";
		return p;
	}

	render() {
		let st = this.state;
		return (
			<div style={{ margin: "0 2% 0 2%" }}>
				<div
					style={{
						position: "absolute",
						top: "9%",
						left: "1rem",
						zIndex: "50000",
					}}
				>
					<Button
						style={{ padding: "inherit" }}
						floating
						size="md"
						data-tip={"Print Table"}
						onClick={() => {
							let pt = this.state.printTable;
							this.setState({
								printTable: ++pt,
							});
						}}
					>
						<MDBIcon icon="print" style={{ fontSize: "2em" }} />
					</Button>
					<ReactTooltip />
				</div>
				<MDBNav tabs className="nav-justified" color="indigo">
					<MDBNavItem>
						<MDBNavLink
							to="#"
							active={st.activeItemJustified === "1"}
							onClick={this.toggleJustified("1")}
							role="tab"
						>
							Not Acknowledged
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink
							to="#"
							active={st.activeItemJustified === "2"}
							onClick={this.toggleJustified("2")}
							role="tab"
						>
							Acknowleged
						</MDBNavLink>
					</MDBNavItem>
				</MDBNav>
				<MDBTabContent className="card" activeItem={st.activeItemJustified}>
					<MDBTabPane tabId="1" role="tabpanel">
						{st.activeItemJustified === "1" &&
						st.currentUser.id !== undefined ? (
							<React.Suspense fallback={<div>...Loading...</div>}>
								<InventoryProductsTable
									params={this.getLocations()}
									printer={this.state.printTable}
								/>
							</React.Suspense>
						) : (
							<div />
						)}
					</MDBTabPane>
					<MDBTabPane tabId="2" role="tabpanel">
						{st.activeItemJustified === "2" &&
						st.currentUser.id !== undefined ? (
							<React.Suspense fallback={<div>...Loading...</div>}>
								<InventoryProductsTable
									params={this.getAckPrams()}
									printer={this.state.printTable}
								/>
							</React.Suspense>
						) : (
							<div />
						)}
					</MDBTabPane>
				</MDBTabContent>
			</div>
		);
	}
}
