import React from "react";
import {
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBCol,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBRow,
    MDBBtn,
} from "mdbreact";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import NoteService from "../../Security/NoteService/noteService";

export default class NotesTab extends React.Component {
    constructor(props) {
        super(props);

        this.toggleNoteModal = props.toggleNoteModal;

        this.state = {
            allNotes: props.allNotes,
            editNoteOpen: false,
            taskCompleted: true,
            modalNote: {},
            noteAssignedTo: null,
            eOrder: props.eOrder,
            selectedNoteTypesArray: []
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        timezoneVariation: PropTypes.object,
        allLiaisons: PropTypes.array,
        fullLiaisonKeys: PropTypes.array,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.allNotes !== prevState.allNotes) {
            return {allNotes: nextProps.allNotes};
        } else if (nextProps.eOrder !== prevState.eOrder) {
            return {eOrder: nextProps.eOrder};
        } else return null;
    }

    formatDate(date) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
        }).format(date);
    }

    sortNotes(allNotes, task) {
        let t = [],
            n = [];

        allNotes.forEach((note) => {
            if (note.completed === false) {
                t.push(note);
            } else {
                n.push(note);
            }
        });

        if (task) {
            return t;
        }
        return n;
    }

    updateNoteModal(note) {
        const {currentUser} = this.context;

        let nText = document.getElementById("noteTextArea").value;

        let upDate = "Updated: " + this.formatDate(new Date()),
            by = " -- By: " + currentUser.username;
        note.text = nText + " -- " + upDate + by;

        this.updateNote(note, null);
    }

    updateNote(note, complete) {
        const {currentUser} = this.context;

        let notes = this.state.allNotes,
            noteToReplace = notes.findIndex((n) => {
                return n.id === note.id;
            });

        if (noteToReplace > -1) {
            notes.splice(noteToReplace, 1, note);
        }

        if (complete != null) {
            note.completed = complete;

            note.text +=
                " --> Completed by " +
                currentUser.username +
                " on " +
                this.formatDate(new Date());
        }

        return NoteService.updateActivityNote(note)
            .then((res) => {
                this.setState({
                    allNotes: notes,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    toggleEditModalPopup(note) {
        const {currentUser} = this.context;

        let currentState = this.state.editNoteOpen,
            ncopy = {};

        if (note) {
            ncopy = JSON.parse(JSON.stringify(note));

            if (currentUser) {
                if (
                    currentUser.role === "ADMIN" ||
                    currentUser.role === "SYSTEM_ADMIN"
                ) {
                    //we are fine, continue
                } else if (currentUser.id !== note.createdBy.id) {
                    //they do not own this note.  They shouldn't be able to edit it.
                    return;
                } else {
                    //determine if within 24 hours...BC
                    let ca = note.createdAt,
                        n = new Date().getTime();

                    if (n - ca < 86400000) {
                        //this note is within 24 hours, continue...BC
                    } else {
                        //This note is older than 24 hours.  do not continue...BC
                        return;
                    }
                }
            } else {
                return;
            }
        }

        this.setState({
            editNoteOpen: !currentState,
            modalNote: ncopy,
        });
    }

    generateNote(note) {
        let nta = this.state.selectedNoteTypesArray;

        if (note.noteCategory && nta.length > 0 && nta.indexOf(note.noteCategory) === -1) {
            return;
        }

        return (
            <MDBCard style={{backgroundColor: "#FFF7D1"}}>
                <MDBCardHeader style={{backgroundColor: "#FFF2AB"}}>
                    <MDBRow>
                        <MDBCol size={"5"}>
                            {this.formatDate(new Date(note.createdAt))}
                        </MDBCol>

                        <MDBCol size={"5"}>
                            {note.createdBy ? note.createdBy.name : ""}
                        </MDBCol>

                        <MDBCol onClick={() => this.toggleEditModalPopup(note)} size={"2"}>
                            <MDBIcon
                                style={{color: "#857E59", padding: 4, float: "right"}}
                                data-tip={"Edit"}
                                icon="ellipsis-v"
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCardHeader>

                <MDBCardBody>
                    <p style={{whiteSpace: "pre-line"}}>{note.text}</p>
                </MDBCardBody>
            </MDBCard>
        );
    }

    renderNotesSection() {
        let notes = this.sortNotes(this.state.allNotes);

        return (
            <div>
                {notes.map((n, idx) => {
                    return (
                        <div key={idx} style={{paddingBottom: 6}}>
                            {this.generateNote(n)}
                        </div>
                    );
                })}
            </div>
        );
    }

    generateTask(note) {
        function renderAssignedTo() {
            if (note.assignedTo) {
                return (
                    <span style={{fontSize: 14}}>
						{" "}
                        <MDBIcon
                            icon="long-arrow-alt-right"
                            data-tip={"Assigned To"}
                        />{" "}
                        {note.assignedTo.name}
					</span>
                );
            }
        }

        let nta = this.state.selectedNoteTypesArray;

        if (note.noteCategory && nta.length > 0 && nta.indexOf(note.noteCategory) === -1) {
            return;
        }

        return (
            <MDBCard style={{backgroundColor: "#FFE4F1"}}>
                <MDBCardHeader style={{backgroundColor: "#FFCCE5"}}>
                    <MDBRow>
                        <MDBCol size={"5"} style={{fontSize: 14}}>
                            {this.formatDate(note.createdAt)}
                        </MDBCol>

                        <MDBCol size={"5"} style={{fontSize: 14}}>
                            {note.createdBy ? note.createdBy.name : ""}
                            {renderAssignedTo()}
                        </MDBCol>

                        <MDBCol siz={"2"}>
                            <MDBIcon
                                onClick={() => this.toggleEditModalPopup(note)}
                                style={{
                                    color: "#7B626E",
                                    float: "right",
                                    padding: "4px 4px 4px 8px",
                                }}
                                data-tip={"Edit"}
                                icon="ellipsis-v"
                            />
                            <MDBIcon
                                style={{
                                    color: "#7B626E",
                                    float: "right",
                                    padding: "4px 8px 4px 4px",
                                }}
                                onClick={() => this.updateNote(note, true)}
                                data-tip={"Complete"}
                                icon="check"
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCardHeader>

                <MDBCardBody>
                    <p style={{whiteSpace: "pre-line"}}>{note.text}</p>
                </MDBCardBody>
                <ReactTooltip/>
            </MDBCard>
        );
    }

    renderTasksSection() {
        let tasks = this.sortNotes(this.state.allNotes, true);

        return (
            <div>
                {tasks.map((n, idx) => {
                    return (
                        <div key={idx} style={{paddingBottom: 6}}>
                            {this.generateTask(n)}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderEditNoteModal() {
        let note = this.state.modalNote,
            isTask = note.completed === false;

        let bgClass = "orderNotesModal",
            bgColor = "#FFF7D1";

        if (isTask) {
            bgClass = "orderTasksModal";
            bgColor = "#FFE4F1";
        }
        return (
            <MDBModal
                className={bgClass}
                centered
                isOpen={this.state.editNoteOpen}
                toggle={() => this.toggleEditModalPopup()}
            >
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol size="12">
							<textarea
                                id={"noteTextArea"}
                                style={{
                                    width: "100%",
                                    height: "200px",
                                    backgroundColor: bgColor,
                                }}
                            >
								{note.text}
							</textarea>
                        </MDBCol>

                        <MDBCol size="12">
                            <span>Updated: {this.formatDate(new Date())}</span>

                            <MDBIcon
                                style={{color: "#7B626E", float: "right", padding: 4}}
                                data-tip={"Update"}
                                icon="check"
                                onClick={() => {
                                    this.updateNoteModal(note);
                                    this.toggleEditModalPopup();
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <ReactTooltip/>
            </MDBModal>
        );
    }

    // noteTypeFiltersArray(array, index) {
    // 	if (array.includes(index)) {
    // 		return array.pop(index);
    // 	} else {
    // 		return array.push(index);
    // 	}
    // }

    // setOutline() {
    // 	let outline = true;
    // 	return !outline;
    // }

    // handleFilterButtonClick() {
    // 	this.noteTypeFiltersArray();
    // 	this.setOutline()
    // }

    handleFilterButtonClick(array, button) {
        if (array.indexOf(button) == true) {
            return true;
        } else {
            return false;
        }
    }

    toggleNoteTypes(type, all) {
        let nta = this.state.selectedNoteTypesArray,
            idx = nta.indexOf(type);

        if (all === true) {
            nta = [];
        } else if (idx > -1) {
            nta.splice(idx, 1)
        } else {
            nta.push(type);
        }

        this.setState({
            selectedNoteTypesArray: nta
        })
    }

    renderFilterButtons() {
        let noteTypesArray = this.state.selectedNoteTypesArray;

        return (
            <MDBRow>
                <MDBCol size="12">

                    <MDBBtn
                        outline={noteTypesArray.length > 0}
                        rounded
                        size="sm"
                        color="info"
                        className="px-2 "
                        onClick={() => this.toggleNoteTypes("", true)}
                    >
                        Show All
                        {/*<MDBIcon icon="scroll"/>*/}
                    </MDBBtn>

                    <MDBBtn
                        outline={noteTypesArray.indexOf("Sales") === -1}
                        rounded
                        size="sm"
                        color="success"
                        className="px-2 "
                        onClick={() => this.toggleNoteTypes("Sales")}
                    >
                        Sales
                        {/*<MDBIcon icon="money-bill"/>*/}
                    </MDBBtn>

                    <MDBBtn
                        outline={noteTypesArray.indexOf("Internal Operations") === -1}
                        size="sm"
                        rounded
                        color="warning"
                        className="px-2"
                        onClick={() => this.toggleNoteTypes("Internal Operations")}
                    >
                        Internal Operations
                        {/*<MDBIcon icon="people-carry"/>*/}
                    </MDBBtn>

                    <MDBBtn
                        outline={noteTypesArray.indexOf("Field Operations") === -1}
                        rounded
                        size="sm"
                        color="danger"
                        className="px-2"
                        onClick={() => this.toggleNoteTypes("Field Operations")}
                    >
                        Field Operations
                        {/*<MDBIcon icon="truck-loading"/>*/}
                    </MDBBtn>

                    <MDBBtn
                        outline={noteTypesArray.indexOf("Billing") === -1}
                        rounded
                        size="sm"
                        color={"secondary"}
                        className="px-2"
                        onClick={() => this.toggleNoteTypes("Billing")}
                    >
                        Billing
                        {/*<MDBIcon icon="credit-card"/>*/}
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
        )
    }

    render() {
        return (
            <div>
                <MDBCol size="12">
                    <div style={{justifyContent: "center"}}>
                        {/*{new Date(this.state.eOrder.followUpDate).toLocaleDateString()}*/}
                        {/*{this.renderFilterButtons()}*/}
                    </div>
                </MDBCol>
                {this.renderEditNoteModal()}
                <MDBRow>
                    <MDBCol className={"pvNotesGroupOuterContainer"} size={6}>
                        <div className={"pvNotesGroupInnerContainer"}>
                            {this.renderNotesSection()}
                        </div>
                    </MDBCol>

                    <MDBCol className={"pvNotesGroupOuterContainer"} size={6}>
                        <div className={"pvNotesGroupInnerContainer"}>
                            {this.renderTasksSection()}
                        </div>
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }
}
