import api from "../api";

class ContactService {
	async getAllContacts(filters) {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				users: filters.users || "",
				q: filters.q || "",
			};
			api
				.get("/contacts", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getContact(id) {
		return new Promise((res, reject) => {
			api
				.get("/contacts/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createContact(con) {
		return new Promise((res, reject) => {
			api
				.post("/contacts/", con)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findAllContactTypes() {
		return new Promise((res, reject) => {
			api
				.get("/contactTypes/")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateContact(contact) {
		return api.put("/contacts/" + contact.id, contact);
	}
}

const contactService = new ContactService();

export default contactService;
