import React from "react";
import {
	Container,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Fa,
	MDBBtn,
	MDBCol,
	MDBIcon,
	MDBRow,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	toast,
} from "mdbreact";
import LiaisonMeetingService from "../../Security/LiaisonMeetingService/LiaisonMeetingService";
import ReactTooltip from "react-tooltip";
import NoteService from "../../Security/NoteService/noteService";
import PropTypes from "prop-types";

export default class LiaisonModal extends React.Component {
    constructor(props) {
        super(props);

        this.closeModal = props.closeModal;
        this.setExistingEmpty = props.setExistingEmpty;

        this.state = {
            resultingLiaisonKeys: props.resultingLiaisonKeys,
            existingMeeting: props.existingMeeting,
            helpModalOpen: false,
            eOrder: props.eOrder,
        }
    }

    static contextTypes = {
        allLiaisons: PropTypes.array,
        currentUser: PropTypes.object,
        timezoneVariation: PropTypes.object,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.helpModalOpen !== prevState.helpModalOpen) {
            return {
                resultingLiaisonKeys: nextProps.resultingLiaisonKeys,
                helpModalOpen: nextProps.helpModalOpen,
                existingMeeting: nextProps.existingMeeting
            }
        }else
            return null;
    }


    createSalesLiaisonMeeting() {
        const {allLiaisons, currentUser, timezoneVariation} = this.context;
        //selected time will have to be parsed to get the date, time, and sales liason
        let v = this.state.selectedTime,
            o = this.state.eOrder;

        if (!v) {
            toast.error('Please select a time');
            return;
        }

        //this will split the date from the time;
        let firstSplit = v.split('|');

        //this will split the date pieces in order of month, day, year
        let dateSplit = firstSplit[0].split('/');

        //this will put the time as the first entry and the sales liaison as the second entry;
        let secondSplit = firstSplit[1].split('--');

        //this will get the liaison;
        let liaison = allLiaisons.find(l => {
            return l.label === secondSplit[1]
        });

        //put a 0 in front of the month...BC
        if (dateSplit[0].length === 1) {
            dateSplit[0] = '0' + dateSplit[0];
        }

        //put a 0 in front of the day...BC
        if (dateSplit[1].length === 1) {
            dateSplit[1] = '0' + dateSplit[1];
        }

        secondSplit[0] = secondSplit[0].trim();

        //split the time portion...BC
        let thirdSplit = secondSplit[0].split(':');

        if (thirdSplit[0].length === 1) {
            secondSplit[0] = '0' + secondSplit[0];
        }

        let scheduledDate = new Date(dateSplit[2].trim() + '-' +
            dateSplit[0] + '-' +
            dateSplit[1] + 'T' + secondSplit[0].trim());

        let obj = {
            orderId: o.id,
            status: o.status,
            reason: o.orderStatusReason ? o.orderStatusReason.name : '',
            salesRep: {
                name: currentUser.username,
                id: currentUser.id
            },
            salesLiaison: {
                name: liaison.label,
                id: liaison.value.id
            },
            scheduledDate: scheduledDate,
            complete: false,
            closedOn: null,
            meetingNotes: '',
            meetingReason: "",
        };

        let estDate = new Date(scheduledDate).setHours(scheduledDate.getHours() + timezoneVariation);

        let text = "A meeting has been scheduled with " + liaison.label + " on " + scheduledDate.toLocaleString()
            + " which is " + new Date(estDate).toLocaleString() + " EST time";

        LiaisonMeetingService.createMeeting(obj).then(r => {
            toast.success("Meeting Created Successfully!");

            this.setState({
                selectedTime: '',
            });

            this.addMeetingNote(currentUser, liaison, text);

            this.closeModal();
        }).catch(e => {
            toast.error("There was an issue creating the meeting. Try again later.");
        });

    }

    renderHelpModal() {
        let st = this.state;

        return (
            <Modal centered
                   isOpen={st.helpModalOpen}
                   toggle={() => {
                       return
                   }}>
                <ModalHeader style={{textAlign: 'center'}}>
                    Request Sales Paperwork Help
                </ModalHeader>
                <ModalBody style={{height: 100}}>
                    {!this.state.existingMeeting ? this.renderHelpModalCreateSection() : this.renderHelpModalCancelSection()}
                </ModalBody>

                <ModalFooter>
                    <span
                        className={"ptBtn cancelBtn"}
                        data-tip={"Cancel"}
                        onClick={this.closeModal}>
                                        <Fa size={'2x'} icon={'times'}> </Fa>
                    </span>
                    {!this.state.existingMeeting ?
                        <span
                            className={"ptBtn saveBtn"}
                            data-tip={"Submit Request"}
                            onClick={() => this.createSalesLiaisonMeeting()}>
                                            <Fa size={'2x'} icon={'check'}> </Fa>
                        </span>
                        : ''}
                </ModalFooter>
                <ReactTooltip/>
            </Modal>
        )
    }

    renderHelpModalCancelSection() {
        let m = this.state.existingMeeting;

        return (
            <MDBRow>
                <Container>
                    A meeting already exists at {new Date(m.scheduledDate).toLocaleString()} with {m.salesLiaison ? m.salesLiaison.name : m.salesLiaison}.

                    <MDBBtn
                        style={{textTransform: 'none'}}
                        color="mdb-color"
                        floating size='sm'
                        data-tip={"Cancel Meeting"}
                        onClick={() => this.cancelMeeting()}>
                        <MDBIcon icon="times"/>
                    </MDBBtn>
                </Container>

            </MDBRow>
        )
    }

    cancelMeeting() {
        const {allLiaisons, currentUser} = this.context;


        function convertStatus(stat){
            switch (stat) {
                case 'Ready to Deliver': return 'ReadyToDeliver';
                case 'In Process': return 'InProcess';
                default:
                    return stat
            }
        }

        let m = Object.assign(this.state.existingMeeting);

        //this will get the liaison;
        let liaison = allLiaisons.find(l => {
            return l.username === m.salesLiaison
        });

        m.salesRep = {name: currentUser.username, id: currentUser.id};
        m.salesLiaison = {name: liaison.username, id: parseInt(liaison.userId)};
        m.closedOn = new Date();
        m.scheduledDate = new Date(m.scheduledDate);
        m.complete = true;
        m.orderId = parseInt(m.orderId);
        m.status = convertStatus(m.status);
        m.meetingReason = "Closed By Sales Rep";
        m.meetingNotes = m.meetingNotes || "";

        LiaisonMeetingService.updateMeeting(m).then(r => {
            this.closeModal();
            this.setExistingEmpty();

            toast.success("Meeting Cancelled");
        }).catch(e => {
            toast.error("There was an error cancelling the meeting. Try again later.");
        })
    }

    renderHelpModalCreateSection() {
        return (
            <MDBRow>
                <MDBCol size={"12"}>
                    <Dropdown dropright className={"idDropdown idDropdownService"}>
                        <DropdownToggle className={"idDropdownToggle"} nav>
                            Time
                            <span style={{float: 'right'}}>
                                        {this.state.selectedTime}
                                <Fa icon={'angle-down'}/>
                                    </span>
                        </DropdownToggle>
                        <DropdownMenu color={"ins"}>
                            {this.state.resultingLiaisonKeys.map((entry, index) => {
                                return (
                                    <DropdownItem key={index} value={entry}
                                                  onClick={(e) => {
                                                      this.setState({selectedTime: e.target.value});
                                                  }}>
                                        {entry}
                                    </DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </Dropdown>
                </MDBCol>
            </MDBRow>
        )
    }

    addMeetingNote(user, liaison, text) {
        let cb = {id: user.id, name: user.username},
            lia = liaison.value,
            currentNotes = this.state.allNotes;

        let note = {
            task: true,
            completed: false,
            assignedTo: lia,
            createdAt: new Date(),
            createdBy: cb,
            account: this.state.eOrder.account,
            patientName: this.state.eOrder.patientName,
            text: text,
        };

        NoteService.createActivityNote(this.state.eOrder.id, note).then(res => {
            //need to set the name since the res doesnt currently have it...BC
            res.createdBy.name = note.createdBy.name;

            //need to set the last note date because otherwise the save overrides it...BC
            let ord = this.state.eOrder;
            ord.lastNoteDate = new Date();

            currentNotes.unshift(res);
            this.setState({
                allNotes: currentNotes,
                eOrder: ord
            });
        }).catch(err => {
            //Maybe send an error to the user?...BC
        })
    }

    render() {
        return (
            <div>
                {this.renderHelpModal()}
            </div>
        )
    }
}
