import React from "react";
import {
	Container,
	Carousel,
	CarouselInner,
	CarouselItem,
	Spinner,
} from "mdbreact";
import "./home.css";
import FindOrders from "../Security/OrderService/orderService";

class Kiosk extends React.Component {
	constructor(props) {
		super(props);

		this.retrieveOrders();

		this.state = {
			orders: [],
			Verification: 0,
			InProcess: 0,
			ReadyToDeliver: 0,
			isLoaded: false,
		};
	}

	retrieveOrders() {
		FindOrders.getAllOrders()
			.then((orders) => {
				this.setState({
					orders: orders.content,
					isLoaded: true,
				});
				this.getOrderCount();
			})
			.catch((err) => {
							console.log(err);
						});
	}

	getOrderCount() {
		let verification = 0,
			inProcessOrders = 0,
			readyOrders = 0;
		this.state.orders.forEach((order) => {
			if (
				order.status === "InProcess" &&
				order.orderStatusReason &&
				order.orderStatusReason.name === "Verification"
			) {
				verification++;
			} else if (order.status === "InProcess") {
				inProcessOrders++;
			} else if (order.status === "ReadyToDeliver") {
				readyOrders++;
			}
		});

		this.setState({
			Verification: verification,
			InProcess: inProcessOrders,
			ReadyToDeliver: readyOrders,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}

		return (
			<div align="center" style={{ marginTop: "15%", display: "block" }}>
				<Container>
					<Carousel
						activeItem={1}
						length={3}
						showControls={true}
						showIndicators={true}
						className="z-depth-1"
					>
						<CarouselInner style={{ color: "#FFFFFF", fontWeight: "bold" }}>
							<CarouselItem itemId="1" style={{ padding: "2rem" }}>
								<div className={"peach-gradient"}>
									<h1 style={{ fontSize: "120px" }}>
										{this.state.Verification}
									</h1>
									<h1 style={{ marginBottom: "3rem" }}>
										Intake / Insurance Verification
									</h1>
									&nbsp;
								</div>
							</CarouselItem>

							<CarouselItem itemId="2">
								<div className={"blue-gradient"}>
									<h1 style={{ fontSize: "120px" }}>{this.state.InProcess}</h1>
									<h1 style={{ marginBottom: "3rem" }}>Insurance Review</h1>
									&nbsp;
								</div>
							</CarouselItem>

							<CarouselItem itemId="3">
								<div className={"d-block w-100 purple-gradient"}>
									<h1 style={{ fontSize: "120px" }}>
										{this.state.ReadyToDeliver}
									</h1>
									<h1 style={{ marginBottom: "3rem" }}>Waiting For Delivery</h1>
									&nbsp;
								</div>
							</CarouselItem>
						</CarouselInner>
					</Carousel>
				</Container>
			</div>
		);
	}
}

export default Kiosk;
