import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
	Grid,
	Table,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar,
	TableSummaryRow,
	TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

import {
	SummaryState,
	IntegratedSummary,
	GroupingState,
	SortingState,
	IntegratedSorting,
	IntegratedGrouping,
	PagingState,
	IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
	Plugin,
	Template,
	TemplateConnector,
	TemplatePlaceholder,
} from "@devexpress/dx-react-core";

import {
	Button,
	Container,
	Col,
	Row,
	Spinner,
	toast,
	ToastContainer,
	MDBIcon,
} from "mdbreact";
import ComplaintService from "../Security/ComplaintService/ComplaintService";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "../Orders/orderList.css";
import ReactTooltip from "react-tooltip";
import "./customerComplaints.css";

const ItemCounter = () => (
	<Plugin name="ItemCounter">
		<Template
			name="tableCell"
			predicate={({ tableRow }) => isGroupTableRow(tableRow)}
		>
			{(params) => (
				<TemplateConnector>
					{({ getCollapsedRows }) => {
						const updatedParams = {
							...params,
							tableRow: {
								...params.tableRow,
								row: {
									...params.tableRow.row,
									collapsedRows: getCollapsedRows(params.tableRow.row) || [],
								},
							},
						};
						return <TemplatePlaceholder params={updatedParams} />;
					}}
				</TemplateConnector>
			)}
		</Template>
	</Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

function getVal(row) {
	if (row.collapsedRows.length > 0) {
		return "Count: " + row.collapsedRows.length;
	} else {
		return "";
	}
}

const Content = ({ row, column }) => (
	<span>
		<span>
			{column.title} : {row.value}
		</span>
		<span style={{ fontWeight: "bold" }}>&nbsp; {getVal(row)}</span>
	</span>
);

export default class complaintsList extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					title: "Feedback ID",
					name: "id",
				},
				{
					title: "Order ID",
					name: "orderId",
				},
				{
					title: "Patient Name",
					name: "patientName",
				},
				{
					title: "Status",
					name: "status",
				},
				{
					title: "Category",
					name: "category",
				},
				{
					title: "Reason",
					name: "reason",
				},
				{
					title: "Opened On",
					name: "openedOn",
				},
				{
					title: "Opened By",
					name: "openedBy",
				},
				{
					title: "Closed On",
					name: "closedOn",
				},
				{
					title: "Closed By",
					name: "closedBy",
				},
				{
					title: "Service Location",
					name: "serviceLocation",
				},
				{
					title: "Last Note Date",
					name: "lastNoteDate",
				},
			],
			rows: [],
		};

		let defaultColumnWidths = [
			{ columnName: "id", width: 120 },
			{ columnName: "orderId", width: 140 },
			{ columnName: "patientName", width: 150 },
			{ columnName: "status", width: 150 },
			{ columnName: "category", width: 150 },
			{ columnName: "reason", width: 150 },
			{ columnName: "openedOn", width: 150 },
			{ columnName: "openedBy", width: 150 },
			{ columnName: "closedOn", width: 150 },
			{ columnName: "closedBy", width: 150 },
			{ columnName: "serviceLocation", width: 150 },
			{ columnName: "lastNoteDate", width: 150 },
		];

		this.state = {
			data: data,
			isLoaded: false,
			grouping: [],
			groupingColumns: [],
			defaultColumnWidths: defaultColumnWidths,
			groupSummaryItems: [],
			notes: [],
		};

		this.changeGrouping = (grouping) => {
			let ary = [];

			grouping.forEach((group) => {
				ary.push({ columnName: group.columnName });
			});
			this.setState({
				groupingColumns: ary,
			});
		};

		this.getResults();
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	downloadFunction() {
		this.clientCSV(this.state.data, "CustomerFeedback.csv");
	}

	//This will convert all the data in the grid to a csv file
	clientCSV(stateData, filename) {
		let result,
			ctr,
			keys = [],
			headers = [],
			columnDelimiter = ",",
			lineDelimiter = "\n",
			data;

		data = stateData.rows || null;
		if (data == null || !data.length) {
			return null;
		}

		stateData.columns.forEach((col) => {
			keys.push(col.name);
			headers.push(col.title);
		});

		let replace = ["patientName", "category", "reason"];

		result = "";
		result += headers.join(columnDelimiter);
		result += lineDelimiter;

		data.forEach(function (item) {
			ctr = 0;
			keys.forEach(function (key) {
				if (ctr > 0) result += columnDelimiter;

				if (replace.indexOf(key) > -1 && item[key] != null) {
					result += item[key].replace(/,/g, " ");
				} else {
					result += item[key];
				}

				ctr++;
			});
			result += lineDelimiter;
		});

		this.downloadBlob(result, filename);
	}

	//this actually prompts the download
	downloadBlob(csvData, filename) {
		let blob = new Blob([csvData], {
			type: "application/csv;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a"),
				csvUrl = URL.createObjectURL(blob);

			link.href = csvUrl;
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	formatDate(date) {
		if (date == null || date === "") {
			return "";
		}
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "short",
			day: "2-digit",
		}).format(date);
	}

	getResults() {
		let d = new Date(),
			e = new Date();

		d.setFullYear(d.getFullYear() - 1);
		d = d.toLocaleDateString();

		e.setDate(e.getDate() + 1);
		e = e.toLocaleDateString();

		let params = {
			startDate: d,
			endDate: e,
		};

		return ComplaintService.getComplaintsViewNative(params)
			.then((res) => {
				this.buildNewResults(res);
			})
			.catch((err) => {
				this.setState({ isLoaded: true });
			});
	}

	buildNewResults(res) {
		let ary = [],
			notes = [],
			dt = this.state.data;
		res.forEach((val) => {
			ary.push({
				id: val.id,
				orderId: val.orderId,
				patientId: val.patientId,
				patientName: val.patientName,
				status: val.issueStatus,
				category: val.issueCategory,
				reason: val.issueReason,
				openedOn: val.openedOn,
				openedBy: val.openedBy,
				closedOn: val.closedOn,
				closedBy: val.closedBy,
				serviceLocation: val.serviceLocation,
				lastNoteDate: val.lastNoteDate,
			});
			notes.push({
				id: val.id,
				note: val.noteText,
			});
		});

		dt.rows = ary;

		toast.success("Found " + res.length + " Results");

		this.setState({
			data: dt,
			notes: notes,
			isLoaded: true,
		});
	}

	renderTable() {
		const Cell = ({ row, column, ...props }) => {
			if (column.name === "lastNoteDate" && row.lastNoteDate) {
				return (
					<Table.Cell
						data-tip={
							this.state.notes[
								this.state.notes.findIndex(
									(note) => note.id === props.tableRow.row.id
								)
							].note
						}
						data-for={"notes"}
						style={{
							cursor: "pointer",
						}}
					>
						{row.lastNoteDate}
						<ReactTooltip
							className={"last-note-tooltip"}
							id={"notes"}
							place={"left"}
							effect={"solid"}
						/>
					</Table.Cell>
				);
			}
			return (
				<Table.Cell
					{...props}
					onClick={(e) => {
						if (e.ctrlKey === true) {
							this.setState({
								targetLink: row.patientId,
							});

							setTimeout(() => {
								document.getElementById("orderListId").click();
							}, 5);
						} else {
							this.props.history.push({
								pathname: "/patientInformation/" + row.patientId,
							});
						}
					}}
					style={{
						cursor: "pointer",
					}}
				/>
			);
		};

		if (this.state.isLoaded === true) {
			return (
				<Grid
					style={{ maxWidth: "1800px !important" }}
					rows={this.state.data.rows}
					rootComponent={Root}
					columns={this.state.data.columns}
				>
					<DragDropProvider />
					<SortingState
						defaultSorting={[{ columnName: "openedOn", direction: "desc" }]}
					/>
					<IntegratedSorting
						columnExtensions={this.state.data.integratedSortingColumnExtensions}
					/>
					<GroupingState
						grouping={this.state.groupingColumns}
						onGroupingChange={this.changeGrouping}
					/>
					<IntegratedGrouping />
					<PagingState defaultCurrentPage={0} pageSize={25} />
					<IntegratedPaging />
					<SummaryState
						totalItems={[]}
						groupItems={this.state.groupSummaryItems}
					/>
					<IntegratedSummary />
					<VirtualTable cellComponent={Cell} height={"auto"} />
					<TableColumnResizing
						defaultColumnWidths={this.state.defaultColumnWidths}
					/>
					<TableHeaderRow showSortingControls />
					<PagingPanel />
					<TableGroupRow contentComponent={Content} showColumnsWhenGrouped />
					<ItemCounter />
					<TableSummaryRow />
					<Toolbar />
					<GroupingPanel />
				</Grid>
			);
		} else {
			return <div />;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderTableOrSpinner() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div className={"purchasingGrid ordersListGrid"} style={{height: 0.9 * window.innerHeight, backgroundColor: "white"}}>
				{this.renderTable()}
			</div>
		);
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={true}
					newestOnTop={true}
					autoClose={3000}
					position={"top-right"}
					style={{ marginTop: "75px" }}
				/>

				<div style={{ maxWidth: "98%", marginLeft: "1rem" }}>
					<Row>
						<Col size="12">{this.renderTableOrSpinner()}</Col>
					</Row>

					<Link
						id="orderListId"
						to={{
							pathname: `/patientInformation/${this.state.targetLink}`,
						}}
						target="_blank"
						rel="opener"
						style={{ display: "none" }}
						activeclassname="active"
					/>

					<div className={"fixed-bottom downloadCsvDiv"}>
						<Button
							className={"downloadCsvButton"}
							floating
							size="sm"
							color={"primary"}
							data-tip={"Download CSV"}
							onClick={this.clientCSV.bind(
								this,
								this.state.data,
								"Billing.csv"
							)}
						>
							<MDBIcon icon="download" style={{ fontSize: "2em" }} />
						</Button>

						<ReactTooltip />
					</div>
				</div>
			</div>
		);
	}
}
