import React from "react";
import PropTypes from "prop-types";
import {
    Container,
    Spinner,
    Col,
    CardHeader,
    Button,
    MDBDatePicker,
    Row,
    toast,
    ToastContainer,
    MDBIcon,
    DataTable, MDBBtn,
} from "mdbreact";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Select from "react-select";
import QuickFilter from "../Filters/quickFilter";
import GroupService from "../Security/UserService/groupService";
import ReactTooltip from "react-tooltip";
import ReverseQualityService from "./reverseQualityService";
import MomentUtils from "@date-io/moment";

export default class ReverseQualityList extends React.Component {
    constructor(props) {
        super(props);

        const devData = {
            columns: [
                {
                    label: "Order Id",
                    field: "orderId",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Reported By",
                    field: "reportedBy",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Reported On",
                    field: "reportedOn",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "User Reported",
                    field: "userReported",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Reviewed By",
                    field: "reviewedBy",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Reviewed On",
                    field: "reviewedOn",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Reverse Quality Category",
                    field: "reverseQualityCategory",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Reverse Quality Reason",
                    field: "reverseQualityReason",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Notes",
                    field: "notes",
                    sort: "asc",
                    width: 350,
                },
            ],
            rows: [],
        };

        const columnHeaders = [
            "orderId",
            "reportedBy",
            "reportedOn",
            "userReported",
            "reviewedBy",
            "reviewedOn",
            "reverseQualityCategory",
            "reverseQualityReason",
            "notes",
        ];

        this.state = {
            devData: devData,
            columnHeaders: columnHeaders,
            isLoaded: true,
            filters: {},
            addExpenseModal: false,
            denyModalOpen: false,
            errorMessage: [],
            allLocs: [],
            allSystemUsers: [],
            locationsSelect: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            allSystemUsersSelect: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            allSystemUsersSelected: [],
            locationsSelected: [],
            showDatePicker: true,
            quickFilter: [],
            quickFilterSelected: null,
            startDate: new Date(),
            endDate: new Date(),
            rawData: [],
            skip: 0,
            rowData: [],
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allLocations: PropTypes.array,
        userRefs: PropTypes.array,
    };

    componentDidMount() {
        this.renderLocationsOptions();
    }

    componentDidUpdate() {
        const {currentUser, allLocations} = this.context;
        let st = this.state;
        if (
            st.allLocs.length !== allLocations.length &&
            st.currUser !== currentUser
        ) {
            this.renderLocationsOptions();
        }
    }

    renderSalesRepsOptions(e) {
        const {userRefs, currentUser} = this.context;
        let options = [];
        if (e === undefined || e.length === 0) {
            if (
                currentUser.role === "ADMIN" ||
                currentUser.role === "SYSTEM_ADMIN" ||
                currentUser.role === "EXECUTIVE_MANAGEMENT" ||
                currentUser.role === "SALES_LIAISON_MANAGER" ||
                currentUser.role === "FINANCE"
            ) {
                // allSystemUsers.map((rep) => {
                //     return options.push({
                //         label: rep.username,
                //         value: rep.id,
                //     });
                // });
            }
            this.setState({
                allSystemUsersSelect: options,
                allSystemUsers: userRefs,
            });
        } else {
            let salesList = [];
            let locs = [];
            e.forEach((location) => {
                locs.push(location.value);
            });
            return GroupService.getAllUsersByLocations(locs)
                .then((res) => {
                    res.forEach((t) => {
                        if (salesList.indexOf(t.id) === -1) {
                            options.push({
                                label: t.username,
                                value: t.id,
                            });
                        }
                        salesList.push(t.id);
                    });

                    this.setState({
                        allSystemUsersSelect: options,
                        allSystemUsersSelected: options,
                    });
                })
                .catch((e) => {
                });
        }
    }

    renderLocationsOptions() {
        const {allLocations, currentUser} = this.context;
        let cu = currentUser;
        let select = [],
            locations = [];
        if (
            cu.role === "ADMIN" ||
            cu.role === "SYSTEM_ADMIN" ||
            cu.role === "EXECUTIVE_MANAGEMENT" ||
            cu.role === "SALES_LIAISON_MANAGER" ||
            cu.role === "FINANCE"
        ) {
            locations = allLocations;
        } else {
            locations = cu.locations;
        }
        locations.map((location) => {
            // Populates the locations dropdown depending on which locations the user is in...JK
            if (location.type === "Internal") {
                return select.push({
                    label: location.name,
                    value: location.id,
                });
            }
            return null;
        });
        this.setState({
            locationsSelect: select,
            allLocs: allLocations,
        });
    }

    handleLocationChange = (e) => {
        this.setState({
            locationsSelected: e,
        });
        this.renderSalesRepsOptions(e);
    };

    handleSaleRepChange = (e) => {
        this.setState({
            allSystemUsersSelected: e,
        });
    };

    handleQuickFilter = (e) => {
        let a = QuickFilter.getDates(e.value),
            startDate = "",
            endDate = "";

        if (a.length > 0) {
            startDate = a[0];
            endDate = a[1];
        }

        this.setState({
            quickFilter: a,
            quickFilterSelected: e,
            startDate: startDate,
            endDate: endDate,
        });
    };

    reviewRecord(row, idx) {
        const {currentUser} = this.context;

        let dt = this.state.devData,
            rows = dt.rows.slice();

        ReverseQualityService.reviewReverseQualityLog(row.id).then(res => {
            let f = rows.find(v => {
                return v.id === row.id
            })


            if(f){
                f.reviewedOn = new Date().toLocaleDateString();
                f.reviewedBy = currentUser.username;

                rows.splice(idx,1,f)

                dt.rows = rows;
                this.setState({
                    devData: dt
                })
            }
        }).catch(e => {
            let cat = 5;
        })
    }

    formatRows(rqLogs) {
        let ary = [],
            dt = this.state.devData;

        let t = this;

        function markReviewed(log, idx) {
            if (!log.reviewedOn) {
                return (
                    <a onClick={() => t.reviewRecord(log, idx)} className={"gridTextLink"} >
                        Reviewed
                    </a>
                )
            } else {
                return new Date(log.reviewedOn).toLocaleDateString()
            }
        }

        rqLogs.forEach((log, idx) => {
            ary.push({
                id: log.id,
                orderId: log.orderId,
                reportedBy: log.reportedBy,
                reportedOn: log.reportedOn,
                userReported: log.userReported,
                reviewedBy: log.reviewedBy,
                reviewedOn: markReviewed(log, idx),
                reverseQualityCategory: log.reverseQualityCategory,
                reverseQualityReason: log.reverseQualityReason,
                notes: log.notes,
            });
        });

        dt.rows = ary;

        this.setState({
            devData: dt,
            isLoaded: true,
        });
    }

    getInternalLocations() {
        const {internalLocations, currentUser} = this.context;

        let ary = [],
            role = currentUser.role;

        ary.push({
            label: "Choose a Location...",
            value: "",
        });

        switch (role) {
            case "SYSTEM_ADMIN":
            case "ADMIN":
            case "EXECUTIVE_MANAGEMENT":
            case "SALES_LIAISON_MANAGER":
            case "FINANCE":
                internalLocations.map((location) => {
                    return ary.push({
                        label: location.name,
                        value: location,
                    });
                });
                break;
            case "SALES_MANAGER":
                if (currentUser.locations) {
                    currentUser.locations.map((location) => {
                        if (location.type === "Internal") {
                            return ary.push({
                                label: location.name,
                                value: location,
                            });
                        }
                        return null;
                    });
                }
                break;
            default:
                break;
        }
        return ary;
    }

    selectLocation(location) {
        let nFilters = this.state.filters;
        nFilters.locationId = location.value.id;
        this.setState({filters: nFilters});
    }

    filterOnLocation() {
        let st = this.state;
        if (
            st.startDate == null ||
            st.startDate === "" ||
            st.endDate == null ||
            st.endDate === ""
        ) {
            toast.error("Please check your date filters");
            return;
        }

        this.setState({isLoaded: false});

        this.getReverseQualityLogs();
    }

    getReverseQualityLogs() {
        let f = {
            startDate: new Date(this.state.startDate).toLocaleDateString(),
            endDate: new Date(this.state.endDate).toLocaleDateString(),
        };

        if (this.state.allSystemUsersSelected.length > 0) {
            let ids = [];

            this.state.allSystemUsersSelected.forEach((u) => {
                ids.push(u.value);
            });

            f.ids = ids;
        }

        ReverseQualityService.getAllLogsNative(f).then((r) => {
            this.formatRows(r);
        });
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <div style={{backgroundColor: "#FFF", padding: 8, marginBottom: "1rem"}} className={"rqGridPadding"}>
                {this.renderDevTable()}
            </div>
        );
    }

    renderDevTable() {
        let filtersData = {
            users: this.state.allSystemUsersSelected,
            locations: this.state.locationsSelected,
            currentPage: this.state.currentPage,
            totalCount: this.state.totalCount,
        };

        //build filters to send over
        if (this.state.showDatePicker) {
            filtersData.startDate = this.state.startDate;
            filtersData.endDate = this.state.endDate;
        } else {
            filtersData.quickFilter = this.state.quickFilter;
            filtersData.quickFilterSelected = this.state.quickFilterSelected;
        }

        return (
            <DataTable
                entriesOptions={[25, 50, 100]}
                entries={25}
                pagesAmount={10}
                data={this.state.devData}
                responsive
                striped
                bordered
                small
            />
        );
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    handleDatePickerChange = (property, value) => {
        this.setState({
            [property]: value,
        });
    };

    renderLocationsFilter() {
        const {currentUser} = this.context;
        let role = currentUser.role;

        switch (role) {
            case "SALES":
            case "OFFICE_MANAGER":
            case "OFFICE_SUPPORT":
            case "CARE_COORDINATOR":
            case "CALL_CENTER":
            case "TECHNICIAN":
            case "DATA_EXPORT":
            case "VERIFICATION":
            case "FOLLOWUP":
            case "COLLECTIONS":
            case "BILLING":
            case "PURCHASING":

            case "SALES_MANAGER":
            default:
                return <div/>;
            case "SYSTEM_ADMIN":
            case "ADMIN":
            case "EXECUTIVE_MANAGEMENT":
            case "SALES_LIAISON_MANAGER":
            case "FINANCE":
                return (
                    <Row>
                        <Col md={6} style={{marginTop: 5}}>
                            <Select
                                placeholder="Select Location"
                                isMulti
                                options={this.state.locationsSelect}
                                onChange={this.handleLocationChange.bind(this)}
                                value={this.state.locationsSelected}
                            />
                        </Col>
                        <Col md={6} style={{marginTop: 5}}>
                            <Select
                                placeholder="Select User"
                                closeMenuOnSelect={false}
                                isMulti
                                options={this.state.allSystemUsersSelect}
                                onChange={this.handleSaleRepChange.bind(this)}
                                value={this.state.allSystemUsersSelected}
                            />
                        </Col>
                    </Row>
                );
        }
    }

    //call to download data
    clientCSV(stateData) {
        let result = "",
            ctr = 0,
            keys = this.state.columnHeaders,
            columnDelimiter = ",",
            lineDelimiter = "\n";

        if (stateData == null || !stateData.length) {
            return null;
        }

        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        stateData.forEach(function (item) {
            ctr = 0;
            keys.forEach(function (key) {
                if (ctr > 0) result += columnDelimiter;

                if (key === "notes" && item[key] != null) {
                    item[key] = item[key].replace(/\s+|,/g, " ");
                }

                if (key ==="reviewedOn"){
                    if (typeof item[key] !== "string"){
                        item[key] = "Not reviewed"
                    }
                }

                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        this.downloadBlob(result, "rqLogs.csv");
    }

    //csv downlaod data here
    downloadBlob(csvData, filename) {
        let blob = new Blob([csvData], {
            type: "application/csv;charset=utf-8;",
        });

        if (window.navigator.msSaveBlob) {
            // FOR IE BROWSER
            navigator.msSaveBlob(blob, filename);
        } else {
            // FOR OTHER BROWSERS
            let link = document.createElement("a"),
                csvUrl = URL.createObjectURL(blob);

            link.href = csvUrl;
            link.style = "visibility:hidden";
            link.download = filename;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {
        return (
            <div style={{marginLeft: "4%", marginRight: "4%"}}>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />
                <CardHeader style={{backgroundColor: "#5881C1"}}>
                    <Row>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Col md={4} style={{color: "#000"}}>
                                {this.renderLocationsFilter()}
                        </Col>

                        <Col md={2} style={{marginTop: 5, color: "#000"}}>
                            <Select
                                placeholder="Quick Filter"
                                options={QuickFilter.getOptions()}
                                onChange={this.handleQuickFilter.bind(this)}
                                value={this.state.quickFilterSelected}
                            />
                        </Col>
                            <Col md={2} className={"  filterSearchBoxCol uiDateOutline uiDateOutlineNext"}>
                                <div >
                                    <DatePicker
                                        className={"expenseSearchDate"}
                                        format="MM/DD/YYYY"
                                        size={"small"}
                                        inputVariant={"outlined"}
                                        keyboard
                                        allowKeyboardControl
                                        value={this.state.startDate}
                                        onChange={(evt) => {
                                            this.setState({startDate:evt});
                                        }}
                                        mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                                        label="Start Date"
                                    />

                                </div>
                            </Col>
                            <Col md={2} className={"filterSearchBoxCol uiDateOutline uiDateOutlineNext"}>
                                <div className={"expenseDateOptions"}>
                                    <DatePicker
                                        className={"expenseSearchDate"}
                                        format="MM/DD/YYYY"
                                        size={"small"}
                                        inputVariant={"outlined"}
                                        keyboard
                                        allowKeyboardControl
                                        value={this.state.endDate}
                                        onChange={(evt) => {
                                            this.setState({endDate:evt});
                                        }}
                                        mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                                        label="End Date"
                                    />
                                </div>
                            </Col>
                             <Col md={2}>
                                 <Button
                                   floating
                                    size="sm"
                                    color={"indigo"}
                                    data-tip={"Search"}
                                    onClick={() => {
                                    this.filterOnLocation();
                                }}
                                >
                                <MDBIcon icon="search-plus" style={{fontSize: "2em"}}/>
                                </Button>
                            </Col>
                        </MuiPickersUtilsProvider>
                    </Row>
                </CardHeader>

                {this.renderTableOrSpinner()}

                <div className={"fixed-bottom downloadCsvDiv"}>
                    <Button
                        className={"downloadCsvButton"}
                        floating
                        size="sm"
                        color={"indigo"}
                        data-tip={"Download CSV"}
                        onClick={() => this.clientCSV(this.state.devData.rows)}
                    >
                        <MDBIcon icon="download" style={{fontSize: "2em"}}/>
                    </Button>

                    <ReactTooltip/>
                </div>
            </div>
        );
    }
}
