import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Input,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
    toast,
    MDBIcon, MDBCollapse, MDBCollapseHeader, MDBBtn, MDBRow, MDBCol, MDBInput,
} from "mdbreact";
import {ToastContainer} from "react-toastify";
import ReasonService from "./reasonService";
import ReverseQualityService from "./reverseQualityService";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

export default class reasons extends React.Component {
    constructor(props) {
        super(props);

        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Category",
                    field: "reverseQualityCategory",
                    sort: "asc",
                },
                {
                    label: "Edit",
                    field: "edit",
                    sort: "asc",
                },
                {
                    label: "Delete",
                    field: "delete",
                    sort: "asc",
                },
            ],
            rows: [],
        };


        this.state = {
            data: data,
            isLoaded: false,
            editModal: false,
            description: "",
            thirdPartyDescription: "",
            status: "",
            currIndex: -1,
            errorMessage: "",
            currReason: {},
            click: "",
            addReason: {},
            newCategoryOpen: false,
            newCategoryValue: "",
            newReasonValue: "",
            additionalCategories: [],
            newReasonCanBeFixed: false
        };
    }

    static contextTypes = {
        reverseQualityCategories: PropTypes.array,
    };

    // Complaining that component wasn't mounted so I couldn't set States therefore had to use this
    componentDidMount() {
        this.retrieveReasons();
    }

    retrieveReasons() {
        let t = this;
        return ReasonService.getAllReverseQualityReasons()
            .then((res) => {
                t.setState({
                    data: res,
                    isLoaded: true,
                });
            })
            .catch((err) => {
                t.setState({
                    isLoaded: true
                })
                //handle error...BC
            });
    }


    addNewCategory() {
        let o = {
            name: this.state.newCategoryValue
        }
        ReverseQualityService.createRQCategory(o).then((v) => {
            toast.success('New Category Created Successfully');

            let adCat = this.state.additionalCategories;

            adCat.push(v);

            this.setState({
                newCategoryValue: "",
                additionalCategories: adCat
            })
        }).catch(e => {

        })
    }

    addNewReason(category) {
        let o = {
            name: this.state.newReasonValue,
            reverseQualityCategory: category,
            canBeFixed: this.state.newReasonCanBeFixed
        }

        ReasonService.createReverseQualityReason(o).then(v => {
            toast.success("Reason added successfully");

            let or = this.state.data;
            or.push(v);

            this.setState({
                data: or,
                newReasonCanBeFixed: false
            })
        }).catch(e => {
            toast.danger("Unable to add reason");
        })

        this.setState({
            newReasonValue: ""
        })
    }

    deleteReason(reason) {
        ReasonService.deleteReverseQualityReason(reason.id).then(() => {
            toast.success('Reason deleted Successfully')

            let or = this.state.data;

            let idx = or.findIndex(v => {
                return v.id === reason.id;
            })

            if (idx > -1) {
                or.splice(idx);
            }

            this.setState({
                data: or
            })

        }).catch(e => {
            toast.danger('Error deleting reason');
        })
    }

    renderCategories() {
        const {reverseQualityCategories} = this.context,
            additionalCategories = this.state.additionalCategories;

        let ary = reverseQualityCategories.concat(additionalCategories);

        let t = this,
            data = this.state.data;

        function flipCollapse(c) {
            let val = t.state[c.name];

            if (val != null) {
                val = !val;
            } else {
                val = true;
            }

            t.setState({
                [c.name]: val
            })
        }

        function renderReasons(category) {
            return (data.map((d, idx) => {
                if (d.reverseQualityCategory.id === category.id) {
                    return (
                        <MDBCol size={12} key={idx}>
                            <MDBRow>
                                <MDBCol style={{fontSize: 16, color: "#222"}} size={4}>
                                    {d.name}
                                </MDBCol>
                                <MDBCol style={{fontSize: 16, color: "#222"}} size={4}>
                                    {d.canBeFixed ? "This reason can be fixed" : ""}
                                </MDBCol>
                                <MDBCol size={4}>

                                    <span style={{float: 'right'}}>
                                    <MDBBtn size="sm"
                                            outline
                                            color={"danger"}
                                            data-tip={"Delete Reason"}
                                            onClick={() => t.deleteReason(d)}
                                    >
                                        Delete Reason
                                    </MDBBtn>
                                    </span>

                                </MDBCol>
                            </MDBRow>


                        </MDBCol>
                    )
                }

            }))
        }

        return (
            <div>
                {ary.map((c, idx) => {
                    return (
                        <div key={idx}>
                            <MDBCollapseHeader
                                className={"patientStatusHeaders"}
                                onClick={() => flipCollapse(c)}>
                                {c.name}
                            </MDBCollapseHeader>
                            <MDBCollapse style={{paddingBottom: 20}} isOpen={this.state[c.name]}>
                                <MDBRow style={{
                                    justifyContent: 'flex-end',
                                    marginLeft: 20,
                                    marginRight: 20,
                                    marginBottom: 10,
                                    borderBottom: "1px solid #999"
                                }}>
                                    <MDBCol size={6}>
                                        <MDBInput value={this.state.newReasonValue}
                                                  onChange={(e) => {
                                                      this.setState({newReasonValue: e.target.value})

                                                  }}
                                                  containerClass={"smallMargin"}
                                                  outline
                                                  style={{backgroundColor: "#fff"}}
                                                  label={"New " + c.name + " Reason"}/>


                                    </MDBCol>
                                    <MDBCol size="4">
                                        <div style={{paddingTop: 10}}>
                                            <Input
                                                onChange={(evt) => {
                                                    this.setState({newReasonCanBeFixed: evt.target.checked});
                                                }}
                                                filled
                                                label="Can Be Fixed"
                                                type="checkbox"
                                                id={c.name + "checkbox"}
                                            />
                                        </div>
                                    </MDBCol>
                                    <MDBCol size={2}>
                                        <MDBBtn size="sm"
                                                outline
                                                disabled={!this.state.newReasonValue}
                                                color={"success"}
                                                data-tip={"Add New Reason"}
                                                onClick={() => this.addNewReason(c)}
                                        >
                                            Add Reason
                                        </MDBBtn>
                                    </MDBCol>
                                </MDBRow>


                                <MDBRow style={{marginLeft: 20, marginRight: 20}}>
                                    {renderReasons(c)}
                                </MDBRow>

                            </MDBCollapse>
                        </div>
                    )
                })}
            </div>
        )
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }


    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <Container className="mt-5">
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Card>
                    <CardHeader

                        style={{backgroundColor: "#5881C1", color: "white", textAlign: "center", verticalAlign: "center"}}
                    >
                        Reverse Quality Categories & Reasons
                        <span style={{float: "right"}}>
                <MDBBtn floating
                        size="sm"
                        color={"secondary"}
                        data-tip={"Add New Category"}
                        onClick={() => this.setState({newCategoryOpen: !this.state.newCategoryOpen})}
                >
                <MDBIcon icon="plus" style={{fontSize: "2em"}}/>
                </MDBBtn>
                </span>
                    </CardHeader>
                    <CardBody>
                        <MDBCollapse isOpen={this.state.newCategoryOpen}>
                            <MDBRow style={{justifyContent: 'flex-end'}}>
                                <MDBCol size={3}>
                                    <MDBInput
                                        value={this.state.newCategoryValue}
                                        onChange={(e) => this.setState({newCategoryValue: e.target.value})}
                                        containerClass={"smallMargin"}
                                        outline
                                        label={"New Category Name"}/>
                                </MDBCol>
                                <MDBCol size={2}>
                                    <MDBBtn size="sm"
                                            outline
                                            color={"success"}
                                            disabled={!this.state.newCategoryValue}
                                            data-tip={"Add New Category"}
                                            onClick={() => this.addNewCategory()}
                                    >
                                        Add Category
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCollapse>

                        {this.renderCategories()}
                    </CardBody>
                </Card>
                <ReactTooltip/>
            </Container>
        );
    }
}
