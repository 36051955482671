import React from "react";
import {MDBBtn, MDBCol, MDBInput, MDBModalBody, MDBModalFooter, MDBRow} from "mdbreact";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Select from "react-select";
import PropTypes from "prop-types";
import {toast} from "react-toastify";

export default class QualityAssuranceScorecard extends React.Component {
    constructor(props) {
        super(props);

        this.onSumbit = props.onSubmit;
        this.onCancel = props.onCancel;

        this.state={
            callDate: "",
            patientName:"",
            reviewer:"",
            beingReviewed:"",
            initialNotes:"",
            finalNotes:"",
            finalScore:"",
            questionTwoRadio:5,
            questionThreeRadio: 5,
            questionFourRadio: 5,
            questionFiveRadio: 5,
            questionSixRadio: 10,
            questionSevenRadio: 5,
            questionEightRadio: 5,
            questionNineRadio: 10,
            questionTenRadio: 5,
            questionElevenRadio: 5,
            questionTwelveRadio: 5,
        }
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        userRefs: PropTypes.array,
    };

    handleTextChange(property, e) {
        this.setState({
            [property]: e.target.value,
        });
    }

    handleReviewedSelected = (e) => {
        let user = e.value;
        this.setState({
            beingReviewed: user,
        });
    };

    getUserList() {
        const { userRefs } = this.context;

        if(!userRefs){
            return;
        }

        let a = userRefs.slice();
        a.unshift({
            label: "Select User...",
            value: "",
        });
        return a;
    }

    checkProperties(){
        const {currentUser} = this.context;

        let st = this.state;
        if(!st.callDate){
            return toast.warn("Please set a call date");
        }
        else if (!st.patientName){
            return toast.warn("Please set the patient's name");
        }
        else if (!st.beingReviewed){
            return toast.warn("Please set the person being reviewed");
        }

        let finalObject = Object.assign(this.state);

        finalObject.reviewer = {
            name: currentUser.firstname +  " " + currentUser.lastname,
            id: currentUser.id
        }

        finalObject.name = "Quality Assurance Log";
        finalObject.callDate = new Date(finalObject.callDate).toLocaleDateString();
        finalObject.finalScore = this.renderOverallScore();

        this.onSumbit(finalObject);
    }

    renderQuestionOne() {
        return (
            <div>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <MDBRow>
                        <MDBCol>
                            <DatePicker size="small"
                                        inputVariant={"outlined"}
                                        format="MM-DD-YYYY"
                                        value={this.state.callDate}
                                        onChange={(evt) => this.setState({ callDate: evt })}
                                        label="Call Date"/>
                        </MDBCol>

                        <MDBCol>
                            <MDBInput
                                onChange={this.handleTextChange.bind(this, "patientName")}
                                containerClass={"noMargin"}
                                outline label={"Patient Name"}/>
                        </MDBCol>

                        <MDBCol>
                            <Select
                                placeholder={
                                    this.state.beingReviewed
                                        ? this.state.beingReviewed.name
                                        : "Select User..."
                                }
                                options={this.getUserList()}
                                onChange={this.handleReviewedSelected.bind(this)}
                            />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol>
                            <MDBInput onChange={this.handleTextChange.bind(this, "initialNotes")}
                                      containerClass={"containerMargin"}
                                      outline label={"Notes"}/>
                        </MDBCol>
                    </MDBRow>



                </MuiPickersUtilsProvider>
            </div>
        )
    }

    renderQuestionTwo(){
        return(
            <div>
                <hr/>
                2:  Greeting - "Thank you for calling Rehab Medical, this is (CSR Name).  Whom do I have the pleasure of speaking with today?
                ___ Are you the patient listed on the account?

                <MDBInput
                    onClick={() => this.setState({ questionTwoRadio: 5 })}
                    checked={this.state.questionTwoRadio === 5}
                    id="questionTwoRadioA"
                    type={"radio"}
                    label={"5 - Yes"}/>

                <MDBInput
                    onClick={() => this.setState({ questionTwoRadio: 0 })}
                    checked={this.state.questionTwoRadio === 0}
                    id="questionTwoRadioB"
                    type={"radio"}
                    label={"0 - No"}/>
            </div>
        )
    }

    renderQuestionThree(){
        return(
            <div>
                <hr/>
                3:  Did the representative verify all HIPAA requirements (Patient Name, DOB, Address, MD, and Phone Number?
                <MDBInput
                    onClick={() => this.setState({ questionThreeRadio: 5 })}
                    checked={this.state.questionThreeRadio === 5}
                    id="questionThreeRadioA"
                    type={"radio"}
                    label={"5 - CSR verified all 5 HIPAA requirements"}/>
                <MDBInput
                    onClick={() => this.setState({ questionThreeRadio: 4 })}
                    checked={this.state.questionThreeRadio === 4}
                    id="questionThreeRadioB"
                    type={"radio"}
                    label={"4 - CSR verified 4 of 5 HIPAA requirements"}/>
                <MDBInput
                    onClick={() => this.setState({ questionThreeRadio: 3 })}
                    checked={this.state.questionThreeRadio === 3}
                    id="questionThreeRadioC"
                    type={"radio"}
                    label={"3 - CSR verified 3 of 5 HIPAA requirements"}/>
                <MDBInput
                    onClick={() => this.setState({ questionThreeRadio: 2 })}
                    checked={this.state.questionThreeRadio === 2}
                    id="questionThreeRadioD"
                    type={"radio"}
                    label={"2 - CSR verified 2 of 5 HIPAA requirements"}/>
                <MDBInput
                    onClick={() => this.setState({ questionThreeRadio: 0 })}
                    checked={this.state.questionThreeRadio === 0}
                    id="questionThreeRadioE"
                    type={"radio"}
                    label={"0 - CSR did not verify HIPAA"}/>
            </div>
        )
    }

    renderQuestionFour(){
        return(
            <div>
                <hr/>
                4:  Hold time or check back at 2 minutes or less?
                <MDBInput
                    onClick={() => this.setState({ questionFourRadio: 5 })}
                    checked={this.state.questionFourRadio === 5}
                    id="questionFourRadioA"
                    type={"radio"}
                    label={"5 - 0 Hold time"}/>
                <MDBInput
                    onClick={() => this.setState({ questionFourRadio: 4 })}
                    checked={this.state.questionFourRadio === 4}
                    id="questionFourRadioB"
                    type={"radio"}
                    label={"4 - Unexplained dead air > 5 seconds"}/>
                <MDBInput
                    onClick={() => this.setState({ questionFourRadio: 3 })}
                    checked={this.state.questionFourRadio === 3}
                    id="questionFourRadioC"
                    type={"radio"}
                    label={"3 - Hold time > 2 minutes or permission was not asked prior to placing on hold"}/>
                <MDBInput
                    onClick={() => this.setState({ questionFourRadio: 2 })}
                    checked={this.state.questionFourRadio === 2}
                    id="questionFourRadioD"
                    type={"radio"}
                    label={"2 - > 20 seconds of dead air without explanation"}/>
                <MDBInput
                    onClick={() => this.setState({ questionFourRadio: 0 })}
                    checked={this.state.questionFourRadio === 0}
                    id="questionFourRadioE"
                    type={"radio"}
                    label={"0 - CSR did not ask permission prior to placing call on hold and/or did not check back within 2 minutes OR had dead air"}/>

            </div>
        )
    }

    renderQuestionFive(){
        return(
            <div>
                <hr/>
                5:  Did CSR educate caller on process expectations for their needed repairs/visits/part(s)?
                <MDBInput
                    onClick={() => this.setState({ questionFiveRadio: 5 })}
                    checked={this.state.questionFiveRadio === 5}
                    id="questionFiveRadioA"
                    type={"radio"}
                    label={"5 - The CSR educated the caller on the process expectations for their needed repairs/visit/part(s).  The CSR gave the patient a realistic timeline and explained the process"}/>

                <MDBInput
                    onClick={() => this.setState({ questionFiveRadio: 3 })}
                    checked={this.state.questionFiveRadio === 3}
                    id="questionFiveRadioB"
                    type={"radio"}
                    label={"3 - CSR provided caller with some of the timeline expectations but not all the evaluator expected"}/>

                <MDBInput
                    onClick={() => this.setState({ questionFiveRadio: 0 })}
                    checked={this.state.questionFiveRadio === 0}
                    id="questionFiveRadioC"
                    type={"radio"}
                    label={"0 - CSR did not provide process or timeline expectations or provided one that was very inaccurate."}/>

            </div>
        )
    }

    renderQuestionSix(){
        return(
            <div>
                <hr/>
                6:  Documentation
                <MDBInput
                    onClick={() => this.setState({ questionSixRadio: 10 })}
                    checked={this.state.questionSixRadio === 10}
                    id="questionSixRadioA"
                    type={"radio"}
                    label={"10 - Account is fully notated"}/>

                <MDBInput
                    onClick={() => this.setState({ questionSixRadio: 5 })}
                    checked={this.state.questionSixRadio === 5}
                    id="questionSixRadioB"
                    type={"radio"}
                    label={"5 - Account is noted, but does not contain all information imparted or received"}/>

                <MDBInput
                    onClick={() => this.setState({ questionSixRadio: 0 })}
                    checked={this.state.questionSixRadio === 0}
                    id="questionSixRadioC"
                    type={"radio"}
                    label={"0 - Account is not noted"}/>

            </div>
        )
    }

    renderQuestionSeven(){
        return(
            <div>
                <hr/>
                7:  Diagnostic Skill: Did the CSR ask all questions possible to diagnose the chair: (Flash codes, chair function or power, etc) and use all resources to diagnose?
                <MDBInput
                    onClick={() => this.setState({ questionSevenRadio: 5 })}
                    checked={this.state.questionSevenRadio === 5}
                    id="questionSevenRadioA"
                    type={"radio"}
                    label={"5 - Not applicable; CSR fully diagnosed teh chair and asked all pertinent questions OR transferred call to other department as needed"}/>

                <MDBInput
                    onClick={() => this.setState({ questionSevenRadio: 3 })}
                    checked={this.state.questionSevenRadio === 3}
                    id="questionSevenRadioB"
                    type={"radio"}
                    label={"3 - Not all possible troubleshooting methods were explored; patient was not educated on preventative steps that " +
                    "should be happening or CSR failed to summarize (mirror) the issue back to the caller before proceeding with diagnostic questions"}/>

                <MDBInput
                    onClick={() => this.setState({ questionSevenRadio: 0 })}
                    checked={this.state.questionSevenRadio === 0}
                    id="questionSevenRadioC"
                    type={"radio"}
                    label={"0 - CSR assumed the caller's issue instead of asking probing questions or failed to summarize " +
                    "(mirror) the issue back to the caller before proceeding with diagnostic questions"}/>

            </div>
        )
    }

    renderQuestionEight(){
        return(
            <div>
                <hr/>
                8:  Did CSR ask the medical screening questions (if appropriate) ?
                <MDBInput
                    onClick={() => this.setState({ questionEightRadio: 5 })}
                    checked={this.state.questionEightRadio === 5}
                    id="questionEightRadioA"
                    type={"radio"}
                    label={"5 - Yes/Not Applicable"}/>

                <MDBInput
                    onClick={() => this.setState({ questionEightRadio: 0 })}
                    checked={this.state.questionEightRadio === 0}
                    id="questionEightRadioB"
                    type={"radio"}
                    label={"0 - No"}/>
            </div>
        )
    }

    renderQuestionNine(){
        return(
            <div>
                <hr/>
                9:  Ticket Creation: Was a ticket created when necessary and all documents attached?
                <MDBInput
                    onClick={() => this.setState({ questionNineRadio: 10 })}
                    checked={this.state.questionNineRadio === 10}
                    id="questionNineRadioA"
                    type={"radio"}
                    label={"10 - Ticket was created timely; all documentation was attached and routed to the appropriate queue"}/>

                <MDBInput
                    onClick={() => this.setState({ questionNineRadio: 5 })}
                    checked={this.state.questionNineRadio === 5}
                    id="questionNineRadioB"
                    type={"radio"}
                    label={"5 - Ticket was created but not all information was updated, documentation was not attached, or ticket was improperly routed"}/>

                <MDBInput
                    onClick={() => this.setState({ questionNineRadio: 0 })}
                    checked={this.state.questionNineRadio === 0}
                    id="questionNineRadioC"
                    type={"radio"}
                    label={"0 - Ticket was not created timely or at all"}/>

            </div>
        )
    }

    renderQuestionTen(){
        return(
            <div>
                <hr/>
                10: Tone of Voice
                <MDBInput
                    onClick={() => this.setState({ questionTenRadio: 5 })}
                    checked={this.state.questionTenRadio === 5}
                    id="questionTenRadioA"
                    type={"radio"}
                    label={"5 - Tone was professional and warm"}/>

                <MDBInput
                    onClick={() => this.setState({ questionTenRadio: 3 })}
                    checked={this.state.questionTenRadio === 3}
                    id="questionTenRadioB"
                    type={"radio"}
                    label={"3 - Tone was professional, but not warm or inviting"}/>

                <MDBInput
                    onClick={() => this.setState({ questionTenRadio: 0 })}
                    checked={this.state.questionTenRadio === 0}
                    id="questionTenRadioC"
                    type={"radio"}
                    label={"0 - Tone was cold, unhelpful, or disengaging"}/>

            </div>
        )
    }

    renderQuestionEleven(){
        return(
            <div>
                <hr/>
                11:  CSR asked, "Is there anything else I can help you with today?" and voiced proper closing. "Thank you for choosing Rehab Medical"

                <MDBInput
                    onClick={() => this.setState({ questionElevenRadio: 5 })}
                    checked={this.state.questionElevenRadio === 5}
                    id="questionElevenRadioA"
                    type={"radio"}
                    label={"5 - Yes"}/>

                <MDBInput
                    onClick={() => this.setState({ questionElevenRadio: 0 })}
                    checked={this.state.questionElevenRadio === 0}
                    id="questionElevenRadioB"
                    type={"radio"}
                    label={"0 - No"}/>
            </div>
        )
    }

    renderQuestionTwelve(){
        return(
            <div>
                <hr/>
                12:  CSR went above and beyond to resolve caller's issue OR the caller complimented the CSR for extra effort during the call. (5 BONUS POINTS) Innovative

                <MDBInput
                    onClick={() => this.setState({ questionTwelveRadio: 5 })}
                    checked={this.state.questionTwelveRadio === 5}
                    id="questionTwelveRadioA"
                    type={"radio"}
                    label={"5 - Yes"}/>

                <MDBInput
                    onClick={() => this.setState({ questionTwelveRadio: 0 })}
                    checked={this.state.questionTwelveRadio === 0}
                    id="questionTwelveRadioB"
                    type={"radio"}
                    label={"0 - No"}/>
            </div>
        )
    }

    renderQuestionThirteen(){
        return(
            <div>
                <hr/>
                13: Call Summary, Score and Feedback
                <br/>
                {this.renderOverallScore()}
                <MDBInput onChange={this.handleTextChange.bind(this, "finalNotes")}
                    containerClass={"containerMargin"}
                    outline label={"Extra Notes"}/>
            </div>
        )
    }

    renderOverallScore(){
        let total = 0,
            st = this.state;

        total += st.questionTwoRadio;
        total += st.questionThreeRadio;
        total += st.questionFourRadio;
        total += st.questionFiveRadio;
        total += st.questionSixRadio;
        total += st.questionSevenRadio;
        total += st.questionEightRadio;
        total += st.questionNineRadio;
        total += st.questionTenRadio;
        total += st.questionElevenRadio;
        total += st.questionTwelveRadio;

        if(total >= 60){
            return total + " Points; Exceptional"
        }
        else if(total >= 48){
            return total + " Points; Good Work"
        }
        else if(total >= 42){
            return total + " Points; Passing"
        }
        else{
            return total + " Points; Fail"
        }
    }

    renderSaveCloseButtons(){
        return(
            <MDBModalFooter>
                <MDBRow>
                    <MDBBtn color={"success"} size={'sm'} onClick={() => this.checkProperties()}> Submit </MDBBtn>
                    <MDBBtn color={"danger"} size={'sm'} onClick={() => this.onCancel()} > Cancel  </MDBBtn>
                </MDBRow>

            </MDBModalFooter>
        )
    }

    render() {
        return (
            <MDBModalBody>
                {this.renderQuestionOne()}
                {this.renderQuestionTwo()}
                {this.renderQuestionThree()}
                {this.renderQuestionFour()}
                {this.renderQuestionFive()}
                {this.renderQuestionSix()}
                {this.renderQuestionSeven()}
                {this.renderQuestionEight()}
                {this.renderQuestionNine()}
                {this.renderQuestionTen()}
                {this.renderQuestionEleven()}
                {this.renderQuestionTwelve()}
                {this.renderQuestionThirteen()}
                {this.renderSaveCloseButtons()}
            </MDBModalBody>
        )
    }
}