import React from "react";
import PropTypes from "prop-types";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	DatePicker,
	Input,
	InputFile,
	MDBIcon,
	Modal,
	ModalBody,
	Nav,
	NavItem,
	NavLink,
	Row,
	Spinner,
	TabContent,
	TabPane,
	ToastContainer,
	toast, MDBBtn, Fa,
} from "mdbreact";
import ActivityService from "../Security/ActivityService/activityService";
import NoteService from "../Security/NoteService/noteService";
import "./activities.css";
import classnames from "classnames";
import Select from "react-select";
import { Route } from "react-router-dom";
import ReactTooltip from "react-tooltip";

export default class activityEdit extends React.Component {
	constructor(props) {
		super(props);

		let prevPage = "",
			activityId = props.match.params.id;

		if (props.location.state != null) {
			prevPage = props.location.state.prevPage;
		}

		this.retrieveActivity(activityId);

		this.state = {
			id: activityId,
			isLoaded: false,
			activity: {},
			eActivity: {},
			notes: [],
			completed: false,
			noteModalOpen: false,
			noteText: "",
			createdBy: {},
			createdAt: "",
			activeItem: "1",
			selectedExpense: {},
			prevPage: prevPage,
			callTypes: [
				{ label: "Face-To-Face", value: "FTF" },
				{ label: "LCMP Evaluation", value: "LCMP Evaluation" },
				{ label: "Drop Ins", value: "Drop Ins" },
			],
			callReasons: [
				{ label: "Following up with new information from a previous meeting", value: "Following up with new information from a previous meeting" },
				{ label: "Following up regarding new information about changes in LCDs", value: "Following up regarding new information about changes in LCDs" },
				{ label: "Paperwork Education/Streamlining Processes", value: "Paperwork Education/Streamlining Processes" },
				{ label: "Equipment Changes", value: "Equipment Changes" },
				{ label: "Policy Changes", value: "Policy Changes" },
				{ label: "Other industry and/or inter-company changes that our accounts need to be aware of", value: "Other industry and/or inter-company changes that our accounts need to be aware of" },
				{ label: "Other", value: "Other" },
			],
		};
		this.retrieveNotes(activityId);
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		expenseTypes: PropTypes.array,
		cardTypes: PropTypes.array,
	};

	retrieveActivity(id) {
		ActivityService.getActivity(id).then((data) => {
			this.setState({
				activity: data,
				eActivity: JSON.parse(JSON.stringify(data)),
				isLoaded: true,
			});
		});
	}

	retrieveNotes(activityId) {
		NoteService.getNotes(activityId)
			.then((notes) => {
				if (notes === null || notes.content === 0) {
					//we have no notes, return...BC
					return;
				}

				let con = notes.content.slice();

				//sort the notes...BC
				con.sort((a, b) => {
					if (a.createdAt > b.createdAt) {
						return -1;
					} else {
						return 1;
					}
				});

				this.setState({ notes: con });
				this.triggerTaskPopup();
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	triggerTaskPopup() {
		let tasks = 0,
			notes = this.state.notes,
			type = "";
		notes.forEach((note) => {
			if (note.completed === false) {
				type = note.activity.name;
				tasks++;
			}
		});

		if (tasks > 0) {
			let message =
				"You have " + tasks + " task(s) to complete for this " + type + " .";
			toast.warn(message);
		}
	}

	encodeImageFileAsURL(element) {
		let file = element[0],
			reader = new FileReader(),
			t = this;

		reader.onloadend = function () {
			t.state.eActivity.receipt = reader.result;
			t.setState({ eActivity: t.state.eActivity });
		};
		reader.readAsDataURL(file);
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	handleChange = (property, event) => {
		//update the property that the user has edited in the order...BC
		const nActivity = this.state.eActivity;
		nActivity[property] = event.target.value;
		this.setState({ eActivity: nActivity });
	};

	formatDate(date) {
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "long",
			day: "2-digit",
		}).format(date);
	}

	formatCurrency(expense) {
		return new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
		}).format(expense);
	}

	toggle = (tab) => {
		if (this.state.activeItem !== tab) {
			this.setState({ activeItem: tab });
		}
	};

	getCardList() {
		const { cardTypes } = this.context;

		let a = [];
		a.push({
			label: "Select Card Type...",
			value: "",
		});

		cardTypes.map((card) => {
			return a.push({
				label: card.name,
				value: card,
			});
		});

		return a;
	}

	getExpenseTypeList() {
		const { expenseTypes } = this.context;

		let act = this.state.eActivity;

		let a = [];
		a.push({
			label: "Select Expense Type...",
			value: "",
		});

		expenseTypes.map((exp) => {
			if (act.cardType != null) {
				if (exp.cardTypes) {
					let i = exp.cardTypes.findIndex((x) => x.id === act.cardType.id);
					if (i > -1) {
						return a.push({
							label: exp.name,
							value: exp,
						});
					}
				}
			} else {
				return a.push({
					label: exp.name,
					value: exp,
				});
			}

			return null;
		});

		return a;
	}

	renderGoBackButton() {
		return (
			<Route
				render={({ history }) => (
					<Button
						color={"yellow"}
						floating
						size="sm"
						data-tip={"Go Back"}
						data-place="bottom"
						onClick={() => {
							if (this.state.prevPage) {
								history.push({
									pathname: "/account/" + this.state.prevPage + "/detail",
								});
							} else {
								history.push({
									pathname: "/activities",
								});
							}
						}}
					>
						<Fa size="2x" icon={"backward"}/>
					</Button>
				)}
			/>
		);
	}

	handleCardTypeSelected = (e) => {
		let act = this.state.eActivity;

		act.cardType = { name: e.value.name, id: e.value.id };

		this.setState({
			eActivity: act,
		});
	};

	handleExpenseTypeSelected = (e) => {
		let act = this.state.eActivity;

		act.expenseType = { name: e.value.name, id: e.value.id };

		this.setState({
			eActivity: act,
			selectedExpense: e.value,
		});
	};

	validateActivity(activity) {
		let shouldContinue = true;

		if (activity.type === "Appointment") {
			if (!activity.expenseDescription) {
				toast.warn("Please provide a description");
				shouldContinue = false;
			}

			if (!activity.expenseDate) {
				toast.warn("Please set the date");
				shouldContinue = false;
			}

			if (activity.expenses) {
				if (activity.expenses <= 0) {
					toast.warn("Amount spent should be greater than 0");
					shouldContinue = false;
				}

				if (!activity.expenseType) {
					toast.warn("Please select an expense type");
					shouldContinue = false;
				}

				if (!activity.cardType) {
					toast.warn("Please select a card type");
					shouldContinue = false;
				}

				if (
					activity.expenseType &&
					this.state.selectedExpense.receiptRequired === true
				) {
					if (!activity.receipt) {
						toast.warn("Please upload a receipt");
						shouldContinue = false;
					}
				}

				if (!activity.vendor) {
					toast.warn("Please identify the vendor");
					shouldContinue = false;
				}

				if (!activity.peopleAttending) {
					toast.warn("Please identify how many people were attending");
					shouldContinue = false;
				}

				if (!activity.educationalTopics) {
					toast.warn("Please identify the topics discussed");
					shouldContinue = false;
				}
			}
		}
		else if(activity.type === "Call"){
			if(!activity.contacts || activity.contacts.length === 0){
				toast.warn("Please select a contact");
				shouldContinue = false;
			}

			if(!activity.callReason){
				toast.warn("Please select a call reason");
				shouldContinue = false;
			}

			if(!activity.callType){
				toast.warn("Please select a call type");
				shouldContinue = false;
			}

			if(activity.callReason === "Other" && (this.state.noteText === "" || this.state.noteText === null)){
				toast.warn("Please enter the reason note");
				shouldContinue = false;
			}
		}

		return shouldContinue;
	}

	updateActivity() {
		let act = this.state.eActivity,
			cont = this.validateActivity(act);

		if (cont !== true) {
			return;
		}

		if (act.receipt) {
			let rt = act.receipt;
			//get the index of the comma which comes after the part that declares the image type
			let idx = rt.indexOf(","),
				newStr = rt.slice(idx + 1);

			act.receipt = newStr;
		}

		if (act.expenses) {
			act.expenses = parseFloat(act.expenses);
		}

		return ActivityService.updateActivity(act)
			.then((data) => {
				this.setState({
					activity: data,
					eActivity: JSON.parse(JSON.stringify(data)),
				});
				toast.success("Saved Successfully!");
			})
			.catch((err) => {
				toast.warn("An error occurred while saving.");
			});
	}

	renderNotesTask() {
		let notes = this.state.notes;
		return (
			<CardBody>
				<Row>
					<Col size="12">
						<div className={"pvNotesGroupOuterContainer"}>
							<div className={"pvNotesGroupInnerContainer"}>
								{notes.map((note, index) => {
									return this.generateNote(note, index);
								})}
							</div>
						</div>
					</Col>
				</Row>
			</CardBody>
		);
	}

	renderNoteModal() {
		return (
			<Container>
				<Row>
					<Modal
						centered
						className="form-elegant"
						isOpen={this.state.noteModalOpen}
						toggle={() => this.toggleNoteModalPopup()}
					>
						<CardHeader color={"indigo"}  className="text-center activity-header">
						Add New Note
						</CardHeader>

						<ModalBody style={{ backgroundColor: "#FFFFFF" }} >
							<form className=" mx-3 grey-text">
								<Input
									outline
									label="Note Text"
									value={this.state.noteText}
									onChange={this.noteChange.bind(this, "noteText")}
								/>
								<div data-tip="If this is a note, check the 'completed' box. If it is a task, leave it unchecked.">
									<Input
										onChange={this.completedChange.bind(this)}
										filled
										label="Complete"
										type="checkbox"
										id="checkbox3"
									/>
								</div>

								<Button onClick={this.addNewNote.bind(this)}>Add Note</Button>

								<ReactTooltip />
							</form>
						</ModalBody>
					</Modal>
				</Row>
			</Container>
		);
	}

	toggleNoteModalPopup() {
		let currentState = this.state.noteModalOpen;
		this.setState({ noteModalOpen: !currentState });
	}

	generateNote(note, index) {
		let t = this;

		function renderCompleteButton(note) {
			if (note.completed === false) {
				return (
					<Button
						className={"pvButtonFloat"}
						color={"red darken-2"}
						onClick={() => t.updateNote(note)}
						size="sm"
					>
						Complete
					</Button>
				);
			}
		}

		return (
			<div className={"pvNoteContainer"} key={index}>
				<Card>
					<CardHeader color={"teal darken-2"}>
						{note.createdBy.name} &nbsp;&nbsp;&nbsp;
						<span style={{ float: "right" }}>
							{t.formatDate(note.createdAt)}
						</span>
					</CardHeader>
					<CardBody className={"pvNoteText"}>
						{note.text}
						<br />
						{renderCompleteButton(note)}
					</CardBody>
				</Card>
				<br />
			</div>
		);
	}

	noteChange = (property, event) => {
		this.setState({ [property]: event.target.value });
	};

	completedChange = (event) => {
		this.setState({ completed: event.target.checked });
	};

	addNewNote() {
		//get the current user context that can be passed into the note DTO...BC
		//another comment...BC
		const { currentUser } = this.context;
		let cb = { id: currentUser.id, name: currentUser.username },
			currentNotes = this.state.notes,
			act = this.state.activity;

		let note = {
			type: "ActivityNote",
			text: this.state.noteText,
			createdAt: new Date(),
			account: { id: act.account.id, name: act.account.name },
			task: false,
			createdBy: cb,
			completed: this.state.completed,
		};

		return NoteService.createActivityNote(act.id, note)
			.then((res) => {
				this.toggleNoteModalPopup();
				currentNotes.unshift(note);
				this.setState({ notes: currentNotes });
			})
			.catch((err) => {
				//Maybe send an error to the user?...BC
			});
	}

	updateNote(note) {
		let allNotes = this.state.notes;
		note.completed = true;
		return NoteService.updateActivityNote(note)
			.then((res) => {
				this.setState({ notes: allNotes });
			})
			.catch((err) => {
				//maybe throw an error if it fails...BC
			});
	}

	determineSection(act) {
		if (act.type === "Appointment") {
			return this.renderAppointmentSection();
		} else {
			return this.renderCallSection();
		}
	}

	renderAppointmentSection() {
		return (
			<div>
				<Row>
					<Col size="3">
						<div className={"datePickerOptions"}>
							<DatePicker
								format="MM-DD-YYYY"
								hint={"01-01-1900"}
								keyboard
								getValue={(evt) => {
									let act = this.state.eActivity;
									act.expenseDate = evt;
									this.setState({ eActivity: act });
								}}
								mask={[
									/\d/,
									/\d/,
									"-",
									/\d/,
									/\d/,
									"-",
									/\d/,
									/\d/,
									/\d/,
									/\d/,
								]}
								label="Appointment Date"
							/>
						</div>
					</Col>
					<Col size="3">
						<Input
							outline
							label="Amount Spent"
							icon={"dollar"}
							value={this.state.eActivity.expenses}
							onChange={(evt) => {
								let act = this.state.eActivity;
								act.expenses = evt.target.value;
								this.setState({ eActivity: act });
							}}
						/>
					</Col>

					<Col size="3">
						<div style={{ marginTop: "1.5rem" }}>
							<Select
								placeholder={
									this.state.eActivity.cardType
										? this.state.eActivity.cardType.name
										: "Select Card Type"
								}
								options={this.getCardList()}
								onChange={this.handleCardTypeSelected.bind(this)}
							/>
						</div>
					</Col>
					<Col size="3">
						<div style={{ marginTop: "1.5rem" }}>
							<Select
								placeholder={
									this.state.eActivity.expenseType
										? this.state.eActivity.expenseType.name
										: "Select Expense Type"
								}
								options={this.getExpenseTypeList()}
								onChange={this.handleExpenseTypeSelected.bind(this)}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col size="8">
						<Input
							outline
							label="Appointment Description"
							value={this.state.eActivity.expenseDescription || ""}
							onChange={(evt) => {
								let act = this.state.eActivity;
								act.expenseDescription = evt.target.value;
								this.setState({ eActivity: act });
							}}
						/>
					</Col>
					<Col size="4">
						<Input
							outline
							label="Vendor"
							value={this.state.eActivity.vendor || ""}
							onChange={(evt) => {
								let act = this.state.eActivity;
								act.vendor = evt.target.value;
								this.setState({ eActivity: act });
							}}
						/>
					</Col>
				</Row>

				<Row></Row>

				<Row>
					<Col size="4">
						<Input
							outline
							label="Topics Discussed"
							value={this.state.eActivity.educationalTopics || ""}
							onChange={(evt) => {
								let act = this.state.eActivity;
								act.educationalTopics = evt.target.value;
								this.setState({ eActivity: act });
							}}
						/>
					</Col>

					<Col size="4">
						<Input
							outline
							label="People Attending (amount)"
							value={this.state.eActivity.peopleAttending || ""}
							onChange={(evt) => {
								let act = this.state.eActivity;
								act.peopleAttending = evt.target.value;
								this.setState({ eActivity: act });
							}}
						/>
					</Col>

					<Col size="4">
						<Input
							outline
							label="Physicians Attending (names)"
							value={this.state.eActivity.physiciansAttending || ""}
							onChange={(evt) => {
								let act = this.state.eActivity;
								act.physiciansAttending = evt.target.value;
								this.setState({ eActivity: act });
							}}
						/>
					</Col>
				</Row>
			</div>
		);
	}

	renderCallSection() {
		return (
			<Row>
				<Col size="6">
					<div className="userCheckbox">
						<Input
							onChange={(evt) => {
								let call = this.state.eActivity;
								call.quality = evt.target.checked;
								if(call.quality === true){
									call.callType = null;
								}
								else{
									call.callReason = null;
								}
								this.setState({ eActivity: call });
							}}
							filled
							checked={this.state.eActivity.quality}
							label="Marketing"
							type="checkbox"
							id="checkbox6"
						/>
					</div>
				</Col>

				<Col size="6">
					<div className="datePickerOptions">
						<DatePicker
							format="MM-DD-YYYY"
							hint={"01-01-1900"}
							keyboard
							value={this.state.eActivity.when}
							getValue={(evt) => {
								let act = this.state.eActivity;
								act.when = evt;
								this.setState({ eActivity: act });
							}}
							mask={[/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
							label="Call Date"
						/>
					</div>
				</Col>

				<Col md="12">
					{this.state.eActivity.quality === false ?
						<Select
							placeholder={"Select Call Type...(Required)"}
							options={this.state.callTypes}
							value={{ label: this.state.eActivity.callType, value: this.state.eActivity.callType}}
							onChange={(e) => {
								let act = this.state.eActivity;
								act.callType = e.value;
								this.setState({
									eActivity: act,
								})
							}}
						/>
					:
						<Select
							placeholder={"Select Call Reason...(Required)"}
							value={{ label: this.state.eActivity.callReason, value: this.state.eActivity.callReason}}
							options={this.state.callReasons}
							onChange={(e) => {
								let modalOpen = false;
								let act = this.state.eActivity;
								act.callReason = e.value;
								if(e.value === "Other"){
									modalOpen = true;
								}
								this.setState({
									noteModalOpen: modalOpen,
									eActivity: act,
								})
							}}
						/>
					}
				</Col>
			</Row>
		);
	}

	renderReceiptSection() {
		return (
			<div>
				<InputFile
					type="file"
					textFieldTitle={"Upload receipt (.jpg or .png only)"}
					className="form-control-file"
					name="receipt"
					getValue={this.encodeImageFileAsURL.bind(this)}
				/>
				<Row style={{ justifyContent: "space-evenly" }}>
					{this.renderReceipt()}
				</Row>
			</div>
		);
	}

	renderReceipt() {
		let rct = this.state.eActivity.receipt;

		if (!rct) {
			return <div></div>;
		}

		if (rct.indexOf("base64") === -1) {
			return (
				<img
					style={{ width: "200px", height: "300px" }}
					src={"data:image/png;base64," + rct}
					alt="Invalid format"
				/>
			);
		} else {
			return (
				<img
					style={{ width: "200px", height: "300px" }}
					src={rct}
					alt="Invalid format"
				/>
			);
		}
	}

	render() {
		let act = this.state.eActivity;

		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}

		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>

				<CardHeader
					style={{
						paddingTop: 12,
						paddingBottom: 8,
						marginBottom: 10,
						color: "white",
						fontSize: 30,
						backgroundColor: "#5881C1",
					}}
				>
					<Row style={{ alignItems: 'center' }}>
						<Col md={"3"} style={{textAlign: 'right'}}> {this.renderGoBackButton()} </Col>
						<Col md={"6"} style={{textAlign: 'center'}}>
							{act.account ? act.account.name : ""}
							{act.isExpense ? "This is an expense" : ""}
							{act.contacts ? act.contacts.map((ct) => {
									return <h6> {ct.firstname + " " + ct.lastname}</h6>;
								})
								: ""}
						</Col>
						<Col md={"3"} >
							<Button
								color={"yellow"}
								floating
								size="sm"
								data-tip={"Add Note"}
								onClick={this.toggleNoteModalPopup.bind(this)}
							>
								<Fa size="2x" icon={"notes-medical"}>
									{" "}
								</Fa>
							</Button>

							<Button
								floating
								size="sm"
								color={"success"}
								data-tip={"Save Activity"}
								data-place="bottom"
								onClick={this.updateActivity.bind(this)}
							>
								<Fa far size="2x" icon={"save"}>
									{" "}
								</Fa>
							</Button>
						</Col>

					</Row>
					<Row size="12">

					</Row>
				</CardHeader>

				{this.renderNoteModal()}

				<Container className="mt-4">
					<Row>
						<Col size="12" >

							<Nav tabs
								 className="nav-justified activity-header"
								 style={{ marginTop: 20 }}
								>
								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({ active: this.state.activeItem === "1",})}
										onClick={() => {
											this.toggle("1");
										}}
									>
										<MDBIcon icon="info" size="2x" />
										<br />
										Details
									</NavLink>
								</NavItem>

								{act.type === "Appointment" ? (
									<NavItem>
										<NavLink
											link
											to="#"
											className={classnames({ active: this.state.activeItem === "2",})}
											onClick={() => {
												this.toggle("2");
											}}
										>
											<MDBIcon icon="camera" size="2x" />
											<br />
											Receipt
										</NavLink>
									</NavItem>
								) : (
									""
								)}

								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({
											active: this.state.activeItem === "3",
										})}
										onClick={() => {
											this.toggle("3");
										}}
									>
										<MDBIcon icon="comments" size="2x" />
										<br />
										Notes & Tasks
									</NavLink>
								</NavItem>
							</Nav>

							<TabContent className="activity-body" activeItem={this.state.activeItem}>
								<TabPane tabId="1" role="tabpanel">
									{this.determineSection(act)}
								</TabPane>

								<TabPane tabId="2" role="tabpanel">
									{this.renderReceiptSection()}
								</TabPane>

								<TabPane tabId="3" role="tabpanel">
									{this.renderNotesTask()}
								</TabPane>

								<br />

							</TabContent>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
