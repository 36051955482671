import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	CardHeader,
	Col,
	Row,
	Modal,
	ModalBody,
	ModalFooter,
	Fa,
	MDBIcon,
	MDBInput,
} from "mdbreact";
import ManualDocumentCreation from "./manualDocumentBuilderService";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

export default class manualDocumentBuilder extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: "",
			success: "",
			idTextbox: "",
			modal: false,
			docs: [],
			options: [],
			optionSelected: {},
		};
	}

	renderMessage() {
		let t = this.state;

		if (t.error.length > 0) {
			return <h5 style={{ color: "#D50000", textAlign: "left" }}>{t.error}</h5>;
		} else if (t.success.length > 0) {
			return (
				<h5 style={{ color: "#00C851", textAlign: "left" }}>{t.success}</h5>
			);
		} else {
			return <div></div>;
		}
	}

	clearMessage() {
		setTimeout(() => {
			this.setState({ error: "", success: "" });
		}, 5000);
	}

	createDeliveryPaperwork = () => {
		let values = document.getElementById("docText").value,
			obj = JSON.parse(values);
		return ManualDocumentCreation.createEquipmentDeliveryDocument(obj)
			.then((res) => {
				this.setState({
					error: "",
					success: "Success",
				});
			})
			.catch((err) => {
				this.setState({
					error: err,
					success: "",
				});
			})
			.finally(() => {
				this.clearMessage();
			});
	};

	createSafetyAssessment = () => {
		let values = document.getElementById("docText").value,
			obj = JSON.parse(values);

		return ManualDocumentCreation.createSafetyAssessment(obj)
			.then((res) => {
				this.setState({
					error: "",
					success: "Success",
				});
			})
			.catch((err) => {
				this.setState({
					error: err,
					success: "",
				});
			})
			.finally(() => {
				this.clearMessage();
			});
	};

	createHomeAssessment = () => {
		let values = document.getElementById("docText").value,
			obj = JSON.parse(values);

		return ManualDocumentCreation.createHomeAssessment(obj)
			.then((res) => {
				this.setState({
					error: "",
					success: "Success",
				});
			})
			.catch((err) => {
				this.setState({
					error: err,
					success: "",
				});
			})
			.finally(() => {
				this.clearMessage();
			});
	};

	createABN = () => {
		let values = document.getElementById("docText").value,
			obj = JSON.parse(values);

		return ManualDocumentCreation.createABN(obj)
			.then((res) => {
				this.setState({
					error: "",
					success: "Success",
				});
			})
			.catch((err) => {
				this.setState({
					error: err,
					success: "",
				});
			})
			.finally(() => {
				this.clearMessage();
			});
	};

	createPickup = () => {
		let values = document.getElementById("docText").value,
			obj = JSON.parse(values);

		return ManualDocumentCreation.createPickup(obj)
			.then((res) => {
				this.setState({
					error: "",
					success: "Success",
				});
			})
			.catch((err) => {
				this.setState({
					error: err,
					success: "",
				});
			})
			.finally(() => {
				this.clearMessage();
			});
	};

	createServiceTicket = () => {
		let values = document.getElementById("docText").value,
			obj = JSON.parse(values);

		return ManualDocumentCreation.createServiceTicket(obj)
			.then((res) => {
				this.setState({
					error: "",
					success: "Success",
				});
			})
			.catch((err) => {
				this.setState({
					error: err,
					success: "",
				});
			})
			.finally(() => {
				this.clearMessage();
			});
	};

	createComplexPaperwork = () => {
		let values = document.getElementById("docText").value,
			obj = JSON.parse(values);

		return ManualDocumentCreation.createComplexPaperwork(obj)
			.then((res) => {
				this.setState({
					error: "",
					success: "Success",
				});
			})
			.catch((err) => {
				this.setState({
					error: err,
					success: "",
				});
			})
			.finally(() => {
				this.clearMessage();
			});
	};

	createSalesAgreement = () => {
		let values = document.getElementById("docText").value,
			obj = JSON.parse(values);

		return ManualDocumentCreation.createSalesAgreement(obj)
			.then((res) => {
				this.setState({
					error: "",
					success: "Success",
				});
			})
			.catch((err) => {
				this.setState({
					error: err,
					success: "",
				});
			})
			.finally(() => {
				this.clearMessage();
			});
	};

    createDonationForm = () => {
        let values = document.getElementById("docText").value,
            obj = JSON.parse(values);

        return ManualDocumentCreation.createDonationForm(obj).then(res => {
            this.setState({
                error: "",
                success: "Success"
            });
        }).catch(err => {
            this.setState({
                error: err,
                success: ""
            });
        }).finally(() => {
            this.clearMessage()
        });
    }

    createEmailDocument = () => {
        let values = document.getElementById("docText").value,
            obj = JSON.parse(values);

		return ManualDocumentCreation.createEmailDocument(obj)
			.then((res) => {
				this.setState({
					error: "",
					success: "Success",
				});
			})
			.catch((err) => {
				this.setState({
					error: err,
					success: "",
				});
			})
			.finally(() => {
				this.clearMessage();
			});
	};

	mergePaperwork = () => {
		let values = document.getElementById("docText").value,
			obj = JSON.parse(values);

		return ManualDocumentCreation.mergePaperwork(obj)
			.then((res) => {
				this.setState({
					error: "",
					success: "Success",
				});
			})
			.catch((err) => {
				this.setState({
					error: err,
					success: "",
				});
			})
			.finally(() => {
				this.clearMessage();
			});
	};

	getPaperWorkById = () => {
		return ManualDocumentCreation.getDeliveryDocs({
			orderId: this.state.idTextbox,
		})
			.then((res) => {
				let o = [];
				res.map((doc) => {
					return o.push({
						label:
							doc.documnetType +
							" - " +
							new Date(doc.uploadedOn).toLocaleString(),
						value: doc.docInfo,
					});
				});
				this.setState({
					docs: res,
					options: o,
					error: "",
				});
				this.toggleModal();
			})
			.catch((err) => {
							console.log(err);
						});
	};

	toggleModal = () => {
		this.setState({
			modal: !this.state.modal,
		});
	};

	renderModal() {
		return (
			<Modal
				centered
				size="med"
				isOpen={this.state.modal}
				toggle={this.toggleModal}
			>
				<ModalBody style={{ backgroundColor: "#FFFFFF" }}>
					<CardHeader
						style={{ borderRadius: "8px" }}
						className="form-header text-center font-weight-bold blue-gradient"
					>
						<h3>Select Document</h3>
					</CardHeader>
					<Select
						placeholder={"Document Type"}
						options={this.state.options}
						onChange={this.handleSelectChange.bind(this)}
					></Select>
				</ModalBody>
				<ModalFooter color={"indigo"}>
					<Button
						size="sm"
						color={"success"}
						data-tip={"Select Document"}
						onClick={this.handleSaveClick}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>
					<Button
						color={"red"}
						size="sm"
						data-tip={"Cancel"}
						onClick={this.toggleModal}
					>
						<Fa size={"2x"} icon={"times"}>
							{" "}
						</Fa>
					</Button>
					<ReactTooltip />
				</ModalFooter>
			</Modal>
		);
	}

	handleSaveClick = () => {
		let v = this.state.optionSelected.value;

		try {
			let o = JSON.parse(v);

			for (let prop in o) {
				if (o[prop] && o[prop].millis != null) {
					o[prop] = o[prop].millis;
				}
			}
			document.getElementById("docText").value = JSON.stringify(o);
		} catch (e) {
			document.getElementById("docText").value = JSON.stringify(v);
		}

		this.toggleModal();
	};

	handleSelectChange = (e) => {
		this.setState({
			optionSelected: e,
		});
	};

	render() {
		return (
			<Container className="mt-5">
				<Card>
					<CardHeader style={{ textAlign: "center" }}>
						Manual Document Builder
					</CardHeader>
					<CardBody>
						{this.renderMessage()}
						<Row>
							<Col md={8}>
								<textarea
									id="docText"
									rows="5"
									placeholder="Input Area"
									style={{
										height: "32rem",
										width: "45rem",
										textAlign: "left",
										borderRadius: "4px",
									}}
								/>
							</Col>
							<Col md={4}>
								<MDBInput
									outline
									label={"Order Id"}
									valueDefault={this.state.idTextbox}
									onBlur={(e) => this.setState({ idTextbox: e.target.value })}
								/>

                                <Button color="primary" onClick={() => this.getPaperWorkById()}
                                        style={{width: '18rem'}}>Find Paperwork by Order ID</Button>
                                <Button color="primary" onClick={() => this.createDeliveryPaperwork()}
                                        style={{width: '18rem'}}>Create Delivery Paperwork</Button>
                                <Button color="primary" onClick={() => this.createSafetyAssessment()}
                                        style={{width: '18rem'}}>Create Safety Paperwork</Button>
                                <Button color="primary" onClick={() => this.createHomeAssessment()}
                                        style={{width: '18rem'}}>Create Home Paperwork</Button>
                                <Button color="primary" onClick={() => this.createABN()} style={{width: '18rem'}}>Create
                                    ABN Form</Button>
                                <Button color="primary" onClick={() => this.createPickup()} style={{width: '18rem'}}>Create
                                    Pickup Form</Button>
                                <Button color="primary" onClick={() => this.createServiceTicket()}
                                        style={{width: '18rem'}}>Create Service Ticket</Button>
                                <Button color="primary" onClick={() => this.createComplexPaperwork()}
                                        style={{width: '18rem'}}>Create Complex Paperwork</Button>
                                <Button color="primary" onClick={() => this.createSalesAgreement()}
                                        style={{width: '18rem'}}>Create Sales Agreement</Button>
                                <Button color="primary" onClick={() => this.createDonationForm()}
                                        style={{width: '18rem'}}>Create Donation Form</Button>
                                <Button color="primary" onClick={() => this.createEmailDocument()}
                                        style={{width: '18rem'}}>Create Email Document</Button>
                                <Button color="primary" onClick={() => this.mergePaperwork()} style={{width: '18rem'}}>Merge
                                    Paperwork</Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {this.renderModal()}
            </Container>
        )
    }
}
