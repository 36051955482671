import api from "../Security/api";

class DocumentTypeService {
	async getAllDocumentTypes() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				paged: false,
			};
			api
				.get("/documentTypes", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateDocumentType(doc) {
		return new Promise((res, reject) => {
			api
				.put("/documentTypes/" + doc.id, doc)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createDocumentType(doc) {
		return new Promise((res, reject) => {
			api
				.post("/documentTypes", doc)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
	async deleteDocumentType(id) {
		return new Promise((res, reject) => {
			api
				.delete("/documentTypes/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const documentTypeService = new DocumentTypeService();

export default documentTypeService;
