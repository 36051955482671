import React from "react";
import PropTypes from "prop-types";
import {
	Card,
	CardHeader,
	CardBody,
	Container,
	Input,
	FormInline,
	Spinner,
} from "mdbreact";
import "./home.css";
import NoteService from "../Security/NoteService/noteService";

class TaskList extends React.Component {
	constructor(props) {
		super(props);

		this.getTasksForUser();

		this.state = {
			tasks: [],
			isLoaded: false,
			groupedTasks: {},
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	formatDate(date) {
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "long",
			day: "2-digit",
			hour: "numeric",
			minute: "numeric",
		}).format(date);
	}

	getTasksForUser() {
		NoteService.getOpenTherapistNotesByOwner()
			.then((tasks) => {
				this.setState({
					tasks: tasks,
					isLoaded: true,
				});
			})
			.catch((err) => {
							console.log(err);
						});
	}

	updateTask(note, event) {
		note.completed = event.target.checked;
		return NoteService.updateTherapistNote(note)
			.then((res) => {})
			.catch((err) => {
							console.log(err);
						});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	generateNote(note, idx) {
		let t = this;

		function cardHeader() {
			let noteId = "checkbox" + idx.toString(),
				name = note.patientName != null ? note.patientName : "No Patient Name";

			return (
				<CardHeader style={{ backgroundColor: "blue", color: "white" }}>
					<FormInline className={"individualNote"}>
						<span>{t.formatDate(note.createdAt)}</span>
						<span style={{ margin: "auto" }}>{name}</span>
						<Input
							onChange={t.updateTask.bind(this, note)}
							label="Mark As Complete"
							type="checkbox"
							id={noteId}
						/>
					</FormInline>
				</CardHeader>
			);
		}

		return (
			<div key={idx}>
				<Card>
					{cardHeader()}
					<CardBody>{note.text}</CardBody>
				</Card>
				<br />
			</div>
		);
	}

	renderTaskList(theme) {
		let tasks = this.state.tasks;
		return (
			<div>
				{tasks.map((task, idx) => {
					return this.generateNote(task, idx);
				})}
			</div>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}

		return (
			<div align="center" style={{ marginTop: "1rem" }}>
				<Container>
					<CardHeader
						className="text-center"
						style={{
							fontSize: "36px",
							marginBottom: "1rem",
						}}
					>
						Currently Open Tasks
					</CardHeader>
					{this.renderTaskList()};
				</Container>
			</div>
		);
	}
}

export default TaskList;
