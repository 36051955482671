import api from "../Security/api";

class PatientTrainingLinkService {
	async getAllPatientTrainingLinks() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				paged: false,
			};
			api
				.get("/patientTrainingLink", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getVideosByPatient(id) {
		return new Promise((res, reject) => {
			let url = "/patientTrainingLinkByPatient/" + id;

			return api
				.get(url)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updatePatientTrainingLink(reason) {
		return new Promise((res, reject) => {
			api
				.put("/patientTrainingLink/" + reason.id, reason)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async addPatientTrainingLink(reason) {
		return new Promise((res, reject) => {
			api
				.post("/patientTrainingLink", reason)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deletePatientTrainingLink(id) {
		return new Promise((res, reject) => {
			api
				.delete("/patientTrainingLink/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const patientTrainingLinkService = new PatientTrainingLinkService();

export default patientTrainingLinkService;
