import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBIcon,
	MDBContainer,
	MDBRow,
	MDBDataTableV5,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import InsuranceService from "../Security/InsuranceService/insuranceService";
import ReactTooltip from "react-tooltip";

export default class insuranceSubType extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
				},
				{
					label: "Payor Source",
					field: "payorsource",
					sort: "asc",
				},
				{
					label: "Display Order",
					field: "displayorder",
					sort: "asc",
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
				},
				{
					label: "Delete",
					field: "delete",
					sort: "asc",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			isLoaded: false,
			editModal: false,
			confirmationModal: false,
			addNewModal: false,
			rawData: [],
			newInsurance:{
				id: -1,
				name: "",
				order: "",
				insuranceType: {
					name: "",
				},
			},
			currInsurance: {
				id: -1,
				name: "",
				order: "",
				insuranceType: {
					name: "",
				},
			},
			currIndex: -1,
			errorMessage: "",
			insuranceNames: [],
		};
	}

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveInsurance();
		this.retrieveInsuranceNames();
	}

	retrieveInsuranceNames() {
		let t = this;
		return InsuranceService.findAllInsurance()
			.then((res) => {
				let arr = [];

				res.forEach((insurance, index) => {
					arr.push({
						label: insurance.name,
						value: insurance,
					});
				});

				t.setState({
					insuranceNames: arr,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	retrieveInsurance() {
		let t = this;
		return InsuranceService.findAllSubInsurance()
			.then((res) => {
				let arr = [],
					d = this.state.data;

				res.forEach((insurance, index) => {
					arr.push({
						id: insurance.id,
						name: insurance.name,
						payorsource: insurance.insuranceType.name,
						displayorder:
							typeof insurance.order === "number"
								? insurance.order
								: "no order",
						edit: t.renderEditButton(insurance, index),
						delete: t.renderRemoveButton(insurance, index),
					});
				});

				d.rows = arr;

				t.setState({
					data: d,
					rawData: res,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderEditButton(insurance, index) {
		return (
				<MDBIcon icon="edit"
						 style={{color: "#7ac5ff"}}
						 onClick={() => {this.handleEditClick(insurance, index);}}
				/>
		);
	}

	renderRemoveButton(insurance, index) {
		return (
				<MDBIcon icon="trash"
						 style={{color: "#db0404"}}
						 onClick={() => this.handleRemoveClick(insurance, index)}
				/>
		);
	}

	handleConfirmationDelete(id){
		InsuranceService.deleteInsuranceSubType(id)
			.then((res) => {
				this.retrieveInsurance();
				toast.success("Insurance Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Insurance");
			});
		this.handleConfirmationClose();
	}

	handleRemoveClick(insurance, index) {
		let i = {
			id: insurance.id,
			name: insurance.name,
			order: insurance.order,
		};
		this.setState({
			confirmationModal: !this.state.confirmationModal,
			currInsurance: i,
			currIndex: index,
		});
	}

	handleEditClick(insurance, index) {
		let i = JSON.parse(JSON.stringify(insurance));
		let names = this.state.insuranceNames;
		// Have to do this since its the insurance obj is missing the order number initially
		for (let e in names) {
			if (names[e].value.id === i.insuranceType.id) {
				i.insuranceType = names[e].value;
				break;
			}
		}

		this.setState({
			editModal: !this.state.editModal,
			currInsurance: i,
			currIndex: index,
		});
	}

	confirmationToggle = () => {
		this.setState({
			confirmationModal: !this.state.confirmationModal,
			errorMessage: "",
		})
	}

	addNewToggle = () => {
		this.setState({
			addNewModal: !this.state.addNewModal,
			errorMessage: "",
		});
	};

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};


	renderConfirmationModal() {
		return (
			<Modal isOpen={this.state.confirmationModal} toggle={this.confirmationToggle}>
				<ModalHeader
					style={{ backgroundColor: "#F93154", color: "white" }}
					toggle={this.confirmationToggle}
				>
					Confirm Delete
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderConfirmationModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Confirm Delete"}
						onClick={() => this.handleConfirmationDelete(this.state.currInsurance.id)}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleConfirmationClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderConfirmationModalBody() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<MDBContainer>
					<MDBRow>
						Are you sure you want to delete {this.state.currInsurance.name}?
					</MDBRow>
				</MDBContainer>
			</div>
		);
	}

	renderAddNewModal() {
		return (
			<Modal isOpen={this.state.addNewModal} toggle={this.addNewToggle}>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.addNewToggle}
				>
					Add New Insurance
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderAddNewModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Add New Insurance"}
						onClick={this.handleAddNewSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleAddNewClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderAddNewModalBody() {
		if (this.state.addNewModal) {
			return (
				<div>
					<p style={{ color: "red" }}>{this.state.errorMessage}</p>
					<Input
						outline
						label="Name"
						onChange={this.handleAddNewChange.bind(this, "name")}
					/>
					<Select
						placeholder="Payor Source"
						options={this.state.insuranceNames}
						onChange={this.handleAddNewChange.bind(this, "insurancetype")}
					/>
					<Input
						outline
						label="Order"
						onChange={this.handleAddNewChange.bind(this, "order")}
					/>
				</div>
			);
		}
	}

	renderEditModal() {
		return (
			<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
				<ModalHeader
					style={{backgroundColor: "#5881C1", color: "white" }}
					toggle={this.editToggle}
				>
					Insurance
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderEditModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Update Insurance"}
						onClick={this.handleEditSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleEditClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderEditModalBody() {
		if (this.state.editModal) {
			let i = this.state.currInsurance.insuranceType.name;
			return (
				<div>
					<p style={{ color: "red" }}>{this.state.errorMessage}</p>
					<Input
						outline
						label="Name"
						value={this.state.currInsurance.name}
						onChange={this.handleEditChange.bind(this, "name")}
					/>
					<Select
						placeholder={i}
						options={this.state.insuranceNames}
						onChange={this.handleEditChange.bind(this, "insurancetype")}
					/>
					<Input
						outline
						label="Order"
						value={String(this.state.currInsurance.order)}
						onChange={this.handleEditChange.bind(this, "order")}
					/>
				</div>
			);
		}
	}

	handleAddNewSave = () => {
		if (this.validateAddNew()) {
			let obj = this.state.newInsurance
			return InsuranceService.createSubInsurance(obj)
				.then((res) => {
					this.setState({
						errorMessage: "",
						newInsurance: {
							name: "",
							order: "",
							insuranceType: {},
						},

					});

					this.handleAddNewClose();
					this.retrieveInsurance();
					toast.success(obj.name + " Insurance has been Added!");
				})
				.catch((err) => {
					toast.warn(
						"An error occurred::: Make sure order number is different from others"
					);
				});

		}
	};

	handleEditSave = () => {
		if (this.validateEdit()) {
			let obj = this.state.currInsurance,
				d = this.state.data,
				i = this.state.currIndex;
			return InsuranceService.updateSubInsurance(obj)
				.then((res) => {
					d.rows[i].name = obj.name;
					d.rows[i].displayorder = obj.order;
					d.rows[i].payorsource = obj.insuranceType.name;
					this.setState({
						data: d,
						editModal: !this.state.editModal,
						errorMessage: "",
					});
					toast.success("Insurance Edited!");
				})
				.catch((err) => {
					toast.warn(
						"An error occurred: Make sure order number is different from others"
					);
				});
		}
	};

	validateAddNew() {
		this.setErrorMessage("");
		let n = this.state.newInsurance.name,
			o = this.state.newInsurance.order,
			i = this.state.newInsurance.insuranceType
		if (n === "") {
			this.setErrorMessage("Please enter an Insurance name");
			return false;
		} else if (i ==="") {
			this.setErrorMessage("Please choose a Payor Source");
			return false;
		} else if (o === "") {
			this.setErrorMessage("Please enter an Order Number");
			return false;
		} else if (this.validateOrderNumber()){
			this.setErrorMessage("Order Number Already Exists");
			return false;
		}
		this.setErrorMessage("")
		return true;
	}

	validateOrderNumber(){
		let contains = false;
		let order = this.state.newInsurance.order;
		for (let i=0; i<this.state.rawData.length; i++){
			if (this.state.rawData[i].order == order){
				contains = true;
			}
		}
		return contains;
	}

	validateEdit() {
		let i = this.state.currInsurance;
		if (i.name === "") {
			this.setErrorMessage("Please enter a Name");
			return false;
		} else if (i.order === "") {
			this.setErrorMessage("Please enter an Order Number");
			return false;
		}
		return true;
	}

	setErrorMessage(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleConfirmationClose = () => {
		let i = {
			id: -1,
			name: "",
			order: "",
		};
		this.setState({
			currInsurance: i,
			currIndex: -1,
			errorMessage: "",
			confirmationModal: !this.state.confirmationModal,
		});
	}

	handleAddNewClose = () =>{
		this.setState({
			newInsurance: {
				insuranceType : "",
				name :"",
				order : "",
			},
			currIndex: -1,
			errorMessage: "",
			addNewModal: !this.state.addNewModal,
		})
	}

	handleEditClose = () => {
		this.setState({
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
		});
	};

	handleAddNewChange(property, e){
		let i = this.state.newInsurance;

		if (property !== "insurancetype") {
			i[property] = e.target.value;
		} else {
			i.insuranceType = e.value;
		}
		this.setState({
			newInsurance: i,
		})
	}

	handleEditChange(property, e) {
		let i = this.state.currInsurance;

		if (property !== "insurancetype") {
			i[property] = e.target.value;
		} else {
			i.insuranceType = e.value;
		}

		this.setState({
			currInsurance: i,
		});
	}

	addNewClick = () => {
		this.setState({
			addNewModal: !this.state.addNewModal,
		})
	};

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBDataTableV5  hover paging={false} data={{columns: this.state.data.columns, rows: this.state.data.rows}}
								searchTop searchBottom={false}
								order={['payorsource', 'ascending']}/>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30 }}
					>
						Insurance
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Insurance Type"}
							onClick={this.addNewClick}
						>
							<MDBIcon icon="credit-card" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				{this.renderEditModal()}
				{this.renderAddNewModal()}
				{this.renderConfirmationModal()}
				<ReactTooltip />
			</Container>
		);
	}
}
