import React from "react";
import {ResponsiveBullet} from '@nivo/bullet'
import {MDBBadge, MDBCol, MDBIcon, MDBRow, MDBSimpleChart, NavItem, NavLink} from "mdbreact";
import "../jkTooltip.css";
import {ResponsiveBar} from "@nivo/bar";
import PropTypes from "prop-types";

export default class UserMetricCollapse extends React.Component {
    constructor(props) {
        super(props)


        this.state = {
            metricsData: [],
            roleQuotas: []
        };


    }

    static contextTypes = {
        currentUser: PropTypes.object,
    }


    componentDidUpdate(nextProps) {
        const {metricsData, roleQuotas} = this.props
        if (nextProps.metricsData !== metricsData) {
            this.setState({metricsData: metricsData})
        }
        if (nextProps.roleQuotas !== roleQuotas) {
            this.setState({roleQuotas: roleQuotas})
        }
    }


    render() {
        const {currentUser} = this.context;

        let p = this.props,
            rqCount = 0;

        let t = this;

        if (p.roleQuotas && currentUser) {
            let v = p.roleQuotas.find(quo => {
                return quo.name !== currentUser.role;
            })

            if (v) {
                data[0].ranges = [0, v.dailyQuota, 60];
            }
        }

        if (p.metricsData) {
            p.metricsData.forEach(md => {
                switch (md.metricType) {
                    case "Task Zero":
                        secondaryData[0].markers[0] = md.value;
                        secondaryData[0].ranges = [0, md.value, 500];
                        break;
                    case "Daily Quota":
                        data[0].measures[0] = md.value;
                        break;
                    case "Reverse Quality":
                    default:
                        rqCount = md.value;
                        break;
                }
            })
        }

        function renderPCRNotifications() {
            if (["PATIENT_CARE_REP", "ADMIN", "EXECUTIVE_MANAGEMENT", "SYSTEM_ADMIN"].includes(currentUser.role)) {
                return (
                    <NavLink to="/pcrNotifications" style={{paddingBottom: 0, paddingTop: 0}}>
                        <MDBIcon data-tip={"PCR Notifications"}
                                 data-place={"bottom"} size={"2x"}
                                 icon="comments" style={{paddingTop: 0, color: "#FFF"}}>

                        </MDBIcon>
                    </NavLink>

                )
            }
        }

        return (
            <MDBRow style={{paddingTop: 5}}>
                <MDBCol size={10}/>
                {/*<MDBCol size={5} className={"idBulletContainer"}>*/}
                {/*    <ResponsiveBullet*/}
                {/*        data={data}*/}
                {/*        height={40}*/}
                {/*        margin={{top: 20, right: 10, bottom: 10, left: 70}}*/}
                {/*        spacing={46}*/}
                {/*        titleAlign="start"*/}
                {/*        titleOffsetX={-50}*/}
                {/*        measureSize={0.25}*/}
                {/*        markerSize={2}*/}
                {/*        rangeColors={['#F93154', '#FFF', '#FFA900', '#00B74A', '#1266F1']}*/}
                {/*        markerColors={['#FF0', '#000']}*/}
                {/*        measureColors={'#262626'}*/}
                {/*        axisPosition={'before'}*/}
                {/*    />*/}
                {/*</MDBCol>*/}
                {/*<MDBCol size={5}/>*/}

                {/*<MDBCol size={5} className={"idBulletContainer"}>*/}
                {/*    <ResponsiveBullet*/}
                {/*        data={secondaryData}*/}
                {/*        reverse={true}*/}
                {/*        height={40}*/}
                {/*        margin={{top: 22, right: 10, bottom: 10, left: 70}}*/}
                {/*        spacing={46}*/}
                {/*        titleAlign="start"*/}
                {/*        titleOffsetX={-60}*/}
                {/*        measureSize={0.25}*/}
                {/*        markerSize={2}*/}
                {/*        markerColors={"#000"}*/}
                {/*        rangeColors={['#F00', '#FFF', '#1266F1', '#00B74A', '#FFA900', '#F93154']}*/}
                {/*        measureColors={'#262626'}*/}
                {/*        axisPosition={'before'}*/}
                {/*    />*/}
                {/*</MDBCol>*/}
                <MDBCol size={2}>
                    <MDBRow>
                        <NavLink to="/myReverseQuality" style={{paddingBottom: 0, paddingTop: 0}}>
                            <MDBIcon data-tip={"My Reverse Quality"}
                                     data-place={"bottom"} size={"2x"}
                                     icon="exclamation-triangle" style={{paddingTop: 0, color: "#FFA900"}}>
                                <MDBBadge style={{fontSize: '50%', position: 'relative', left: -20, top: -10}}
                                          color="danger"
                                          className="ml-2">{rqCount}
                                </MDBBadge>
                            </MDBIcon>
                        </NavLink>


                        {renderPCRNotifications()}
                    </MDBRow>

                </MDBCol>

            </MDBRow>


        );
    }
}

const data = [
    {
        "id": "Quota",
        "ranges": [
            0,
            35,
            75
        ],
        "measures": [
            0
        ],
        "markers": [
            0
        ]
    }
]


const secondaryData = [
    {
        "id": "Task Zero",
        "ranges": [
            0,
            500,
        ],
        "measures": [
            0
        ],
        "markers": [
            0,
        ]
    }
]