import React from "react";
import PropTypes from "prop-types";
import {Route} from "react-router-dom";
import {
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBRow,
    MDBCol,
    ToastContainer,
    MDBIcon,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBBtn,
    MDBBtnGroup,
    MDBCardImage,
    MDBCardText, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTabPane, MDBRotatingCard,
} from "mdbreact";
import moment from "moment";
import "./home.css";
import FindOrders from "../Security/OrderService/orderService";
import NoteService from "../Security/NoteService/noteService";
import ReactTooltip from "react-tooltip";
import SalesPipelineView from "../Orders/salesPipelineView";
import MonthGlanceView from "../../Components/Pipeline/MonthGlance";
import ColumnPipeline from "../../Components/Pipeline/ColumnPipeline";
import DeliveryAverageDays from "../../Components/Deliveries/DeliveryAverageDays";
import OrderService from "../Security/OrderService/orderService";
import PurchaseQueueTable from "../PurchaseQueue/purchaseQueueTable";
import TaskTable from "../Home/taskTable";
import RQTable from '../Home/rqTable';
import PCRNotificationsTable from '../Home/pcrNotificationsTable'
import InventoryReqeustTable from "../PurchaseQueue/inventoryRequestTable";

class Kiosk extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveOrders();
        this.getTaskInformation();
        // this.getTasksAssignedOut();
        this.newSearch()

        this.state = {
            orders: [],
            tasks: [],
            newOrders: 0,
            processOrders: 0,
            deliveryOrders: 0,
            setUpOrders: 0,
            newOrdersPoints: 0,
            processOrdersPoints: 0,
            deliveryOrdersPoints: 0,
            setUpOrdersPoints: 0,
            priority: [],
            isLoaded: false,
            tasksAssignedOut: [],
            productionMonthlyToggle: false,
            currentView: "standardView",
            pipelineResults: [],
            stateTotals: {},
            activeItemJustified: "1",
            tasksLoaded: false,
            flippedNew: false,
            flippedInProcess: false,
            flippedSetup: false,
            flippedReadyToDeliver: false,
        };

        alert("Please be aware of the following changes:\n1. Documents can no longer be uploaded in this version. If you need to add attachments, please go to https://salespilot.com \n2. Documents uploaded in this version can be downloaded FOR NOW.\n3. This version is nearing its end of life: Monday May 29, 2023. Any requests to this page will be redirected to https://salespilot.com \nIf you are having issues logging in to salespilot.com, please enter a helpdesk ticket so that we can assist.")
    }


    static contextTypes = {
        currentUser: PropTypes.object,
        allRegions: PropTypes.array
    };

    toggleJustified = (tab) => () => {
        if (this.state.activeItemJustified !== tab) {
            this.setState({
                activeItemJustified: tab,
            });
        }
    };

    formatDate(date) {
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
        }).format(date);
    }

    formatStatus(order) {
        if (order.status === "ReadyToDeliver") {
            return "Waiting for Delivery";
        } else if (order.status === "InProcess") {
            if (
                order.orderStatusReason &&
                order.orderStatusReason.name === "Verification"
            ) {
                return "Intake / Insurance Verification";
            } else {
                return "Insurance Review";
            }
        } else {
            return order.status;
        }
    }

    retrieveOrders() {
        let end = new Date(),
            start = new Date(),
            filters = {};

        start.setDate(end.getDate() - 14);

        filters.statuses = ["New", "InProcess", "ReadyToDeliver", "Setup"];
        filters.startDate = start;
        filters.endDate = end;

        FindOrders.getDashboardCounts()
            .then((orders) => {
                this.setState({
                    orders: orders,
                    isLoaded: true,
                });
                this.getOrderCount();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getTasksForUser() {
        return NoteService.getOpenActivityNotesByOwner()
            .then(tasks => {
                tasks.forEach(t => {
                    t.type = "ActivityNote"
                });
                return Promise.resolve(tasks)
            })
            .catch((err) => {

                console.log(err);
                return Promise.resolve([])
            });
    }

    getTasksForAccount() {
        return NoteService.getOpenAccountNotesByOwner()
            .then(tasks => {
                tasks.forEach(t => {
                    t.type = "AccountNote"
                });
                return Promise.resolve(tasks)
            })
            .catch((err) => {
                console.log(err);
                return Promise.resolve([])
            });
    }

    getTaskInformation() {
        Promise.all([this.getTasksForUser(), this.getTasksForAccount(), this.getTasksAssignedOut()])
            .then(d => {
                this.concatTasks(d)

            })
    }

    concatTasks(data) {
        // let sorted = data[1].sort((a, b) => {
        //     if (a.dueDate && b.dueDate) {
        //         return new Date(a.dueDate) > new Date(b.dueDate) ? 1 : -1
        //     } else {
        //         return a ? 1 : -1
        //     }
        // });
        let combo = data[0].concat(data[1], data[2]);
        this.setState({
            tasks: combo,
            tasksLoaded: true,
        });
    }

    getTasksAssignedOut() {
        return NoteService.getOpenActivityNotesAssignedOut()
            .then((tasks) => {
                tasks.forEach(t => {
                    t.type = "ActivityNote"
                });
                return Promise.resolve(tasks)
            })
            .catch((err) => {
                console.log(err);
                return Promise.resolve([])
            });
    }

    updateTask(note) {
        const {currentUser} = this.context;

        let tasks = this.state.tasks;
        note.completed = true;
        note.type = "ActivityNote";

        let name = currentUser ? currentUser.username : "user";

        note.text +=
            " --> Completed by " + name + " on " + this.formatDate(new Date());

        return NoteService.updateActivityNote(note)
            .then((res) => {
                this.setState({
                    tasks: tasks,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getOrderCount() {
        let newOrders = 0,
            processOrders = 0,
            deliveryOrders = 0,
            setUpOrders = 0,
            newOrdersPoints = 0,
            processOrdersPoints = 0,
            deliveryOrdersPoints = 0,
            setUpOrdersPoints = 0;

        this.state.orders.forEach((order) => {
            if (order.status === "New") {
                newOrders += order.orderCount;
                newOrdersPoints += parseFloat(order.totalPoints);

            } else if (order.status === "InProcess") {
                processOrders += order.orderCount;
                processOrdersPoints += parseFloat(order.totalPoints);

            } else if (order.status === "Ready to Deliver") {
                deliveryOrders += order.orderCount;
                deliveryOrdersPoints += parseFloat(order.totalPoints);

            } else if (order.status === "Setup") {
                setUpOrders += order.orderCount;
                setUpOrdersPoints += parseFloat(order.totalPoints);

            }

        });

        this.setState({
            newOrders: newOrders,
            processOrders: processOrders,
            deliveryOrders: deliveryOrders,
            setUpOrders: setUpOrders,
            newOrdersPoints: newOrdersPoints.toFixed(2),
            processOrdersPoints: processOrdersPoints.toFixed(2),
            deliveryOrdersPoints: deliveryOrdersPoints.toFixed(2),
            setUpOrdersPoints: setUpOrdersPoints.toFixed(2),
        });
    }

    generateTask(note, assignedOut) {
        let bgColor = "#FFE4F1",
            headerColor = "#FFCCE5",
            t = this;

        if (assignedOut) {
            bgColor = "#E4F9E0";
            headerColor = "#CBF1C4";
        }

        if (note.isAccountTask) {
            bgColor = "#E2F1FF";
            headerColor = "#CDE9FF";
        }

        if (note.dueDate) {
            if (new Date(note.dueDate).getDate() === new Date().getDate()) {
                bgColor = "#FFD175";
                headerColor = "#FFBE3D";
            } else if (new Date(note.dueDate).getDate() < new Date().getDate()) {
                bgColor = "#FFA6A3";
                headerColor = "#FF8C7C";
            }
        }

        function routeToOrder(note) {
            if (note.activity && note.activity.id) {
                t.props.history.push({
                    pathname: "/order/" + note.activity.id,
                });
            }
        }

        function routeToAccount(note) {
            if (note.account && note.account.id) {
                t.props.history.push({
                    pathname: "/account/" + note.account.id + "/detail",
                });
            }
        }

        function renderAssignedTo() {
            if (note.assignedTo) {
                return (
                    <span style={{fontSize: 14}}>
						{" "}
                        <MDBIcon
                            icon="long-arrow-alt-right"
                            data-tip={"Assigned To"}
                        />{" "}
                        {note.assignedTo.name}
					</span>
                );
            }
        }

        function renderAccountPatient(note) {
            return (
                <MDBRow style={{fontSize: 13}}>
                    <MDBCol
                        size="4"
                        onClick={() => {
                            routeToAccount(note);
                        }}
                    >
                        {note.account ? note.account.name : "No Account"}
                    </MDBCol>

                    <MDBCol
                        size="4"
                        onClick={() => {
                            routeToOrder(note);
                        }}
                    >
                        {note.patientName || ""}
                    </MDBCol>

                    <MDBCol
                        size="4"
                    >
                        {note.dueDate ? new Date(note.dueDate).toLocaleString() : ""}
                    </MDBCol>
                </MDBRow>
            );
        }

        return (
            <MDBCard style={{backgroundColor: bgColor, margin: 10}}>
                <MDBCardHeader
                    style={{backgroundColor: headerColor, cursor: "pointer"}}
                >
                    <MDBRow>
                        <MDBCol size="5" style={{fontSize: 14}}>
                            {this.formatDate(note.createdAt)}
                        </MDBCol>

                        <MDBCol size="5" style={{fontSize: 14}}>
                            {note.createdBy ? note.createdBy.name : ""}
                            {renderAssignedTo()}
                        </MDBCol>

                        <MDBCol size="2">
                            <MDBIcon
                                style={{
                                    color: "#7B626E",
                                    float: "right",
                                    padding: "4px 8px 4px 4px",
                                }}
                                onClick={this.updateTask.bind(this, note)}
                                data-tip={"Complete"}
                                icon="check"
                            />
                        </MDBCol>
                    </MDBRow>

                    {renderAccountPatient(note)}
                </MDBCardHeader>

                <MDBCardBody>{note.text}</MDBCardBody>
                <ReactTooltip/>
            </MDBCard>
        );
    }

    renderRouteButton(order) {
        return (
            <Route
                render={({history}) => (
                    <div style={{float: "right", fontSize: "16px", color: "#fff"}}>
                        <h4
                            className="fa fa-external-link"
                            aria-hidden="true"
                            onClick={() => {
                                history.push({
                                    pathname: "/order/" + order.id,
                                    state: {order: order},
                                });
                            }}
                        ></h4>
                    </div>
                )}
            />
        );
    }

    renderTaskList() {
        let tasks = this.state.tasks;
        return (
            <div>
                {tasks.map((task, idx) => {
                    return this.generateTask(task, idx, false);
                })}
            </div>
        );
    }

    renderTaskAssignedOutList() {
        let tasks = this.state.tasksAssignedOut;
        return (
            <div>
                {tasks.map((task, idx) => {
                    return this.generateTask(task, idx, true);
                })}
            </div>
        );
    }

    renderReasons(statusName, start, end, points) {
        let orders = this.state.orders;
        return (
            <div className={"listOfReasons"} style={{textAlign: "right"}}>
                {orders.map((order) => {
                    if (order.status === statusName) {
                        return this.renderReasonLink(order, start, end, points);
                    }
                    return null;
                })}
            </div>
        );
    }

    renderReasonLink(order, start, end, points) {
        let displayNumber = order.orderCount;
        if(points){
             displayNumber = order.totalPoints;
        }
        if (order.description !== "Blank Reason") {
            return (
                <Route
                    key={order.id}
                    render={({history}) => (
                        <div
                            className={"reasonHostLink listOfReasons"}
                            onClick={(e) => {
                                e.stopPropagation();

                                history.push({
                                    pathname: "/order",
                                    state: {
                                        shouldOverride: true,
                                        filters: {
                                            statuses: [
                                                order.status === "Ready to Deliver"
                                                    ? "ReadyToDeliver"
                                                    : order.status,
                                            ],
                                            reasonIds: [order.id],
                                            startDate: start.toLocaleDateString(),
                                            endDate: end.toLocaleDateString(),
                                            shouldOverride: true,
                                        },
                                    },
                                });
                            }}
                        >
							<span className={"dbStatusCardsText"}>
								<b>
									{order.description}: &emsp;{displayNumber}
								</b>
							</span>
                        </div>
                    )}
                />
            );
        } else {
            return (
                <div key={order.id}>
					<span className={"dbStatusCardsText"}>
						<b>
							{order.description}: &emsp;{displayNumber}
						</b>
					</span>
                </div>
            );
        }
    }

    renderStatusCards() {
        let end = new Date(),
            start = new Date();

        start.setDate(end.getDate() - 14);

        return (
            <div>
                <MDBRow style={{padding: 10, marginLeft: 30, marginTop: 60, marginRight: 30}}>

                        <MDBCol style={{marginRight: 10, marginLeft: 10}}>
                            <Route
                                render={({history}) => (
                                    <MDBCard narrow
                                             className={"statusCard"}>
                                        <MDBRotatingCard flipped={this.state.flippedNew}>

                                            <MDBCardBody cascade className='face front text-center statusCardBody statusCard'
                                                         onClick={() => {
                                                             history.push({
                                                                 pathname: "/order",
                                                                 state: {
                                                                     shouldOverride: true,
                                                                     filters: {
                                                                         statuses: ["New"],
                                                                         startDate: start,
                                                                         endDate: end,
                                                                     },
                                                                 },
                                                             });
                                                         }}>
                                                {this.renderReasons("New", start, end)}
                                            </MDBCardBody>
                                            <MDBCardBody cascade className='face back text-center statusCardBody statusCard'
                                                         onClick={() => {
                                                             history.push({
                                                                 pathname: "/order",
                                                                 state: {
                                                                     shouldOverride: true,
                                                                     filters: {
                                                                         statuses: ["New"],
                                                                         startDate: start,
                                                                         endDate: end,
                                                                     },
                                                                 },
                                                             });
                                                         }}>
                                                {this.renderReasons("New", start, end,true)}
                                            </MDBCardBody>
                                        </MDBRotatingCard>

                                        <MDBRotatingCard flipped={this.state.flippedNew} style={{
                                            width: "160px",
                                            height: "120px",
                                            left: "-12px",
                                            top: "-12px"
                                        }}>
                                            <MDBCard className="face front deliveryCards"
                                                     style={{left: "-12px", top: "-50px"}}
                                                     onClick={() => {
                                                         this.setState({
                                                             flippedNew: true,
                                                         })
                                                     }}>
                                                <MDBCardBody>
                                                    <h2 className='orderCountCard'>{this.state.newOrders}</h2>
                                                    <p style={{margin : 0}}>New
                                                    </p>
                                                    <div style={{float:"right", position: "relative", left: "15px"}}>
                                                    <MDBIcon
                                                        icon="exchange-alt"
                                                    />
                                                    </div>
                                                    </MDBCardBody>
                                            </MDBCard>
                                            <MDBCard className="face back deliveryCards"
                                                     style={{left: "12px", top: "-50px"}}
                                                     onClick={() => {
                                                         this.setState({
                                                             flippedNew: false,
                                                         })
                                                     }}>
                                                <MDBCardBody>
                                                    <h2 className='orderCountCard'>{this.state.newOrdersPoints}</h2>
                                                    <p style={{margin : 0}}>New Points</p>
                                                    <div style={{float:"right", position: "relative", left: "15px"}}>
                                                        <MDBIcon
                                                            icon="exchange-alt"
                                                        />
                                                    </div>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBRotatingCard>
                                    </MDBCard>

                                )}
                            />
                        </MDBCol>

                    {/*In Process*/}

                    <MDBCol style={{marginRight: 10, marginLeft: 10}}>
                        <Route
                            render={({history}) => (
                                <MDBCard narrow
                                         className={"statusCard"}>
                                    <MDBRotatingCard flipped={this.state.flippedInProcess}>

                                        <MDBCardBody cascade className='face front text-center statusCardBody statusCard'
                                                     onClick={() => {
                                                         history.push({
                                                             pathname: "/order",
                                                             state: {
                                                                 shouldOverride: true,
                                                                 filters: {
                                                                     statuses: ["InProcess"],
                                                                     startDate: start,
                                                                     endDate: end,
                                                                 },
                                                             },
                                                         });
                                                     }}>
                                            {this.renderReasons("InProcess", start, end)}
                                        </MDBCardBody>
                                        <MDBCardBody cascade className='face back text-center statusCardBody statusCard'
                                                     onClick={() => {
                                                         history.push({
                                                             pathname: "/order",
                                                             state: {
                                                                 shouldOverride: true,
                                                                 filters: {
                                                                     statuses: ["InProcess"],
                                                                     startDate: start,
                                                                     endDate: end,
                                                                 },
                                                             },
                                                         });
                                                     }}>
                                            {this.renderReasons("InProcess", start, end,true)}
                                        </MDBCardBody>
                                    </MDBRotatingCard>

                                    <MDBRotatingCard flipped={this.state.flippedInProcess} style={{
                                        width: "160px",
                                        height: "120px",
                                        left: "-12px",
                                        top: "-12px"
                                    }}>
                                        <MDBCard className="face front deliveryCards"
                                                 style={{left: "-12px", top: "-50px"}}
                                                 onClick={() => {
                                                     this.setState({
                                                         flippedInProcess: true,
                                                     })
                                                 }}>
                                            <MDBCardBody>
                                                <h2 className='orderCountCard'>{this.state.processOrders}</h2>
                                                <p style={{margin: 0}}>In Process</p>
                                                <div style={{float:"right", position: "relative", left: "15px"}}>
                                                    <MDBIcon
                                                        icon="exchange-alt"
                                                    />
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                        <MDBCard className="face back deliveryCards"
                                                 style={{left: "12px", top: "-50px"}}
                                                 onClick={() => {
                                                     this.setState({
                                                         flippedInProcess: false,
                                                     })
                                                 }}>
                                            <MDBCardBody>
                                                <h2 className='orderCountCard'>{this.state.processOrdersPoints}</h2>
                                                <p>In Process Points</p>
                                                <div  className={"titleCardLong"}>
                                                    <MDBIcon
                                                        icon="exchange-alt"
                                                    />
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBRotatingCard>
                                </MDBCard>

                            )}
                        />
                    </MDBCol>

                    {/*Ready To Deliver*/}

                    <MDBCol style={{marginRight: 10, marginLeft: 10}}>
                        <Route
                            render={({history}) => (
                                <MDBCard narrow
                                         className={"statusCard"}>
                                    <MDBRotatingCard flipped={this.state.flippedReadyToDeliver}>

                                        <MDBCardBody cascade className='face front text-center statusCardBody statusCard'
                                                     onClick={() => {
                                                         history.push({
                                                             pathname: "/order",
                                                             state: {
                                                                 shouldOverride: true,
                                                                 filters: {
                                                                     statuses: ["ReadyToDeliver"],
                                                                     startDate: start,
                                                                     endDate: end,
                                                                 },
                                                             },
                                                         });
                                                     }}>
                                            {this.renderReasons("Ready to Deliver", start, end)}
                                        </MDBCardBody>
                                        <MDBCardBody cascade className='face back text-center statusCardBody statusCard'
                                                     onClick={() => {
                                                         history.push({
                                                             pathname: "/order",
                                                             state: {
                                                                 shouldOverride: true,
                                                                 filters: {
                                                                     statuses: ["Ready to Deliver"],
                                                                     startDate: start,
                                                                     endDate: end,
                                                                 },
                                                             },
                                                         });
                                                     }}>
                                            {this.renderReasons("Ready to Deliver", start, end,true)}
                                        </MDBCardBody>
                                    </MDBRotatingCard>

                                    <MDBRotatingCard flipped={this.state.flippedReadyToDeliver} style={{
                                        width: "160px",
                                        height: "120px",
                                        left: "-12px",
                                        top: "-12px"
                                    }}>
                                        <MDBCard className="face front deliveryCards"
                                                 style={{left: "-12px", top: "-50px"}}
                                                 onClick={() => {
                                                     this.setState({
                                                         flippedReadyToDeliver: true,
                                                     })
                                                 }}>
                                            <MDBCardBody>
                                                <h2 className='orderCountCard'>{this.state.deliveryOrders}</h2>

                                                <p>Ready To Deliver</p>

                                                <div  className={"titleCardLong"}>
                                                    <MDBIcon
                                                        icon="exchange-alt"
                                                    />
                                                </div>


                                            </MDBCardBody>
                                        </MDBCard>
                                        <MDBCard className="face back deliveryCards"
                                                 style={{left: "12px", top: "-50px"}}
                                                 onClick={() => {
                                                     this.setState({
                                                         flippedReadyToDeliver: false,
                                                     })
                                                 }}>
                                            <MDBCardBody>
                                                <h2 className='orderCountCard'>{this.state.deliveryOrdersPoints}</h2>
                                                <p>Ready To Deliver Points</p>
                                                <div  className={"titleCardLong"}>
                                                    <MDBIcon
                                                        icon="exchange-alt"
                                                    />
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBRotatingCard>
                                </MDBCard>

                            )}
                        />
                    </MDBCol>

                    {/*Setup*/}

                    <MDBCol style={{marginRight: 10, marginLeft: 10}}>
                        <Route
                            render={({history}) => (
                                <MDBCard narrow
                                         className={"statusCard"}>
                                    <MDBRotatingCard flipped={this.state.flippedSetup}>

                                        <MDBCardBody cascade className='face front text-center statusCardBody statusCard'
                                                     onClick={() => {
                                                         history.push({
                                                             pathname: "/order",
                                                             state: {
                                                                 shouldOverride: true,
                                                                 filters: {
                                                                     statuses: ["Setup"],
                                                                     startDate: start,
                                                                     endDate: end,
                                                                 },
                                                             },
                                                         });
                                                     }}>
                                            {this.renderReasons("Setup", start, end)}
                                        </MDBCardBody>
                                        <MDBCardBody cascade className='face back text-center statusCardBody statusCard'
                                                     onClick={() => {
                                                         history.push({
                                                             pathname: "/order",
                                                             state: {
                                                                 shouldOverride: true,
                                                                 filters: {
                                                                     statuses: ["Setup"],
                                                                     startDate: start,
                                                                     endDate: end,
                                                                 },
                                                             },
                                                         });
                                                     }}>
                                            {this.renderReasons("Setup", start, end,true)}
                                        </MDBCardBody>
                                    </MDBRotatingCard>

                                    <MDBRotatingCard flipped={this.state.flippedSetup} style={{
                                        width: "160px",
                                        height: "120px",
                                        left: "-12px",
                                        top: "-12px"
                                    }}>
                                        <MDBCard className="face front deliveryCards"
                                                 style={{left: "-12px", top: "-50px"}}
                                                 onClick={() => {
                                                     this.setState({
                                                         flippedSetup: true,
                                                     })
                                                 }}>
                                            <MDBCardBody>
                                                <h2 className='orderCountCard'>{this.state.setUpOrders}</h2>
                                                <p style={{margin:0}}>Setup</p>
                                                <div style={{float:"right", position: "relative", left: "15px"}}>
                                                    <MDBIcon
                                                        icon="exchange-alt"
                                                    />
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                        <MDBCard className="face back deliveryCards"
                                                 style={{left: "12px", top: "-50px"}}
                                                 onClick={() => {
                                                     this.setState({
                                                         flippedSetup: false,
                                                     })
                                                 }}>
                                            <MDBCardBody>
                                                <h2 className='orderCountCard'>{this.state.setUpOrdersPoints}</h2>
                                                <p style={{margin:0}}>Setup Points</p>
                                                <div style={{float:"right", position: "relative", left: "15px"}}>
                                                    <MDBIcon
                                                        icon="exchange-alt"
                                                    />
                                                </div>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBRotatingCard>
                                </MDBCard>

                            )}
                        />
                    </MDBCol>
                </MDBRow>

            </div>
        );
    }

    getTasksDueToday() {
        let today = new Date().setHours(0, 0, 0, 0);
        let due = this.getAllTasks().filter((task) => {
            return new Date(task.dueDate).getTime() === today
        });
        if (due.length === 0) {
            return [{noTask: true}]
        }
        return due;

    }

    getTasksOverDue() {
        let today = new Date().setHours(0, 0, 0, 0);
        let due = this.getAllTasks().filter((task) => {
            return new Date(task.dueDate).getTime() > today
        });
        if (due.length === 0) {
            return [{noTask: true}]
        }
        return due;
    }

    renderTaskLists() {
        let st = this.state;
        let props = {
            tasks: this.getAllTasks(),
            loaded: this.state.tasksLoaded,
        }
        return (
            <MDBRow>
                <MDBCol size="12" style={{paddingLeft: 30, paddingRight: 30}}>
                    <h5 className={"dashboardText"} style={{paddingLeft: 8}}>
                        Tasks
                    </h5>
                    <hr className={"dashboardLine"}/>

                    <MDBNav tabs className="nav-justified" color="primary">
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={st.activeItemJustified === "1"}
                                onClick={this.toggleJustified("1")}
                                role="tab"
                            >
                                All Tasks
                            </MDBNavLink>
                        </MDBNavItem>
                        {/*<MDBNavItem>*/}
                        {/*    <MDBNavLink*/}
                        {/*        link*/}
                        {/*        to="#"*/}
                        {/*        active={st.activeItemJustified === "2"}*/}
                        {/*        onClick={this.toggleJustified("2")}*/}
                        {/*        role="tab"*/}
                        {/*    >*/}
                        {/*        Due Today*/}
                        {/*    </MDBNavLink>*/}
                        {/*</MDBNavItem>*/}
                        {/*<MDBNavItem>*/}
                        {/*    <MDBNavLink*/}
                        {/*        link*/}
                        {/*        to="#"*/}
                        {/*        active={st.activeItemJustified === "3"}*/}
                        {/*        onClick={this.toggleJustified("3")}*/}
                        {/*        role="tab"*/}
                        {/*    >*/}
                        {/*        Overdue*/}
                        {/*    </MDBNavLink>*/}
                        {/*</MDBNavItem>*/}
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={st.activeItemJustified === "4"}
                                onClick={this.toggleJustified("4")}
                                role="tab"
                            >
                                Reverse Quality
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={st.activeItemJustified === "5"}
                                onClick={this.toggleJustified("5")}
                                role="tab"
                            >
                                Sales Notifications
                            </MDBNavLink>
                        </MDBNavItem>
                    </MDBNav>


                    <MDBTabContent
                        className="card"
                        style={{paddingTop: "1rem"}}
                        activeItem={st.activeItemJustified}
                    >
                        <MDBTabPane tabId="1" role="tabpanel">
                            {st.activeItemJustified === "1" ? (
                                <TaskTable {...props}/>
                            ) : (
                                <div/>
                            )}
                        </MDBTabPane>
                        <MDBTabPane tabId="2" role="tabpanel">
                            {st.activeItemJustified === "2" ? (
                                <TaskTable tasks={this.getTasksDueToday()}/>
                            ) : (
                                <div/>
                            )}
                        </MDBTabPane>
                        <MDBTabPane tabId="3" role="tabpanel">
                            {st.activeItemJustified === "3" ? (
                                <TaskTable tasks={this.getTasksOverDue()}/>
                            ) : (
                                <div/>
                            )}
                        </MDBTabPane>
                        <MDBTabPane tabId="4" role="tabpanel">
                            {st.activeItemJustified === "4" ? (
                                <RQTable/>
                            ) : (
                                <div/>
                            )}
                        </MDBTabPane>
                        <MDBTabPane tabId="5" role="tabpanel">
                            {st.activeItemJustified === "5" ? (
                                <PCRNotificationsTable/>
                            ) : (
                                <div/>
                            )}
                        </MDBTabPane>
                    </MDBTabContent>


                </MDBCol>


                {/*<MDBCol size="6" style={{paddingLeft: 30, paddingRight: 30}}>*/}
                {/*    <h5 className={"dashboardText"} style={{paddingLeft: 8}}>*/}
                {/*        Open Tasks*/}
                {/*    </h5>*/}
                {/*    <hr className={"dashboardLine"}/>*/}
                {/*    <div style={{maxHeight: "45vh", overflowY: "auto"}}>*/}
                {/*        {this.renderTaskList()}*/}
                {/*    </div>*/}
                {/*</MDBCol>*/}

                {/*<MDBCol size="6" style={{paddingLeft: 30, paddingRight: 30}}>*/}
                {/*    <h5 className={"dashboardText"}>Tasks You've Assigned</h5>*/}
                {/*    <hr className={"dashboardLine"}/>*/}
                {/*    <div style={{maxHeight: "45vh", overflowY: "auto"}}>*/}
                {/*        {this.renderTaskAssignedOutList()}*/}
                {/*    </div>*/}
                {/*</MDBCol>*/}
            </MDBRow>
        )
    }

    getAllTasks() {
        let arr = this.state.tasks.concat(this.state.tasksAssignedOut);
        if (arr.length === 0) {
            return [{noTask: true}]
        }
        return arr
    }

    renderBasicView() {
        return (
            <div>
                <div>{this.renderStatusCards()}</div>
                {this.renderTaskLists()}
            </div>
        )
    }

    renderAdminView() {
        return (

            <div>

                <MDBRow style={{justifyContent: 'center', marginTop: 12, paddingBottom: 0}}>


                    <button type="button" data-tip={"Home"} className="btn dashButton btn-sm"
                            onClick={() => this.setState({currentView: "standardView"})}>
                        Home
                    </button>
                    {/*<button type="button" data-tip={"Sales Pipeline Grid"} className="btn dashButton btn-sm"*/}
                    {/*        onClick={() => this.setState({currentView: "salesPipeline"})}>*/}
                    {/*    Pipeline Grid*/}
                    {/*</button>*/}
                    <button type="button" data-tip={"Sales Pipeline Chart"} className="btn  btn-sm dashButton"
                            onClick={() => this.setState({currentView: "pipelineGraph"})}>
                        Pipeline Chart
                    </button>
                    <button type="button" data-tip={"Delivery Averages"} className="btn dashButton btn-sm"
                            onClick={() => this.setState({currentView: "deliveryAverages"})}>
                        Delivery Averages
                    </button>
                    <button type="button" data-tip={"Month at a Glance"} className="btn dashButton btn-sm"
                            onClick={() => this.setState({currentView: "monthGlance"})}>
                        Month at a Glance
                    </button>
                </MDBRow>
            </div>
        )
    }


    renderStandardView() {
        if (this.state.currentView === "standardView") {
            return (
                this.renderBasicView()
            )
        }
    }


    newSearch(region) {
        this.setState({
            isLoaded: false,
        });
        let filters = {
            endDate: new Date().toLocaleDateString(),
            startDate: new Date(new Date().setDate(-14)).toLocaleDateString(),
            locationType: "Sales",
            statuses: ["New", "InProcess", "ReadyToDeliver", "Setup"]
        };

        if (region) {
            filters.regionId = region
        }

        return OrderService.getAllOrdersNative(filters)
            .then((res) => {
                this.formatPipelineResults(res);
                //this.setState({pipelineResults: res})
            })
            .catch((err) => {
                this.setState({isLoaded: true});
            });
    }

    formatPipelineResults(res) {
        let bigAry = [];

        res.forEach(order => {
            let push = false;

            if (!order.salesLocation) {
                return;
            }

            if (order.reason === "Contract Services") {
                return;
            }

            let v = bigAry.find(vals => {
                return vals["Sales Location"] === order.salesLocation;
            })

            if (v) {
                if (order.status === "Setup") {
                    if (order.setupDate) {
                        let sd = new Date(order.setupDate);

                        if (sd.getMonth() === new Date().getMonth()) {
                            push = true;
                        }
                    }
                } else {
                    push = true;
                }

                if (push === true) {
                    v.TotalPoints += order.totalPoints || 0;
                    v[order.status] += order.totalPoints || 0;
                    v[order.status] = parseFloat(v[order.status].toFixed(2));

                    searchForRep(order, v[order.status + "Data"]);
                }

            } else {
                pushInitialObject(order);
            }

        })


        function searchForRep(order, aryToSearch) {
            let rv = aryToSearch.find(repVal => {
                return repVal.salesRep === order.salesRep;
            })

            if (rv) {
                rv.points += order.totalPoints || 0;
                rv.points = Number(rv.points.toFixed(2));
            } else {
                aryToSearch.push({
                    salesRep: order.salesRep,
                    points: order.totalPoints || 0
                })
            }

        }

        function pushInitialObject(order) {
            let push = false;

            let outsideData = {
                "Sales Location": order.salesLocation,
                "TotalPoints": 0,
                "New": 0,
                "NewData": [],
                "InProcess": 0,
                "InProcessData": [],
                "Ready to Deliver": 0,
                "Ready to DeliverData": [],
                "Setup": 0,
                "SetupData": [],
            }

            if (order.status === "Setup") {
                if (order.setupDate) {
                    let sd = new Date(order.setupDate);

                    if (sd.getMonth() === new Date().getMonth()) {
                        push = true;
                    }
                }
            } else {
                push = true;
            }

            if (push === true) {
                outsideData.TotalPoints += order.totalPoints || 0;
                outsideData[order.status] += order.totalPoints || 0;
                outsideData[order.status] = parseFloat(outsideData[order.status].toFixed(2));

                outsideData[order.status + "Data"].push({
                    salesRep: order.salesRep,
                    points: order.totalPoints
                })
            }


            bigAry.push(outsideData);
        }

        bigAry = bigAry.filter(a => {
            return a.TotalPoints > 1;
        })

        let nAry = [],
            median = 0,
            totals = {newTotal: 0, processTotal: 0, rtdTotal: 0, setupTotal: 0}

        bigAry.forEach(v => {
            if (v.New > 0) {
                nAry.push(v.New);

                totals.newTotal += v.New;
                totals.processTotal += v.InProcess;
                totals.rtdTotal += v["Ready to Deliver"];
                totals.setupTotal += v.Setup;
            }
        })

        for (let p in totals) {
            totals[p] = totals[p].toFixed(2);
        }

        nAry.sort((a, b) => {
            return a > b ? -1 : 1;
        })

        median = nAry[Math.round(nAry.length / 2)]

        bigAry.sort((a, b) => {
            return a.TotalPoints > b.TotalPoints ? -1 : 1;
        })

        this.setState({
            pipelineResults: bigAry,
            pipelineMedian: median,
            stateTotals: totals
        })
    }

    sortByRegion(region) {
        this.newSearch(region)
    }

    renderSalesPipeline() {
        if (this.state.currentView === "salesPipeline") {
            return (
                <SalesPipelineView/>
            )
        }
    }

    renderMonthGlance(){
        if (this.state.currentView === "monthGlance") {
            return (
                <MonthGlanceView/>
            )
        }
    }

    renderPipelineGraph() {
        if (this.state.currentView === "pipelineGraph") {

            return (
                <div style={{backgroundColor: '#FFF', padding: 25, margin: 25, paddingBottom: 50, marginTop: 20}}>
                    <ColumnPipeline props={{
                        sortByRegion: this.sortByRegion.bind(this),
                        pro: this.state.pipelineResults,
                        median: this.state.pipelineMedian,
                        totals: this.state.stateTotals
                    }}/>
                </div>
            )
        }
    }

    renderDeliveryAverages() {
        const {allRegions} = this.context;

        let ary = [];

        if (allRegions.length > 0) {
            allRegions.forEach((region) => {
                ary.push({
                    label: region.name,
                    value: region.id,
                });
            });
        }


        if (this.state.currentView === "deliveryAverages") {
            return (
                <DeliveryAverageDays regions={ary}/>
            )
        }
    }

    render() {
        return (
            <div style={{overflowY: "hidden", overflowX: "hidden", minHeight: 500}}>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />
                {this.renderAdminView()}
                {this.renderStandardView()}
                {this.renderSalesPipeline()}
                {this.renderMonthGlance()}
                {this.renderPipelineGraph()}
                {this.renderDeliveryAverages()}
            </div>
        );
    }
}

export default Kiosk;
