import api from "../api";

class ActivityService {
	async getAllMyActivities(filters) {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 150,
				orders_only: false,
				activities_only: true,
				q: filters.q || "",
				users: filters.users || [],
			};

			if (filters.startDate) {
				params.startDate = filters.startDate;
			}

			if (filters.endDate) {
				params.endDate = filters.endDate;
			}

			api
				.get("/activitiesReact", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	// calls proc spGetActivities
	async getActivityListing(filters) {
		return new Promise((res, reject) => {
			api
				.get("/activitiesNative", filters)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getActivity(id) {
		return new Promise((res, reject) => {
			api
				.get("/activities/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getCreditCardTypes() {
		return new Promise((res, reject) => {
			api
				.get("/creditCardTypes")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getExpenseTypes() {
		return new Promise((res, reject) => {
			api
				.get("/expenseTypes")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getAllContacts() {
		return new Promise((res, reject) => {
			api
				.get("/contacts")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getByPolicyNumber(number) {
		return new Promise((res, reject) => {
			api
				.get("/findByPolicyNumber", {policyNumber: number})
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateActivity(activity) {
		return new Promise((res, reject) => {
			api
				.put("/activities/" + activity.id, activity)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createActivity(activity) {
		return api.post("/activities", activity);
	}

	async getActivitiesByAccount(id) {
		return new Promise((res, reject) => {
			let params = {
					page: 0,
					size: 100,
					orders_only: false,
					activities_only: true,
				},
				url = "/accounts/" + id + "/activities";

			return api
				.get(url, params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const activityService = new ActivityService();

export default activityService;
