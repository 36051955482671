import React from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Spinner,
    CardHeader,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
    toast, MDBContainer, MDBRow,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import  RegionService from "./regionServices";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

export default class regions extends React.Component {
    constructor(props) {
        super(props);

        const data = {
            columns: [
                {
                    label: "ID",
                    field: "id",
                    sort: "asc",
                },
                {
                    label: "Name",
                    field: "name",
                    sort: "asc",
                },
                {
                    label: "Sales Director",
                    field: "salesDirector",
                    sort: "asc",
                },
                {
                    label: "Operations Director",
                    field: "operationsDirector",
                    sort: "asc",
                },
                {
                    label: "Edit",
                    field: "edit",
                    sort: "asc",
                },
                {
                    label: "Delete",
                    field: "delete",
                    sort: "asc",
                },
            ],
            rows: [],
        };

        this.state = {
            data: data,
            isLoaded: false,
            editModal: false,
            confirmationModal: false,
            addNewModal: false,
            name: "",
            currIndex: -1,
            errorMessage: "",
            newRegion:{
                name:"",
            },
            currRegion:{},
            newContact:{
                name:"",
            },
            loadedUsers: false,
            currContact: {},
            salesDirectors: [],
            allSystemUsers: [],
            operationsDirectors: [],}
        ;
    }
    static contextTypes = {
        allActiveUsers: PropTypes.array,
    };

    // Complaining that component wasn't mounted so I couldn't set States therefore had to use this
    componentDidMount() {
        this.renderAllUsers();
        this.retrieveRegions();

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { allActiveUsers } = this.context;
        if (allActiveUsers.length !== 0 && this.state.loadedUsers === false ) {
            this.renderAllUsers();
            this.setState({
                loadedUsers: true
            })
        }
    }

    renderAllUsers() {
        const { allActiveUsers } = this.context;
        let users = [];
        //filter(region => region.value === regionId);
        let allSalesManagers = allActiveUsers,//.filter(user => user.role === "SALES_MANAGER"),
            allAreaManagers = allActiveUsers;//.filter(user => user.role === "AREA_MANAGER");

        let arrSales = [];
        let arrOps = [];

        allAreaManagers.forEach((user) => {
            arrOps.push({
                label: user.firstname + " " + user.lastname,
                value: parseInt(user.userId),
            });
        });

        allSalesManagers.forEach((user) => {
            arrSales.push({
                label: user.firstname + " " + user.lastname,
                value: parseInt(user.userId),
            });
        });



        allActiveUsers.map((user) => {
            return users.push({
                label: user.firstname + " " + user.lastname,
                value: parseInt(user.userId),
                active: false,
            });
        });
        this.setState({

            allSystemUsers: users,
            salesDirectors: arrSales,
            operationsDirectors: arrOps,
        });

    }

    retrieveRegions(){
        let t = this;
        return RegionService.getAllRegions()
            .then((res) => {
                let arr = [],
                    d = this.state.data;
                res.forEach((region, index) => {
                    arr.push({
                        id: region.id,
                        name: region.name,
                        salesDirector: this.getDirector(region.salesDirector),
                        operationsDirector: this.getDirector(region.operationsDirector),
                        edit: t.renderEditButton(region, index),
                        remove: t.renderRemoveButton(region),
                    });
                });

                d.rows = arr;

                t.setState({
                    data: d,
                    isLoaded: true,
                });
            })
            .catch((err) => {
                //handle error...BC
            });
    }



    getDirector(director){
        if(!director){
            return("No Director")
        }
        return director.name
    }

    renderEditButton(region, index) {
        return (
            <MDBIcon icon="edit"
                     onClick={() => this.handleEditClick(region, index)}
                     style={{color: "#7ac5ff"}}
            />
        );
    }


    renderRemoveButton(region) {
        return (
            <MDBIcon icon="trash"
                     onClick={() => this.handleRemoveClick(region)}
                     style={{color: "#db0404"}}
            />
        );
    }



    handleConfirmationDelete(id){
        RegionService.deleteRegion(id)
            .then((res) => {
                this.retrieveRegions();
                toast.success("Region Was Removed");
                this.handleConfirmationClose();
            })
            .catch((err) => {
                toast.warn("Error occurred while removing Region");
            });
    }

    handleRemoveClick(region) {
        let i = {
            id: region.id,
            name: region.name,
        };
        this.setState({
            confirmationModal: !this.state.confirmationModal,
            currRegion: i,
        });
    }

    handleEditClick(region, index) {
        let a = {
            id: region.id,
            name: region.name,
            salesDirector: region.salesDirector,
            operationsDirector: region.operationsDirector,
        };
        this.setState({
            currRegion: a,
            editModal: !this.state.editModal,
            currIndex: index,
        });
    }

    handleConfirmationClose= () => {
        let i = {
            id: -1,
            name: "",
            salesDirector: null,
            operationsDirector: null,
        };
        this.setState({
            currRegion: i,
            currIndex: -1,
            errorMessage: "",
            confirmationModal: !this.state.confirmationModal,
        });
    }

    confirmationToggle = () => {
        this.setState({
            confirmationModal: !this.state.confirmationModal,
            errorMessage: "",
        })
    }

    editToggle = () => {
        this.setState({
            editModal: !this.state.editModal,
            errorMessage: "",
        });
    };

    handleAddNewChange(e) {
        let i = this.state.newRegion;
        i.name = e.target.value;
        this.setState({
                newRegion: i,
            }
        )
    }

    renderConfirmationModal() {
        return (
            <Modal isOpen={this.state.confirmationModal} toggle={this.confirmationToggle}>
                <ModalHeader
                    style={{ backgroundColor: "#F93154", color: "white" }}
                    toggle={this.confirmationToggle}
                >
                    Confirm Delete
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    {this.renderConfirmationModalBody()}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Confirm Delete"}
                        onClick={() => this.handleConfirmationDelete(this.state.currRegion.id)}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.handleConfirmationClose}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderConfirmationModalBody() {
        return (
            <div>
                <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                <MDBContainer>
                    <MDBRow>
                        Are you sure you want to delete {this.state.currRegion.name}?
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }

    renderAddNewModal() {
        return (
            <Modal isOpen={this.state.addNewModal} toggle={this.handleAddNewClose}>
                <ModalHeader
                    style={{ backgroundColor: "#5881C1", color: "white" }}
                    toggle={this.handleAddNewClose}
                >
                    Add New Region
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                    {this.renderAddNewModalBody()}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Add New Contact Type"}
                        onClick={this.addNewRegion}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Cancel"}
                        onClick={this.handleAddNewClose}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>
                </ModalFooter>
                <ReactTooltip />
            </Modal>
        );
    }

    renderAddNewModalBody() {
        if (this.state.addNewModal) {

            let v = this.state.newRegion;

            let sdIndex = this.state.salesDirectors.find((rep) => {
                return rep.value === (v.salesDirector ? v.salesDirector.id : "wee");
            });

            let odIndex = this.state.operationsDirectors.find((rep) => {
                return rep.value === (v.operationsDirector ? v.operationsDirector.id : "wee");
            });

            return (
                <div>
                    <p style={{ color: "red" }}>{this.state.errorMessage}</p>

                    <TextField
                        className={"inputField"}
                        id="outlined-select-status"
                        select
                        size="small"
                        fullWidth
                        label="Sales Director"
                        value={sdIndex || ""}
                        onChange={this.handleSalesDirectorChange.bind(this)}
                        variant="outlined"
                    >
                        {this.state.salesDirectors.map((option, idx) => (
                            <MenuItem key={idx} value={option}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        id="outlined-select-status"
                        select
                        size="small"
                        fullWidth
                        label="Operations Director"
                        value={odIndex || ""}
                        onChange={this.handleOperationsDirectorChange.bind(this)}
                        variant="outlined"
                    >
                        {this.state.operationsDirectors.map((option, idx) => (
                            <MenuItem key={idx} value={option}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Input
                        outline
                        label="Region Name"
                        onChange={this.handleAddNewChange.bind(this)}
                    />

                </div>
            );
        }
    }

    renderEditModal(){

        let v = this.state.currRegion;

        let sdIndex = this.state.salesDirectors.find((rep) => {
            return rep.value === (v.salesDirector ? v.salesDirector.id : "wee");
        });

        let odIndex = this.state.operationsDirectors.find((rep) => {
            return rep.value === (v.operationsDirector ? v.operationsDirector.id : "wee");
        });


        return (
            <Modal isOpen={this.state.editModal} toggle={this.editToggle}>
                <ModalHeader
                    style={{ backgroundColor: "#5881C1", color: "white" }}
                    toggle={this.editToggle}
                >
                    Edit Region
                </ModalHeader>

                <ModalBody style={{ backgroundColor: "white" }}>
                    <div>
                        <p style={{ color: "red" }}>{this.state.errorMessage}</p>

                        <Input
                            outline
                            label="Region Name"
                            value={this.state.currRegion.name}
                            onChange={this.handleEditChange.bind(this)}
                        />

                        <TextField
                            className={"inputField"}
                            id="outlined-select-status"
                            select
                            size="small"
                            fullWidth
                            label="Sales Director"
                            value={sdIndex || ""}
                            onChange={this.handleSalesDirectorChange.bind(this)}
                            variant="outlined"
                        >
                            {this.state.salesDirectors.map((option, idx) => (
                                <MenuItem key={idx} value={option}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            className={"inputField"}
                            id="outlined-select-status"
                            select
                            size="small"
                            fullWidth
                            label="Operations Director"
                            value={odIndex || ""}
                            onChange={this.handleOperationsDirectorChange.bind(this)}
                            variant="outlined"
                        >
                            {this.state.operationsDirectors.map((option, idx) => (
                                <MenuItem key={idx} value={option}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>

                    </div>
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Update Region"}
                        onClick={this.handleEditSave}
                    >
                        <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"red"}
                        data-tip={"Close"}
                        onClick={this.handleEditClose}
                    >
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>

                    <ReactTooltip />
                </ModalFooter>
            </Modal>
        );
    }

    handleOperationsDirectorChange = (e) => {
        let v = e.target ? e.target.value : {};

        let l = this.state.newRegion,
            prop = "newRegion";

        if (this.state.editModal === true) {
            l = this.state.currRegion;
            prop = "currRegion";
        }

        l.operationsDirector = {
            name: v.label,
            id: v.value
        };

        this.setState({
            [prop]: l
        });
    };
    handleSalesDirectorChange = (e) => {
        let v = e.target ? e.target.value : {};

        let l = this.state.newRegion,
            prop = "newRegion";

        if (this.state.editModal === true) {
            l = this.state.currRegion;
            prop = "currRegion";
        }

        l.salesDirector = {
            name: v.label,
            id: v.value
        };

        this.setState({
            [prop]: l
        });
    };

    handleAddNewClose = () => {
        this.setState({
            newRegion:{
                name: "",
            },
            addNewModal: !this.state.addNewModal,
            errorMessage: "",
        })
    }

    handleEditSave = () => {
        if (this.validateEdit()) {
            let d = this.state.data,
                i = this.state.currIndex,
                t = this;
            return RegionService.updateRegion(this.state.currRegion)
                .then((region) => {
                    d.rows[i].name = region.name;
                    d.rows[i].edit = t.renderEditButton(region, i);
                    this.setState({
                        data: d,
                        editModal: !this.state.editModal,
                    });
                    toast.success("Region Edited!");
                    this.retrieveRegions();
                })
                .catch((err) => {
                    toast.warn("An error occurred while saving.");
                });
        }
    };

    validateAddNew(){
        let a = this.state.newRegion;
        if (a.name === "") {
            this.setError("Please add a Region Name");
            return false;
        }
        return true;
    }

    addNewRegion = () => {
        if(this.validateAddNew()){
            let obj = this.state.newRegion;
            return RegionService.createRegion(obj)
                .then((res) => {
                    this.setState({
                        errorMessage: "",
                        name: "",
                    });
                    this.retrieveRegions();
                    this.handleAddNewClose();
                    toast.success(obj.name + " Region has been Added!");
                })
                .catch((err) => {
                    toast.warn("An error occurred adding a new Region");
                });
        }
    }

    validateEdit() {
        let a = this.state.currContact;
        if (a.name === "") {
            this.setError("Please add a Contact Name");
            return false;
        }
        return true;
    }

    setError(e) {
        this.setState({
            errorMessage: e,
        });
    }

    handleEditClose = () => {
        this.setState({
            currIndex: -1,
            errorMessage: "",
            editModal: !this.state.editModal,
            currRegion: {},
        });
    };

    handleEditChange(e) {
        let c = this.state.currRegion;
        c.name = e.target.value;
        this.setState({
            currRegion: c,
        });
    }

    renderTable() {
        if (this.state.isLoaded === true) {
            return (
                <MDBTable
                    small
                    striped
                    style={{ textAlign: "center" }}>
                    <MDBTableHead columns={this.state.data.columns} />
                    <MDBTableBody rows={this.state.data.rows} />
                </MDBTable>
            );
        } else {
            return <div> </div>;
        }
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{ textAlign: "center", verticalAlign: "center" }}>
                    <Spinner multicolor />
                </div>
            </Container>
        );
    }

    addNewClick = () => {
        this.setState({
            addNewModal: true
        })
    };

    render() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (
            <Container className="mt-5">
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={3000}
                />
                <Card>
                    <CardHeader
                        style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30}}
                    >
                        Regions
                        <Button
                            style={{ float: "right" }}
                            floating
                            size="sm"
                            color={"secondary"}
                            data-tip={"Add New Region"}
                            onClick={this.addNewClick}
                        >
                            <MDBIcon icon="globe-americas" style={{ fontSize: "2em" }} />
                        </Button>
                    </CardHeader>
                    <CardBody>{this.renderTable()}</CardBody>
                </Card>
                <ReactTooltip />
                {this.renderEditModal()}
                {this.renderConfirmationModal()}
                {this.renderAddNewModal()}
            </Container>
        );
    }
}
