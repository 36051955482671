import React from "react";
import PropTypes from "prop-types";
import {
	MDBTable,
	MDBTableHead,
	MDBTableBody,
	toast,
	MDBIcon,
	MDBRow,
	MDBCol,
	Card,
	CardHeader,
	CardBody,
	Button,
} from "mdbreact";
import PurchaseQueueService from "../Security/PurchaseQueueService/purchaseQueueService";
import ReactTooltip from "react-tooltip";
import Select from "react-select";


const columns = [
	{
		label: "ID",
		field: "id",
		sort: "asc",
	},
	{
		label: "Vendor",
		field: "vendor",
		sort: "asc",
	},
	{
		label: "Product",
		field: "product",
		sort: "asc",
	},
	{
		label: "Qty",
		field: "qty",
		sort: "asc",
	},
];

export default class inventoryRequest extends React.Component {
	constructor(props) {
		super(props);

		this.onClose = props.onClose;

		this.state = {
			productCategory: null,
			productVendor: null,
			equipment: [],
			selectedProduct: null,
			selectedLocation: null,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		productSubTypes: PropTypes.array,
		productTypes: PropTypes.array,
		internalLocations: PropTypes.array,
		allVendors: PropTypes.array,
	};

	handleCategoryChange = (e) => {
		this.setState({
			productCategory: e,
		});
	};

	handleVendorChange = (e) => {
		if (e.value == null) {
			e = null;
		}

		this.setState({
			productVendor: e,
		});
	};

	getCategoryList() {
		const { productTypes } = this.context;
		let a = [];
		if (productTypes.length > 0) {
			productTypes.map((productType) => {
				return a.push({
					label: productType.name,
					value: productType,
				});
			});
		}
		return a;
	}

	getVendorList() {
		const { allVendors } = this.context;
		let a = [];
		if (allVendors.length > 0) {
			a.push({
				label: "Clear",
				value: null,
			});

			allVendors.map((vendor) => {
				return a.push({
					label: vendor.name,
					value: vendor,
				});
			});
		}
		return a;
	}

	handleProductChange = (e) => {
		this.addProduct(e.value);

		this.setState({
			selectedProduct: null,
		});
	};

	addProduct(product) {
		const { productTypes, currentUser } = this.context;
		let st = this.state,
			e = st.equipment,
			productCategory = productTypes.find((p) => {
				return (p.id === product.productType.id);
			}),
			row = {
				itemId: product.itemId || null,
				quantity: 1,
				requestedOn: new Date(),
				productSubType: { id: product.id, name: product.name },
				requestedBy: { id: currentUser.id, name: currentUser.username },
				vendor: product.vendor
					? { id: product.vendor.id, name: product.vendor.name }
					: null,
				productId: productCategory.id,
				statusChangedOn: new Date(),
				statusChangedBy: { id: currentUser.id, name: currentUser.username },
				status: "PENDING",
			};

		e.push(row);

		this.setState({
			equipment: e,
			//productCategory: null,
		});
	}

	getProductList = () => {
		const { productSubTypes } = this.context;
		let a = [],
			subTypes = productSubTypes,
			cc = this.state.productCategory,
			vv = this.state.productVendor;

		subTypes = subTypes.filter((product) => {
			return product.itemId && product.itemId.length > 0;
		});

		if (cc) {
			subTypes = subTypes.filter((product) => {
				return product.productType.id === cc.value.id;
			});
		}

		if (vv) {
			subTypes = subTypes.filter((product) => {
				return product.vendor ? product.vendor.id === vv.value.id : false;
			});
		}

		subTypes.map((product) => {
			let h = product.hcpcs !== null ? " | " + product.hcpcs : "";
			let i = product.itemId ? " | " + product.itemId : "";
			let	v = product.vendor ? " | " + product.vendor.name : "";
			let l = product.productType.name + " | " + product.name + h + v + i;

			return a.push({
				label: l,
				value: product,
			});
		});
		return a;
	}

	renderCategoryDropdown() {
		return (
			<div>
				<Select
					placeholder={"Category..."}
					options={this.getCategoryList()}
					onChange={this.handleCategoryChange.bind(this)}
					value={this.state.productCategory}
				/>
			</div>
		);
	}

	renderVendorDropdown() {
		return (
			<div>
				<Select
					placeholder={"Vendor..."}
					options={this.getVendorList()}
					onChange={this.handleVendorChange.bind(this)}
					value={this.state.productVendor}
				/>
			</div>
		);
	}

	renderProductDropdown() {
		return (
			<div>
				<Select
					placeholder={"Product..."}
					options={this.getProductList()}
					onChange={this.handleProductChange.bind(this)}
					value={this.state.selectedProduct}
				/>
			</div>
		);
	}

	renderLocationDropdown() {
		return (
			<div>
				<Select
					placeholder={"Location..."}
					options={this.getLocations()}
					onChange={this.handleLocationChange.bind(this)}
					value={this.state.selectedLocation}
				/>
			</div>
		);
	}

	getLocations() {
		const { internalLocations, currentUser } = this.context;
		let locations = [];
		switch (currentUser.role) {
			case "ADMIN":
			case "EXECUTIVE_MANAGEMENT":
			case "SYSTEM_ADMIN":
				locations = internalLocations;
				break;
			default:
				locations = currentUser.locations;
		}
		let arr = [];
		locations.map((loc) => {
			return arr.push({
				label: loc.name,
				value: { id: loc.id, name: loc.name },
			});
		});
		return arr;
	}

	handleLocationChange(evt) {
		this.setState({
			selectedLocation: evt,
		});
	}

	renderRows() {
		let arr = [];

		this.state.equipment.map((r, index) => {
			return arr.push({
				id: r.productSubType.id,
				vendor: r.vendor ? r.vendor.name : "No Vendor",
				product: r.productSubType.name,
				quantity: this.renderEditRow(r, index),
			});
		});

		return arr;
	}

	renderEditRow(row, index) {
		return (
			<MDBRow style={{ width: 200 }}>
				<MDBCol size={"3"}>
					<input type="number" value={row.quantity} onChange={this.handleQtyChangeInput.bind(this,index)}/>
				</MDBCol>
				<MDBCol size={"4"}>

				</MDBCol>
			</MDBRow>
		);
	}
	handleQtyChangeInput(index, e) {
		let st = this.state,
			eq = st.equipment;

		eq[index].quantity = Math.trunc(Number(e.target.value));

		if (eq[index].quantity == 0) {
			eq.splice(index, 1);
		}

		this.setState({
			equipment: eq,
		});
	}

	handleSave = () => {
		let st = this.state,
			e = st.equipment,
			loc = st.selectedLocation;

		if (this.validateSave(e, loc)) {
			e.forEach((r) => {
				r.location = loc.value;
			});

			this.onClose();

			PurchaseQueueService.createPurchaseQueues(e)
				.then((res) => {
					this.setState({
						equipment: [],
						productCategory: null,
					});
					toast.success("Equipment Purchased");
				})
				.catch((err) => {
					toast.error(
						"There was an error submitting the request.  Please try again later or contact support."
					);
				});
		}
	};

	validateSave(equip, loc) {
		let arr = equip.filter((r) => {
			return !r.quantity;
		});
		if (!loc) {
			toast.warn("Please select a location");
			return false;
		}
		if (equip.length === 0) {
			toast.warn("Must add a product");
			return false;
		}
		if (arr.length > 0) {
			toast.warn("Please enter a quntity for all products");
			return false;
		}
		return true;
	}

	render() {
		return (
			<div>
				<CardHeader
					style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30 }}
				>
					Inventory Request
				</CardHeader>
				<Card style={{ margin: "0 0 1% 0", padding: "2%" }}>
					<MDBRow>
						<MDBCol size="4">
							<div className="form-group">
								<label>Category</label>
								{this.renderCategoryDropdown()}
							</div>
						</MDBCol>

						<MDBCol size="4">
							<div className="form-group">
								<label>Vendor</label>
								{this.renderVendorDropdown()}
							</div>
						</MDBCol>

						<MDBCol size="4">
							<div className="form-group">
								<label>Location</label>
								{this.renderLocationDropdown()}
							</div>
						</MDBCol>
					</MDBRow>
					<MDBRow style={{ justifyContent: "space-between" }}>
						<MDBCol size="8">
							<div className="form-group">
								<label>Product</label>
								{this.renderProductDropdown()}
							</div>
						</MDBCol>

						<MDBCol size={"2"}>
							<Button
								style={{ margin: "30% 0 0 0" }}
								color={"green"}
								data-tip={"Save"}
								size={"sm"}
								onClick={this.handleSave}
							>
								<MDBIcon icon="check" style={{ fontSize: "2em" }} />
							</Button>
						</MDBCol>
					</MDBRow>
					<ReactTooltip />
				</Card>
				<Card style={{ margin: "0", padding: "2%" }}
					  className={'test'}>
					<MDBTable striped small>
						<MDBTableHead columns={columns} />
						<MDBTableBody rows={this.renderRows()} />
					</MDBTable>
				</Card>
			</div>
		);
	}
}
