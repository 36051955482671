import React from "react";
import PropTypes from "prop-types";
import {
    Container,
    Spinner,
    Col,
    CardHeader,
    Button,
    Input,
    InputFile,
    MDBDatePicker,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    toast,
    ToastContainer,
    View,
    MDBIcon,
    Fa,
    MDBBtn, MDBCard, MDBCol, MDBRow, Navbar,
} from "mdbreact";
import UserService from "../Security/UserService/userService";
import Select from "react-select";

import ReactTooltip from "react-tooltip";

const formatDate = (date) => {
    if (date == null || date === "") {
        return "";
    }
    return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "numeric",
        day: "2-digit",
    }).format(date);
};

export default class PCRLink extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            isLoaded: true,
            allLocs: [],
            allReps: [],
            allLinks: [],
            filteredLinks: [],
            locationsSelect: [
                {
                    label: "Loading...",
                    value: "loading",
                },
            ],
            locationsSelected: [],
            repsSelect: [
                {
                    label: "loading...",
                    value: "loading",
                }
            ],
            allPCR: [],
            pcrSelect: [
                {
                    label: "loading...",
                    value: "loading",
                }
            ],
            repsSelected: [],
            pcrSelected: [],
            allLinkRecords: []
        };

    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allLocations: PropTypes.array,
        salesReps: PropTypes.array,
        allPCR: PropTypes.array,
    };

    componentDidMount() {
        this.renderLocationsOptions();
        this.getPcrLinks();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {allLocations, allPCR} = this.context;
        let st = this.state;

        if (st.allLocs.length !== allLocations.length) {
            this.renderLocationsOptions();

        }
        if (st.allPCR.length !== allPCR.length) {
            this.renderPCROptions();
        }
    }

    getPcrLinks() {
        return UserService.getPCRLinks().then(r => {
            this.setState({
                allLinks: r
            })
        }).catch(e => {

        })
    }

    createPCRLink() {
        let r = this.state.repsSelected,
            pcr = this.state.pcrSelected,
            fl = this.state.filteredLinks;

        if (!r.value || !pcr.value) {
            toast.warn("Please select a PCR and a Sales Rep")
            return;
        }

        let o = {
            name: "nothing",
            salesRep: {
                name: r.label,
                id: r.value
            },
            patientCareRep: {
                name: pcr.label,
                id: pcr.value
            }
        }

        UserService.createPCRLink(o).then(ret => {
            toast.success("Link Created!");

            ret.salesRep.name = r.label;

            fl.push(ret);

            this.setState({
                pcrSelected: [],
                repsSelected: [],
                filteredLinks: fl
            })
        }).catch(e => {
            toast.warn("There was an error creating the link")
        })

    }

    updateExistingLink(v) {
        UserService.updatePCRLink(v).then(ret => {
            toast.success("Link Updated!");
        }).catch(e => {
            toast.warn("There was an error updating the link")
        })
    }

    renderRepOptions(e) {
        const {salesReps} = this.context;
        let options = [];

        if (e === undefined || e.length === 0) {
            options = salesReps;
        } else {
            salesReps.map((rep) => {
                let hasLocation = rep.locations.find(l => {
                    return l.id === e.value
                })

                if (hasLocation != null) {
                    return options.push({
                        label: rep.firstname + " " + rep.lastname,
                        value: rep.id,
                    });
                }
            });
        }



        let al = this.state.allLinks;

        let fl = al.filter(f => {
            let v = options.find(r => {
                return parseInt(r.value) === (f.salesRep ? parseInt(f.salesRep.id) : "weeee")
            })

            if (v != null) {
                return true;
            }
        })

        //TODO: Filter the records from the repLinkTable with the sales reps in the list.

        this.setState({
            allReps: options,
            repsSelect: options,
            filteredLinks: fl,
        });

    }

    renderLocationsOptions() {
        const {allLocations} = this.context;

        let select = [];

        allLocations.map((location) => {
            // Populates the locations dropdown depending on which locations the user is in...JK
            if (location.type === "Internal") {
                return select.push({
                    label: location.name,
                    value: location.id,
                });
            }
            return null;
        });

        this.setState({
            locationsSelect: select,
            allLocs: allLocations,
        });
    }

    renderPCROptions() {
        const {allPCR} = this.context;

        let select = [];

        allPCR.map((pcr) => {
            // Populates the locations dropdown depending on which locations the user is in...JK
            return select.push({
                label: pcr.label,
                value: pcr.value.id,
            });
        });

        this.setState({
            pcrSelect: select,
            allPCR: allPCR
        });
    }

    handleLocationChange = (e) => {
        this.setState({
            locationsSelected: e,
        });
        this.renderRepOptions(e);
    };

    handleRepChange = (e) => {
        let links = this.state.filteredLinks,
            idx = links.findIndex(v => {
                return e.value === v.salesRep.id;
            });

        if (idx > -1) {
            toast.warning("Rep Link already exists.  Please update the existing record below");
            return;
        }

        this.setState({
            repsSelected: e,
        });
    }

    handlePCRChange = e => {
        this.setState({
            pcrSelected: e,
        });
    }

    updateProfile(profile) {
        return UserService.updateUser(profile)
            .then((data) => {
                toast.success("Saved Successfully!");
            })
            .catch((err) => {
                toast.warn("An error occurred while saving.");
            });
    }

    renderGreetingTextInput(user) {
        return (
            <Input
                valueDefault={user.greeting || ""}
                type="textarea"
                label="Enter greeting.."
                rows="2"
                cols="50"
                outline
                onBlur={(e) => {
                    user.greeting = e.target.value
                }}
            />
        )
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }
        return (

            this.renderPCRLinkCards()

        );
    }

    renderPCRLinkCards() {
        let at = this.state.filteredLinks,
            t = this;


        function renderCard(link, idx) {
            if(link.salesRep === null || link.patientCareRep === null){
                return;
            }

            let v = t.state.pcrSelect.findIndex(r => {
                    return parseInt(r.value) === (link.patientCareRep ? parseInt(link.patientCareRep.id) : "weeee");
                }),
                sval;

            if (v != null) {
                sval = t.state.pcrSelect[v]
            }

            return (

                <CardHeader style={{backgroundColor: "#ffffff", marginTop: 4}}>
                    <MDBRow key={link.salesRep.id}>

                        <MDBCol size={3} style={{marginTop: 'auto', marginBottom: 'auto', fontWeight: 'bold'}}>
                            Sales Rep: {link.salesRep.name}
                        </MDBCol>
                        <MDBCol size={3}>
                            <Select
                                placeholder="PCR"
                                // closeMenuOnSelect={false}
                                options={t.state.pcrSelect}
                                onChange={(e) => {
                                    link.patientCareRep = {id: e.value, name: e.label};
                                    sval = e;
                                    t.forceUpdate();
                                }}
                                value={sval}
                            />

                        </MDBCol>
                        <MDBCol size={3}/>
                        <MDBCol size={3}>
                            <MDBBtn size={"sm"} onClick={() => t.updateExistingLink(link)}>
                                Update
                            </MDBBtn>
                        </MDBCol>


                    </MDBRow>
                </CardHeader>

            )
        }

        return (
            at.map((rep, idx) => {
                return (renderCard(rep, idx))
            })
        )


    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    renderLocationsFilter() {
        const {currentUser} = this.context;
        let role = currentUser.role;

        switch (role) {
            case "SALES":
            case "OFFICE_SUPPORT":
            case "CARE_COORDINATOR":
            case "CALL_CENTER":
            case "TECHNICIAN":
            case "DATA_EXPORT":
            case "VERIFICATION":
            case "FOLLOWUP":
            case "COLLECTIONS":
            case "BILLING":
            case "PURCHASING":
            case "FINANCE":
            default:
                return <div/>;
            case "SYSTEM_ADMIN":
            case "ADMIN":
            case "EXECUTIVE_MANAGEMENT":
            case "OFFICE_MANAGER":
            case "SALES_MANAGER":
                return (
                    <Row>
                        <Col md={12} style={{marginTop: 5}}>
                            <Select
                                placeholder="Select Location"
                                // closeMenuOnSelect={false}
                                options={this.state.locationsSelect}
                                onChange={this.handleLocationChange.bind(this)}
                                value={this.state.locationsSelected}
                            />
                        </Col>
                    </Row>
                );
        }
    }

    renderRepsFilter() {
        return (
            <Row>
                <Col md={12} style={{marginTop: 5}}>
                    <Select
                        placeholder="Select Rep"
                        // closeMenuOnSelect={false}
                        options={this.state.repsSelect}
                        onChange={this.handleRepChange.bind(this)}
                        value={this.state.repsSelected}
                    />
                </Col>
            </Row>
        );
    }

    renderPCRFilter() {
        return (
            <Row>
                <Col md={12} style={{marginTop: 5}}>
                    <Select
                        placeholder="Select PCR"
                        // closeMenuOnSelect={false}
                        options={this.state.pcrSelect}
                        onChange={this.handlePCRChange.bind(this)}
                        value={this.state.pcrSelected}
                    />
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <div style={{marginLeft: "4%", marginRight: "4%"}}>
                <ToastContainer
                    hideProgressBar={false}
                    newestOnTop={true}
                    autoClose={5000}
                />

                <CardHeader style={{backgroundColor: "#778899"}}>
                    <Row>
                        <Col md={3} style={{color: "#000"}}>
                            {this.renderLocationsFilter()}
                        </Col>
                        <Col md={3} style={{color: "#000"}}>
                            {this.renderRepsFilter()}
                        </Col>
                        <Col md={3} style={{color: "#000"}}>
                            {this.renderPCRFilter()}
                        </Col>
                        <Col md={3} style={{color: "#000"}}>
                            <MDBBtn size={"sm"} onClick={() => this.createPCRLink()}>
                                Add PCR Link
                            </MDBBtn>
                        </Col>
                    </Row>
                </CardHeader>


                {this.renderTableOrSpinner()}

            </div>
        );
    }
}
