import React from "react";
import PropTypes from "prop-types";
import {
	Container,
	ToastContainer,
	Row,
	Fa,
	Col,
	CardBody,
	toast,
	DataTable,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	MDBIcon,
	Button,
	Spinner,
	Modal,
	ModalFooter,
	ModalBody,
	CardHeader,
	Input,
	MDBBtn,
	MDBCol,
	MDBRow,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBModal,
	MDBModalBody,
	MDBContainer,
	DatePicker,
	InputFile,
	MDBInput,
} from "mdbreact";
import NoteService from "../Security/NoteService/noteService";
import ActivityService from "../Security/ActivityService/activityService";
import ContactService from "../Security/ContactService/contactService";
import OrderService from "../Security/OrderService/orderService";
import UserService from "../Security/UserService/userService";
import classnames from "classnames";
import "./account.css";
import AccountService from "../Security/AccountService/accountService";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

export default class accountInfo extends React.Component {
	constructor(props) {
		super(props);

		let account = {},
			id = props.match.params.id;

		const notes = {
			columns: [
				{
					label: "Type",
					field: "type",
					sort: "asc",
					width: 100,
				},
				{
					label: "Created By",
					field: "createdBy",
					sort: "asc",
					width: 100,
				},
				{
					label: "Created",
					field: "createdOn",
					sort: "asc",
					width: 100,
				},
				{
					label: "Complete",
					field: "completed",
					sort: "asc",
					width: 100,
				},
				{
					label: "Text",
					field: "text",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "button",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		const activities = {
			columns: [
				{
					label: "Type",
					field: "type",
					sort: "asc",
					width: 100,
				},
				{
					label: "Date",
					field: "expenseDate",
					sort: "asc",
					width: 100,
				},
				{
					label: "Marketing",
					field: "quality",
					sort: "asc",
					width: 100,
				},
				{
					label: "Spent",
					field: "expenses",
					sort: "asc",
					width: 100,
				},
				{
					label: "At",
					field: "at",
					sort: "asc",
					width: 100,
				},
				{
					label: "For",
					field: "for",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		const orders = {
			columns: [
				{
					label: "Order Id",
					field: "id",
					sort: "asc",
					width: 100,
				},
				{
					label: "Status",
					field: "status",
					sort: "asc",
					width: 100,
				},
				{
					label: "Reason",
					field: "reason",
					sort: "asc",
					width: 100,
				},
				{
					label: "Name",
					field: "patientName",
					sort: "asc",
					width: 100,
				},
				{
					label: "Date",
					field: "when",
					sort: "asc",
					width: 100,
				},
				{
					label: "Payor Source",
					field: "insuranceType",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		const contacts = {
			columns: [
				{
					label: "Name",
					field: "name",
					sort: "asc",
					width: 100,
				},
				{
					label: "Type",
					field: "type",
					sort: "asc",
					width: 100,
				},
				{
					label: "Title",
					field: "role",
					sort: "asc",
					width: 100,
				},
				{
					label: "Phone",
					field: "phone",
					sort: "asc",
					width: 100,
				},
				{
					label: "Email",
					field: "mail",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
					width: 100,
				},
			],
			rows: [],
		};

		this.state = {
			account: account,
			accountId: id,
			eAccount: JSON.parse(JSON.stringify(account)),
			notes: notes,
			nLoaded: false,
			activities: activities,
			aLoaded: false,
			orders: orders,
			oLoaded: false,
			contacts: contacts,
			cLoaded: false,
			taskCompleted: false,
			noteModalOpen: false,
			contactModalOpen: false,
			noteText: "",
			activeItem: "4",
			typeRadio: "Note",
			nContact: {
				account: {},
				contactType: {},
				email: "",
				firstname: "",
				lastname: "",
				phone: "",
				role: "",
			},
			nActivity: {
				account: account,
				expenseDate: new Date(),
				quality: false,
				contacts: [],
				type: "Appointment",
			},
			nCall: {
				account: account,
				quality: false,
				contacts: [],
				type: "Call",
				when: new Date(),
				callType: null,
				callReason: null,
			},
			callTypes: [
				{ label: "Face-To-Face", value: "FTF" },
				{ label: "LCMP Evaluation", value: "LCMP Evaluation" },
				{ label: "Drop Ins", value: "Drop Ins" },
			],
			callReasons: [
				{ label: "Following up with new information from a previous meeting", value: "Following up with new information from a previous meeting" },
				{ label: "Following up regarding new information about changes in LCDs", value: "Following up regarding new information about changes in LCDs" },
				{ label: "Paperwork Education/Streamlining Processes", value: "Paperwork Education/Streamlining Processes" },
				{ label: "Equipment Changes", value: "Equipment Changes" },
				{ label: "Policy Changes", value: "Policy Changes" },
				{ label: "Other industry and/or inter-company changes that our accounts need to be aware of", value: "Other industry and/or inter-company changes that our accounts need to be aware of" },
				{ label: "Other", value: "Other" },
			],
			modalNote: {},
			editNoteOpen: false,
			callModalOpen: false,
			appointmentModalOpen: false,
			dueDate: null,
			contactList: [],
			callNoteText: "",
			allNotes: [],
		};

		this.fetchAccount(id);
		this.retrieveNotes(id);
		this.retrieveActivities(id);
		this.retrieveOrders(id);
		this.retrieveContacts(id);
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		contactTypes: PropTypes.array,
		marketingCampaigns: PropTypes.array,
		accountTypes: PropTypes.array,
		cardTypes: PropTypes.array,
		expenseTypes: PropTypes.array,
	};

	toggle = (tab) => {
		if (this.state.activeItem !== tab) {
			this.setState({
				activeItem: tab,
			});
		}
	};

	//DATA
	fetchAccount(id) {
		let t = this;

		let nAct = this.state.nActivity,
			nCall = this.state.nCall;

		return AccountService.getAccount(id).then((data) => {
			let accRef = { id: data.id, name: data.name };

			nAct.account = accRef;
			nCall.account = accRef;

			t.setState({
				account: data,
				nActivity: nAct,
				nCall: nCall,
				eAccount: JSON.parse(JSON.stringify(data)),
				isLoaded: true,
			});
		});
	}

	retrieveNotes(id) {
		let t = this;


		return NoteService.getNotesAndActivityNotesByAccount(id)
			.then((data) => {
				t.setState({
					allNotes: data,
					nLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});


		return NoteService.getNotesByAccount(id)
			.then((data) => {
				t.setState({
					allNotes: data.content,
					nLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	getCardList() {
		const { cardTypes } = this.context;

		let a = [];
		a.push({
			label: "Select Card Type...",
			value: "",
		});

		cardTypes.map((card) => {
			return a.push({
				label: card.name,
				value: card,
			});
		});

		return a;
	}

	getExpenseTypeList() {
		const { expenseTypes } = this.context;

		let act = this.state.nActivity;

		let a = [];
		a.push({
			label: "Select Expense Type...",
			value: "",
		});

		expenseTypes.map((exp) => {
			if (act.cardType != null) {
				if (exp.cardTypes) {
					let i = exp.cardTypes.findIndex((x) => x.id === act.cardType.id);
					if (i > -1) {
						return a.push({
							label: exp.name,
							value: exp,
						});
					}
				}
			} else {
				return a.push({
					label: exp.name,
					value: exp,
				});
			}

			return null;
		});

		return a;
	}

	getContactTypesList() {
		const { contactTypes } = this.context;
		let a = [];
		a.push({
			label: "Select Category...",
			value: "",
		});
		contactTypes.map((type) => {
			return a.push({
				label: type.name,
				value: type,
			});
		});
		return a;
	}

	handleCardTypeSelected = (e) => {
		let act = this.state.nActivity;

		act.cardType = { name: e.value.name, id: e.value.id };

		this.setState({
			nActivity: act,
		});
	};

	handleExpenseTypeSelected = (e) => {
		let act = this.state.nActivity;

		act.expenseType = { name: e.value.name, id: e.value.id };

		this.setState({
			nActivity: act,
			selectedExpense: e.value,
		});
	};

	validateActivity(activity) {
		let shouldContinue = true;

		if (!activity.account) {
			toast.warn("Please pick an account");
			shouldContinue = false;
		}

		if (!activity.type) {
			toast.warn("Please select an activity type");
			shouldContinue = false;
		}

		if (activity.type === "Appointment") {
			if (!activity.expenseDescription) {
				toast.warn("Please provide a description");
				shouldContinue = false;
			}

			if (!activity.expenseDate) {
				toast.warn("Please set the date");
				shouldContinue = false;
			}

			if (activity.expenses < 0) {
				toast.warn("Amount spent should be no less than 0");
				shouldContinue = false;
			}

			if (!activity.vendor) {
				toast.warn("Please identify the vendor");
				shouldContinue = false;
			}

			if (!activity.peopleAttending) {
				toast.warn("Please identify how many people were attending");
				shouldContinue = false;
			}

			if (!activity.educationalTopics) {
				toast.warn("Please identify the topics discussed");
				shouldContinue = false;
			}

			if (activity.expenses > 0) {
				if (!activity.expenseType) {
					toast.warn("Please select an expense type");
					shouldContinue = false;
				}

				if (!activity.cardType) {
					toast.warn("Please select a card type");
					shouldContinue = false;
				}

				if (
					activity.expenseType &&
					this.state.selectedExpense.receiptRequired === true
				) {
					if (!activity.receipt) {
						toast.warn("Please upload a receipt");
						shouldContinue = false;
					}
				}
			}
		}
		else if(activity.type === "Call"){
			if(activity.quality === true && (!activity.contacts || activity.contacts.length === 0)){
				toast.warn("Please select a contact");
				shouldContinue = false;
			}

			if(activity.quality === false && !activity.callType){
				toast.warn("Please select a call type");
				shouldContinue = false;
			}

			if(activity.quality === true && activity.callReason === null){
				toast.warn("Please select a call reason");
				shouldContinue = false;
			}
			
			let nText = document.getElementById("newNoteText");
			if(activity.quality === true && activity.callReason === "Other" && nText != null && nText.value === ""){
				toast.warn("Please enter the reason note");
				shouldContinue = false;
			}
		}

		return shouldContinue;
	}

	createActivity(type) {
		this.setState({ saveLoading: true });
		let act = this.state.nActivity;

		if (type === "Appointment") {
			let shouldCreate = this.validateActivity(act);

			if (!shouldCreate) {
				this.setState({ saveLoading: false });
				return;
			}

			//If we have a receipt, format it before save...BC
			if (act.receipt) {
				let rt = act.receipt;
				//get the index of the comma which comes after the part that declares the image type
				let idx = rt.indexOf(","),
					newStr = rt.slice(idx + 1);

				act.receipt = newStr;
			}

			if (act.expenses) {
				act.expenses = parseFloat(act.expenses);
			}

			act.when = new Date();
		} else {
			act = this.state.nCall;

			let shouldCreate = this.validateActivity(act);

			if (!shouldCreate) {
				this.setState({ saveLoading: false });
				return;
			}
		}

		return ActivityService.createActivity(act)
			.then((res) => {
				let oActivity = this.state.nActivity;

				let nActivity = {
					account: oActivity.account,
					expenseDate: new Date(),
					quality: false,
					contacts: [],
					type: "Appointment",
				};

				let nCall = {
					account: oActivity.account,
					quality: false,
					contacts: [],
					type: "Call",
					when: new Date(),
				};

				this.setState({
					nActivity: nActivity,
					nCall: nCall,
					aLoaded: false,
					callModalOpen: false,
					appointmentModalOpen: false,
				});
				
				let nText = document.getElementById("newNoteText");
				if(act.type === "Call" && this.state.callNoteText){
					this.createActivityNote(res);
				}

				this.retrieveActivities(this.state.accountId);
			})
			.catch((err) => {
				toast.warn("Oh! There was an error creating the Activity.");
			});
	}

	createActivityNote(activity) {
		const { currentUser } = this.context;
		let noteRadio = this.state.noteRadio,
			accountDTO = activity.account,
			txt = this.state.callNoteText,
			cb = { id: currentUser.id, name: currentUser.username };
			
		let note = {
			text: txt,
			account: accountDTO,
			completed: true,
			task: false,
			createdAt: new Date(),
			createdBy: cb,
		};

		if (noteRadio === "Task") {
			note.completed = false;
			note.task = true;
		}

		return NoteService.createActivityNote(activity.id, note)
			.then((res) => {
			})
			.catch((err) => {
				console.log("Oh! There was an error creating the Task.");
			});
	}

	encodeImageFileAsURL(element) {
		let file = element[0],
			reader = new FileReader(),
			t = this;

		reader.onloadend = function () {
			t.state.nActivity.receipt = reader.result;
			t.setState({ nActivity: t.state.nActivity });
		};
		reader.readAsDataURL(file);
	}

	getMarketingCampaigns() {
		const { marketingCampaigns } = this.context;

		let a = [];
		marketingCampaigns.map((type) => {
			return a.push({
				label: type.name,
				value: type.id,
				mcVal: type,
			});
		});

		return a;
	}

	updateNoteModal(note) {
		let nText = document.getElementById("noteTextArea").value;

		let upDate = "Updated: " + this.formatDate(new Date());
		note.text = nText + " -- " + upDate;

		this.updateNote(note, null, true);
	}

	updateNote(note, complete) {
		const { currentUser } = this.context;

		let notes = this.state.allNotes,
			noteToReplace = notes.findIndex((n) => {
				return n.id === note.id;
			});

		if (noteToReplace > -1) {
			notes.splice(noteToReplace, 1, note);
		}

		if (complete != null) {
			note.completed = complete;

			note.text +=
				" --> Completed by " +
				currentUser.username +
				" on " +
				this.formatDate(new Date());
		}

		return NoteService.updateActivityNote(note)
			.then((res) => {
				this.setState({
					allNotes: notes,
				});
			})
			.catch((err) => {
							console.log(err);
						});
	}

	sortNotes(allNotes, task) {
		let t = [],
			n = [];

		allNotes.forEach((note) => {
			if (note.completed === false) {
				t.push(note);
			} else {
				n.push(note);
			}
		});

		if (task) {
			return t;
		}
		return n;
	}

	toggleEditModalPopup(note) {
		const { currentUser } = this.context;

		let currentState = this.state.editNoteOpen,
			ncopy = {};

		if (note) {
			ncopy = JSON.parse(JSON.stringify(note));

			if (currentUser) {
				if (
					currentUser.role === "ADMIN" ||
					currentUser.role === "SYSTEM_ADMIN"
				) {
					//we are fine, continue
				} else if (currentUser.username !== note.createdBy.name) {
					//they do not own this note.  They shouldn't be able to edit it.
					return;
				}
			} else {
				return;
			}
		}

		this.setState({
			editNoteOpen: !currentState,
			modalNote: ncopy,
		});
	}

	toggleNoteModalPopup() {
		let currentState = this.state.noteModalOpen;
		this.setState({ noteModalOpen: !currentState });
	}

	toggleContactModalPopup() {
		let currentState = this.state.contactModalOpen;
		this.setState({ contactModalOpen: !currentState });
	}

	noteChange = (property, event) => {
		this.setState({ [property]: event.target.value });
	};

	contactChange = (property, event) => {
		let contact = this.state.nContact;
		contact[property] = event.target.value;
		this.setState({ nContact: contact });
	};

	handleContactTypeSelected = (e) => {
		let con = this.state.nContact;

		con.contactType = { name: e.value.name, id: e.value.id };

		this.setState({
			nContact: con,
		});
	};

	completedChange = (event) => {
		this.setState({ completed: event.target.checked });
	};

	addNewNote() {
		//get the current user context that can be passed into the note DTO...BC
		//another comment...BC
		const { currentUser } = this.context;
		let cb = { id: currentUser.id, name: currentUser.username },
			currentNotes = this.state.allNotes,
			acc = this.state.account;

		let nText = document.getElementById("newActivityNoteText").value;

		let note = {
			type: "AccountNote",
			text: nText,
			createdAt: new Date(),
			account: { id: acc.id, name: acc.name },
			task: !this.state.taskCompleted,
			createdBy: cb,
			completed: this.state.taskCompleted,
			dueDate: this.state.dueDate,
		};

		if (!this.state.completed) {
			note.assignedTo = this.state.account.owner;
		}

		return NoteService.createAccountNote(note)
			.then((res) => {
				this.toggleNoteModalPopup();
				currentNotes.unshift(note);
				this.setState({ allNotes: currentNotes });
			})
			.catch((err) => {
				//Maybe send an error to the user?...BC
			});
	}

	addNewContact() {
		let con = this.state.nContact,
			acc = this.state.eAccount;

		con.account = { name: acc.name, id: acc.id };

		this.setState({ cLoaded: false });

		//change to add contact
		return ContactService.createContact(con)
			.then((res) => {
				toast.success("Contact Created");

				let dt = this.state.contacts;

				dt.rows.push({
					name: res.lastname + "," + res.firstname,
					type: res.contactType ? res.contactType.name : "No Type",
					role: res.role || "No Role",
					phone: res.phone || "No Phone",
					mail: res.email || "No Email",
					edit: this.routeContact(res),
				});

				this.setState({
					cLoaded: true,
					contacts: dt,
				});

				this.createNoteTask(acc, res.id);
			})
			.catch((err) => {
				toast.error("There was an error.  Make sure all fields are filled out");
				this.setState({ cLoaded: true });
				//Maybe send an error to the user?...BC
			});
	}

	createNoteTask(account, contactID) {
		const { currentUser } = this.context;

		let typeRadio = this.state.typeRadio,
			accountDTO = { id: account.id, name: account.name },
			cb = { id: currentUser.id, name: currentUser.username };

		let nText = document.getElementById("newNoteText").value;

		//we don't have any text, no note to create
		if (nText === "") {
			this.toggleContactModalPopup();
		} else {
			let note = {
				text: nText,
				account: accountDTO,
				completed: true,
				task: false,
				createdAt: new Date(),
				createdBy: cb,
			};

			if (typeRadio === "Task") {
				note.completed = false;
				note.task = true;
			}

			return NoteService.createContactNote(contactID, note)
				.then((res) => {
					toast.success("Contact task successfully created.");
					this.toggleContactModalPopup();
				})
				.catch((err) => {
					toast.warn("Oh! There was an error creating the contact task.");
					this.toggleContactModalPopup();
				});
		}
	}

	updateAccount() {
		let acc = this.state.eAccount;
		return AccountService.updateAccount(acc)
			.then((data) => {
				this.setState({
					account: data,
					eAccount: JSON.parse(JSON.stringify(data)),
				});
				toast.success("Saved Successfully!");
			})
			.catch((err) => {
				toast.warn("An error occurred while saving.");
			});
	}

	handleChange = (property, event) => {
		//update the property that the user has edited in the order...BC
		const nAccount = this.state.eAccount;
		nAccount[property] = event.target.value;
		this.setState({ eAccount: nAccount });
	};

	handleObjChange = (obj, property, event) => {
		//update the property on the object that the user has edited in the order...BC
		const nAccount = this.state.eAccount;
		if (!nAccount.address) {
			nAccount.address = { lines: [] };
		}
		if (property === "lines") {
			nAccount[obj][property][0] = event.target.value;
		} else {
			nAccount[obj][property] = event.target.value;
		}
		this.setState({ eAccount: nAccount });
	};

	handleSelectChange = (property, e) => {
		let n = this.state.eAccount;

		if (property === "marketingCampaign") {
			n.marketingCampaign = e.mcVal;
		} else if (property === "accountType") {
			n.accountType = { id: e.value.id, name: e.value.name };
		}
		this.setState({ eAccount: n });
	};

	retrieveActivities(id) {
		let t = this;

		return ActivityService.getActivitiesByAccount(id)
			.then((data) => {
				let aAry = [],
					dt = this.state.activities,
					activities = data.content;

				activities.forEach((activity, index) => {
					aAry.push({
						type: activity.type,
						expenseDate: activity.expenseDate
							? t.formatDate(activity.expenseDate)
							: t.formatDate(activity.when),
						quality: activity.quality ? "Yes" : "No",
						expenses: activity.expenses
							? t.formatCurrency(activity.expenses)
							: "No Expenses",
						at: activity.vendor || "No Vendor",
						for: activity.expenseDescription || "No Description",
						edit: t.renderRouteButton(activity, index),
					});
				});

				dt.rows = aAry;

				t.setState({
					activities: dt,
					aLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderRouteButton(activity, index) {
		return (
			<MDBIcon
				icon="edit"
				aria-hidden="true"
				color="warning"
				onClick={() => {
					this.props.history.push({
						pathname: "/activity/" + activity.id,
						state: {
							activity: activity,
							prevPage: this.state.account.id + "",
						},
					});
				}}
			/>
		);
	}

	renderActivityTable() {
		if (this.state.aLoaded === true) {
			return (
				<DataTable striped info={false} small data={this.state.activities}>
					{" "}
				</DataTable>
			);
		} else {
			return <Spinner size="small" />;
		}
	}

	retrieveOrders(id) {
		let t = this;

		return OrderService.getOrdersByAccount(id)
			.then((data) => {
				let oAry = [],
					dt = this.state.orders,
					orders = data.content;
				orders.forEach((order, index) => {
					oAry.push({
						id: t.routeOrder(order, index),
						status: order.status || "No Status",
						reason: order.orderStatusReason
							? order.orderStatusReason.name
							: "No Reason",
						patientName: order.patientName,
						when: t.formatDate(order.when),
						insuranceType: order.insuranceType
							? order.insuranceType.name
							: "No Insurance",
					});
				});

				dt.rows = oAry;

				t.setState({
					orders: dt,
					oLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	routeOrder(order) {
		return (
			<a
				style={{ color: "#00afd7" }}
				href={"/order/" + order.id}
				onClick={() => {
					this.setState({ order: order });
				}}
			>
				{order.id}
			</a>
		);
	}

	renderOrderTable() {
		if (this.state.oLoaded === true) {
			return (
				<DataTable striped info={false} small data={this.state.orders}>
					{" "}
				</DataTable>
			);
		} else {
			return <div></div>;
		}
	}

	renderReceipt() {
		if (!this.state.nActivity.receipt) {
			return <div></div>;
		} else {
			return (
				<img
					style={{ width: "200px", height: "300px" }}
					src={this.state.nActivity.receipt}
					alt="Invalid format"
				/>
			);
		}
	}

	renderInformationSection() {
		let account = this.state.eAccount;
		return (
			<div>
				<Row style={{ display: "flex", justifyContent: "space-evenly" }}></Row>

				<Row style={{ display: "flex", justifyContent: "space-evenly" }}>
					<Col size="3">
						<Input
							value={account.name}
							outline
							onChange={this.handleChange.bind(this, "name")}
							label="Account Name"
							size="sm"
							icon="phone"
						/>
					</Col>

					<Col size="3">
						<Input
							value={account.phone || "No Phone"}
							outline
							onChange={this.handleChange.bind(this, "phone")}
							label="Phone"
							size="sm"
							icon="phone"
						/>
					</Col>

					<Col size="3">
						<Input
							value={account.fax || "No Fax"}
							outline
							onChange={this.handleChange.bind(this, "fax")}
							label="Fax"
							size="sm"
							icon="fax"
						/>
					</Col>

					<Col size="3">
						<Input
							value={account.email || "No Email"}
							outline
							onChange={this.handleChange.bind(this, "email")}
							label="E-mail"
							size="sm"
							icon="envelope"
						/>
					</Col>
				</Row>

				<Row style={{ display: "flex", justifyContent: "space-evenly" }}>
					<Col size="4">
						<Input
							value={account.address ? account.address.lines[0] : "No Address"}
							outline
							onChange={this.handleObjChange.bind(this, "address", "lines")}
							label="Address"
							size="sm"
							icon="address-book"
						/>
					</Col>

					<Col size="3">
						<Input
							value={account.address ? account.address.city : "No City"}
							outline
							onChange={this.handleObjChange.bind(this, "address", "city")}
							label="City"
							size="sm"
							icon="location-arrow"
						/>
					</Col>

					<Col size="2">
						<Input
							value={account.address ? account.address.state : "No State"}
							outline
							onChange={this.handleObjChange.bind(this, "address", "state")}
							label="State"
							size="sm"
							icon="map-pin"
						/>
					</Col>

					<Col size="3">
						<Input
							value={account.address ? account.address.zip : "No Zip"}
							outline
							onChange={this.handleObjChange.bind(this, "address", "zip")}
							label="ZipCode"
							size="sm"
							icon="map-pin"
						/>
					</Col>
				</Row>

				<Row>
					<Col md="6" style={{ marginTop: "10px" }}>
						<Select
							placeholder={
								account.marketingCampaign
									? account.marketingCampaign.name
									: "Select Marketing Campaign..."
							}
							options={this.getMarketingCampaigns()}
							onChange={this.handleSelectChange.bind(this, "marketingCampaign")}
						/>
					</Col>
					<Col md="6" style={{ marginTop: "10px" }}>
						<Select
							placeholder={
								account.accountType
									? account.accountType.name
									: "Select Account Type..."
							}
							options={this.getAccountTypeList()}
							onChange={this.handleSelectChange.bind(this, "accountType")}
						/>
					</Col>
				</Row>
			</div>
		);
	}

	getAccountTypeList() {
		const { accountTypes } = this.context;

		let a = [];
		accountTypes.map((type) => {
			return a.push({
				label: type.name,
				value: type,
			});
		});
		return a;
	}

	retrieveContacts(id) {
		let t = this;

		return UserService.getContactsByAccount(id)
			.then((users) => {
				let cAry = [],
					cList = [],
					dt = this.state.contacts,
					contacts = users.content;

				contacts.forEach((contact) => {
					cAry.push({
						name: contact.lastname + "," + contact.firstname,
						type: contact.contactType ? contact.contactType.name : "No Type",
						role: contact.role || "No Role",
						phone: contact.phone || "No Phone",
						mail: contact.email || "No Email",
						edit: this.routeContact(contact),
					});
					cList.push({
						label: contact.firstname + ' ' + contact.lastname,
						value: { id: contact.id, firstname: contact.firstname, lastname: contact.lastname },
					})
				});

				dt.rows = cAry;

				t.setState({
					contacts: dt,
					contactList: cList,
					cLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	routeContact(contact) {
		return (
			<Fa
				icon={"edit"}
				style={{ color: "#00afd7" }}
				onClick={() => {
					this.props.history.push({
						pathname: "/contact/" + contact.id,
						state: { contact: contact },
					});
				}}
			/>
		);
	}

	renderContactTable() {
		if (this.state.cLoaded === true) {
			return (
				<DataTable striped info={false} small data={this.state.contacts}>
					{" "}
				</DataTable>
			);
		} else {
			return <div></div>;
		}
	}

	formatDate(date) {
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "numeric",
			day: "2-digit",
		}).format(date);
	}

	formatDateLong(date) {
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "numeric",
			day: "2-digit",
			hour: "numeric",
			minute: "numeric"
		}).format(date);
	}

	formatCurrency(expense) {
		return new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
		}).format(expense);
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	generateNote(note) {
		return (
			<MDBCard style={{ backgroundColor: "#FFF7D1" }}>
				<MDBCardHeader style={{ backgroundColor: "#FFF2AB" }}>
					<MDBRow>
						<MDBCol size="5">
							{note.createdAt ? this.formatDateLong(new Date(note.createdAt)) : ""}
						</MDBCol>

						<MDBCol size="5">
							{note.createdBy ? note.createdBy.name : ""}
						</MDBCol>

						<MDBCol onClick={() => this.toggleEditModalPopup(note)} size="2">
							<MDBIcon
								style={{ color: "#857E59", padding: 4, float: "right" }}
								data-tip={"Edit"}
								icon="ellipsis-v"
							/>
						</MDBCol>
					</MDBRow>
				</MDBCardHeader>

				<MDBCardBody>{note.text}</MDBCardBody>
			</MDBCard>
		);
	}

	renderNotesSection() {
		let notes = this.sortNotes(this.state.allNotes);

		return (
			<div>
				{notes.map((n, idx) => {
					return (
						<div key={idx} style={{ paddingBottom: 6 }}>
							{this.generateNote(n)}
						</div>
					);
				})}
			</div>
		);
	}

	generateTask(note) {
		function renderAssignedTo() {
			if (note.assignedTo) {
				return (
					<span style={{ fontSize: 14 }}>
						{" "}
						<MDBIcon
							icon="long-arrow-alt-right"
							data-tip={"Assigned To"}
						/>{" "}
						{note.assignedTo.name}
					</span>
				);
			}
		}

		return (
			<MDBCard style={{ backgroundColor: "#FFE4F1" }}>
				<MDBCardHeader style={{ backgroundColor: "#FFCCE5" }}>
					<MDBRow>
						<MDBCol size="5" style={{ fontSize: 14 }}>
							{note.createdAt ? this.formatDateLong(new Date(note.createdAt)) : ""}
						</MDBCol>

						<MDBCol size="5" style={{ fontSize: 14 }}>
							{note.createdBy ? note.createdBy.name : ""}
							{renderAssignedTo()}
						</MDBCol>

						<MDBCol siz="2">
							<MDBIcon
								onClick={() => this.toggleEditModalPopup(note)}
								style={{
									color: "#7B626E",
									float: "right",
									padding: "4px 4px 4px 8px",
								}}
								data-tip={"Edit"}
								icon="ellipsis-v"
							/>
							<MDBIcon
								style={{
									color: "#7B626E",
									float: "right",
									padding: "4px 8px 4px 4px",
								}}
								onClick={() => this.updateNote(note, true)}
								data-tip={"Complete"}
								icon="check"
							/>
						</MDBCol>
					</MDBRow>
				</MDBCardHeader>

				<MDBCardBody>{note.text}</MDBCardBody>
				<ReactTooltip />
			</MDBCard>
		);
	}

	renderTasksSection() {
		let tasks = this.sortNotes(this.state.allNotes, true);

		return (
			<div>
				{tasks.map((n, idx) => {
					return (
						<div key={idx} style={{ paddingBottom: 6 }}>
							{this.generateTask(n)}
						</div>
					);
				})}
			</div>
		);
	}

	renderNotesList() {
		return (
			<div>
				<MDBRow>
					<MDBCol className={"pvNotesGroupOuterContainer"} size="6">
						<div className={"pvNotesGroupInnerContainer"}>
							{this.renderNotesSection()}
						</div>
					</MDBCol>

					<MDBCol className={"pvNotesGroupOuterContainer"} size="6">
						<div className={"pvNotesGroupInnerContainer"}>
							{this.renderTasksSection()}
						</div>
					</MDBCol>
				</MDBRow>
			</div>
		);
	}

	renderAddContactModal() {
		return (
			<div>
				<Container>
					<Row>
						<Modal
							centered
							className="form-elegant"
							isOpen={this.state.contactModalOpen}
							toggle={() => this.toggleContactModalPopup()}
						>
							<ModalBody
								style={{ backgroundColor: "#FFFFFF" }}
								className="mx-3"
							>
								<CardHeader
									color={"indigo"}
									className="form-header text-center"
								>
									Add New Contact
								</CardHeader>

								<CardBody>
									<Row style={{ display: "flex", justifyContent: "center" }}>
										<Col size="4">
											<Input
												label="First Name"
												value={this.state.nContact.firstname}
												onChange={this.contactChange.bind(this, "firstname")}
											/>
										</Col>

										<Col size="4">
											<Input
												label="Last Name"
												value={this.state.nContact.lastname}
												onChange={this.contactChange.bind(this, "lastname")}
											/>
										</Col>

										<Col size="4">
											<Input
												label="Phone"
												value={this.state.nContact.phone}
												onChange={this.contactChange.bind(this, "phone")}
											/>
										</Col>
									</Row>

									<Row style={{ display: "flex", justifyContent: "center" }}>
										<Col size="5">
											<Input
												label="Email"
												value={this.state.nContact.email}
												onChange={this.contactChange.bind(this, "email")}
											/>
										</Col>

										<Col size="5">
											<Input
												label="Title"
												value={this.state.nContact.role}
												onChange={this.contactChange.bind(this, "role")}
											/>
										</Col>
									</Row>

									<Row style={{ display: "flex", justifyContent: "center" }}>
										<Col size="8">
											<Select
												placeholder={"Select Contact Type..."}
												options={this.getContactTypesList()}
												onChange={this.handleContactTypeSelected.bind(this)}
											/>
										</Col>
									</Row>

									<Col size="12">
										<hr />
									</Col>

									<Row>
										<Col size="5">
											<Input
												onClick={() => this.setState({ typeRadio: "Note" })}
												checked={this.state.typeRadio === "Note"}
												label="Note"
												type="radio"
												id="noteRadio"
											/>
										</Col>
										<Col size="5">
											<Input
												onClick={() => this.setState({ typeRadio: "Task" })}
												checked={this.state.typeRadio === "Task"}
												label="Task"
												type="radio"
												id="taskRadio"
											/>
										</Col>
									</Row>

									<Row>
										<Col md="12">
											<textarea
												placeholder={"Enter Text"}
												id={"newNoteText"}
												style={{ width: "100%", height: "160px" }}
											></textarea>
										</Col>
									</Row>
								</CardBody>
								<Button onClick={this.addNewContact.bind(this)}>
									{" "}
									Add Contact{" "}
								</Button>
							</ModalBody>
						</Modal>
					</Row>
				</Container>
			</div>
		);
	}

	renderNoteModal() {
		return (
			<MDBContainer>
				<MDBRow>
					<Modal
						centered
						isOpen={this.state.noteModalOpen}
						toggle={() => this.setState({ noteModalOpen: false })}
					>
						<ModalBody style={{ backgroundColor: "#FFFFFF" }} className="mx-3">
							<CardHeader color={"indigo"} className="form-header text-center">
								Add New Note
							</CardHeader>
							<form className=" mx-3 grey-text">
								<textarea
									placeholder={"Add note text here."}
									autoFocus
									id={"newActivityNoteText"}
									style={{ width: "100%", height: "200px" }}
								></textarea>

								<Row>
									<Col size="12">
										<div className={"datePickerOptions"}>
											<DatePicker
												format="MM-DD-YYYY"
												hint={"01-01-1900"}
												keyboard
												getValue={(evt) => {
													this.setState({ dueDate: evt });
												}}
												mask={[
													/\d/,
													/\d/,
													"-",
													/\d/,
													/\d/,
													"-",
													/\d/,
													/\d/,
													/\d/,
													/\d/,
												]}
												label="Due Date"
											/>
										</div>
									</Col>
								</Row>

								<Row>
									<Col size="6">
										<Input
											type="radio"
											gap
											label={"Note"}
											checked={this.state.taskCompleted}
											onClick={() => this.setState({ taskCompleted: true })}
											id={"noteRadio"}
										/>
									</Col>

									<Col size="6">
										<Input
											type="radio"
											gap
											label={"Task"}
											checked={!this.state.taskCompleted}
											onClick={() => this.setState({ taskCompleted: false })}
											id={"taskRadio"}
										/>
									</Col>
								</Row>
								<br />

								<ModalFooter>
									<span
										className={"ptBtn saveBtn"}
										data-tip={"Add Note"}
										onClick={this.addNewNote.bind(this)}
									>
										<Fa size="2x" icon={"check"}>
											{" "}
										</Fa>
									</span>
									<span
										className={"ptBtn cancelBtn"}
										data-tip={"Cancel"}
										onClick={() => this.setState({ noteModalOpen: false })}
									>
										<Fa size="2x" icon={"times"}>
											{" "}
										</Fa>
									</span>
								</ModalFooter>
								<ReactTooltip />
							</form>
						</ModalBody>
					</Modal>
				</MDBRow>
			</MDBContainer>
		);
	}

	renderAddCallModal() {
		return (
			<MDBModal
				centered
				isOpen={this.state.callModalOpen}
				toggle={() => this.setState({ callModalOpen: false })}
			>
				<MDBContainer>
					<CardHeader
						style={{ marginBottom: 2 }}
						color={"indigo"}
						className="form-header text-center"
					>
						Add New Call
					</CardHeader>
					<MDBRow>
						<MDBCol size="6" className={"smallMargin uiDateOutline"}>
							<DatePicker
								style={{ paddingLeft: 0 }}
								format="MM-DD-YYYY"
								variant={"outlined"}
								hint={"01-01-1900"}
								size="small"
								getValue={(evt) => {
									let act = this.state.nCall;
									act.when = evt;
									this.setState({ nCall: act });
								}}
								mask={[
									/\d/,
									/\d/,
									"-",
									/\d/,
									/\d/,
									"-",
									/\d/,
									/\d/,
									/\d/,
									/\d/,
								]}
								label="Call Date"
							/>
						</MDBCol>

						<MDBCol size="6">
							<div style={{ paddingTop: 34 }}>
								<Input
									onChange={(evt) => {
										let nCall = this.state.nCall;
										nCall.quality = evt.target.checked;
										nCall.callType = null;
										nCall.callReason = null;
										this.setState({ nCall: nCall });
									}}
									filled
									label="Marketing"
									type="checkbox"
									id="checkbox6"
								/>
							</div>
						</MDBCol>

						<MDBCol size="12">
							{ this.state.nCall.quality === true ?
								<Select
									placeholder={"Select Contact..."}
									options={this.state.contactList}
									onChange={(e) => {
										let nCall = this.state.nCall;
										let c = [];
										c.push(e.value);
										nCall.contacts = c;
										this.setState({ nCall: nCall });
									}}
								/>
								: '' }
						</MDBCol>
						
						<MDBCol size="12"><br/></MDBCol>

						<MDBCol size="12">
							{ this.state.nCall.quality === false ?
								<Select
									placeholder={"Select Call Type..."}
									options={this.state.callTypes}
									onChange={(e) => {
										let nCall = this.state.nCall;
										nCall.callType = e.value;
										this.setState({ nCall: nCall });
									}}
								/>
								:
								<Select
									placeholder={"Select Call Reason..."}
									options={this.state.callReasons}
									onChange={(e) => {
										let nCall = this.state.nCall;
										nCall.callReason = e.value;
										this.setState({ nCall: nCall });
									}}
								/>
							}
						</MDBCol>

						<MDBCol size="12"><br/></MDBCol>

						<MDBCol size="12">
							{ this.state.nCall.callReason === "Other" ?
								<textarea
									id={"newNoteText"}
									style={{width:"100%"}}
									placeholder={"Add a note..."}
									onChange={(e) => {
										let txt = e.target.value;
										this.setState({
											callNoteText: txt
										})
									}}
								></textarea>
								: ''
							}
						</MDBCol>

						<MDBCol size="9"></MDBCol>
						<MDBCol size="3">
							<div style={{ paddingTop: 25 }}>
								<MDBBtn
									size="sm"
									color={"success"}
									onClick={() => this.createActivity("Call")}
								>
									Save
								</MDBBtn>
							</div>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</MDBModal>
		);
	}

	renderAddAppointmentModal() {
		return (
			<MDBModal
				centered
				isOpen={this.state.appointmentModalOpen}
				toggle={() => this.setState({ appointmentModalOpen: false })}
			>
				<MDBContainer>
					<CardHeader
						style={{ marginBottom: 2 }}
						color={"indigo"}
						className="form-header text-center"
					>
						Add New Appointment
					</CardHeader>

					<MDBRow>
						<MDBCol size="6" className={"smallMargin uiDateOutline"}>
							<DatePicker
								style={{ paddingLeft: 0 }}
								format="MM-DD-YYYY"
								variant={"outlined"}
								size="small"
								hint={"01-01-1900"}
								getValue={(evt) => {
									let act = this.state.nActivity;
									act.expenseDate = evt;
									this.setState({ nActivity: act });
								}}
								mask={[
									/\d/,
									/\d/,
									"-",
									/\d/,
									/\d/,
									"-",
									/\d/,
									/\d/,
									/\d/,
									/\d/,
								]}
								label="Appointment Date"
							/>
						</MDBCol>
						<MDBCol size="6">
							<MDBInput
								label="Amount Spent"
								outline
								style={{ paddingTop: 10 }}
								value={this.state.nActivity.expenses}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.expenses = evt.target.value;
									this.setState({ nActivity: act });
								}}
							/>
						</MDBCol>
					</MDBRow>

					<MDBRow>
						<Col size="6">
							<Select
								placeholder={"Select Card Type"}
								options={this.getCardList()}
								onChange={this.handleCardTypeSelected.bind(this)}
							/>
						</Col>

						<Col size="6">
							<Select
								placeholder={"Select Expense Type"}
								options={this.getExpenseTypeList()}
								onChange={this.handleExpenseTypeSelected.bind(this)}
							/>
						</Col>
					</MDBRow>

					<MDBRow>
						<Col size="12">
							<Input
								label="Appointment Description"
								outline
								containerClass={"medMargin"}
								value={this.state.nActivity.expenseDescription}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.expenseDescription = evt.target.value;
									this.setState({ nActivity: act });
								}}
							/>
						</Col>
					</MDBRow>

					<MDBRow>
						<Col size="6">
							<Input
								label="Vendor"
								outline
								containerClass={"medMargin"}
								value={this.state.nActivity.vendor}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.vendor = evt.target.value;
									this.setState({ nActivity: act });
								}}
							/>
						</Col>

						<Col size="6">
							<Input
								label="People Attending (amount)"
								outline
								containerClass={"medMargin"}
								value={this.state.nActivity.peopleAttending}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.peopleAttending = evt.target.value;
									this.setState({ nActivity: act });
								}}
							/>
						</Col>
					</MDBRow>

					<MDBRow>
						<Col size="6">
							<Input
								label="Topics Discussed"
								outline
								containerClass={"medMargin"}
								value={this.state.nActivity.educationalTopics}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.educationalTopics = evt.target.value;
									this.setState({ nActivity: act });
								}}
							/>
						</Col>

						<Col size="6">
							<Input
								label="Physicians Attending (names)"
								outline
								containerClass={"medMargin"}
								value={this.state.nActivity.physiciansAttending}
								onChange={(evt) => {
									let act = this.state.nActivity;
									act.physiciansAttending = evt.target.value;
									this.setState({ nActivity: act });
								}}
							/>
						</Col>
					</MDBRow>

					<InputFile
						type="file"
						textFieldTitle={"Upload receipt (.jpg or .png only)"}
						className="form-control-file"
						name="file"
						getValue={this.encodeImageFileAsURL.bind(this)}
					/>
					<MDBRow style={{ justifyContent: "space-evenly" }}>
						{this.renderReceipt()}
					</MDBRow>

					<MDBBtn
						size="sm"
						color={"success"}
						onClick={() => this.createActivity("Appointment")}
						style={{ float: "right" }}
					>
						Save
					</MDBBtn>
				</MDBContainer>
			</MDBModal>
		);
	}

	renderEditNoteModal() {
		let note = this.state.modalNote,
			isTask = note.completed === false;

		let bgClass = "orderNotesModal",
			bgColor = "#FFF7D1";

		if (isTask) {
			bgClass = "orderTasksModal";
			bgColor = "#FFE4F1";
		}
		return (
			<MDBModal
				className={bgClass}
				centered
				isOpen={this.state.editNoteOpen}
				toggle={() => this.toggleEditModalPopup()}
			>
				<MDBModalBody>
					<MDBRow>
						<MDBCol size="12">
							<textarea
								id={"noteTextArea"}
								style={{
									width: "100%",
									height: "200px",
									backgroundColor: bgColor,
								}}
							>
								{note.text}
							</textarea>
						</MDBCol>

						<MDBCol size="12">
							<span>Updated: {this.formatDate(new Date())}</span>

							<MDBIcon
								style={{ color: "#7B626E", float: "right", padding: 4 }}
								data-tip={"Update"}
								icon="check"
								onClick={() => {
									this.updateNoteModal(note);
									this.toggleEditModalPopup();
								}}
							/>
						</MDBCol>
					</MDBRow>
				</MDBModalBody>
				<ReactTooltip />
			</MDBModal>
		);
	}

	render() {
		if (this.state.nLoaded === false) {
			return this.renderLoadingSpinner();
		}

		let account = this.state.eAccount;

		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>

				<CardHeader
					style={{
						paddingTop: 12,
						paddingBottom: 8,
						marginBottom: 10,
						color: "white",
						fontSize: 18,
						backgroundColor: "#1976D2",
					}}
				>
					<Row>
						<Col size="2">
							<MDBBtn
								className="px-2 toolbarButton"
								data-tip="Return to Order Listing"
								data-place={"right"}
								outline
								rounded
								onClick={() => this.props.history.push("/accounts")}
								size="sm"
								color={"warning"}
							>
								<MDBIcon icon="long-arrow-alt-left" />
							</MDBBtn>
							<ReactTooltip />
						</Col>
						<Col size="5">
							<span style={{ fontSize: 24 }}>{account.name}</span>
						</Col>
						<Col size="3">
							<span style={{ fontSize: 24 }}>
								{account.owner ? account.owner.name : "No Owner"}
							</span>
						</Col>

						<Col size="2">
							<MDBBtn
								className="px-2 toolbarButton"
								outline
								rounded
								size="sm"
								color={"secondary"}
								data-tip={"Add Note / Task"}
								data-place={"bottom"}
								onClick={() => {
									this.toggleNoteModalPopup();
								}}
							>
								<MDBIcon icon="sticky-note" />
							</MDBBtn>

							<MDBBtn
								className="px-2 toolbarButton"
								outline
								rounded
								size="sm"
								color={"amber"}
								data-tip={"Add Call"}
								data-place={"bottom"}
								onClick={() => {
									this.setState({
										callModalOpen: true,
									});
								}}
							>
								<MDBIcon icon="phone" />
							</MDBBtn>

							<MDBBtn
								className="px-2 toolbarButton"
								outline
								rounded
								size="sm"
								color={"yellow"}
								data-tip={"Add Appointment"}
								data-place={"bottom"}
								onClick={() => {
									this.setState({
										appointmentModalOpen: true,
									});
								}}
							>
								<MDBIcon icon="handshake" />
							</MDBBtn>

							<MDBBtn
								className="px-2 toolbarButton"
								outline
								rounded
								size="sm"
								color={"orange"}
								data-tip={"Add Contact"}
								data-place={"bottom"}
								onClick={() => {
									this.toggleContactModalPopup();
								}}
							>
								<MDBIcon icon="user" />
							</MDBBtn>

							<MDBBtn
								onClick={() => this.updateAccount()}
								data-tip={"Save Order"}
								data-place={"bottom"}
								outline
								rounded
								size="sm"
								color="success"
								className="px-2 toolbarButton"
							>
								<MDBIcon icon="save" />
							</MDBBtn>
						</Col>
					</Row>
				</CardHeader>
				{this.renderNoteModal()}
				{this.renderAddCallModal()}
				{this.renderAddAppointmentModal()}
				<Container className="mt-4">
					<Row>
						<Col md="12">
							<Nav tabs className="nav-justified" color={"blue"}>
								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({
											active: this.state.activeItem === "1",
										})}
										onClick={() => {
											this.toggle("1");
										}}
										role="tab"
									>
										Notes & Tasks
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({
											active: this.state.activeItem === "2",
										})}
										onClick={() => {
											this.toggle("2");
										}}
										role="tab"
									>
										Activities
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({
											active: this.state.activeItem === "3",
										})}
										onClick={() => {
											this.toggle("3");
										}}
										role="tab"
									>
										Orders
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({
											active: this.state.activeItem === "4",
										})}
										onClick={() => {
											this.toggle("4");
										}}
										role="tab"
									>
										Contacts
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										link
										to="#"
										className={classnames({
											active: this.state.activeItem === "5",
										})}
										onClick={() => {
											this.toggle("5");
										}}
										role="tab"
									>
										Details
									</NavLink>
								</NavItem>
							</Nav>

							<TabContent className="card" activeItem={this.state.activeItem}>
								<TabPane tabId="1" role="tabpanel">
									{this.renderNotesList()}

									{this.renderEditNoteModal()}
								</TabPane>
								<TabPane tabId="2" role="tabpanel">
									{this.renderActivityTable()}
								</TabPane>
								<TabPane tabId="3" role="tabpanel">
									{this.renderOrderTable()}
								</TabPane>
								<TabPane tabId="4" role="tabpanel">
									{this.renderContactTable()}
									{this.renderAddContactModal()}
								</TabPane>
								<TabPane tabId="5" role="tabpanel">
									{this.renderInformationSection()}
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</Container>
				<ReactTooltip />
			</div>
		);
	}
}
