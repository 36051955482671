import React from "react";
import {MDBBtn, MDBCard, MDBCol, MDBRow, MDBSpinner} from "mdbreact";
import PropTypes from "prop-types";
import AccountService from "../../Seating/Security/AccountService/accountService";

export default class MonthGlanceView extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            isLoaded: false,
            usersLoaded: true,
            currentMonth: new Date().toLocaleString('default', {month: 'short', year: 'numeric'}),
            usersGrid: [],
            locationsGrid: [],
            byRep: false,
            grandTotals:{},
        };
    }

    static contextTypes = {
        currentUser: PropTypes.object,
        allActiveUsers: PropTypes.array,
    };


    componentDidMount() {
        this.determineAnalytics();
    }

    determineAnalytics() {
        const {currentUser} = this.context;
        let r = currentUser.role,
            locations = currentUser.locations;

        if (r === 'SYSTEM_ADMIN' || r === 'ADMIN' || r === 'SALES_MANAGER' || r === 'EXECUTIVE_MANAGEMENT') {
            this.getAllLocationAnalytics(currentUser.id);
        } else {
            this.setState({
                byRep: true
            })

            this.getRepAnalytics(currentUser.id);
        }


    }

    getAllLocationAnalytics(id) {
        let m = (new Date().getMonth() + 1),
            y = new Date().getFullYear();

        AccountService.getLocationsStatisticsMonth(id, m, y).then(d => {
            this.setState({
                analytics: d
            });

            this.sortAnalytics(d);
        })
    }


    getAllLocationAnalyticsForReps(locationId) {
        let m = (new Date().getMonth() + 1),
            y = new Date().getFullYear();

        this.setState({
            usersLoaded: false
        })

        AccountService.getRepsForLocationsMonth(locationId, m, y).then(d => {
            this.sortAnalyticsRep(d);
        })

    }

    getRepAnalytics(userId) {
        AccountService.getRepAnalytics(userId, "M").then(d => {
            this.sortAnalyticsRep(d);
        }).catch(err => {

        });
    }

    sortAnalytics(vals) {
        const {currentUser} = this.context;

        let locationsGrid = [],
            locationIds = [],
            at = 0,
            dat = 0,
            pt = 0,
            dpt = 0,
            rtd = 0,
            drtd = 0,
            rvfu = 0,
            drvfu = 0,
            gt = {};

        let userLocations = currentUser.locations;

        vals.forEach(val => {
            let currLoc = userLocations.find(p => {
                return p.id === val.spLocationId;
            });

            if (!currLoc) {
                //if the user doesn't have this location, skip it...BC
                return;
            }

            let idx = locationIds.indexOf(val.spLocationId),
                obj = {
                    locationId: val.spLocationId,
                    locationName: currLoc.name
                };

            if (idx > -1) {
                obj = locationsGrid[idx];
            } else {
                locationIds.push(val.spLocationId);
                locationsGrid.push(obj);
            }

            obj[val.spEntryType] = val;


            switch (val.spEntryType) {
                case 'approval':
                    at += parseFloat(val.spValue);
                    break;
                case 'dailyApproval':
                    dat += parseFloat(val.spValue);
                    break;
                case 'points':
                    pt += parseFloat(val.spValue);
                    break;
                case 'dailyPoints':
                    dpt += parseFloat(val.spValue);
                    break;
                case 'RTD':
                    rtd += parseFloat(val.spValue);
                    break;
                case 'dailyRTD':
                    drtd += parseFloat(val.spValue);
                    break;
                case 'revFollowUP':
                    rvfu += parseFloat(val.spValue);
                    break;
                case 'dailyRevFollowUp':
                    drvfu += parseFloat(val.spValue);
                    break;

                default:
                    break;
            }

            gt = {
                monthlyApprovalsTotal: at ? at.toFixed(2) : 0,
                dailyApprovalsTotal: dat ? dat.toFixed(2) : 0,
                monthlyPointsTotal: pt ? pt.toFixed(2) : 0,
                dailyPointsTotal: dpt ? dpt.toFixed(2) : 0,
                monthlyRTDTotal: rtd ? rtd.toFixed(2) : 0,
                dailyRTDTotal: drtd ? drtd.toFixed(2) : 0,
                monthlyReviewTotal: rvfu ? rvfu.toFixed(2) : 0,
                dailyReviewTotal: drvfu ? drvfu.toFixed(2) : 0,
            }
        });

        this.setState({
            locationsGrid: locationsGrid,
            isLoaded: true,
            grandTotals: gt

        });

    }

    sortAnalyticsRep(vals) {
        const {allActiveUsers, currentUser} = this.context;

        let UsersGrid = [],
            UserIds = [];

        vals.forEach(val => {
            let currUser = allActiveUsers.find(p => {
                return parseInt(p.userId) === val.spLocationId;
            });

            if (!currUser) {
                if (this.state.byRep) {
                    currUser = currentUser;
                } else {
                    //if the user is not active, skip it...BC
                    return;
                }
            }

            let idx = UserIds.indexOf(val.spLocationId),
                obj = {
                    userId: val.spLocationId,
                    userName: currUser.firstname + ' ' + currUser.lastname
                };

            if (idx > -1) {
                obj = UsersGrid[idx];
            } else {
                UserIds.push(val.spLocationId);
                UsersGrid.push(obj);
            }

            obj[val.spEntryType] = val;

        });

        this.setState({
            usersGrid: UsersGrid,
            usersLoaded: true,
            isLoaded: true,
        });

    }

    renderTotalCard(){
        let gt = this.state.grandTotals;

        function renderPoints(val) {
            if (val.monthlyPointsTotal) {
                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>Setup Pts</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.dailyPointsTotal}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.monthlyPointsTotal}</span>
                        </MDBCol>
                    </MDBRow>
                )
            }
        }

        function renderApprovals(val) {
            if (val.monthlyApprovalsTotal) {
                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>Approvals</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.dailyApprovalsTotal}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.monthlyApprovalsTotal}</span>
                        </MDBCol>
                    </MDBRow>
                )
            }
        }

        function renderReview(val) {
            if (val.monthlyReviewTotal) {
                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>R/F</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.dailyReviewTotal}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.monthlyReviewTotal}</span>
                        </MDBCol>
                    </MDBRow>
                )
            }
        }

        function renderRTD(val) {
            if (val.monthlyRTDTotal) {
                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>RTD Pts</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.dailyRTDTotal}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.monthlyRTDTotal}</span>
                        </MDBCol>
                    </MDBRow>
                )
            }
        }

        return(
            <MDBCol style={{paddingBottom: 30}}>
                <MDBBtn block className={"monthGlanceCard"} color={"white"}>
                    <div>
                        <MDBRow style={{height: 25}}>
                            <MDBCol>
                                        <span style={{
                                            textAlign: 'center',
                                            color: 'black',
                                            fontSize: 18
                                        }}>All Locations Total</span>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{height: 20}}>
                            <MDBCol/>
                            <MDBCol>
                                        <span style={{
                                            textAlign: 'center',
                                            textDecorationLine: 'underline',
                                            color: 'black',
                                            fontSize: 16
                                        }}>Day</span>
                            </MDBCol>
                            <MDBCol>
                                        <span style={{
                                            textAlign: 'center',
                                            textDecorationLine: 'underline',
                                            color: 'black',
                                            fontSize: 16
                                        }}>Month</span>
                            </MDBCol>
                        </MDBRow>
                        {renderApprovals(gt)}
                        {renderReview(gt)}
                        {renderRTD(gt)}
                        {renderPoints(gt)}
                    </div>
                </MDBBtn>
            </MDBCol>
        )
    }

    renderCards() {
        let vals = this.state.locationsGrid;

        function renderPoints(val) {
            if (val.points) {

                let dp = val.dailyPoints ? val.dailyPoints.spValue : 0;
                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>Setup Pts</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{dp}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.points.spValue}</span>
                        </MDBCol>
                    </MDBRow>
                )
            }
        }

        function renderApprovals(val) {
            if (val.approval) {
                let da = val.dailyApproval ? val.dailyApproval.spValue : 0;

                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>Approvals</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{da}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.approval.spValue}</span>
                        </MDBCol>
                    </MDBRow>
                )
            }
        }

        function renderReview(val) {
            if (val.revFollowUP) {
                let dp = val.dailyRevFollowUp ? val.dailyRevFollowUp.spValue : 0;
                let v = val.revFollowUP ? val.revFollowUP.spValue : 0;
                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>R/F</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{dp}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{v}</span>
                        </MDBCol>
                    </MDBRow>
                )
            }
        }

        function renderRTD(val) {
            if (val.RTD) {

                let dp = val.dailyRTD ? val.dailyRTD.spValue : 0;
                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>RTD Pts</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{dp}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.RTD.spValue}</span>
                        </MDBCol>
                    </MDBRow>
                )
            }
        }

        return (
            vals.map((v, idx) => {
                return (
                    <MDBCol key={idx} style={{paddingBottom: 30}}>
                        <MDBBtn block className={"monthGlanceCard"} color={"white"}
                                onClick={() => this.getAllLocationAnalyticsForReps(v.locationId)}>
                            <div>
                                <MDBRow style={{height: 25}}>
                                    <MDBCol>
                                        <span className={"monthGlanceCardRowTitle"}>{v.locationName}</span>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow style={{height: 20}}>
                                    <MDBCol/>
                                    <MDBCol>
                                        <span className={"monthGlanceCardRowTitleHeaders"}>Day</span>
                                    </MDBCol>
                                    <MDBCol>
                                        <span className={"monthGlanceCardRowTitleHeaders"}>Month</span>
                                    </MDBCol>
                                </MDBRow>
                                {renderApprovals(v)}
                                {renderReview(v)}
                                {renderRTD(v)}
                                {renderPoints(v)}
                            </div>
                        </MDBBtn>
                    </MDBCol>
                )
            })
        )
    }

    renderCardsReps() {
        let vals = this.state.usersGrid;

        function renderDayMonth(val) {
            if (val.points || val.approval) {
                return (
                    <MDBRow style={{height: 20}}>
                        <MDBCol/>
                        <MDBCol>
                            <span style={{
                                fontSize: 12,
                                textAlign: 'center',
                                textDecorationLine: 'underline',
                                color: 'white'
                            }}>Day</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{
                                fontSize: 12,
                                textAlign: 'center',
                                textDecorationLine: 'underline',
                                color: 'white'
                            }}>Month</span>
                        </MDBCol>
                    </MDBRow>
                )
            }
        }

        function renderPoints(val) {
            if (val.points) {

                let dp = val.dailyPoints ? val.dailyPoints.spValue : 0;

                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>Points</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{dp}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.points.spValue}</span>
                        </MDBCol>
                    </MDBRow>
                )
            }
        }

        function renderApprovals(val) {
            if (val.approval) {
                let da = val.dailyApproval ? val.dailyApproval.spValue : 0;

                return (
                    <MDBRow className={"monthGlanceCardRow"}>
                        <MDBCol>
                            <span>Approvals</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{da}</span>
                        </MDBCol>
                        <MDBCol>
                            <span style={{textAlign: 'center'}}>{val.approval.spValue}</span>
                        </MDBCol>
                    </MDBRow>
                )
            }
        }

        function renderExpenses(val) {
            let app = val.approvedExpenses ? val.approvedExpenses.spValue : 0,
                uap = val.unapprovedExpenses ? val.unapprovedExpenses.spValue : 0;

            if (app || uap) {
                return (
                    <div>
                        <MDBRow style={{height: 30, paddingTop: 10}}>
                            <MDBCol/>
                            <MDBCol>
                                <span style={{
                                    fontSize: 12,
                                    textAlign: 'center',
                                    textDecorationLine: 'underline',
                                    color: 'white'
                                }}>Approved</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{
                                    fontSize: 12,
                                    textAlign: 'center',
                                    textDecorationLine: 'underline',
                                    color: 'white'
                                }}>Not Approved</span>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className={"monthGlanceCardRow"}>
                            <MDBCol>
                                <span>Expenses</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{textAlign: 'center'}}>{app}</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{textAlign: 'center'}}>{uap}</span>
                            </MDBCol>
                        </MDBRow>
                    </div>
                )
            }
        }

        function renderCalls(val) {
            let cs = val.callsS ? val.callsS.spValue : 0,
                cq = val.callsQ ? val.callsQ.spValue : 0;

            if (cs || cq) {
                return (
                    <div>
                        <MDBRow style={{height: 30, paddingTop: 10}}>
                            <MDBCol/>
                            <MDBCol>
                                <span style={{
                                    fontSize: 12,
                                    textAlign: 'center',
                                    textDecorationLine: 'underline',
                                    color: 'white'
                                }}>Standard</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{
                                    fontSize: 12,
                                    textAlign: 'center',
                                    textDecorationLine: 'underline',
                                    color: 'white'
                                }}>Marketing</span>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className={"monthGlanceCardRow"}>
                            <MDBCol>
                                <span>Calls</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{textAlign: 'center'}}>{cs}</span>
                            </MDBCol>
                            <MDBCol>
                                <span style={{textAlign: 'center'}}>{cq}</span>
                            </MDBCol>
                        </MDBRow>
                    </div>
                )
            }
        }

        //onPress={() => this._routeToAppointments(v.userName, v.userId)}

        if (!this.state.usersLoaded) {
            return (<MDBSpinner/>)
        }

        return (
            vals.map((v, idx) => {
                return (
                    <MDBCol key={idx} style={{paddingBottom: 30}}>
                        <MDBBtn block className={"monthGlanceCardRep"} color={"grey"}>
                            <div>
                                <MDBRow style={{height: 25}}>
                                    <MDBCol>
                                        <span style={{
                                            textAlign: 'center',
                                            fontSize: 20,
                                            textDecorationLine: 'underline',
                                            paddingBottom: 4,
                                            color: 'white'
                                        }}>{v.userName}</span>
                                    </MDBCol>
                                </MDBRow>
                                {renderDayMonth(v)}
                                {renderApprovals(v)}
                                {renderPoints(v)}
                                {renderCalls(v)}
                                {renderExpenses(v)}
                            </div>
                        </MDBBtn>
                    </MDBCol>
                )
            })
        )
    }

    render() {
        if (!this.state.isLoaded) {
            return (<MDBSpinner/>)
        }

        return (
            <div style={{padding: 50}}>
                <MDBRow>
                    <MDBCol size={12}>
                        {this.renderTotalCard()}
                    </MDBCol>

                    <MDBCol size={6}>
                        {this.renderCards()}
                    </MDBCol>
                    <MDBCol size={6}>
                        {this.renderCardsReps()}
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }


}

