import React from "react";
import {
	Button,
	Card,
	CardBody,
	Col,
	Collapse,
	Fa,
	Input,
	MDBBadge,
	MDBCol,
	MDBDatePicker,
	MDBDropdown,
	MDBDropdownItem,
	MDBDropdownMenu,
	MDBDropdownToggle,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Spinner,
	toast,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import ActivityService from "../Security/ActivityService/activityService";
import OrderService from "../Security/OrderService/orderService";
import GroupService from "../Security/UserService/groupService";
import PropTypes from "prop-types";
import "./deliveries.css";

export default class deliveries extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [],
			rows: [],
		};

		let startDate = new Date(),
			startDay = startDate.getDay(),
			endDate = new Date();

		startDate.setDate(startDate.getDate() + -startDay);

		endDate.setDate(startDate.getDate() + 7);

		let df = {
			statuses: ["ReadyToDeliver"],
			users: [],
			deliveryDateStart: startDate.toLocaleDateString(),
			deliveryDateEnd: endDate.toLocaleDateString(),
		};

		this.state = {
			data: data,
			filterParams: df,
			collapseID: "collapse1",
			openOrders: [],
			openOrdersReady: false,
			openModal: false,
			openOrder: {},
			openOrderTech: null,
			openOrderDate: "",
			openOrderTime: [],
			errorMessage: "",
			locationsLoaded: false,
			deliveriesLoaded: false,
			searchField: "",
			applyOrderFilterLoaded: true,
			applyCalendarFiltersLoaded: true,
			allLocations: [],
			techSelectArr: [],
			techsSelected: [],
			userLocations: [],
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		allLocations: PropTypes.array,
		productTypes: PropTypes.array,
	};

	toggleCollapse = (collapseID) => () =>
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : "",
		}));

	componentDidMount() {
		this.getUserLocations();
	}

	componentDidUpdate() {
		const { allLocations } = this.context;

		if (allLocations.length > 0 && this.state.allLocations.length === 0) {
			this.getUserLocations();
		}
	}

	getUserLocations() {
		const { currentUser, allLocations } = this.context;

		let locations = [];

		if (
			currentUser.role === "ADMIN" ||
			currentUser.role === "SYSTEM_ADMIN" ||
			currentUser.role === "EXECUTIVE_MANAGEMENT"
		) {
			locations = allLocations;
		} else {
			locations = currentUser.locations;
		}

		this.setState({
			userLocations: locations,
			allLocations: allLocations,
		});

		this.getTechsFromLocation(locations);
		this.getOrdersByLocation(locations);
		this.retrieveDeliveries(locations);
	}

	//Get all techs that are part of the same groups as the current user...JK
	getTechsFromLocation(locations) {
		let locationIDs = [],
			techList = [],
			a = [];

		locations.forEach((loc) => {
			if (loc.type === "Internal") {
				locationIDs.push(loc.id);
			}
		});

		GroupService.getAllTechniciansByLocations(locationIDs)
			.then((res) => {
				// This is to create the the technician options for the dropdowns
				res.forEach((t) => {
					// If a tech is part of multiple locations and so is the current user and they are the same location
					// this prevents the tech from being listed more than once in the dropdown
					if (techList.indexOf(t.id) === -1) {
						let loc = "";

						if (t.locations) {
							loc = t.locations[0].name;

							if (loc.indexOf("Inactive") > -1) {
								//don't push inactive users...BC
								return;
							}
						}

						a.push({
							label: t.username + " -- " + loc,
							value: {
								name: t.username,
								id: t.id,
							},
						});
					}
					techList.push(t.id);
				});
				this.setState({
					techSelectArr: a,
					techsSelected: techList,
				});
			})
			.catch((e) => {});

		this.setState({
			locationsLoaded: true,
		});
	}

	// Grabs all orders that are ready to be scheduled...JK
	getOrdersByLocation(locations) {
		let internal = [],
			openOrders = [];

		// This takes the locations selected in the dropdown and adds them as the part of the parameters for api call...JK
		locations.map((location) => {
			return internal.push(location.id);
		});

		let f = {
			statuses: "ReadyToDeliver",
			internalLocations: internal,

			q: this.state.searchField,
		};

		OrderService.getOrdersWithLocationsNoDeliveryDate(f)
			.then((res) => {
				res.content.forEach((order) => {
					openOrders.push(order);
				});
				// Sorts orders so the orders that have been in RTD status the longest will be displayed first...JK
				openOrders.sort((a, b) => {
					return a.lastStatusChange - b.lastStatusChange;
				});

				this.setState({
					openOrdersReady: true,
					openOrders: openOrders,
					applyOrderFilterLoaded: true,
				});
				this.renderOpenOrders();
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	// Grabs all the order that scheduled for delivery to be added to the calendar...JK
	retrieveDeliveries(locations) {
		let filters = this.state.filterParams,
			locas = [];

		locations.map((location) => {
			return locas.push(location.id);
		});

		filters.internalLocations = locas;

		return OrderService.getOrdersWithLocationsDeliveryDate(filters)
			.then((res) => {
				this.setState({
					rawData: res.content,
				});

				this.formatGrid(res.content);
			})
			.catch((err) => {
							console.log(err);
						});
	}

	formatGrid(dels) {
		let deliveries = [],
			filters = this.state.filterParams,
			t = this,
			d = this.state.data,
			techs = this.state.techsSelected,
			maxArr = [],
			max = 0;

		dels.forEach((order) => {
			deliveries.push([
				{
					address: order.address,
					city: order.city,
					patientName: order.patientName,
					state: order.state,
					technician: order.technician,
					products: order.products,
					id: order.id,
					zipCode: order.zipCode,
					scheduledDeliveryDate: order.scheduledDeliveryDate,
					fullOrder: order,
				},
				order.scheduledDeliveryDate,
			]);
		});
		// Sorting by scheduledDeliveryDate so earlier
		// deliveries in a particular day are listed before later ones in the day...JK
		deliveries.sort((a, b) => {
			return a[1] - b[1];
		});

		let deliveriesSorted = [];
		deliveries.forEach((order) => {
			if (
				order[0].technician == null ||
				techs.indexOf(order[0].technician.id) > -1
			) {
				deliveriesSorted.push(order[0]);
			}
		});
		// index:  0 = Sunday, 1 = Monday ....
		let weekList = [[], [], [], [], [], [], []];

		deliveriesSorted.forEach((order, index) => {
			switch (new Date(order.scheduledDeliveryDate).getDay()) {
				default:
				case 0:
					weekList[0].push(t.createCard(order, "rgba-blue-light"));
					break;
				case 1:
					weekList[1].push(t.createCard(order, "rgba-red-light"));
					break;
				case 2:
					weekList[2].push(t.createCard(order, "rgba-pink-light"));
					break;
				case 3:
					weekList[3].push(t.createCard(order, "rgba-purple-light"));
					break;
				case 4:
					weekList[4].push(t.createCard(order, "rgba-indigo-light"));
					break;
				case 5:
					weekList[5].push(t.createCard(order, "rgba-teal-light"));
					break;
				case 6:
					weekList[6].push(t.createCard(order, "rgba-green-light"));
					break;
			}
		});

		let startDate = new Date(filters.deliveryDateStart),
			startDay = startDate.getDay(),
			colArr = [],
			rowArr = [];

		//set the startDate to Sunday in the calendar...BC
		startDate.setDate(startDate.getDate() + -startDay);

		for (let i = 0; i <= 6; i++) {
			let s = new Date(startDate);
			colArr.push({
				label: new Date(s.setDate(s.getDate() + i)).toLocaleDateString(
					"en-EN",
					{
						weekday: "short",
						month: "numeric",
						day: "numeric",
					}
				),
				field: "day" + i,
			});
			maxArr.push(weekList[i].length);
		}
		d.columns = colArr;
		max = Math.max(...maxArr);

		for (let i = 0; i < max; i++) {
			rowArr.push({
				day1: weekList[startDay % 7][i] || "",
				day2: weekList[(startDay + 1) % 7][i] || "",
				day3: weekList[(startDay + 2) % 7][i] || "",
				day4: weekList[(startDay + 3) % 7][i] || "",
				day5: weekList[(startDay + 4) % 7][i] || "",
				day6: weekList[(startDay + 5) % 7][i] || "",
				day7: weekList[(startDay + 6) % 7][i] || "",
			});
		}
		d.rows = rowArr;
		t.setState({
			data: d,
			deliveriesLoaded: true,
			applyCalendarFiltersLoaded: true,
		});
	}

	toggleCollapse = (collapseID) => () => {
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : "",
		}));
	};

	createCard(order, color) {
		// let colors = ['rgba-blue-light','rgba-red-light','rgba-pink-light','rgba-purple-light','rgba-indigo-light','rgba-cyan-light','rgba-teal-light','rgba-green-light','rgba-lime-light','rgba-orange-light'];
		// let i = Math.floor(Math.random() * colors.length);

		return (
			<Card style={{ maxWidth: "15rem" }} className={color}>
				<CardBody className="text-center">
					<div>
						<div onClick={(e) => this.handleOpenOrderClick(order.fullOrder, e)}>
							{this.renderDeliveryTime(order.scheduledDeliveryDate)}
							{order.technician ? order.technician.name : "Unassigned"}
							<Input
								style={{ textAlign: "center" }}
								label="Patient"
								disabled={true}
								value={order.patientName}
								size="sm"
							/>
							{this.rednerOrderRoute(order.id)}
							{this.renderAddress(order)}
						</div>
						{this.renderProducts(order)}
					</div>
				</CardBody>
			</Card>
		);
	}

	rednerOrderRoute(id) {
		return (
			<a className="blue-text" href={"order/" + id}>
				Order ID: {id}
			</a>
		);
	}

	renderTechSelect(tech, id) {
		let t = tech ? tech.name : "Select a Technician";
		return (
			<Select
				placeholder={t}
				options={this.state.techSelectArr}
				onChange={this.handleTechSelectChange.bind(this, id)}
			/>
		);
	}

	// Called if a different tech is chosen for a scheduled delivery and updates the order...JK
	async handleTechSelectChange(id, e) {
		// I don't have the full order so I'm grabbing it first and then updating...JK
		let order = await this.getAct(id),
			d = this.state.data;
		order.technician = e.value;
		return ActivityService.updateActivity(order)
			.then((res) => {
				this.setState({
					data: d,
				});
				toast.success("Tech Changed!");
			})
			.catch((err) => {
				toast.warn("An error occurred");
			});
	}

	getAct(id) {
		return ActivityService.getActivity(id)
			.then((res) => {
				return res;
			})
			.catch((err) => {
				toast.warn("An error occurred");
			});
	}

	renderDeliveryTime(time) {
		if (!time) {
			return "no time set";
		}
		return (
			<p className="font-weight-bold">
				{new Date(time).toLocaleTimeString([], {
					hour: "2-digit",
					minute: "2-digit",
				})}
			</p>
		);
	}

	renderProducts(order) {
		return (
			<MDBDropdown size="sm" className="mt-2">
				<MDBDropdownToggle caret color="primary">
					Products
					<MDBBadge style={{ marginLeft: "5px" }} color="danger">
						{order.products.length}
					</MDBBadge>
				</MDBDropdownToggle>
				<MDBDropdownMenu basic>
					{this.renderItems(order.products)}
				</MDBDropdownMenu>
			</MDBDropdown>
		);
	}

	renderItems(products) {
		return products.map((p, index) => {
			return (
				<MDBDropdownItem key={index}>{p.productType.name}</MDBDropdownItem>
			);
		});
	}

	renderAddress(order) {
		let street = order.address;
		let state = order.city + ", " + order.state + " " + order.zipCode;
		return (
			<div style={{ marginTop: "5px" }}>
				{street}
				<br />
				{state}
			</div>
		);
	}

	renderDeliveriesFilters() {
		return (
			<Card style={{ marginTop: "1%" }}>
				<CardBody>
					<Row style={{ justifyContent: "space-between" }}>
						<Button
							size="sm"
							onClick={this.setDifferentWeek.bind(this, "previous")}
						>
							{" "}
							Previous
						</Button>
						<Button size="sm" onClick={this.setCurrentWeek.bind(this)}>
							{" "}
							This Week
						</Button>
						<Button
							size="sm"
							onClick={this.setDifferentWeek.bind(this, "next")}
						>
							{" "}
							Next
						</Button>
					</Row>
					{this.renderTechnicianBoxes()}
				</CardBody>
			</Card>
		);
	}

	technicianSelected(id) {
		let techs = this.state.techsSelected,
			idx = techs.indexOf(id);

		if (idx > -1) {
			techs.splice(idx, 1);
		} else {
			techs.push(id);
		}

		this.setState({
			techSelected: techs,
		});

		this.formatGrid(this.state.rawData);
		//Only show deliveries where no tech or tech id is selected
	}

	renderTechnicianBoxes() {
		let techs = this.state.techSelectArr;

		return (
			<div>
				<div
					style={{ paddingTop: "6px", paddingBottom: "6px", cursor: "pointer" }}
					onClick={this.toggleCollapse("collapse1")}
				>
					<hr style={{ borderColor: "#555555", borderWidth: "3px" }} />
				</div>
				<Collapse id="collapse1" isOpen={this.state.collapseID}>
					<Row>
						{techs.map((tech, idx) => {
							let techId = tech.value ? tech.value.id : 99999;

							return (
								<Col key={idx} size="2">
									<Input
										label={tech.label}
										checked={this.state.techsSelected.indexOf(techId) > -1}
										onChange={() => this.technicianSelected(techId)}
										type="checkbox"
										id={"checkbox" + techId}
									/>
								</Col>
							);
						})}
					</Row>
				</Collapse>
			</div>
		);
	}

	renderDeliveriesCalendar() {
		if (this.state.deliveriesLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Card style={{ marginTop: "1%" }}>
				<CardBody>{this.renderTable()}</CardBody>
			</Card>
		);
	}

	// Iterates and creates a card for each delivery ready to be scheduled...JK
	renderOpenOrders() {
		let o = this.state.openOrders;

		if (this.state.openOrdersReady === false) {
			return (
				<div style={{ textAlign: "Center" }}>
					<div className="spinner-grow text-primary" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				</div>
			);
		} else if (o.length === 0) {
			return (
				<div style={{ textAlign: "center", paddingBottom: "25px" }}>
					<p>No Orders Available</p>
				</div>
			);
		} else {
			return (
				<div className={"innerContainer"} style={{ marginBottom: "15px" }}>
					{o.map((order, index) => {
						return (
							<div key={index} className={"orderReadyContainer"}>
								<Card className={"openOrderCard"}>
									<CardBody
										onClick={(e) => this.handleOpenOrderClick(order, e)}
										style={{ overflow: "visible", paddingBottom: ".2rem" }}
										className={"openOrderCard"}
									>
										<h6>{order.patientName}</h6>
										<p>{order.address} </p>
										<p>
											{order.city}, {order.state}&nbsp; {order.zipCode}{" "}
										</p>
										<Fa icon="phone" size="sm" /> <span>{order.phone} </span>
										<br />
										<p style={{ marginBottom: ".2rem" }}>
											Deliver By:{" "}
											{order.approvals[0]
												? order.approvals[0].deliverByDate
													? new Date(
															order.approvals[0].deliverByDate
													  ).toLocaleDateString()
													: "Not Specified"
												: "Not Specified"}
										</p>
									</CardBody>
									{this.renderProducts(order)}
								</Card>
							</div>
						);
					})}
				</div>
			);
		}
	}

	dateAge(d) {
		if (d == null) {
			return 0;
		}

		let a = new Date().getTime(),
			oneDay = 1000 * 60 * 60 * 24,
			c = a - d,
			v = Math.round(c / oneDay);

		return v;
	}

	handleOpenOrderClick = (order, e) => {
		const { productTypes } = this.context;
		let prods = order.products;
		if (prods) {
			let serials = 0;
			prods.forEach((prod) => {
				if (
					!prod.serialNumber &&
					productTypes.find((typ) => typ.id === prod.productType.id)
						.requiresSerialNumber
				) {
					serials++;
				}
			});
			if (serials > 0) {
				toast.warn(
					"There are " +
						serials +
						" product(s) included that require a serial number."
				);
			}
		}
		this.setState({
			openOrder: order,
			openOrderTech: order.technician,
		});
		this.toggleOpenOrderModal();
	};

	renderOpenOrderModal() {
		let order = this.state.openOrder;
		return (
			<Modal
				backdrop={false}
				size="sm"
				side
				position="top-right"
				isOpen={this.state.openModal}
				toggle={() => this.toggleOpenOrderModal("outside")}
			>
				<ModalHeader>{"Order " + order.id}</ModalHeader>
				<ModalBody>
					<p className="red-text">{this.state.errorMessage || ""}</p>
					<Input
						className="mt-0"
						value={order.patientName}
						disabled
						label="Patient Name"
					/>
					{order.address}
					<br />
					{order.city + ", " + order.state + " " + order.zipCode}
					<br />
					<Fa icon={"phone"} /> <a href={"tel:" + order.phone}>{order.phone}</a>
					<br />
					{this.renderDeliverByDate(order.approvals)}
					<Select
						placeholder={
							order.technician ? order.technician.name : "Select a Technician"
						}
						options={this.state.techSelectArr}
						onChange={this.handleOpenOrderSelectChange.bind(this)}
					/>
					<MDBDatePicker
						label={
							order.scheduledDeliveryDate
								? "Current Scheduled Date"
								: "Select a Delivery Date"
						}
						showTodayButton
						valueDefault={
							order.scheduledDeliveryDate
								? new Date(order.scheduledDeliveryDate)
								: new Date()
						}
						getValue={this.handleOpenOrderDatePicker}
					/>
					<label>
						{order.scheduledDeliveryDate
							? "Current Scheduled Time"
							: "Schedule a Delivery Time"}
					</label>
					<Select
						placeholder={this.timeSelectPlaceholder(
							order.scheduledDeliveryDate
						)}
						options={this.renderTimeOptions()}
						onChange={this.handleOpenOrderTimeChange.bind(this)}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						rounded
						size="sm"
						color="secondary"
						onClick={() => this.toggleOpenOrderModal()}
					>
						Close
					</Button>
					<Button
						rounded
						size="sm"
						color="primary"
						onClick={() => this.scheduleDeliveryClicked(order)}
					>
						Schedule Delivery
					</Button>
				</ModalFooter>
			</Modal>
		);
	}

	timeSelectPlaceholder(date) {
		if (date) {
			return new Date(date).toLocaleTimeString("en-US", {
				hour: "2-digit",
				minute: "2-digit",
			});
		} else {
			return "Select a Time";
		}
	}

	renderDeliverByDate(approvals) {
		// Try it in case approvals array in still null...JK
		try {
			if (approvals[0].deliverByDate !== null) {
				let d = this.formatDate(approvals[0].deliverByDate, 0);
				return <Input value={d} disabled label="Deliver By Date" />;
			} else {
				return <Input value="Not set" disabled label="Delivery By Date" />;
			}
		} catch (e) {
			return <Input value="Not set" disabled label="Delivery By Date" />;
		}
	}

	handleOpenOrderTimeChange(e) {
		this.setState({
			openOrderTime: e.value,
		});
	}

	renderTimeOptions() {
		let h = [],
			ampm = [],
			hour = 0,
			times = [],
			minutes = ["00", "15", "30", "45"];
		for (let i = 6; i < 22; i++) {
			hour = i % 12 === 0 ? 12 : i % 12;
			h.push(hour.toString());
			i % 24 < 12 ? ampm.push("AM") : ampm.push("PM");
		}

		h.map((nh, k) => {
			return minutes.map((m) => {
				return times.push({
					label: nh + ":" + m + " " + ampm[k],
					value: [nh, m],
				});
			});
		});
		return times;
	}

	handleOpenOrderDatePicker = (value) => {
		this.setState({
			openOrderDate: value,
		});
	};

	handleOpenOrderSelectChange(e) {
		this.setState({
			openOrderTech: e.value,
		});
	}

	// I'm checking to see whether or not the close button was clicked or they are just clicking outside the area of the modal
	// I have to check this since the modal will automatically close when a date is being chosen on the datepicker...JK
	toggleOpenOrderModal = (outside) => {
		if (outside === undefined) {
			this.setState({
				openModal: !this.state.openModal,
			});
		}
	};

	validateSchedule() {
		let d = this.state.openOrderDate,
			t = this.state.openOrderTime,
			o = this.state.openOrder;

		const { currentUser } = this.context;
		if (currentUser.role === "OFFICE" || currentUser.role === "SALES") {
			this.setErrorMessage(
				"You do not have permissions to schedule a delivery"
			);
			return false;
		}

		if (!o.scheduledDeliveryDate) {
			if (d.length === 0) {
				this.setErrorMessage("Please select a delivery date");
				return false;
			} else if (t.length === 0) {
				this.setErrorMessage("Please a select a delivery time");
				return false;
			}
		}
		this.setState({
			errorMessage: "",
		});
		return true;
	}

	setErrorMessage(error) {
		this.setState({
			errorMessage: error,
		});
	}

	scheduleDeliveryClicked = (order) => {
		if (this.validateSchedule()) {
			let dateTime,
				c = true;

			if (
				!order.scheduledDeliveryDate ||
				(this.state.openOrderDate && this.state.openOrderTime)
			) {
				let d = this.formatDate(this.state.openOrderDate, 0),
					t = this.state.openOrderTime,
					dd = d.split("/");

				dateTime = new Date(
					parseInt(dd[2]),
					parseInt(dd[0]) - 1,
					parseInt(dd[1]),
					t[0],
					parseInt(t[1])
				);
				order.scheduledDeliveryDate = dateTime;
			}

			const { currentUser } = this.context;

			order.technician = this.state.openOrderTech;

			order.lastUpdatedBy = {
				id: currentUser.id,
				name: currentUser.username,
			};

			try {
				if (order.approvals[0].sameOrSimilar) {
					c = window.confirm(
						"There is a same or similar on file: " +
							order.approvals[0].sameOrSimilar +
							"\nWould you still like to schedule the delivery"
					);
				}
				if (
					order.approvals[0].deliverByDate !== null &&
					order.scheduledDeliveryDate > order.approvals[0].deliverByDate
				) {
					c = false;
					// I set this value back to null so it checks the date again and grabs the changes if the user corrects the date...JK
					order.scheduledDeliveryDate = null;
					this.setErrorMessage(
						"Cannot schedule. Delivery date is past deliver by date"
					);
				}
			} catch (e) {
				// This means there is no same or similar or deliver by date on the order so you dont have to worry about it...JK
			}
			if (c) {
				return ActivityService.updateActivity(order)
					.then((res) => {
						this.setState({
							openOrderTech: null,
							errorMessage: "",
							openOrderDate: "",
							openOrderTime: [],
						});
						toast.success("Delivery Scheduled!");

						let loc = this.state.userLocations;

						this.getOrdersByLocation(loc);
						this.retrieveDeliveries(loc);

						this.toggleOpenOrderModal();
					})
					.catch((err) => {
						toast.warn("An error occurred");
					});
			}
		}
	};

	renderTable() {
		return (
			<MDBTable responsive>
				<MDBTableHead columns={this.state.data.columns} />
				<MDBTableBody rows={this.state.data.rows} />
			</MDBTable>
		);
	}

	toggleCollapse = (collapseID) => () => {
		this.setState((prevState) => ({
			collapseID: prevState.collapseID !== collapseID ? collapseID : "",
		}));
	};

	setDifferentWeek(direction) {
		let fp = this.state.filterParams,
			sd = new Date(fp.deliveryDateStart),
			ed = new Date(fp.deliveryDateEnd);

		if (direction === "previous") {
			sd.setDate(sd.getDate() - 7);
			ed.setDate(ed.getDate() - 7);
		} else {
			sd.setDate(sd.getDate() + 7);
			ed.setDate(ed.getDate() + 7);
		}

		fp.deliveryDateStart = sd.toLocaleDateString();
		fp.deliveryDateEnd = ed.toLocaleDateString();

		this.setState({
			filterParams: fp,
			deliveriesLoaded: false,
		});

		this.retrieveDeliveries(this.state.userLocations);
	}

	setCurrentWeek() {
		let fp = this.state.filterParams,
			sd = new Date(),
			ed = new Date(),
			cd = new Date().getDay(),
			daysToModify = 0;

		if (cd !== 0) {
			//this is any other day of the week, we need to subtract that number to get the starting monday.
			daysToModify = -cd;
		}

		sd.setDate(sd.getDate() + daysToModify);
		ed.setDate(sd.getDate() + daysToModify + 7);

		fp.deliveryDateStart = sd.toLocaleDateString();
		fp.deliveryDateEnd = ed.toLocaleDateString();

		this.setState({
			filterParams: fp,
			deliveriesLoaded: false,
		});

		this.retrieveDeliveries(this.state.userLocations);
	}

	formatDate(date, param) {
		let formatDate = new Date(date);
		if (param === 0) {
			return [
				formatDate.getMonth() + 1,
				formatDate.getDate(),
				formatDate.getFullYear(),
			].join("/");
		} else {
			formatDate.setDate(formatDate.getDate() + 6);
			return [
				formatDate.getMonth() + 1,
				formatDate.getDate(),
				formatDate.getFullYear(),
			].join("/");
		}
	}

	handleSearchFieldChange = (e) => {
		this.setState({
			searchField: e.target.value,
		});
	};

	renderLoadingSpinner() {
		return (
			<Card style={{ marginTop: "1%" }}>
				<CardBody>
					<div style={{ textAlign: "center", verticalAlign: "center" }}>
						<Spinner multicolor />
					</div>
				</CardBody>
			</Card>
		);
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>

				<div style={{ display: "flex", justifyContent: "space-around" }}>
					<div
						style={{ width: "92%", maxWidth: "92%", marginBottom: "1%" }}
						className="mt-2"
					>
						<Card style={{ marginTop: "1%", overflowY: "visible" }}>
							<CardBody>
								<MDBCol size="12">
									<div className={"ordersViewScroll"}>
										{this.renderOpenOrders()}
									</div>
								</MDBCol>
							</CardBody>
						</Card>
						{this.renderDeliveriesFilters()}
						{this.renderDeliveriesCalendar()}
					</div>
					{this.renderOpenOrderModal()}
				</div>
			</div>
		);
	}
}
