import api from "../Security/api";

class TrainingLinkService {
	async getAllTrainingLinks() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				paged: false,
			};
			api
				.get("/trainingLink", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateTrainingLink(reason) {
		return new Promise((res, reject) => {
			api
				.put("/trainingLink/" + reason.id, reason)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createTrainingLink(reason) {
		return new Promise((res, reject) => {
			api
				.post("/trainingLink", reason)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deleteTrainingLink(id) {
		return new Promise((res, reject) => {
			api
				.delete("/trainingLink/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const trainingLinkService = new TrainingLinkService();

export default trainingLinkService;
