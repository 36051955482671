import api from "../Security/api";

class ProductSizeService {
	async getAllProductSizes() {
		return new Promise((res, reject) => {
			let params = {
				page: 0,
				size: 100,
				paged: false,
			};
			api
				.get("/productSizes", params)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateProductSizes(product) {
		return new Promise((res, reject) => {
			api
				.put("/productSizes/" + product.id, product)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createProductSizes(product) {
		return new Promise((res, reject) => {
			api
				.post("/productSizes", product)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deleteProductSize(id) {
		return new Promise((res, reject) => {
			api
				.delete("/productSizes/" + id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const productSizeService = new ProductSizeService();

export default productSizeService;
