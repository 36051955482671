import api from "../api";

class LocationService {
	async findAllLocations() {
		return new Promise((res, reject) => {
			return api
				.get("/locations")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async findAllInternalLocations() {
		return new Promise((res, reject) => {
			return api
				.get("/internalLocations")
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async getLocation(location) {
		return new Promise((res, reject) => {
			return api
				.get("/locations/" + location.id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async updateLocation(location) {
		return new Promise((res, reject) => {
			api
				.put("/locations/" + location.id, location)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async createLocation(location) {
		return new Promise((res, reject) => {
			api
				.post("/locations", location)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}

	async deleteLocation(location) {
		return new Promise((res, reject) => {
			api
				.delete("/locations/" + location.id)
				.then((data) => {
					res(data);
				})
				.catch((err) => {
					reject();
				});
		});
	}
}

const locationService = new LocationService();

export default locationService;
