import React from "react";
import { Button, DataTable, Container, Spinner, MDBIcon } from "mdbreact";
import ReportsService from "../Security/ReportsService/reportsService";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

export default class activityReport extends React.Component {
	constructor(props) {
		super(props);

		let filters = this.determineFilters(props.filters);
		let topFilters = props.topFilters;

		//table headers & columns
		const data = {
			columns: [
				{
					label: "Sales Rep",
					field: "username",
					width: 200,
				},
				{
					label: "Office",
					field: "groupname",
					sort: "asc",
					width: 200,
				},
				{
					label: "Calls (Total)",
					field: "totalCalls",
					width: 200,
				},
				{
					label: "Calls (Marketing)",
					field: "qualityCalls",
					width: 200,
				},
				{
					label: "In-Services",
					field: "appointments",
					width: 200,
				},
				{
					label: "Orders (Active)",
					field: "newOrders",
					width: 200,
				},
				{
					label: "Orders (Setup)",
					field: "setups",
					width: 200,
				},
				{
					label: "Points (Setup)",
					field: "points",
					width: 200,
				},
			],
			rows: [],
		};

		this.state = {
			allActivities: [],
			activities: data,
			startDate: new Date(filters.startDate),
			endDate: new Date(filters.endDate),
			isLoaded: true,
			csvFunction: props.csvFunction,
			users: [],
			currUser: {},
			topFilters: topFilters,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		salesReps: PropTypes.array,
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Get the most up-to-date filters passed down from parent
		if (
			JSON.stringify(this.props.topFilters) !==
			JSON.stringify(this.state.topFilters)
		) {
			this.setState({
				topFilters: this.props.topFilters,
			});
		}
		// Checks if the generate reports button has been clicked...JK
		if (prevProps.generateClicked !== this.props.generateClicked) {
			this.getActivityReport();
		}
	}

	determineFilters(userFilters) {
		let d = new Date(),
			filters = {};

		if (userFilters) {
			if (userFilters.startDate) {
				filters.startDate = new Date(userFilters.startDate);
			} else {
				filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			}

			if (userFilters.endDate) {
				filters.endDate = new Date(userFilters.endDate);
			} else {
				filters.endDate = d;
			}
		} else {
			filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			filters.endDate = d;
		}

		return filters;
	}

	getActivityReport() {
		const { salesReps, currentUser } = this.context;
		let st = this.state,
			reps = [],
			tp = st.topFilters;

		let filters = {
			startDate: tp.startDate,
			endDate: tp.endDate,
		};

		try {
			filters = {
				startDate: new Date(tp.startDate).toLocaleDateString(),
				endDate: new Date(tp.endDate).toLocaleDateString(),
			};
		} catch {
			// toast.error("Please check your dates.");
			return;
		}

		// Have to check len since showDatePicker defaults to false at startup
		if (!tp.showDatePicker && tp.quickFilter.length > 0) {
			filters.startDate = tp.quickFilter[0].toLocaleDateString();
			filters.endDate = tp.quickFilter[1].toLocaleDateString();
		} else {
			// Check to make sure they have selected a date and the dates aren't set to the default if not using the quickfilter
			if (
				filters.startDate === "12/31/1969" ||
				filters.endDate === "12/31/1969"
			) {
				// toast.error('Please check your dates');
				return;
			}
		}
		// Get users in the sales rep dropdown and add to the filter
		if (tp.salesRepsSelected.length > 0) {
			filters.users = tp.salesRepsSelected;
		}
		// Only default to getting all users if they have the correct permissions
		else if (
			currentUser.role === "ADMIN" ||
			currentUser.role === "SYSTEM_ADMIN" ||
			currentUser.role === "EXECUTIVE_MANAGEMENT" ||
			currentUser.role === "FINANCE"
		) {
			salesReps.forEach((rep) => {
				reps.push(rep.id);
			});

			filters.users = reps;
		}

		this.setState({ isLoaded: false });

		filters.location = tp.locationsSelected
			? [...new Set(tp.locationsSelected.map((l) => l.value))]
			: [];

		return ReportsService.getActivityReport(filters)
			.then((res) => {
				this.setState({
					allActivities: res,
				});

				this.formatRows(res);
			})
			.catch((err) => {
				//handle error..BC
			});
	}

	formatRows(activities) {
		let ary = [],
			dt = this.state.activities;

		activities.forEach((activity) => {
			ary.push({
				username: activity.salesRep || "No Sales Rep",
				groupname: activity.office || "No Office",
				totalCalls: activity.totalCalls ? parseInt(activity.totalCalls) : 0,
				qualityCalls: activity.qualityCalls
					? parseInt(activity.qualityCalls)
					: 0,
				appointments: activity.inService ? parseInt(activity.inService) : 0,
				newOrders: activity.activeOrders ? parseInt(activity.activeOrders) : 0,
				setups: activity.setupOrders ? parseInt(activity.setupOrders) : 0,
				points: activity.points ? parseFloat(activity.points) : 0,
			});
		});
		dt.rows = ary;

		this.setState({
			activities: dt,
			isLoaded: true,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	//get the data in the table
	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div>
				<DataTable
					entriesOptions={[25, 50, 100]}
					pagesAmount={10}
					// order={['groupname', 'asc']}
					data={this.state.activities}
					responsive
					fixed
					striped
					bordered
				></DataTable>

				<Button
					style={{ float: "right" }}
					color={"blue"}
					floating
					size="sm"
					data-tip={"Download CSV"}
					onClick={this.state.csvFunction.bind(
						this,
						this.state.activities,
						"activitiesExport.csv"
					)}
				>
					<MDBIcon icon="download" style={{ fontSize: "2em" }} />
				</Button>
				<ReactTooltip />
			</div>
		);
	}
}
