import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	Input,
	toast,
	CardHeader,
	MDBIcon,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	ToastContainer,
	MDBCol,
	MDBRow,
	MDBCard,
	MDBSelect,
} from "mdbreact";
import PropTypes from "prop-types";
import ProductSubTypeService from "../Security/ProductService/productSubTypeService";
import AddProdcutMatrixEntry from "./addProdcutMatrixEntry";
import ReactTooltip from "react-tooltip";
import Select from "react-select";
import EquipmentTrainingLinkService from "./EquipmentTrainingLinkService";
import TrainingLinkService from "./trainingLinkService";

export default class editProductSubType extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
					width: 100,
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
					width: 200,
				},
				{
					label: "HCPCS",
					field: "hcpcs",
					sort: "asc",
					width: 100,
				},
				{
					label: "Item ID",
					field: "itemid",
					sort: "asc",
					width: 100,
				},
				{
					label: "Vendor",
					field: "vendor",
					sort: "asc",
					width: 100,
				},
				{
					label: "Override Points",
					field: "overridepoints",
					sort: "asc",
					width: 100,
				},
				{
					label: "Edit",
					field: "button",
					sort: "asc",
					width: 100,
				},
				{
					label: "Delete",
					field: "delete",
					width: 100,
				},
			],
			rows: [],
		};

		let productType = {},
			id = props.match.params.id;

		if (id) {
			this.retrieveProducts(id);
		}

		this.state = {
			data: data,
			isLoaded: false,
			updatedProduct: {},
			productType: productType,
			modal: false,
			errorMessage: "",
			currIndex: -1,
			allVendors: [],
			vendorOptions: [],
			currentEquipmentVideos: [],
			videosLoaded: false,
			selectedVendor: null,
			overrideModal: false,
			allVideos: [],
			vidsList: [],
			fullVideosLoaded: false,
			chosenVideo: {},
		};

		this.getAllVideos();
	}

	static contextTypes = {
		allVendors: PropTypes.array,
	};

	componentDidMount() {
		this.renderVendorsOptions();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { allVendors } = this.context;
		if (
			allVendors.length > 0 &&
			allVendors.length !== prevState.allVendors.length
		) {
			this.renderVendorsOptions();
		}
	}

	renderVendorsOptions() {
		const { allVendors } = this.context;
		let arr = [];
		allVendors.map((v) => {
			return arr.push({
				label: v.name,
				value: v.id,
				val: { id: v.id, name: v.name },
			});
		});
		this.setState({
			allVendors: allVendors,
			vendorOptions: arr,
		});
	}

	renderCardHeader() {
		return (
			<CardHeader color={"blue-grey lighten-2"} style={{ textAlign: "center" }}>
				<Button
					style={{ float: "left" }}
					floating
					size="sm"
					color={"warning"}
					data-tip={"Go Back"}
					onClick={() => this.props.history.push("/products")}
				>
					<MDBIcon icon="backward" style={{ fontSize: "2em" }} />
				</Button>

				{this.state.productType ? this.state.productType.name : " Products"}

				<Button
					style={{ float: "right" }}
					floating
					size="sm"
					color={"success"}
					data-tip={"Add New Product"}
					onClick={() => this.addButtonClick()}
				>
					<MDBIcon icon="plus" style={{ fontSize: "2em" }} />
				</Button>

				<Button
					style={{ float: "right" }}
					floating
					size="sm"
					color={"secondary"}
					data-tip={"View Point Overrides"}
					onClick={() =>
						this.props.history.push({
							pathname: "/productMatrixEntry",
							state: {
								id: this.state.productType.id,
								product: this.state.productType,
							},
						})
					}
				>
					<MDBIcon icon="external-link-alt" />
				</Button>
			</CardHeader>
		);
	}

	retrieveProducts(id) {
		let t = this;
		return ProductSubTypeService.getOneProductSubType(id)
			.then((res) => {
				let arr = [],
					dt = this.state.data,
					productType = { id: id };

				res.forEach((product, index) => {
					if (!product.overridePoints) {
						product.overridePoints = false;
					}

					productType = product.productType;

					arr.push({
						id: product.id,
						name: product.name || "",
						hcpcs: product.hcpcs || "",
						itemid: product.itemId || "",
						vendor: product.vendor ? product.vendor.name : "",
						overridepoints: product.overridePoints ? "true" : "",
						edit: t.editButton(product, index),
						remove: t.renderRemoveButton(product.id),
					});
				});
				dt.rows = arr;
				t.setState({
					data: dt,
					isLoaded: true,
					productType: productType,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	getAllVideos() {
		TrainingLinkService.getAllTrainingLinks().then((r) => {
			let obj = [];

			r.forEach((v) => {
				obj.push({ text: v.name + ":   " + v.description, value: v });
			});

			this.setState({
				allVideos: r,
				vidsList: obj,
				fullVideosLoaded: true,
			});
		});
	}

	getProductVideos(id) {
		EquipmentTrainingLinkService.getVideosByProduct(id).then((r) => {
			this.setState({
				currentEquipmentVideos: r,
				videosLoaded: true,
			});
		});
	}

	addVideo() {
		let video = this.state.chosenVideo,
			prod = this.state.updatedProduct,
			v = this.state.currentEquipmentVideos;

		if (!video.id) {
			return;
		}

		if (
			v.findIndex((vals) => {
				return vals.trainingLinkId === video.id;
			}) > -1
		) {
			toast.warning("Video already exists foRr this product.");
			return;
		}

		this.setState({
			videosLoaded: false,
		});

		let vo = {
			name: video.name,
			productSubTypeId: prod.id,
			trainingLinkId: video.id,
			productCategoryId: -1,
		};

		EquipmentTrainingLinkService.createTrainingLink(vo)
			.then((r) => {
				v.push(r);

				this.setState({
					currentEquipmentVideos: v,
					chosenVideo: {},
				});
			})
			.catch((e) => {
				console.log(e);
			})
			.finally((a) => {
				this.setState({
					videosLoaded: true,
				});
			});
	}

	editButton(product, index) {
		return (
			<MDBIcon
				style={{ color: "green" }}
				icon="edit"
				aria-hidden="true"
				onClick={() => {
					this.editButtonClick(product, index);
				}}
			/>
		);
	}

	renderRemoveButton(id) {
		return (
			<MDBIcon
				style={{ color: "red" }}
				icon="trash"
				aria-hidden="true"
				onClick={() => this.handleRemoveClick(id)}
			/>
		);
	}

	handleRemoveClick(id) {
		ProductSubTypeService.deleteProductSubType(id)
			.then((res) => {
				this.retrieveProducts(this.state.productType.id);
				toast.success("Product Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Product");
			});
	}

	editButtonClick(product, index) {
		let p = JSON.parse(JSON.stringify(product));
		this.setState({
			updatedProduct: p,
			currIndex: index,
		});
		this.toggleEdit();
		this.getProductVideos(product.id);
	}

	toggleEdit = () => {
		this.setState({
			modal: !this.state.modal,
			currentEquipmentVideos: [],
			videosLoaded: false,
			errorMessage: "",
			selectedVendor: null,
		});
	};

	renderModal() {
		if (this.state.currIndex > -1) {
			let st = this.state,
				up = st.updatedProduct;
			return (
				<Container>
					<Modal size={"lg"} isOpen={this.state.modal} toggle={this.toggleEdit}>
						<ModalHeader style={{ backgroundColor: "white" }}>
							Edit Product
						</ModalHeader>
						<ModalBody style={{ backgroundColor: "white" }}>
							<Container>
								<MDBRow>
									<MDBCol size="12">
										<p style={{ color: "red" }}>{this.state.errorMessage}</p>
									</MDBCol>
									<MDBCol size="12">
										<Input
											containerClass={"smallMargin"}
											outline
											value={up.name || ""}
											onChange={this.handleChange.bind(this, "name")}
											label="Name"
											size="sm"
										/>
									</MDBCol>
									<MDBCol size="6">
										<Input
											containerClass={"smallMargin"}
											outline
											value={up.hcpcs || ""}
											onChange={this.handleChange.bind(this, "hcpcs")}
											label="HCPCS"
											size="sm"
										/>
									</MDBCol>
									<MDBCol size="6">
										<Input
											containerClass={"smallMargin"}
											outline
											value={up.itemId || ""}
											onChange={this.handleChange.bind(this, "itemId")}
											label="Item Id"
											size="sm"
										/>
									</MDBCol>
									<MDBCol size="8">
										<Select
											placeholder={up.vendor ? up.vendor.name : "Select Vendor"}
											options={st.vendorOptions}
											onChange={this.handleVendorChange.bind(this)}
											value={st.selectedVendor}
										/>
									</MDBCol>
									<MDBCol size="4" style={{ paddingTop: 8 }}>
										<Input
											value={up.overridePoints}
											id="overridepnts"
											checked={up.overridePoints}
											type={"checkbox"}
											onChange={this.handleChange.bind(this, "overridePoints")}
											label="Override Points"
											size="sm"
										/>
									</MDBCol>
								</MDBRow>
								<hr style={{ paddingTop: 10 }} />
								<MDBRow>
									<MDBCol size="10">
										<MDBSelect
											outline
											color="default"
											style={{ maxHeight: "100px", marginTop: 1 }}
											options={this.state.vidsList}
											label={"Choose a Video to Add"}
											getValue={this.handleVideoChange.bind(this)}
										/>
									</MDBCol>
									<MDBCol size="2">
										<MDBIcon
											data-tip={"Add Video"}
											size={"2x"}
											icon={"plus"}
											style={{ color: "green" }}
											onClick={() => this.addVideo()}
										/>
									</MDBCol>
								</MDBRow>

								<MDBRow>{this.renderProductVideos()}</MDBRow>
							</Container>
						</ModalBody>
						<ModalFooter style={{ backgroundColor: "white" }}>
							<Button
								size={"sm"}
								color="primary"
								data-tip={"Add Product Override"}
								onClick={this.handleOverrideClick}
							>
								<MDBIcon icon="cart-plus" style={{ fontSize: "2em" }} />
							</Button>
							<Button
								size={"sm"}
								data-tip={"Close"}
								color="red"
								onClick={this.toggleEdit}
							>
								<MDBIcon icon="times" style={{ fontSize: "2em" }} />
							</Button>
							<Button
								size={"sm"}
								data-tip={"Save"}
								onClick={this.editSaveButtonClick}
								color="success"
							>
								<MDBIcon far icon="save" style={{ fontSize: "2em" }} />
							</Button>
						</ModalFooter>
						<ReactTooltip />
					</Modal>
				</Container>
			);
		}
	}

	removeVideoFromEquipment(v, idx) {
		this.setState({
			videosLoaded: false,
		});

		let myVideos = this.state.currentEquipmentVideos.slice();

		EquipmentTrainingLinkService.deleteTrainingLink(v.id)
			.then((r) => {
				myVideos.splice(idx, 1);
				this.setState({
					currentEquipmentVideos: myVideos,
				});
			})
			.catch((e) => {
				toast.warning("There was an error.  Try again later.");
			})
			.finally((a) => {
				this.setState({
					videosLoaded: true,
				});
			});
	}

	renderProductVideos() {
		let t = this;

		function deleteButton(v, idx) {
			return (
				<MDBIcon
					style={{ color: "red" }}
					icon={"trash"}
					onClick={() => t.removeVideoFromEquipment(v, idx)}
				/>
			);
		}

		if (!this.state.videosLoaded) {
			return <Spinner small />;
		}
		return this.state.currentEquipmentVideos.map((v, idx) => {
			return (
				<MDBCol size="6">
					<MDBCard key={idx} style={{ margin: 6, padding: 4 }}>
						<MDBRow>
							<MDBCol size="9">{v.name}</MDBCol>

							<MDBCol size="3">{deleteButton(v, idx)}</MDBCol>
						</MDBRow>
					</MDBCard>
				</MDBCol>
			);
		});
	}

	handleVideoChange(video) {
		this.setState({ chosenVideo: video[0] });
	}

	handleOverrideClick = () => {
		this.setState((state) => ({
			productSubType: {
				name: state.updatedProduct.name,
				id: state.updatedProduct.id,
			},
		}));
		this.toggleEdit();
		this.toggleOverrideModal();
	};

	overrideModal() {
		return (
			<Modal
				isOpen={this.state.overrideModal}
				toggle={() => {
					return;
				}}
			>
				<AddProdcutMatrixEntry
					closeButton={this.toggleOverrideModal.bind(this)}
					productSubType={this.state.productSubType}
				/>
			</Modal>
		);
	}

	toggleOverrideModal = () => {
		this.setState({
			overrideModal: !this.state.overrideModal,
		});
	};

	handleVendorChange = (e) => {
		this.setState({
			selectedVendor: e,
		});
	};

	handleChange(property, e) {
		let up = this.state.updatedProduct;
		if (property === "overridePoints") {
			up[property] = !up[property];
		} else {
			up[property] = e.target.value;
		}
		this.setState({
			updatedProduct: up,
		});
	}

	addButtonClick() {
		return this.props.history.push({
			pathname: "/productType/new",
			state: { productType: this.state.productType },
		});
	}

	validate() {
		let up = this.state.updatedProduct;
		if (up.name === "") {
			this.setError("Please enter Product Name");
			return false;
		} else if (up.hcpcs === "") {
			this.setError("Please enter HCPCS");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	editSaveButtonClick = () => {
		if (this.validate()) {
			let t = this,
				st = t.state,
				cp = st.updatedProduct,
				d = st.data,
				i = st.currIndex,
				v = st.selectedVendor;
			if (v) {
				cp.vendor = v.val;
			}
			return ProductSubTypeService.updateProductSubType(cp)
				.then(() => {
					toast.success("Saved Successfully!");
					d.rows[i].id = cp.id;
					d.rows[i].name = cp.name;
					d.rows[i].hcpcs = cp.hcpcs;
					d.rows[i].itemid = cp.itemId || "";
					d.rows[i].vendor = cp.vendor ? cp.vendor.name : "";
					d.rows[i].overridepoints = cp.overridePoints ? "true" : "";
					d.rows[i].edit = t.editButton(cp, i);
					t.setState({
						data: d,
						modal: !this.state.modal,
						errorMessage: "",
						productSubType: { name: cp.name, id: cp.id },
					});
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div></div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}

		return (
			<div>
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Container className="mt-5">
					<Card>
						{this.renderCardHeader()}
						<CardBody>{this.renderTable()}</CardBody>
						<ReactTooltip />
					</Card>
					{this.overrideModal()}
					{this.renderModal()}
				</Container>
			</div>
		);
	}
}
