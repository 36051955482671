import React from "react";
import { Button, DataTable, Container, Spinner, MDBIcon } from "mdbreact";
import ReportsService from "../Security/ReportsService/reportsService";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

export default class accountReport extends React.Component {
	constructor(props) {
		super(props);

		let filters = this.determineFilters(props.filters);
		let topFilters = props.topFilters;

		//table headers & columns
		const data = {
			columns: [
				{
					label: "Sales Rep",
					field: "user",
					sort: "asc",
					width: 200,
				},
				{
					label: "Account",
					field: "account",
					sort: "asc",
					width: 200,
				},
				{
					label: "Account Type",
					field: "accountType",
					sort: "asc",
					width: 200,
				},
				{
					label: "Office",
					field: "userGroup",
					sort: "asc",
					width: 200,
				},
				{
					label: "Calls (Total)",
					field: "totalCount",
					sort: "asc",
					width: 200,
				},
				{
					label: "Calls (Marketing)",
					field: "qualityCount",
					sort: "asc",
					width: 200,
				},
				{
					label: "In-Services",
					field: "appointmentCount",
					sort: "asc",
					width: 200,
				},
				{
					label: "Expenses",
					field: "totalExpenses",
					sort: "asc",
					width: 200,
				},
				{
					label: "Orders (Setup)",
					field: "ordersSetup",
					sort: "asc",
					width: 200,
				},
				{
					label: "Points (Setup)",
					field: "setupPoints",
					sort: "asc",
					width: 200,
				},
				{
					label: "Points (Total)",
					field: "totalPoints",
					sort: "asc",
					width: 200,
				},
				{
					label: "Patients",
					field: "patients",
					sort: "asc",
					width: 200,
				},
			],
			rows: [],
		};

		this.state = {
			accountsData: data,
			allAccounts: [],
			startDate: new Date(filters.startDate),
			endDate: new Date(filters.endDate),
			isLoaded: true,
			csvFunction: props.csvFunction,
			topFilters: topFilters,
		};
	}

	static contextTypes = {
		currentUser: PropTypes.object,
		salesReps: PropTypes.array,
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		// Get the most up-to-date filters passed down from parent
		if (
			JSON.stringify(this.props.topFilters) !==
			JSON.stringify(this.state.topFilters)
		) {
			this.setState({
				topFilters: this.props.topFilters,
			});
		}
		// Checks if the generate reports button has been clicked...JK
		if (prevProps.generateClicked !== this.props.generateClicked) {
			this.getAccountsReport();
		}
	}

	formatCurrency(expense) {
		return new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
		}).format(expense);
	}

	determineFilters(userFilters) {
		let d = new Date(),
			filters = {};

		if (userFilters) {
			if (userFilters.startDate) {
				filters.startDate = new Date(userFilters.startDate);
			} else {
				filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			}

			if (userFilters.endDate) {
				filters.endDate = new Date(userFilters.endDate);
			} else {
				filters.endDate = d;
			}
		} else {
			filters.startDate = new Date(d.getFullYear(), d.getMonth(), 1);
			filters.endDate = d;
		}
		return filters;
	}

	getAccountsReport() {
		const { salesReps, currentUser } = this.context;
		let st = this.state,
			reps = [];
		let tp = st.topFilters;

		let filters = {
			startDate: tp.startDate,
			endDate: tp.endDate,
			users: ""
		};

		try {
			filters = {
				startDate: new Date(tp.startDate).toLocaleDateString(),
				endDate: new Date(tp.endDate).toLocaleDateString(),
			};
		} catch {
			// toast.error("Please check your dates.");
			return;
		}

		// Have to check len since showDatePicker defaults to false at startup
		if (!tp.showDatePicker && tp.quickFilter.length > 0) {
			filters.startDate = tp.quickFilter[0].toLocaleDateString();
			filters.endDate = tp.quickFilter[1].toLocaleDateString();
		} else {
			// Check to make sure they have selected a date and the dates aren't set to the default if not using the quickfilter
			if (
				filters.startDate === "12/31/1969" ||
				filters.endDate === "12/31/1969"
			) {
				// toast.error('Please check your dates');
				return;
			}
		}
		// Get users in the sales rep dropdown and add to the filter
		if (tp.salesRepsSelected.length > 0) {
			filters.users = tp.salesRepsSelected;
		}
		// Only default to getting all users if they have the correct permissions
		else if (
			currentUser.role === "ADMIN" ||
			currentUser.role === "SYSTEM_ADMIN" ||
			currentUser.role === "EXECUTIVE_MANAGEMENT" ||
			currentUser.role === "FINANCE"
		) {
			salesReps.forEach((rep) => {
				reps.push(rep.id);
			});

			filters.users = reps;
		}

		this.setState({ isLoaded: false });

		return ReportsService.getAccountsReport(filters)
			.then((res) => {
				this.setState({ allAccounts: res });

				this.formatRows();
			})
			.catch((err) => {
							console.log(err);
						});
	}

	formatRows() {
		let accounts = this.state.allAccounts,
			ary = [],
			dt = this.state.accountsData;

		accounts.forEach((account) => {
			ary.push({
				user: account.salesRep ? account.salesRep : " ",
				account: account.account ? account.account.replace(/,/g, " ") : " ",
				accountType: account.accountType
					? account.accountType.replace(/,/g, " ")
					: " ",
				userGroup: account.office
					? account.office.replace(/,/g, " ")
					: "No Office",
				totalCount: account.totalCalls ? parseInt(account.totalCalls) : 0,
				qualityCount: account.qualityCalls ? parseInt(account.qualityCalls) : 0,
				appointmentCount: account.appointments
					? parseInt(account.appointments)
					: 0,
				totalExpenses: account.expenses ? parseFloat(account.expenses) : 0,
				ordersSetup: account.setupOrders ? parseInt(account.setupOrders) : 0,
				setupPoints: account.setupPoints || 0,
				totalPoints: account.totalPoints || 0,
				patients: account.patientCount ? parseInt(account.patientCount) : 0,
			});
		});
		dt.rows = ary;

		this.setState({
			accountsData: dt,
			isLoaded: true,
		});
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	//load in the table
	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div>
				<DataTable
					entriesOptions={[25, 50, 100]}
					pagesAmount={10}
					data={this.state.accountsData}
					responsive
					fixed
					striped
					bordered
				></DataTable>

				<Button
					style={{ float: "right" }}
					color={"blue"}
					floating
					size="sm"
					data-tip={"Download CSV"}
					onClick={this.state.csvFunction.bind(
						this,
						this.state.accountsData,
						"accountExport.csv"
					)}
				>
					<MDBIcon icon="download" style={{ fontSize: "2em" }} />
				</Button>
				<ReactTooltip />
			</div>
		);
	}
}
