import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	Spinner,
	CardHeader,
	MDBRow,
	MDBCol,
	MDBIcon,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	MDBContainer,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	toast,
} from "mdbreact";
import { ToastContainer } from "react-toastify";
import InsuranceService from "../Security/InsuranceService/insuranceService";
import ReactTooltip from "react-tooltip";

export default class insurance extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Name",
					field: "name",
					sort: "asc",
				},
				{
					label: "Display Order",
					field: "displayorder",
					sort: "asc",
				},
				{
					label: "Edit",
					field: "edit",
					sort: "asc",
				},
				{
					label: "Delete",
					field: "delete",
				},
			],
			rows: [],
		};

		this.state = {
			data: data,
			rawData: [],
			isLoaded: false,
			editModal: false,
			addNewModal: false,
			confirmationModal: false,
			currInsurance: {
				id: -1,
				name: "",
				order: "",
			},
			currIndex: -1,
			errorMessage: "",
			newInsurance:{
				name: "",
				order: "",
			}
		};
	}

	// Complaining that component wasn't mounted so I couldn't set States therefore had to use this
	componentDidMount() {
		this.retrieveInsurance();
	}

	retrieveInsurance() {
		let t = this;
		return InsuranceService.findAllInsurance()
			.then((res) => {
				let arr = [],
					d = this.state.data;
				res.forEach((insurance, index) => {
					arr.push({
						id: insurance.id,
						name: insurance.name,
						displayorder:
							typeof insurance.order === "number"
								? insurance.order
								: "no order",
						edit: t.renderEditButton(insurance, index),
						remove: t.renderRemoveButton(insurance, index),
					});
				});

				d.rows = arr;

				t.setState({
					data: d,
					rawData: res,
					isLoaded: true,
				});
			})
			.catch((err) => {
				//handle error...BC
			});
	}

	renderEditButton(insurance, index) {
		return (
				<MDBIcon
					onClick={() => {this.handleEditClick(insurance, index);}}
					icon="edit"
					style={{color: "#7ac5ff"}}
				/>
		);
	}

	renderRemoveButton(insurance, index) {
		return (
				<MDBIcon
					onClick={() => this.handleRemoveClick(insurance, index)}
					icon="trash"
					style={{color: "#db0404"}}
				/>
		);
	}

	handleConfirmationDelete(id){


		InsuranceService.deleteInsurance(id)
			.then((res) => {
				this.retrieveInsurance();
				toast.success("Payor Source Removed");
			})
			.catch((err) => {
				toast.warn("Error occurred while removing Payor Source");
			});
		this.handleConfirmationClose();

	}

	handleRemoveClick(insurance, index) {

			let i = {
				id: insurance.id,
				name: insurance.name,
				order: insurance.order,
			};
			this.setState({
				confirmationModal: !this.state.confirmationModal,
				currInsurance: i,
				currIndex: index,
			});
	}

	handleEditClick(insurance, index) {
		let i = {
			id: insurance.id,
			name: insurance.name,
			order: insurance.order,
		};
		this.setState({
			editModal: !this.state.editModal,
			currInsurance: i,
			currIndex: index,
		});
	}

	addNewToggle = () => {
		this.setState({
			newInsurance:{
				name:"",
				order:"",
			},
			errorMessage: "",
			addNewModal: !this.state.addNewModal,
		});
	};

	editToggle = () => {
		this.setState({
			editModal: !this.state.editModal,
			errorMessage: "",
		});
	};
	confirmationToggle = () => {
		this.setState({
			confirmationModal: !this.state.confirmationModal,
			errorMessage: "",
		});
	};

	renderAddNewModal() {
		return (
			<Modal isOpen={this.state.addNewModal} toggle={this.addNewToggle}>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.addNewToggle}
				>
					Add New Payor Source
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderAddNewModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Add New Payor Source"}
						onClick={this.handleAddNewSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleAddNewClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderAddNewModalBody() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<MDBContainer>
					<MDBRow>
						<MDBCol>
				<Input
					outline
					label="Payor Source Name"
					value={this.state.newInsurance.name}
					onChange={this.handleAddNewChange.bind(this, "name")}
				/>
						</MDBCol>
						<MDBCol>
				<Input
					outline
					label="Order Number"
					value={String(this.state.newInsurance.order)}
					onChange={this.handleAddNewChange.bind(this, "order")}
				/>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</div>
		);
	}

	renderConfirmationModal() {
		return (
			<Modal isOpen={this.state.confirmationModal} toggle={this.confirmationToggle}>
				<ModalHeader
					style={{ backgroundColor: "#F93154", color: "white" }}
					toggle={this.confirmationToggle}
				>
					Confirm Delete
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderConfirmationModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Confirm Delete"}
						onClick={() => this.handleConfirmationDelete(this.state.currInsurance.id)}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleConfirmationClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderConfirmationModalBody() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
				<MDBContainer>
					<MDBRow>
							Are you sure you want to delete {this.state.currInsurance.name}?
					</MDBRow>
				</MDBContainer>
			</div>
		);
	}

	renderEditModal() {
		return (
			<Modal isOpen={this.state.editModal} toggle={this.editToggle}>
				<ModalHeader
					style={{ backgroundColor: "#5881C1", color: "white" }}
					toggle={this.editToggle}
				>
					Payor Source
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					{this.renderEditModalBody()}
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"success"}
						data-tip={"Update Payor Source"}
						onClick={this.handleEditSave}
					>
						<MDBIcon icon="check" style={{ fontSize: "2em" }} />
					</Button>

					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.handleEditClose}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderEditModalBody() {
		return (
			<div>
				<p style={{ color: "red" }}>{this.state.errorMessage}</p>
					<MDBContainer>
						<MDBRow>
							<MDBCol>
				<Input
					outline
					label="Name"
					value={this.state.currInsurance.name}
					onChange={this.handleEditChange.bind(this, "name")}
				/>
							</MDBCol>
							<MDBCol>
				<Input
					outline
					label="Order"
					value={String(this.state.currInsurance.order)}
					onChange={this.handleEditChange.bind(this, "order")}
				/>
							</MDBCol>
						</MDBRow>
					</MDBContainer>
			</div>
		);
	}

	handleAddNewSave= () => {
		if (this.validateAddNew()) {
			let n = this.state.newInsurance.name,
				h = this.state.newInsurance.order;
			let obj = {
				order: h,
				name: n,
			};
			return InsuranceService.createInsurance(obj)
				.then((res) => {
					this.setState({
						errorMessage: "",
						name: "",
						order: "",
					});
					this.handleAddNewClose();
					this.retrieveInsurance();
					toast.success(n + " Payor Source has been Added!");
				})
				.catch((err) => {
					toast.warn("An error occurred adding a new product");
				});

		}
	};

	handleEditSave = () => {
		if (this.validateEdit()) {
			let d = this.state.data;
			let i = this.state.currIndex;
			return InsuranceService.updateInsurance(this.state.currInsurance)
				.then((insurance) => {
					d.rows[i].name = insurance.name;
					d.rows[i].displayorder =
						typeof insurance.order === "number" ? insurance.order : "no order";
					this.setState({
						data: d,
						editModal: !this.state.editModal,
					});
					toast.success("Payor Source Edited!");
				})
				.catch((err) => {
					toast.warn("An error occurred while saving.");
				});
		}
	};


	validateOrderNumber(){

		let contains = false;
		let order = this.state.newInsurance.order;
		for (let i=0; i<this.state.rawData.length; i++){
			if (this.state.rawData[i].order == order){
				contains = true;
			}
		}
		return contains;
	}


	validateAddNew() {
		let i = this.state.newInsurance;
		if (i.name == ""){
			this.setError("Please add a payor name");
			return false;
		} else if (i.order == "") {
			this.setError("Please add an order number");
			return false;
		} else if(this.validateOrderNumber()){
			this.setError("Order number already exists");
			return false;
		}
		return true;
	}

	validateEdit() {
		let i = this.state.currInsurance;
		if (i.name === "") {
			this.setError("Please add a name");
			return false;
		} else if (i.order === "") {
			this.setError("Please add an order number");
			return false;
		}
		return true;
	}

	setError(e) {
		this.setState({
			errorMessage: e,
		});
	}

	handleAddNewClose = () => {
		this.setState({
			errorMessage: "",
			newInsurance: {
				name :"",
				order : "",
			},
			addNewModal: !this.state.addNewModal
		})
}

	handleConfirmationClose = () => {
		let i = {
			id: -1,
			name: "",
			order: "",
		};
		this.setState({
			currInsurance: i,
			currIndex: -1,
			errorMessage: "",
			confirmationModal: !this.state.confirmationModal,
		});
	}

	handleEditClose = () => {
		let i = {
			id: -1,
			name: "",
			order: "",
		};
		this.setState({
			currInsurance: i,
			currIndex: -1,
			errorMessage: "",
			editModal: !this.state.editModal,
		});
	};

	handleAddNewChange(property, e){
		let i = this.state.newInsurance;
		i[property] = e.target.value;
		this.setState({
			newInsurance: i,
		})
		return(this.state.newInsurance);
	}

	handleEditChange(property, e) {
		let i = this.state.currInsurance;
		i[property] = e.target.value;
		this.setState({
			currInsurance: i,
		});
	}

	renderTable() {
		if (this.state.isLoaded === true) {
			return (
				<MDBTable small style={{ textAlign: "center" }}>
					<MDBTableHead columns={this.state.data.columns} />
					<MDBTableBody rows={this.state.data.rows} />
				</MDBTable>
			);
		} else {
			return <div> </div>;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	addNewClick = () => {
		this.setState({
			addNewModal: !this.state.addNewModal,
		});
	};

	render() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						style={{ textAlign: "center", backgroundColor: "#5881C1", color: "#FFF", fontSize:30 }}
					>
						Payor Sources
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={"secondary"}
							data-tip={"Add New Payor Source"}
							onClick={this.addNewClick}
						>
							<MDBIcon icon="credit-card" style={{ fontSize: "2em" }} />
						</Button>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
					<ReactTooltip />
				</Card>
				{this.renderAddNewModal()}
				{this.renderEditModal()}
				{this.renderConfirmationModal()}
			</Container>
		);
	}
}
