import React from "react";
import OrderService from "../Security/OrderService/orderService";
import PropTypes from "prop-types";
import {
    Button,
    Col,
    Container,
    Fa,
    MDBBtn, MDBIcon,
    MDBModal,
    MDBModalHeader, Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    toast,
    ToastContainer
} from "mdbreact";
import {
    ColumnChooser,
    DragDropProvider,
    Grid, GroupingPanel, PagingPanel,
    Table,
    TableColumnResizing,
    TableColumnVisibility, TableGroupRow, TableHeaderRow, Toolbar,
    VirtualTable
} from "@devexpress/dx-react-grid-material-ui";
import ReactTooltip from "react-tooltip";
import {
    GroupingState,
    IntegratedGrouping, IntegratedPaging,
    IntegratedSorting, IntegratedSummary,
    PagingState,
    SortingState, SummaryState
} from "@devexpress/dx-react-grid";
import {formatDate} from "devextreme/localization";
import PqQuoteSelector from "../Patient/pqQuoteSelector";

const Root = (props) => <Grid.Root {...props} style={{height: "100%"}}/>;

const Content = ({row, column}) => (
    <span>
		<span>
			{column.title} : {row.value}
		</span>
		<span style={{fontWeight: "bold"}}>&nbsp; </span>
	</span>
);

export default class RecurringOrders extends React.Component {
    constructor(props) {
        super(props);


        const data = {
            columns: [
                {
                    title: "Id",
                    name: "id",
                },
                {
                    title: "Order Id",
                    name: "orderId",
                },
                {
                    title: "Patient Name",
                    name: "name",
                },
                {
                    title: "Frequency",
                    name: "frequency",
                },
                {
                    title: "Next Creation Date",
                    name: "nextDate",
                },
                {
                    title: "Suspend",
                    name: "suspend",
                },
            ],
            rows: [],
        }

        let defaultColumnWidths = [
            {columnName: "id", width: 150},
            {columnName: "orderId", width: 250},
            {columnName: "name", width: 300},
            {columnName: "frequency", width: 300},
            {columnName: "nextDate", width: 200},
            {columnName: "suspend", width: 300},
        ];

        this.state = {
            data: data,
            recurringOrders: [],
            defaultColumnWidths: defaultColumnWidths,
            isLoaded: false,
            currentOrder: {},
            suspendModalOpen: false
        }

    }

    static contextTypes = {
        currentUser: PropTypes.object,
    };


    componentDidMount() {
        this.retrieveOrders();
    }

    formatDate(date) {
        if (date == null || date === "") {
            return "";
        }
        return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "long",
            day: "2-digit",
        }).format(date);
    }

    retrieveOrders() {
        OrderService.getAllOrderFrequency().then(res => {
            this.buildNewResults(res)
        }).catch(e => {

        })
    }

    suspendOrderClicked(order) {
        this.setState({
            currentOrder: order,
            suspendModalOpen: true
        })
    }

    updateSuspendOrder(order) {
         order.nextDate = null;
         order.frequency = "None / Disabled"

        OrderService.updateOrderFrequency(order).then(r => {
            toast.success("Successfully suspended order frequency creation")
            this.retrieveOrders();
        }).catch(e => {
            toast.warning("There was an issue suspending the order frequency creation")
        })

        this.setState({
            currentOrder: {},
            suspendModalOpen: false,
            isLoaded: false
        })

    }

    renderPriorityIcon(order) {

        return (
            <MDBBtn size={"sm"} color={"secondary"} onClick={() => this.suspendOrderClicked(order)}>
                Suspend Order Creation
            </MDBBtn>
        )

        return <Fa icon={"arrow-circle-up"}/>;

    }

    buildNewResults(res) {
        let ary = [],
            dt = this.state.data;

        res.forEach((order) => {
            ary.push({
                id: order.id,
                orderId: order.orderId,
                name: order.name,
                frequency: order.frequency,
                nextDate: this.formatDate(order.nextDate),
                suspend: this.renderPriorityIcon(order),
            });
        });

        dt.rows = ary;

        this.setState({
            data: dt,
            isLoaded: true,
        });
    }

    renderLoadingSpinner() {
        return (
            <Container className="mt-5">
                <div style={{textAlign: "center", verticalAlign: "center"}}>
                    <Spinner multicolor/>
                </div>
            </Container>
        );
    }

    renderTableOrSpinner() {
        if (this.state.isLoaded === false) {
            return this.renderLoadingSpinner();
        }

        return (
            <div
                className={"purchasingGrid ordersListGrid"}
                style={{height: 0.75 * window.innerHeight, backgroundColor: "white"}}
            >
                {this.renderTable()}
            </div>
        );
    }


    renderSuspendModal() {
        let st = this.state,
            o = st.currentOrder;

        return (
            <Modal centered isOpen={this.state.suspendModalOpen} toggle={() => this.setState({suspendModalOpen: false})}>
                <ModalHeader style={{ backgroundColor: "#F93154", color: "white" }}>
                    Confirm Suspension
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "white" }}>
                   Are you sure you want to suspend frequency creation for this order with ID: {o.orderId}?
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "white" }}>
                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"success"}
                        data-tip={"Confirm Suspension"}
                        onClick={() => {this.updateSuspendOrder(o);}}>
                            <MDBIcon icon="check" style={{ fontSize: "2em" }} />
                    </Button>

                    <Button
                        style={{ float: "right" }}
                        floating
                        size="sm"
                        color={"danger"}
                        data-tip={"Cancel"}
                        onClick={() => this.setState({suspendModalOpen: false})}>
                        <MDBIcon icon="times" style={{ fontSize: "2em" }} />
                    </Button>

                </ModalFooter>
                <ReactTooltip />
            </Modal>
        )
    }

    renderTable() {
        const Cell = ({row, column, ...props}) => {

            if (column.name !== "orderId") {
                return (
                    <Table.Cell
                        {...props} />
                )
            }

            return (
                <Table.Cell
                    {...props}
                    onClick={(e) => {
                        if (e.ctrlKey === true) {
                            this.setState({
                                targetLink: row.id,
                            });

                            setTimeout(() => {
                                document.getElementById("orderListId").click();
                            }, 5);
                        } else {
                            this.props.history.push({
                                pathname: "/order/" + row.orderId,
                            });
                        }
                    }}
                    style={{
                        cursor: "pointer",
                    }}
                />
            );
        };

        if (this.state.isLoaded === true) {
            return (
                <Grid
                    style={{maxWidth: "1800px !important"}}
                    rows={this.state.data.rows}
                    rootComponent={Root}
                    columns={this.state.data.columns}
                >
                    <DragDropProvider/>
                    <SortingState
                        defaultSorting={[
                            {columnName: "id", direction: "desc"},
                        ]}
                    />
                    <IntegratedSorting
                        columnExtensions={this.state.data.integratedSortingColumnExtensions}
                    />
                    <GroupingState/>
                    <IntegratedGrouping/>
                    <PagingState defaultCurrentPage={0} pageSize={50}/>
                    <IntegratedPaging/>
                    <SummaryState
                        totalItems={[]}
                        groupItems={this.state.data.groupSummaryItems}
                    />
                    <IntegratedSummary/>
                    <VirtualTable
                        cellComponent={Cell}
                        height={"auto"}
                    />
                    <TableColumnResizing
                        defaultColumnWidths={this.state.defaultColumnWidths}
                    />
                    <TableHeaderRow showSortingControls/>
                    <PagingPanel/>
                    <TableGroupRow contentComponent={Content} showColumnsWhenGrouped/>
                    <Toolbar/>
                    <GroupingPanel/>
                </Grid>
            );
        } else {
            return <div/>;
        }
    }

    render() {
        return (
            <div>
                <ToastContainer
                    hideProgressBar={true}
                    newestOnTop={true}
                    autoClose={3000}
                    position={"top-right"}
                    style={{marginTop: "75px"}}
                />

                <div style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
                    {this.renderSuspendModal()}

                    <Row>
                        <Col size="12">{this.renderTableOrSpinner()}</Col>
                    </Row>
                </div>
            </div>
        )
    }

}