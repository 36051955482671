import api from "../api";

class UserRoleService {
    async getAllUserRoleQuotas() {
        return new Promise((res, reject) => {
            let params = {
                page: 0,
                size: 100,
                paged: false,
            };
            api
                .get("/userRoleTypes", params)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async updateUserRoleQuota(roleQuotaType) {
        return new Promise((res, reject) => {
            api
                .put("/userRoleTypes/" + roleQuotaType.id, roleQuotaType)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async getUserRoleQuotaById(id) {
        return new Promise((res, reject) => {
            api
                .get("/userRoleTypes/" + id)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

    async createUserRoleQuota(roleQuotaType) {
        return new Promise((res, reject) => {
            api
                .post("/userRoleTypes", roleQuotaType)
                .then((data) => {
                    res(data);
                })
                .catch((err) => {
                    reject();
                });
        });
    }

}

const userRoleService = new UserRoleService();

export default userRoleService;
