import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { isGroupTableRow } from "@devexpress/dx-grid-core";
import {
	Grid,
	Table,
	VirtualTable,
	TableHeaderRow,
	PagingPanel,
	TableGroupRow,
	GroupingPanel,
	DragDropProvider,
	Toolbar,
	TableSummaryRow,
	TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";

import {
	SummaryState,
	IntegratedSummary,
	GroupingState,
	SortingState,
	IntegratedSorting,
	IntegratedGrouping,
	PagingState,
	IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
	Plugin,
	Template,
	TemplateConnector,
	TemplatePlaceholder,
} from "@devexpress/dx-react-core";

import {
	Button,
	Container,
	Col,
	Row,
	Spinner,
	toast,
	ToastContainer,
	MDBIcon,
} from "mdbreact";
import OrderService from "../Security/OrderService/orderService";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import "./orderList.css";
import ReactTooltip from "react-tooltip";

const ItemCounter = () => (
	<Plugin name="ItemCounter">
		<Template
			name="tableCell"
			predicate={({ tableRow }) => isGroupTableRow(tableRow)}
		>
			{(params) => (
				<TemplateConnector>
					{({ getCollapsedRows }) => {
						const updatedParams = {
							...params,
							tableRow: {
								...params.tableRow,
								row: {
									...params.tableRow.row,
									collapsedRows: getCollapsedRows(params.tableRow.row) || [],
								},
							},
						};
						return <TemplatePlaceholder params={updatedParams} />;
					}}
				</TemplateConnector>
			)}
		</Template>
	</Plugin>
);

const Root = (props) => <Grid.Root {...props} style={{ height: "100%" }} />;

function getVal(row) {
	if (row.collapsedRows.length > 0) {
		return "Count: " + row.collapsedRows.length;
	} else {
		return "";
	}
}

const Content = ({ row, column }) => (
	<span>
		<span>
			{column.title} : {row.value}
		</span>
		<span style={{ fontWeight: "bold" }}>&nbsp; {getVal(row)}</span>
	</span>
);

const compareDate = (a, b) => {
	const priorityA = new Date(a);
	const priorityB = new Date(b);
	if (priorityA === priorityB) {
		return 0;
	}
	return priorityA < priorityB ? -1 : 1;
};

export default class collectionsView extends React.Component {
	constructor(props) {
		super(props);

		const groupColumns = [
			{ columnName: "status" },
			{ columnName: "approvalDate" },
			{ columnName: "serviceLocation" },
		];

		const data = {
			columns: [
				{
					title: "Id",
					name: "id",
				},
				{
					title: "Approval Date",
					name: "approvalDate",
				},
				{
					title: "Patient Name",
					name: "patientName",
				},
				{
					title: "Phone",
					name: "phone",
				},
				{
					title: "Service Location",
					name: "serviceLocation",
				},
				{
					title: "Patient Responsibility Amount",
					name: "patientResponsibilityAmount",
				},
				{
					title: "Sales Tax",
					name: "stateSalesTax",
				},
				{
					title: "Status",
					name: "status",
				},
			],
			rows: [],
			integratedSortingColumnExtensions: [
				{ columnName: "approvalDate", compare: compareDate },
			],
		};

		let defaultColumnWidths = [
			{ columnName: "id", width: 120 },
			{ columnName: "approvalDate", width: 140 },
			{ columnName: "patientName", width: 250 },
			{ columnName: "phone", width: 250 },
			{ columnName: "serviceLocation", width: 300 },
			{ columnName: "patientResponsibilityAmount", width: 300 },
			{ columnName: "stateSalesTax", width: 200 },
			{ columnName: "status", width: 150 },
		];

		this.state = {
			data: data,
			isLoaded: false,
			grouping: [],
			groupingColumns: groupColumns,
			defaultColumnWidths: defaultColumnWidths,
			groupSummaryItems: [],
		};

		this.getResults();
	}

	static contextTypes = {
		currentUser: PropTypes.object,
	};

	getResults() {
		return OrderService.getCollectionsViewNative()
			.then((res) => {
				this.buildNewResults(res);
			})
			.catch((err) => {
				this.setState({ isLoaded: true });
			});
	}

	downloadFunction() {
		this.clientCSV(this.state.data, "collections.csv");
	}

	//This will convert all the data in the grid to a csv file
	clientCSV(stateData, filename) {
		let result,
			ctr,
			keys = [],
			headers = [],
			columnDelimiter = ",",
			lineDelimiter = "\n",
			data;

		data = stateData.rows || null;
		if (data == null || !data.length) {
			return null;
		}

		stateData.columns.forEach((col) => {
			keys.push(col.name);
			headers.push(col.title);
		});

		let replace = ["patientName", "phone"];

		result = "";
		result += headers.join(columnDelimiter);
		result += lineDelimiter;

		data.forEach(function (item) {
			ctr = 0;
			keys.forEach(function (key) {
				if (ctr > 0) result += columnDelimiter;

				if (replace.indexOf(key) > -1 && item[key] != null) {
					result += item[key].replace(/,/g, " ");
				} else {
					result += item[key];
				}

				ctr++;
			});
			result += lineDelimiter;
		});

		this.downloadBlob(result, filename);
	}

	//this actually prompts the download
	downloadBlob(csvData, filename) {
		let blob = new Blob([csvData], {
			type: "application/csv;charset=utf-8;",
		});

		if (window.navigator.msSaveBlob) {
			// FOR IE BROWSER
			navigator.msSaveBlob(blob, filename);
		} else {
			// FOR OTHER BROWSERS
			let link = document.createElement("a"),
				csvUrl = URL.createObjectURL(blob);

			link.href = csvUrl;
			link.style = "visibility:hidden";
			link.download = filename;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	formatDate(date) {
		if (date == null || date === "") {
			return "";
		}
		return new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "short",
			day: "2-digit",
		}).format(date);
	}

	buildNewResults(res) {
		let ary = [],
			dt = this.state.data;

		res.forEach((val) => {
			ary.push({
				id: val.id,
				approvalDate: new Date(val.approvalDate).toLocaleDateString(),
				patientName: val.patientName,
				phone: val.phone,
				serviceLocation: val.serviceLocation,
				patientResponsibilityAmount: val.patientResponsibility,
				stateSalesTax: val.stateSalesTax,
				status: val.status,
			});
		});

		dt.rows = ary;

		toast.success("Found " + res.length + " Results");

		this.setState({
			data: dt,
			isLoaded: true,
		});
	}

	renderTable() {
		const Cell = ({ row, column, ...props }) => {
			return (
				<Table.Cell
					{...props}
					onClick={(e) => {
						if (e.ctrlKey === true) {
							this.setState({
								targetLink: row.id,
							});

							setTimeout(() => {
								document.getElementById("orderListId").click();
							}, 5);
						} else {
							this.props.history.push({
								pathname: "/order/" + row.id,
								state: { goto: "collections" },
							});
						}
					}}
					style={{
						cursor: "pointer",
					}}
				/>
			);
		};

		if (this.state.isLoaded === true) {
			return (
				<Grid
					style={{ maxWidth: "1800px !important" }}
					rows={this.state.data.rows}
					rootComponent={Root}
					columns={this.state.data.columns}
				>
					<DragDropProvider />
					<SortingState
						defaultSorting={[{ columnName: "approvalDate", direction: "desc" }]}
					/>
					<IntegratedSorting
						columnExtensions={this.state.data.integratedSortingColumnExtensions}
					/>
					<GroupingState
						grouping={this.state.groupingColumns}
						onGroupingChange={this.changeGrouping}
					/>
					<IntegratedGrouping />
					<PagingState defaultCurrentPage={0} pageSize={25} />
					<IntegratedPaging />
					<VirtualTable cellComponent={Cell} height={"auto"} />
					<TableColumnResizing
						defaultColumnWidths={this.state.defaultColumnWidths}
					/>
					<TableHeaderRow showSortingControls />
					<PagingPanel />
					<TableGroupRow contentComponent={Content} showColumnsWhenGrouped />
					<ItemCounter />
					<Toolbar />
					<GroupingPanel />
				</Grid>
			);
		} else {
			return <div />;
		}
	}

	renderLoadingSpinner() {
		return (
			<Container className="mt-5">
				<div style={{ textAlign: "center", verticalAlign: "center" }}>
					<Spinner multicolor />
				</div>
			</Container>
		);
	}

	renderTableOrSpinner() {
		if (this.state.isLoaded === false) {
			return this.renderLoadingSpinner();
		}
		return (
			<div className={"purchasingGrid ordersListGrid"} style={{height: 0.9 * window.innerHeight, backgroundColor: "white"}}>
				{this.renderTable()}
			</div>
		);
	}

	render() {
		return (
			<div>
				<ToastContainer
					hideProgressBar={true}
					newestOnTop={true}
					autoClose={3000}
					position={"top-right"}
					style={{ marginTop: "75px" }}
				/>

				<div style={{ maxWidth: "98%", marginLeft: "1rem" }}>
					<Row>
						<Col size="12">{this.renderTableOrSpinner()}</Col>
					</Row>
					<Link
						id="orderListId"
						to={{
							pathname: `/order/${this.state.targetLink}`,
							state: { goto: "collections" },
						}}
						target="_blank"
						rel="opener"
						style={{ display: "none" }}
						activeclassname="active"
					/>

					<div className={"fixed-bottom downloadCsvDiv"}>
						<Button
							className={"downloadCsvButton"}
							floating
							size="sm"
							color={"primary"}
							data-tip={"Download CSV"}
							onClick={this.clientCSV.bind(
								this,
								this.state.data,
								"Collections.csv"
							)}
						>
							<MDBIcon icon="download" style={{ fontSize: "2em" }} />
						</Button>

						<ReactTooltip />
					</div>
				</div>
			</div>
		);
	}
}
