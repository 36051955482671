import React from "react";
import {
	Button,
	Card,
	CardBody,
	Container,
	CardHeader,
	MDBIcon,
	MDBTable,
	MDBTableBody,
	MDBTableHead,
	MDBRow,
	MDBCol,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Input,
	InputFile,
	toast,
	ToastContainer,
	MDBBtn,
} from "mdbreact";
import VersionService from "../Security/VersionService/versionService";
import ReactTooltip from "react-tooltip";

export default class addVersion extends React.Component {
	constructor(props) {
		super(props);

		const data = {
			columns: [
				{
					label: "ID",
					field: "id",
					sort: "asc",
				},
				{
					label: "Version",
					field: "version",
					sort: "asc",
				},
			],
			rows: [],
		};

		this.state = {
			nVersion: "",
			data: data,
			isLoading: true,
			modal: false,
			currVersion: "",
			formActivePanel1: 1,
			srcLoading: false,
		};
	}

	componentDidMount() {
		this.retrieveVersions();
		this.retrieveCurrVersion();
	}

	retrieveCurrVersion() {
		return VersionService.getCurrentVersion()
			.then((res) => {
				this.setState({
					currVersion: res.version,
				});
			})
			.catch((err) => []);
	}

	retrieveVersions() {
		let t = this;
		return VersionService.getVersions()
			.then((res) => {
				let arr = [],
					d = this.state.data;
				res.forEach((v, index) => {
					arr.push({
						id: v[0],
						version: v[1],
						src: this.renderDownload(v[0], v[1]),
					});
				});

				d.rows = arr;
				t.setState({
					data: d,
					isLoading: false,
				});
			})
			.catch((err) => {
							console.log(err);
						});
	}

	renderDownload(id, version) {
		return (
			<div>
				<Button
					floating
					size="sm"
					data-tip="Download Build"
					onClick={() => this.handleDownload(id, version)}
				>
					<MDBIcon icon="download" style={{ fontSize: "2em" }} />
				</Button>
				<ReactTooltip />
			</div>
		);
	}

	handleDownload = (id, version) => [
		VersionService.downloadBuild(id)
			.then((res) => {
				let bytes = new Uint8Array(res),
					file = new Blob([bytes], { type: "application/octet-stream" }),
					link = document.createElement("a");

				link.href = window.URL.createObjectURL(file);
				link.download = version + "_build.zip";
				link.click();
				document.removeChild(link);
			})
			.catch((err) => {}),
	];

	modalToggle = () => {
		let m = this.state.modal;
		if (m) {
			this.setState({
				formActivePanel1: 1,
			});
		}
		this.setState({
			modal: !m,
		});
	};

	handleSrcSave = () => {
		let st = this.state,
			id = st.versionId;
		if (!st.file) {
			return;
		}
		this.setState((state) => ({
			srcLoading: !state.srcLoading,
		}));
		return VersionService.addSrc(id)
			.then((res) => {
				let d = st.data;
				d.rows.push({
					id: res.id,
					version: res.version,
					src: this.renderDownload(res.id, res.version),
				});
				this.setState((state) => ({
					modal: !state.modal,
					srcLoading: !state.srcLoading,
					data: d,
					formActivePanel1: 1,
				}));
				toast.success("Version Added!");
			})
			.catch((err) => {
				toast.warn("An error occurred while saving.");
			});
	};

	handleSave = () => {
		let st = this.state,
			v = st.nVersion;

		if (!v) {
			return;
		}
		return VersionService.addVersion({ version: v })
			.then((res) => {
				this.setState({
					formActivePanel1: 2,
					formActivePanel1Changed: true,
					versionId: res.id,
				});
			})
			.catch((err) => {
				toast.warn("An error occurred while saving.");
			});
	};

	handleEditChange(e) {
		this.setState({
			nVersion: e.target.value,
		});
	}

	renderFileInput() {
		return (
			<div>
				<InputFile
					type="file"
					className="form-control-file"
					name="file"
					getValue={(file) => {
						this.setState({ file: file });
					}}
				/>
			</div>
		);
	}

	renderModal() {
		let st = this.state,
			panel = st.formActivePanel1;
		return (
			<Modal
				isOpen={st.modal}
				toggle={() => {
					return;
				}}
			>
				<ModalHeader style={{ backgroundColor: "#90A4AE", color: "white" }}>
					Add Version
				</ModalHeader>
				<ModalBody style={{ backgroundColor: "white" }}>
					<MDBRow>
						{panel === 1 && (
							<MDBCol md="12">
								<h3 className="font-weight-bold pl-0 my-4">
									<strong>Version Number</strong>
								</h3>
								<div>
									<Input
										label="Version Number"
										onChange={this.handleEditChange.bind(this)}
									/>
								</div>
							</MDBCol>
						)}

						{panel === 2 && (
							<MDBCol md="12">
								<h3 className="font-weight-bold pl-0 my-4">
									<strong>Build File</strong>
								</h3>
								{this.renderFileInput()}
							</MDBCol>
						)}
					</MDBRow>
				</ModalBody>
				<ModalFooter style={{ backgroundColor: "white" }}>
					<Button
						style={{ float: "right" }}
						floating
						size="sm"
						color={"red"}
						data-tip={"Cancel"}
						onClick={this.modalToggle}
					>
						<MDBIcon icon="times" style={{ fontSize: "2em" }} />
					</Button>
					{st.srcLoading ? (
						<div
							style={{ marginLeft: "20px" }}
							className="spinner-border text-primary"
							role="status"
						>
							<span className="sr-only">Loading...</span>
						</div>
					) : (
						<Button
							style={{ float: "right" }}
							floating
							size="sm"
							color={panel === 1 ? "primary" : "success"}
							data-tip={panel === 1 ? "Next" : "Save"}
							onClick={panel === 1 ? this.handleSave : this.handleSrcSave}
						>
							<MDBIcon
								icon={panel === 1 ? "arrow-right" : "check"}
								style={{ fontSize: "2em" }}
							/>
						</Button>
					)}
				</ModalFooter>
				<ReactTooltip />
			</Modal>
		);
	}

	renderTable() {
		let st = this.state;
		if (st.isLoading) {
			return <div></div>;
		}
		return (
			<MDBTable style={{ textAlign: "center" }}>
				<MDBTableHead columns={st.data.columns} />
				<MDBTableBody rows={st.data.rows} />
			</MDBTable>
		);
	}

	render() {
		return (
			<Container className="mt-5">
				<ToastContainer
					hideProgressBar={false}
					newestOnTop={true}
					autoClose={3000}
				/>
				<Card>
					<CardHeader
						color={"blue-grey lighten-2"}
						style={{ textAlign: "center" }}
					>
						Current Version:{" "}
						{this.state.currVersion ? (
							this.state.currVersion
						) : (
							<div
								style={{ marginLeft: "20px" }}
								className="spinner-border text-primary"
								role="status"
							>
								<span className="sr-only">Loading...</span>
							</div>
						)}
						<MDBBtn
							floating
							style={{ float: "right" }}
							size="sm"
							data-tip="Add Version"
							onClick={this.modalToggle}
						>
							<MDBIcon icon="plus" />
						</MDBBtn>
					</CardHeader>
					<CardBody>{this.renderTable()}</CardBody>
				</Card>
				{this.renderModal()}
				<ReactTooltip />
			</Container>
		);
	}
}
